import { css } from 'emotion'

export default css`
& {
  .SelectReportsFieldsModal-title {
    margin-bottom: 10px;
  }
  .SelectReportsFieldsModal-fieldsList {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin-bottom: 10px;
      margin-right: 20px;
      &.line-break {
        width: 100%;
      }
    }
  }
}
`
