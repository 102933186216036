import { css } from 'emotion'

export default css`
  & {
    background: white;
    .FileBrowser-top {
      padding: 15px 0;
      @media (min-width: 992px) {
        display: flex;
        justify-content: space-between;
      }
      .FileBrowser-breadcrumbs {
        padding: 0 15px;
        margin-bottom: 10px;
        @media (min-width: 992px) {
          margin-bottom: 0px;
        }
        > *:first-child i {
          font-size: 16px;
          @media (min-width: 992px) {
            font-size: 30px;
          }
        }
        > span:not(:first-child) {
          font-size: 12px;
          @media (min-width: 992px) {
            .ant-breadcrumb-link {
              font-size: initial;
            }
          }
        }
      }

      .FileBrowser-actions {
        padding: 0 15px;
        button {
          margin-left: 5px;
        }
      }
    }
    .FileBrowser-tableWrapper {
      &.is-loading {
        text-align: center;
        min-height: 300px;
      }
      tr,
      th {
        td {
          /*padding: 5px 8px;*/
        }
      }
    }
    .FileBrowser-row-icon {
      width: 1px;
      font-size: 20px;
      cursor: pointer;
    }
    .FileBrowser-row-name {
      cursor: pointer;
    }
    .FileBrowser-row-date{
    }
  }
`
