import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import withEnhancedRouter from 'src/components/withEnhancedRouter'
import EmailsTabThreads from './EmailsTabThreads'
import EmailsTabThread from './EmailsTabThread'
import AttachmentActions from './AttachmentActions'

export const EmailsTab = props => {
  const { tabData = {}, paperId, formDisabled } = props
  const { messageThreadId } = tabData
  return (
    <article className="EmailsTab">
      {!messageThreadId && <EmailsTabThreads {...props} />}
      {messageThreadId && (
        <EmailsTabThread
          messageThreadId={messageThreadId}
          attachmentActions={props => {
            if (formDisabled) return null
            return <AttachmentActions {...props} paperId={paperId} />
          }}
        />
      )}
    </article>
  )
}

export default compose(
  withEnhancedRouter,
  withPropsOnChange(['match'], ({ match }) => ({
    tabData: match.search.tabData,
  })),
)(EmailsTab)
