import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { Row, Col, Badge } from 'antd'
import $ from 'jquery'
import FormattedDate from 'src/components/format/FormattedDate'
import Mobile from 'src/components/layout/Responsive/Mobile'
import Desktop from 'src/components/layout/Responsive/Desktop'
import './EmailHeader.style'

const EmailHeader = props => {
  const {
    activePanels = [],
    config = { isPaperIdVisible: true },
    email = {},
    senderName,
    strippedBody,
    onReplyBtnClick,
  } = props
  const isActive = activePanels.includes(String(email.id))

  return (
    <article className="EmailHeader">
      <Row className="EmailHeader-row" type="flex" gutter={8}>
        <Col className="EmailHeader-sender">
          {config.isPaperIdVisible && (
            <span className="EmailHeader-sender-badge">
              {email.paperId && (
                <Badge
                  count={`Pr. ${email.paperId}`}
                  style={{
                    backgroundColor: '#fff',
                    color: '#999',
                    boxShadow: '0 0 0 1px #d9d9d9 inset',
                  }}
                />
              )}
            </span>
          )}
          <b style={{ display: 'inlineBlock' }}>{senderName}</b>
          <br/>
          <Mobile>
            <FormattedDate date={email.date} format={'D MMM YY - HH:mm'}/>
            <Col className="EmailHeader-contentExtract">
              {!isActive && strippedBody}
            </Col>
          </Mobile>
        </Col>
        <Desktop>
          {[
            <Col className="EmailHeader-contentExtract">
              {!isActive && strippedBody}
            </Col>,
            <Col className="EmailHeader-right">
              <div className="EmailHeader-right-date">
                <FormattedDate date={email.date} format={'D MMM YY - HH:mm'}/>
              </div>
            </Col>,
          ]}
        </Desktop>
      </Row>
    </article>
  )
}

export default compose(
  withPropsOnChange(['email'], ({ email }) => {
    if (!email) return {}
    let senderName = email.from.email
    const agent = email.from.agent
    if (agent) {
      senderName = `${agent.firstname} ${agent.lastname}`
    }

    const strippedBody = $('<div/>')
      .html(email.body)
      .text()

    return { senderName, strippedBody }
  }),
)(EmailHeader)
