import React from 'react'
import _ from 'lodash'
import { Input, Button, Row, Col } from 'antd'
import { connect } from 'react-redux'
import { compose, withHandlers, defaultProps } from 'recompose'
import { openEditFormulaModal } from 'src/components/modals/EditFormulaModal/index'
import style from './FormulaInput.style'

const TextArea = Input.TextArea

const FormulaInput = props => {
  const { handleBtnClick } = props
  return (
    <div className={style}>
      <TextArea autosize={true} rows={1} {...props} readOnly/>
      <Button className="FormulaInput-button" size="small" type="dashed" onClick={handleBtnClick}>
        Modifica
      </Button>
    </div>
  )
}

export default compose(
  connect(null, {
    openEditFormulaModal,
  }),
  defaultProps({
    onChange: () => {},
  }),
  withHandlers({
    handleBtnClick: props => async () => {
      const value = _.get(props, 'value')
      const result = await props.openEditFormulaModal({ initialValue: value })
      if (!_.isError(result)) {
        props.onChange(result)
      }
    },
  }),
)(FormulaInput)
