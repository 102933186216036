import React from 'react'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { Icon, Menu, Dropdown, Button, Checkbox as TheCheckbox } from 'antd'
import { bindActionCreators } from 'redux'
import { select } from '@rematch/select'
import _ from 'lodash'
import withConnectedForm from '../../../../../components/form/withConnectedForm'
import withReduxFormProps from 'src/components/form/withReduxFormProps'
import FormItem from 'src/components/form/FormItem'
import { getPath } from '../../../../../store/selectors'
import { openAddClientToPaperModal } from '../../../../../components/modals/AddClientToPaperModal'
import { openEditClientModal } from '../../../../../components/clients/EditClientModal'
import SimpleTable from '../../../../../components/tables/SimpleTable/SimpleTable'
import SubmitButton from 'src/components/form/SubmitButton'
import ResetButton from 'src/components/form/ResetButton'
import Spinner from 'src/components/Spinner'
import Link from 'src/components/navigation/Link'
import style from './ClientsTab.style'

const Checkbox = withReduxFormProps(TheCheckbox)

const query = {
  filter: {
    fields: ['clients', 'id'],
    populate: [
      {
        property: 'clients.clientId',
        model: 'Client',
        on: 'client',
      },
    ],
  },
}

export const ClientsTab = props => {
  const {
    paper,
    handleAddClientBtnClick,
    readOnly,
    submitting,
    handleSubmit,
    fetchData,
    columns,
    formDisabled,
  } = props

  return (
    <section className={style}>
      <Spinner fetchData={fetchData}>
        <form onSubmit={handleSubmit}>
          {!formDisabled && (
            <Button
              onClick={handleAddClientBtnClick}
              icon="plus"
              size="small"
              style={{ marginBottom: '10px' }}
            >
              Aggiungi
            </Button>
          )}
          <section className="ClientsTab-list">
            <SimpleTable
              {...props}
              data={paper && paper.clients}
              columns={columns}
              pagination={false}
            />
          </section>
          {!readOnly && (
            <section className="FormActions">
              <SubmitButton />
              <ResetButton />
            </section>
          )}
        </form>
      </Spinner>
    </section>
  )
}

export default compose(
  connect(
    state => ({
      initialValues: getPath(state)('papers.tabs.clients.paper'),
      isAdmin: select.agents.isAdmin(state),
    }),
    dispatch => ({
      openAddClientToPaperModal: bindActionCreators(
        openAddClientToPaperModal,
        dispatch,
      ),
      openEditClientModal: bindActionCreators(openEditClientModal, dispatch),
      find: dispatch.papers.findOne,
      update: dispatch.papers.update,
      createLogWithDiff: dispatch.actionLogs.createWithDiff,
    }),
  ),
  withConnectedForm('clientsTab'),
  withPropsOnChange(['isAdmin'], ({ isAdmin }) => {
    return {
      columns: _.compact([
        {
          displayName: 'Cliente',
          render(paperClient) {
            const client = (paperClient || {}).client
            return client ? `${client.firstname} ${client.lastname}` : null
          },
        },
        { displayName: 'Tipo', field: 'type' },
        {
          displayName: 'Codice fiscale',
          field: 'fiscalCode',
          render: (ignore, paperClient) =>
            _.get(paperClient, 'client.fiscalCode'),
        },
        {
          displayName: 'Contatti',
          field: 'id',
          render(ignore, paperClient) {
            const client = paperClient.client || {}
            const email = client.email
            const phone = (() => {
              if (!client.phones || !client.phones.length) return null

              const clientPhone =
                _.find(client.phones, { isPrimary: true }) || client.phones[0]
              return clientPhone.phone
            })()

            return _.compact([
              email && (
                <span>
                  <b>Email:</b> {email}
                </span>
              ),
              email && <br />,
              phone && (
                <span>
                  <b>Telefono:</b> {phone}
                </span>
              ),
            ])
          },
        },
        isAdmin && {
          displayName: 'Trattamento dei dati',
          render(paperClient, value, index, props) {
            return (
              <section>
                <div>
                  <label
                    style={{
                      margin: 'none',
                    }}
                  >
                    <FormItem
                      name={`clients[${index}].allowPersonalDataProcessing`}
                      normalize={value => (value ? new Date() : null)}
                      style={{
                        margin: '0 5px 0 0',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                      component={Checkbox}
                    />
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      Autorizza finalitá commerciali
                    </div>
                  </label>
                </div>
                <div>
                  <label
                    style={{
                      margin: 'none',
                    }}
                  >
                    <FormItem
                      name={`clients[${index}].allowThirdPartyPersonalDataProcessing`}
                      normalize={value => (value ? new Date() : null)}
                      style={{
                        margin: '0 5px 0 0',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                      component={Checkbox}
                    />
                    <div
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      Autorizza finalitá commerciali di terzi
                    </div>
                  </label>
                </div>
              </section>
            )
          },
        },
        {
          displayName: '',
          render(paperClient) {
            const { handleDeleteBtnClick, handleModifyBtnClick } = this.props

            const actionsDropdown = (
              <Menu>
                <Menu.Item key="0">
                  <div onClick={() => handleModifyBtnClick(paperClient.client)}>
                    Modifica dettagli anagrafica
                  </div>
                </Menu.Item>
                <Menu.Item key="1">
                  <Link to={`/clients/edit/${paperClient.client.id}`}>
                    Visualizza
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <div onClick={() => handleDeleteBtnClick(paperClient)}>
                    Elimina
                  </div>
                </Menu.Item>
              </Menu>
            )
            return (
              <Dropdown overlay={actionsDropdown} trigger={['click']}>
                <a>
                  <Icon type="setting" style={{ fontSize: 16 }} />
                </a>
              </Dropdown>
            )
          },
        },
      ]),
    }
  }),
  withHandlers({
    fetchData: props => () => {
      const { find, paperId } = props

      return find(
        {
          ...query,
          filter: {
            ...query.filter,
            where: { id: paperId },
          },
        },
        { basePath: 'tabs.clients' },
      )
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e && e.preventDefault()
      const {
        formValues,
        dispatchSubmit,
        paperId,
        update,
        fetchData,
        pristineValues,
        createLogWithDiff,
      } = props
      await dispatchSubmit(update({ id: paperId, ...formValues }))

      await createLogWithDiff({
        data: {
          context: 'paper',
          type: 'clientsTab',
          paperId,
        },
        previous: pristineValues,
        current: formValues,
      })

      return fetchData()
    },
  }),
  withHandlers({
    handleDeleteBtnClick: props => paperClient => {
      const { array, formValues: { clients = [] } = {} } = props
      const index = clients.indexOf(paperClient)
      array.remove('clients', index)
    },
    handleAddClientBtnClick: props => async e => {
      const { openAddClientToPaperModal, array, handleSubmit } = props
      const paperClient = await openAddClientToPaperModal()
      if (paperClient) {
        array.push('clients', paperClient)
        return handleSubmit()
      }
    },
    handleModifyBtnClick: props => async client => {
      const { openEditClientModal, fetchData } = props
      const savedClient = await openEditClientModal(client)
      if (savedClient) {
        return fetchData()
      }
    },
  }),
  withPropsOnChange(['formValues'], props => {
    return {
      paper: props.formValues,
    }
  }),
)(ClientsTab)
