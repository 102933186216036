import _fp from 'lodash/fp'
import {
  CLEAR_STATE_AT_PATH,
} from '../actions/index'


export default (state = {}, { type, payload, meta } = {}) => {
  switch (type) {
    case CLEAR_STATE_AT_PATH:
      const { path } = payload
      return _fp.omit(path, state)
    default:
      return state
  }
}
