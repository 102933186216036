import React from 'react'
import DefaultLayout from 'src/components/layout/DefaultLayout'
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  Input,
  Card,
  Divider,
  Row,
  Col,
  List,
  Icon,
  Modal,
  Checkbox,
  InputNumber,
  message,
} from 'antd'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { select } from '@rematch/select'
import { push } from 'react-router-redux'
import { Helmet } from 'react-helmet'
import FormItem from 'src/components/form/FormItem'
import withConnectedForm from 'src/components/form/withConnectedForm'
import Spinner from 'src/components/Spinner'
import ProductTypeSelect from 'src/components/form/ProductTypeSelect'
import RateTypeSelect from 'src/components/form/RateTypeSelect'
import PurposesSelect from 'src/components/form/PurposesSelect'
import AnnualInstalmentsSelect from 'src/components/form/AnnualInstalmentsSelect'
import ExpensesRow from 'src/components/expenses/ExpensesRows'
import SubmitButton from 'src/components/form/SubmitButton'
import FinancialInstitutionSelect from '../../components/form/FinancialInstitutionSelect/FinancialInstitutionSelect'
import FileBrowser from 'src/components/FileBrowser'
import IndexingParameterSelect from '../../components/form/IndexingParameterSelect'
import PageTitle from '../../components/layout/PageTitle'
import SpreadsTable from './SpreadsTable'
import PremiumsTable from './PremiumsTable'
import LazyLoad from 'src/components/LazyLoad'

const AgeRestriction = ({ onChange, checked, formValues, formError }) => (
  <div>
    <label>
      <Checkbox onChange={onChange} checked={checked} /> Attivo
    </label>
    <Row type="flex" gutter={16}>
      <Col>
        <FormItem
          label="Etá minima"
          name="restrictions.age.min"
          component={InputNumber}
          disabled={!_.get(formValues, 'restrictions.age.active')}
          errors={formError}
        />
      </Col>
      <Col>
        <FormItem
          label="Etá massima"
          name="restrictions.age.max"
          component={InputNumber}
          disabled={!_.get(formValues, 'restrictions.age.active')}
          errors={formError}
        />
      </Col>
    </Row>
  </div>
)

const ProductView = props => {
  const {
    product = {},
    id,
    formValues = {},
    fetchData,
    formError,
    indexingParameterError,
    handleSubmit,
    handleExpensesChange,
    features,
    isAdmin,
    handleDeleteBtnClick,
    modality,
    title,
    productType,
    isEdit,
  } = props

  return (
    <DefaultLayout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Spinner fetchData={fetchData}>
        {({ loaded, error }) => {
          // if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>
          return (
            <>
              <PageTitle title={title} />
              <Row type="flex" gutter={8}>
                <Col sm={24} md={isAdmin ? 20 : 24}>
                  <Card style={{ marginBottom: 20 }}>
                    <form onSubmit={handleSubmit}>
                      <FormItem
                        label="Nome"
                        name="name"
                        component={Input.TextArea}
                        withReduxFormProps={true}
                        layout="horizontal"
                        required={true}
                      />
                      <FormItem
                        label="Descrizione"
                        name="description"
                        component={Input.TextArea}
                        withReduxFormProps={true}
                        layout="horizontal"
                      />
                      <FormItem
                        label="Istituto Finanziario"
                        name="financialInstitutionId"
                        component={FinancialInstitutionSelect}
                        withReduxFormProps={true}
                        layout="horizontal"
                        required={true}
                      />
                      <FormItem
                        label="Prodotto Finanziario"
                        name="productTypeId"
                        component={ProductTypeSelect}
                        withReduxFormProps={true}
                        layout="horizontal"
                        required={true}
                      />
                      <FormItem
                        label="Tasso"
                        name="rateType"
                        component={RateTypeSelect}
                        withReduxFormProps={true}
                        layout="horizontal"
                        required={true}
                      />
                      <FormItem
                        label="Frequenza rate"
                        name="annualInstalments"
                        component={AnnualInstalmentsSelect}
                        withReduxFormProps={true}
                        layout="horizontal"
                        required={true}
                      />
                      <FormItem
                        label="Finalitá"
                        name="purposeIds"
                        component={PurposesSelect}
                        initialQuery={{
                          filter: {
                            order: 'name ASC',
                            skipDeleted: true,
                            where: {
                              productTypeId: (formValues || {}).productTypeId,
                            },
                          },
                        }}
                        forceRefetch={true}
                        withReduxFormProps={true}
                        layout="horizontal"
                        mode="multiple"
                        required={true}
                      />
                      <FormItem
                        label="Parametro indicizzazione"
                        name="indexingParameter"
                        component={IndexingParameterSelect}
                        layout="horizontal"
                        type="product"
                        withReduxFormProps={true}
                        error={indexingParameterError}
                        errors={formError}
                      />
                      <FormItem
                        label="Età consentite"
                        name="restrictions.age.active"
                        component={AgeRestriction}
                        formValues={formValues}
                        formError={formError}
                        layout="horizontal"
                        errors={formError}
                      />
                      <Divider />
                      <section>
                        <h3 className="TitleSection">Spese</h3>
                        <ExpensesRow
                          expenses={formValues.expenses}
                          formError={formError}
                          onValuesChange={handleExpensesChange}
                          valuesType="formula"
                          layout="stack"
                          type="generic"
                        />
                      </section>

                      <section>
                        <h3 className="TitleSection">Assicurazioni</h3>
                        <ExpensesRow
                          expenses={formValues.expenses}
                          formError={formError}
                          onValuesChange={handleExpensesChange}
                          valuesType="formula"
                          layout="stack"
                          type="insurance"
                        />
                      </section>
                      {isAdmin && (
                        <div className="FormActions">
                          <SubmitButton />
                        </div>
                      )}
                    </form>
                  </Card>
                  {isEdit && (
                    <Card title="Documenti" style={{ marginBottom: 20 }}>
                      <LazyLoad>
                        <FileBrowser context="product" productId={id} modality={modality} />
                      </LazyLoad>
                    </Card>
                  )}

                  {features.hasRates && isEdit && (
                    <Card title="Tassi" style={{ marginBottom: 20 }}>
                      <LazyLoad>
                        <SpreadsTable
                          /*form={form}*/
                          productId={id}
                          readOnly={true}
                        />
                      </LazyLoad>
                    </Card>
                  )}
                  {features.hasInsurancePremiums && isEdit && (
                    <Card title="Premi" style={{ marginBottom: 20 }}>
                      <LazyLoad>
                        <PremiumsTable
                          /*form={form}*/
                          productId={id}
                          readOnly={true}
                        />
                      </LazyLoad>
                    </Card>
                  )}
                </Col>
                {isAdmin && isEdit && (
                  <Col sm={24} md={4}>
                    <Card>
                      <List itemLayout="horizontal">
                        {features.hasRates && (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Link
                                  to={{
                                    pathname: `/products/spreads/${product.id}`,
                                  }}
                                >
                                  <Icon type="table" /> Modifica tassi
                                </Link>
                              }
                            />
                          </List.Item>
                        )}
                        {features.hasInsurancePremiums && (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Link
                                  to={{
                                    pathname: `/products/premiums/${product.id}`,
                                  }}
                                >
                                  <Icon type="table" /> Modifica Premi
                                </Link>
                              }
                            />
                          </List.Item>
                        )}
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <a onClick={handleDeleteBtnClick}>
                                <Icon type="delete" /> Elimina
                              </a>
                            }
                          />
                        </List.Item>
                      </List>
                    </Card>
                  </Col>
                )}
              </Row>
            </>
          )
        }}
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  withPropsOnChange(['match'], ({ match }) => ({
    id: _.get(match, 'params.id'),
    isEdit: !!_.get(match, 'params.id'),
  })),
  connect(
    state => ({
      product: select.products.get(state),
      initialValues: select.products.get(state),
      isAdmin: select.agents.isAdmin(state),
    }),
    dispatch => ({
      findOne: dispatch.products.findOne,
      create: dispatch.products.create,
      update: dispatch.products.update,
      deleteProduct: dispatch.products.delete,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withPropsOnChange(['isAdmin'], ({ isAdmin }) => ({
    readOnly: !isAdmin,
  })),
  withPropsOnChange(['isAdmin'], ({ isAdmin }) => ({
    modality: isAdmin ? 'modify' : 'view',
  })),
  withConnectedForm('productView'),
  withPropsOnChange(['product'], ({ product }) => ({
    features: _.get(product, 'productType.features', {}),
  })),

  withPropsOnChange(['id', 'product'], ({ id, product }) => {
    return {
      title:
        id && product
          ? `Modifica Scheda Prodotto: ${product.internalId} - ${product.name}`
          : 'Aggiungi Scheda Prodotto',
    }
  }),
  withPropsOnChange(['formError'], ({ formError }) => ({
    indexingParameterError: _.find(formError, (ignore, key) => key.includes('indexingParameter')),
  })),
  withHandlers({
    fetchData: props => () => {
      const { findOne, id } = props
      if (!id) return null

      return findOne({
        filter: {
          where: { id },
          include: [
            {
              relation: 'rates',
              scope: {
                include: ['durations', 'amounts'],
              },
            },
            {
              relation: 'productType',
              scope: {
                // fields: ['id', 'name', 'displayName'],
              },
            },
          ],
          populate: [
            {
              property: 'expenses.insuranceId',
              model: 'Product',
              on: 'insurance',
            },
          ],
        },
      })
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const { formValues, dispatchSubmit, create, update, id, push, fetchData } = props
      if (id) {
        await dispatchSubmit(update({ ...formValues, id }))
        return fetchData()
      }

      const { id: newId } = await dispatchSubmit(create(formValues))

      return push(`/products/view/${newId}`)
    },
    handleExpensesChange: ({ formValues: { expenses = [] } = {}, change }) => newExpenses => {
      change('expenses', newExpenses)
    },
    handleDeleteBtnClick: props => () => {
      const { id, deleteProduct, push } = props
      Modal.confirm({
        title: 'Elimina prodotto',
        content: 'Sei sicuro?',
        onOk: async () => {
          await deleteProduct({ id })
          await push('/products/list')
          message.success('Prodotto eliminato')
        },
      })
    },
  }),
)(ProductView)
