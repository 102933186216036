import registerTransportsMiddlewares from './registerTransportsMiddlewares'
import bindWindowResize from './bindWindowResize'
import sso from './sso'

const bootScripts = async store => {
  registerTransportsMiddlewares(store)
  bindWindowResize(store)
  sso(store)
}

export default bootScripts
