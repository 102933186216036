import React from 'react'
import { compose, withHandlers, withPropsOnChange, withProps, withState } from 'recompose'
import { Card, message, Modal, notification, Progress } from 'antd'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import { select } from '@rematch/select'
import DefaultLayout from 'src/components/layout/DefaultLayout'
import EditClientForm from 'src/components/clients/EditClientForm'
import Spinner from 'src/components/Spinner'
import SmartTable from 'src/components/tables/SmartTable/SmartTableClass'
import _get from 'lodash/get'
import PageTitle from '../../components/layout/PageTitle'

export const ClientEdit = props => {
  const {
    fetchData,
    handleSubmit,
    title,
    papers,
    papersColumns,
    papersInitialQuery,
    papersCount,
    countPapers,
    findPapers,
    handleFetched,
    isAdd,
    isReady,
  } = props

  return (
    <DefaultLayout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Spinner fetchData={fetchData}>
        {(isAdd || isReady) && <PageTitle title={title} />}
        <Card style={{ marginBottom: 20 }}>
          <EditClientForm {...props} onSubmit={handleSubmit} onFetched={handleFetched} />
        </Card>
        {!isAdd && (
          <Card title="Pratiche cliente" style={{ marginBottom: 20 }}>
            <SmartTable
              data={papers}
              columns={papersColumns}
              initialQuery={papersInitialQuery}
              count={papersCount}
              countMethod={countPapers}
              fieldSelection={true}
              findMethod={findPapers}
            />
          </Card>
        )}
      </Spinner>
    </DefaultLayout>
  )
}

const basePathOptions = { basePath: 'clientEdit' }

export default compose(
  withPropsOnChange(['match'], ({ match }) => ({
    id: _.get(match, 'params.id'),
    isAdd: !_.get(match, 'params.id'),
  })),
  connect(
    (state, props) => ({
      client: select.clients.get(state),
      papers: select.papers.getAll(state, basePathOptions),
      papersCount: select.papers.count(state, basePathOptions),
    }),
    dispatch => ({
      push: (...props) => dispatch(push(...props)),
      findPapers: args => dispatch.papers.find(args, basePathOptions),
      countPapers: args => dispatch.papers.count(args, basePathOptions),
    }),
  ),
  withProps(props => {
    return {
      papersInitialQuery: {
        filter: {
          where: {
            'clients.clientId': props.id,
          },
          fields: ['id', 'financialInstitutionId', 'productId', 'productTypeId'],
          skipDeleted: true,
          include: [
            'progress',
            {
              relation: 'product',
              scope: {
                //fields: ['name', 'financialInstitution'],
                include: {
                  relation: 'financialInstitution',
                  scope: {
                    fields: ['name'],
                  },
                },
              },
            },
            'productType',
          ],
          populate: [
            {
              model: 'Role',
              property: 'agents.roleId',
              on: 'role',
            },
            {
              model: 'Agent',
              property: 'agents.agentId',
              on: 'agent',
            },
            {
              property: 'currentProgress.progressId',
              model: 'Progress',
              on: 'progress',
            },
          ],
        },
      },
      papersColumns: [
        {
          displayName: 'ID',
          field: 'internalId',
          sortable: true,
        },
        {
          displayName: 'Ultimo Cambio Stato',
          field: 'currentProgress.date',
          type: 'date',
          sortable: true,
          defaultSortOrder: 'descend',
        },
        {
          displayName: 'Cliente',
          field: 'client',
          render(client = {}, record) {
            return client && `${client.firstname} ${client.lastname}`
          },
        },
        {
          displayName: 'Agente',
          field: 'agent',
          fields: ['agents'],
          render(ignored, record) {
            const paperAgent = (record.agents || []).find(a => {
              return a.role && a.role.name === 'agent'
            })
            if (!paperAgent) return
            const { agent = {} } = paperAgent

            return `${agent.firstname} ${agent.lastname}`
          },
        },
        {
          displayName: 'Stato pratica',
          field: 'currentProgress',
          render(ignored, paper) {
            return (
              <div>
                <span>{_get(paper, 'currentProgress.progress.displayName')}</span>
                <br />
                <Progress percent={_get(paper, 'currentProgress.progress.value')} strokeWidth={5} />
              </div>
            )
          },
        },
        {
          displayName: 'Importo',
          field: 'amount',
          type: 'currency',
          sortable: true,
        },
        { displayName: 'Tipo prodotto', field: 'productType.displayName' },
        { displayName: 'Istituto', field: 'product.financialInstitution.name' },
        { displayName: 'Prodotto', field: 'product.name' },
        {
          displayName: '',
          type: 'actions',
          itemsType: 'link',
          actions: [
            {
              label: 'Visualizza',
              props: {
                href: ({ record }) => `/#/papers/view/${record.id}`,
              },
            },
          ],
        },
      ],
    }
  }),
  withState('isReady', 'setReady', false),
  withPropsOnChange(['id', 'client'], ({ id, client }) => {
    return {
      title:
        id && client
          ? `Modifica anagrafica cliente: ${client.internalId} - ${client.firstname} ${
              client.lastname
            }`
          : 'Aggiungi anagrafica cliente',
    }
  }),
  withHandlers({
    handleSubmit: props => (e, { id }) => {
      const { isAdd, push } = props
      if (isAdd) {
        return push(`/clients/edit/${id}`)
      }
    },
    handleFetched: props => () => {
      props.setReady(true)
    },
  }),
)(ClientEdit)
