import React, { Fragment } from 'react'
import { Table, Icon, Button, Divider } from 'antd'
import { compose, withPropsOnChange, defaultProps } from 'recompose'
import _ from 'lodash'
import classnames from 'classnames'
import { cx } from 'emotion'
import FormattedDate from 'src/components/format/FormattedDate'
import Currency from 'src/components/format/Currency'
import Percentage from 'src/components/format/Percentage'
import style from './SimpleTable.style'

const { Column } = Table

const createDefaultSorter = field => (aObj, bObj) => {
  const a = aObj[field]
  const b = bObj[field]

  return a - b
}

const renderButtonAction = ({ actionProps, actionLabel }) => {
  return (
    <Button size="small" {...actionProps}>
      {actionProps.children || actionLabel}
    </Button>
  )
}

const renderLinkAction = ({ actionProps, actionLabel }) => {
  return <a {...actionProps}>{actionProps.children || actionLabel}</a>
}

const renderAction = ({ action, itemsType, value, record, props }) => {
  const createProp = prop => {
    if (typeof prop === 'function') return prop({ value, record, props })

    return prop
  }
  if (action.render) {
    return action.render({ value, record, props })
  }

  const actionProps = _.mapValues(action.props, createProp)
  const actionLabel = createProp(action.label)

  if (itemsType === 'button') return renderButtonAction({ actionProps, actionLabel })
  if (itemsType === 'link') return renderLinkAction({ actionProps, actionLabel })

  return () => {}
}

const renderPlainActions = ({ actions, itemsType = 'button', props }) => {
  return (value, record, index) => {
    const filteredActions = actions.filter(a => {
      if (typeof a.visible === 'function') return a.visible({ record, props })()

      return true
    })
    return (
      <div className="SimpleTable-cell-actions">
        {filteredActions.map((action, index) => {
          return [
            renderAction({ action, itemsType, value, record, index, props }),
            index + 1 !== filteredActions.length && <Divider type="horizontal" />,
          ]
        })}
      </div>
    )
  }
}

const createActionsRender = ({ column, props }) => {
  const actions = column.actions || []
  const itemsType = column.itemsType || 'button'
  return renderPlainActions({ actions, itemsType, props })
}

const createRender = ({ column, props }) => {
  let render
  if (typeof column.render === 'function') {
    render = (...args) => column.render.call({ props }, ...args, props)
  } else if (column.type === 'date') {
    render = value => <FormattedDate date={value} />
  } else if (column.type === 'currency') {
    render = value => <Currency value={value} />
  } else if (column.type === 'percentage') {
    render = value => <Percentage value={value} />
  } else if (column.type === 'actions') {
    render = createActionsRender({ column, props })
  }

  return render
}

export const SimpleTable = props => {
  const {
    data,
    locale,
    pagination,
    paginationOpts,
    onChange,
    loading,
    theColumns,
    rowSelection,
    className,
  } = props

  return (
    <div className={cx(style, className)}>
      <Table
        dataSource={data}
        locale={locale}
        pagination={
          _.isObject(pagination) ? { ...pagination, ...paginationOpts, size: 'small' } : pagination
        }
        onChange={onChange}
        loading={loading}
        size="small"
        bordered
        rowSelection={rowSelection}
      >
        {theColumns.map((column, index) => (
          <Column
            key={column.field || index}
            dataIndex={column.field}
            title={column.displayName}
            render={column.render}
            {...column}
          />
        ))}
      </Table>
    </div>
  )
}

export default compose(
  defaultProps({ pagination: {} }),
  withPropsOnChange(['columns'], props => ({
    theColumns: (props.columns || []).map(column => {
      const sorter =
        column.sorter || (column.sortable && (column.sorter || createDefaultSorter(column.field)))
      return {
        ...column,
        render: createRender({ column, props }),
        sorter,
        className: classnames(
          {
            'is-sortable': sorter,
          },
          column.className,
        ),
      }
    }),
  })),
)(SimpleTable)
