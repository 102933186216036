import Promise from 'bluebird'
import { EventEmitter } from 'events'
import { startSubmit, stopSubmit } from 'redux-form'
import { stopSubmit as enhancedStopSubmit } from 'src/store/actions/index'
import {
  FORM_SUBMIT_REQUESTED,
  FORM_SUBMIT_FULFILLED,
  FORM_SUBMIT_FAILED,
} from '../actions/index'

export default function formResultMiddleware({ dispatch, getState }) {
  return next => {
    const promises = {}
    const eventBus = new EventEmitter()
    return async (action = {}) => {
      const { type, meta = {}, payload } = action
      const { form } = meta
      if (type === FORM_SUBMIT_REQUESTED) {
        const payloadIsAPromise = payload && typeof payload.then === 'function'

        const thePromise = promises[form] = new Promise((resolve, reject) => {
          eventBus.once(`${FORM_SUBMIT_FULFILLED}_${form}`, (payload) => {
            next(stopSubmit(form, null, payload, meta))
            resolve(payload)
          })
          eventBus.once(`${FORM_SUBMIT_FAILED}_${form}`, (payload) => {
            next(stopSubmit(form, payload, null, meta))
            reject(payload)
          })
        })

        next(action)
        next(startSubmit(form))

        try {
          const result = await (payloadIsAPromise ? payload : thePromise)
          if (payloadIsAPromise) {
            dispatch(enhancedStopSubmit(form, null, result, meta))
          }
          return result
        } catch (err) {
          if (payloadIsAPromise) {
            dispatch(enhancedStopSubmit(form, err, null, meta))
          }
          throw err
        } finally {
          delete promises[form]
        }
      } else if (type === FORM_SUBMIT_FULFILLED) {
        eventBus.emit(`${FORM_SUBMIT_FULFILLED}_${form}`, action.payload)
      } else if (type === FORM_SUBMIT_FAILED) {
        eventBus.emit(`${FORM_SUBMIT_FAILED}_${form}`, action.payload)
      }

      return next(action)
    }
  }
}
