import React, { Fragment } from 'react'
import { compose, withHandlers, withPropsOnChange, lifecycle } from 'recompose'
import { Card, Divider, Input, Button, Modal, message, Checkbox, Badge } from 'antd'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import _fp from 'lodash/fp'
import { Helmet } from 'react-helmet'
import SubmitButton from '../../../components/form/SubmitButton'
import { select } from '@rematch/select'
import FormItem from '../../../components/form/FormItem'
import DefaultLayout from '../../../components/layout/DefaultLayout'
import Spinner from '../../../components/Spinner'
import DatePicker from '../../../components/form/BrDatePicker'
import AgentGroupSelect from '../../../components/form/AgentGroupSelect'
import RoleSelect from '../../../components/form/RoleSelect'
import withConnectedForm from '../../../components/form/withConnectedForm'
import NameInput from '../../../components/form/NameInput'
import PlaceSearchInput from '../../../components/form/PlaceSearchInput'
import withClearStateOnUnmount from '../../../components/withClearStateOnUnmount'
import OfficeSelect from '../../../components/form/OfficeSelect'
import PageTitle from '../../../components/layout/PageTitle'
import FormattedDate from '../../../components/format/FormattedDate'

const InternalEmailInput = props => (
  <Input {...props} onBlur={e => e.preventDefault()} addonAfter={<span>@{props.domain}</span>} />
)

export const AgentEdit = props => {
  const {
    fetchData,
    isAdmin,
    handleSubmit,
    formValues,
    formError,
    title,
    handleDeleteAgentBtnClick,
    handleActivateAgentBtnClick,
    handleGmapSearchBoxChange,
    isCreateMode,
    isEditMode,
    domain,
    isMe,
    hasEmails,
    areDocumentsVisible,
    id,
  } = props

  const deletedAt = _.get(formValues, 'deletedAt')

  return (
    <DefaultLayout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Spinner fetchData={fetchData}>
        <PageTitle
          title={title}
          extra={
            isEditMode && (
              <>
                <Badge
                  count={deletedAt ? 'Disattivato' : 'Attivo'}
                  style={deletedAt ? {} : { backgroundColor: '#52c41a' }}
                />
                {deletedAt && (
                  <>
                    <br />
                    <span>
                      (<FormattedDate date={deletedAt} />)
                    </span>
                  </>
                )}
              </>
            )
          }
        />
        <Card style={{ marginBottom: 30 }}>
          <form onSubmit={handleSubmit}>
            {isCreateMode && (
              <FormItem
                label={<span>&nbsp;</span>}
                name="sendActivationEmail"
                className="ant-form-item--hideLabel"
                component={props => (
                  <label>
                    <Checkbox onChange={props.onChange} checked={props.checked} /> Invia email di
                    notifica attivazione e impostazione password
                  </label>
                )}
                required
                withReduxFormProps={true}
                layout="horizontal"
                errors={formError}
              />
            )}
            <FormItem
              label="Nome"
              name="firstname"
              component={NameInput}
              required
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Cognome"
              name="lastname"
              component={NameInput}
              required
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Username"
              name="username"
              component={Input}
              disabled={isEditMode && !isAdmin}
              required
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Email personale"
              name="email"
              component={Input}
              disabled={isEditMode}
              required
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Sede"
              name="account.officeId"
              component={OfficeSelect}
              disabled={isEditMode && !isAdmin}
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            {hasEmails && (
              <FormItem
                label="Email gestionale"
                name="account.internalEmail"
                component={InternalEmailInput}
                disabled={isEditMode && !isAdmin}
                domain={domain}
                required
                withReduxFormProps={true}
                layout="horizontal"
                errors={formError}
              />
            )}
            <FormItem
              label="Codice Fiscale"
              name="fiscalCode"
              component={Input}
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Carta Identitá N."
              name="identityCard"
              component={Input}
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Telefono"
              name="phones[0].phone"
              component={Input}
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <Divider />
            <FormItem
              label="Data di nascita"
              name="birth.date"
              component={DatePicker}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Cittá di nascita"
              name="birth.city"
              component={PlaceSearchInput}
              layout="horizontal"
              onChange={handleGmapSearchBoxChange('birth')}
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Provincia di nascita"
              name="birth.province"
              component={Input}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Nazione di nascita"
              name="birth.country"
              component={Input}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <Divider />
            <FormItem
              label="Indirizzo di residenza"
              name="home.address"
              component={PlaceSearchInput}
              layout="horizontal"
              onChange={handleGmapSearchBoxChange('home')}
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Codice Postale"
              name="home.postalCode"
              component={PlaceSearchInput}
              onChange={handleGmapSearchBoxChange('home')}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Cittá di residenza"
              name="home.city"
              component={PlaceSearchInput}
              onChange={handleGmapSearchBoxChange('home')}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Provincia di residenza"
              name="home.province"
              component={Input}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Nazione di residenza"
              name="home.country"
              component={Input}
              layout="horizontal"
              withReduxFormProps={true}
              errors={formError}
            />
            <Divider />
            <FormItem
              label="Gruppi provvigionali"
              name="account.groupIds"
              component={AgentGroupSelect}
              disabled={!isAdmin}
              withReduxFormProps={true}
              mode="multiple"
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Ruoli"
              name="roleIds"
              component={RoleSelect}
              disabled={!isAdmin}
              withReduxFormProps={true}
              mode="multiple"
              layout="horizontal"
              errors={formError}
            />
            <section className="FormActions">
              {(isAdmin || isMe) && <SubmitButton />}
              {isAdmin && isEditMode && !deletedAt && (
                <Button
                  type="danger"
                  icon="delete"
                  size="large"
                  onClick={handleDeleteAgentBtnClick}
                >
                  Disattiva
                </Button>
              )}
              {isAdmin && isEditMode && deletedAt && (
                <Button type="danger" size="large" onClick={handleActivateAgentBtnClick}>
                  Attiva
                </Button>
              )}
            </section>
          </form>
        </Card>
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  withPropsOnChange(['match'], ({ match }) => ({
    id: _.get(match, 'params.id'),
    isCreateMode: !_.get(match, 'params.id'),
    isEditMode: !!_.get(match, 'params.id'),
  })),
  connect(
    (state, { id }) => ({
      agent: select.agents.get(state),
      isAdmin: select.agents.isAdmin(state),
      accountSettings: select.accountSettings.get(state),
      initialValues: id ? select.agents.get(state) : { sendActivationEmail: true },
      me: _.get(select.agents.me(state), 'me'),
      hasEmails: select.accountSettings.hasFeature(state, {
        feature: 'hasEmails',
      }),
    }),
    dispatch => ({
      findOne: dispatch.agents.findOne,
      create: dispatch.agents.customCreate,
      update: dispatch.agents.update,
      deleteById: dispatch.agents.delete,
      getAccountSettings: dispatch.accountSettings.getMine,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withPropsOnChange(['id', 'me'], ({ id, me }) => ({
    isMe: id === me.id,
  })),
  withPropsOnChange(['initialValues', 'hasEmails'], ({ initialValues, hasEmails }) => {
    if (!hasEmails) return null
    const internalEmail = _.get(initialValues, 'account.internalEmail', '')
    return {
      initialValues: _.merge({}, initialValues, {
        account: {
          internalEmail: internalEmail.split('@')[0],
        },
      }),
    }
  }),
  withPropsOnChange(['initialValues'], ({ initialValues }) => {
    return {
      initialValues: _.merge({}, initialValues, {
        roleIds: _.map((initialValues || {}).roles, 'id'),
      }),
    }
  }),
  withConnectedForm('agentView'),
  withPropsOnChange(['accountSettings'], ({ accountSettings }) => {
    return {
      domain: _.get(accountSettings, 'public.emails.domain'),
    }
  }),
  withHandlers({
    fetchData: props => async () => {
      const { id, findOne, getAccountSettings } = props
      await getAccountSettings()
      if (id) {
        const query = {
          filter: {
            where: { id },
            include: [
              {
                relation: 'roles',
              },
            ],
            populate: [
              {
                property: 'account.groupIds',
                model: 'AgentGroup',
                on: 'groups',
              },
            ],
          },
        }
        return findOne({ ...query })
      }

      return null
    },
  }),
  withHandlers({
    handleDeleteAgentBtnClick: props => () => {
      const { deleteById, agent, fetchData } = props
      Modal.confirm({
        title: 'Disattiva agente',
        content: 'Sei sicuro?',
        onOk: async () => {
          await deleteById({ id: agent.id })
          message.success('Agente disattivato')
          return fetchData()
        },
      })
    },
    handleActivateAgentBtnClick: props => () => {
      const { update, agent, fetchData } = props
      Modal.confirm({
        title: 'Attiva agente',
        content: 'Sei sicuro?',
        onOk: async () => {
          await update({ id: agent.id, deletedAt: null })
          message.success('Agente riattivato')
          return fetchData()
        },
      })
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { id, agent, fetchData, initialize } = this.props
      if (nextProps.id && nextProps.id !== id) {
        return id ? fetchData() : null
      }
    },
  }),
  withPropsOnChange(['formValues', 'domain'], ({ formValues, domain }) => {
    const internalEmail = _.get(formValues, 'account.internalEmail')
    if (!internalEmail) return { formValues }
    const formattedFormValues = _fp.set(
      'account.internalEmail',
      `${internalEmail}@${domain}`,
      formValues,
    )
    return {
      formValues: formattedFormValues,
    }
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const { formValues, dispatchSubmit, create, update, id, push, fetchData } = props
      if (id) {
        await dispatchSubmit(update({ ...formValues, id }))
        return fetchData()
      }

      const { sendActivationEmail } = formValues

      try {
        const { id: newId } = await dispatchSubmit(create(formValues), {
          displayMessage: false,
        })
        message.success(
          `Agente creato. ${sendActivationEmail ? 'Email di attivazione inviata.' : ''}`,
        )

        return push(`/agents/edit/${newId}`)
      } catch (err) {
        message.error('Errore durante la richiesta')
      }
    },
    handleGmapSearchBoxChange: props => context => (e, value) => {
      const { change, formValues = {} } = props
      if (value) {
        if (typeof value === 'string') return
        e.preventDefault()
        return change(context, {
          ...formValues[context],
          ...value.formatted,
          address: value.formatted.fullAddress,
        })
      }
    },
  }),
  withPropsOnChange(['isEditMode', 'agent', 'isMe'], ({ isEditMode, agent, isMe }) => {
    if (isMe) return { title: 'Il mio Profilo' }
    return {
      title: agent
        ? `Modifica agente: ${agent.internalId} - ${agent.firstname} ${agent.lastname}`
        : 'Aggiungi agente',
    }
  }),
  withPropsOnChange(['isEditMode', 'isMe', 'isAdmin'], ({ isEditMode, isMe, isAdmin }) => {
    const areDocumentsVisible = isEditMode && (isAdmin || isMe)
    return {
      areDocumentsVisible,
    }
  }),
  withClearStateOnUnmount('agents.agent'),
)(AgentEdit)
