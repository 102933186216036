import _get from 'lodash/get'
import _fp from 'lodash/fp'

export const GET_HIERARCHY_TREE_REQUESTED = (state, payload) =>
  _fp.set(
    'tree',
    {
      isFetching: true,
      data: null,
    },
    state,
  )

export const GET_HIERARCHY_TREE_FULFILLED = (state, payload) =>
  _fp.set(
    'tree',
    {
      data: payload,
      isFetching: false,
      isFetchingFailed: false,
    },
    state,
  )

export const GET_HIERARCHY_TREE_FAILED = (state, payload) =>
  _fp.set(
    'tree',
    {
      isFetching: false,
      isFetchingFailed: _get(payload, 'response.data.error'),
    },
    state,
  )

export const SAVE_HIERARCHY_TREE_REQUESTED = (state, payload) =>
  _fp.set(
    'tree',
    {
      isUpdating: true,
      data: null,
    },
    state,
  )

export const SAVE_HIERARCHY_TREE_FULFILLED = (state, payload) =>
  _fp.set(
    'tree',
    {
      isUpdating: false,
      isUpdatingFailed: false,
    },
    state,
  )

export const SAVE_HIERARCHY_TREE_FAILED = (state, payload) =>
  _fp.set(
    'tree',
    {
      isUpdating: false,
      isUpdatingFailed: _get(payload, 'response.data.error'),
    },
    state,
  )
