import React, { Fragment } from 'react'
import _ from 'lodash'

export const formatName = person => {
  const { internalId, firstname, lastname } = person || {}
  const fullName = [firstname, lastname].filter(_.identity).join(' ')

  return internalId ? `${internalId} - ${fullName}` : fullName
}

const Name = ({ person, children }) => {
  return (
    <Fragment>
      {children && <Fragment>{children} &nbsp;</Fragment>}
      {formatName(person)}
    </Fragment>
  )
}

export default Name
