export const CALCULATE_FORMULA_REQUESTED = (state, payload) => ({
  ...state,
  isCalculateFormulaPending: true,
})

export const CALCULATE_FORMULA_FULFILLED = (state, payload) => ({
  ...state,
  isCalculateFormulaPending: false,
  isCalculateFormulaFailed: false,
  result: payload.result,
})

export const CALCULATE_FORMULA_FAILED = (state, payload) => ({
  ...state,
  isCalculateFormulaPending: false,
  isCalculateFormulaFailed: payload,
  result: null,
})
