import React from 'react'
import { Layout } from 'antd'
import { css, cx } from 'emotion'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { compose, pure, withPropsOnChange } from 'recompose'
import { select } from '@rematch/select'
import ErrorBoundary from 'src/components/core/ErrorBoundary/index'
import TopBar from '../../navigation/TopBar/TopBar'
import LeftPanel from '../../navigation/LeftPanel/LeftPanel'
import Desktop from '../Responsive/Desktop'
import _ from 'lodash'
import { getActiveTopItem } from '../../../store/modules/menu/selectors'

const { Content } = Layout

const style = css`
  height: 100vh;
  overflow-x: hidden;
  & .DefaultLayout-content {
    // padding: 10px;
    .DefaultLayout-content-inner {
      padding: 10px 15px;
      flex-grow: 0;
    }
  }
`

const DefaultLayout = props => {
  const { className, children, leftPanel, logoSrc, isLeftPanelVisible } = props

  return (
    <Layout className={cx('DefaultLayout', className, style)}>
      <Helmet>{logoSrc && <link rel="icon" href={logoSrc} sizes="16x16" />}</Helmet>
      <TopBar />
      <Layout className="ant-layout-has-sider">
        {isLeftPanelVisible && <Desktop>{leftPanel || <LeftPanel />}</Desktop>}
        <Layout className="DefaultLayout-content" id="DefaultLayout-content">
          <Content className="DefaultLayout-content-inner">
            <ErrorBoundary>{children}</ErrorBoundary>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
export default compose(
  pure,
  connect(state => ({
    accountSettings: select.accountSettings.get(state),
    activeTopItem: getActiveTopItem(state),
  })),
  withPropsOnChange(['activeTopItem'], ({ activeTopItem }) => ({
    isLeftPanelVisible: _.has(activeTopItem, 'showLeftPanel') ? activeTopItem.showLeftPanel : true,
  })),
  withPropsOnChange(['accountSettings'], ({ accountSettings }) => ({
    logoSrc: _.get(accountSettings, 'public.ui.logo.src'),
  })),
)(DefaultLayout)
