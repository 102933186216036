import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import _fp from 'lodash/fp'
import Promise from 'bluebird'
import { Icon, Divider } from 'antd'
import { css, cx } from 'emotion'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import SimpleTable from '../SimpleTable/SimpleTable'
import Filters from './Filters'
import Reveal from '../../layout/Reveal'
import Select from '../../form/Select'
import SaveFiltersConfigurationModal from './SaveFiltersConfigurationModal'
import withModalControls from '../../modals/withModalControls'

const style = css`
  &.SmartTable {
    .SmartTable-filters {
      padding: 10px 15px;
    }
  }
`

const deepMerge = (...objs) => {
  function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  }

  return _.mergeWith(...objs, customizer)
}

const includeDeletedFilterConfig = {
  key: 'includeDeleted',
  label: 'Includi eliminati',
  createQueryFilter: value => {
    if (value === 'yes') {
      return { skipDeleted: !value }
    }

    if (value === 'no') {
      return { skipDeleted: value }
    }

    if (value === 'onlyDeleted') {
      return { skipDeleted: false, where: { deletedAt: { exists: true } } }
    }
  },
  component: props => (
    <Select
      allowClear={true}
      options={[
        { label: 'Si', value: 'yes' },
        { label: 'No', value: 'no' },
        { label: 'Solo eliminati', value: 'onlyDeleted' },
      ]}
      {...props}
    />
  ),
}

export class SmartTableClass extends PureComponent {
  constructor() {
    super()
  }

  componentWillMount() {
    this.setState(
      {
        currentPage: 1,
        totalPages: 1,
        itemsPerPage: this.props.itemsPerPage || 10,
        locale: {
          filterTitle: '',
          filterConfirm: '',
          filterReset: '',
          emptyText: '',
        },
        filters: {},
        filtersConfig: _.map(this.props.filtersConfig, fConfig => {
          if (typeof fConfig === 'string' && fConfig === 'includeDeleted')
            return includeDeletedFilterConfig

          return fConfig
        }),
      },
      () => {
        if (this.props.listFilterPreferenceId) {
          return this.fetchListFilterPreference()
        }
        if (this.props.initialFetch) this.makeCall()
        const { currentPage, totalPages, itemsPerPage } = this.state
        this.props.onChange({
          currentPage,
          totalPages,
          itemsPerPage,
          query: this.createQueryFilter(),
        })
      },
    )
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.listFilterPreferenceId !== nextProps.listFilterPreferenceId) {
      if (nextProps.listFilterPreferenceId) {
        return this.fetchListFilterPreference(nextProps.listFilterPreferenceId)
      }

      this.setState(
        {
          currentPage: 1,
          filters: {},
        },
        () => this.makeCall(),
      )
    }
  }

  makeCall() {
    this.setState(
      () => ({ loading: true }),
      async () => {
        const { findMethod, countMethod = () => {} } = this.props

        const query = this.createQueryFilter()
        const countQuery = this.createCountQuery()
        try {
          await Promise.all([findMethod(query), countMethod(countQuery)])
        } catch (err) {
        } finally {
          this.setState({ loading: false })
        }
      },
    )
  }

  handleTableChange = (pagination, filters, sorter) => {
    let order
    if (sorter.field) {
      const direction = this.parseDirection(sorter.order)
      order = `${sorter.field} ${direction}`
    }
    const data = {
      currentPage: pagination.current,
      itemsPerPage: pagination.pageSize,
      order,
    }
    this.setState(data, () => {
      this.makeCall()
      this.props.onChange({
        ...data,
        query: this.createQueryFilter(),
      })
    })
  }

  handleFilterChange = ({ filter, value }) => {
    const filters = this.state.filters
    this.setState(
      {
        currentPage: 1,
        filters: {
          ...filters,
          [filter.key]: value,
        },
      },
      this.makeCall,
    )
  }

  parseDirection(direction) {
    return { ascend: 'ASC', descend: 'DESC' }[direction]
  }

  parseTableFilters() {
    return _.reduce(
      this.state.filtersConfig || [],
      (acc, filter) => {
        const value = _.get(this.state.filters, filter.key)
        if (
          filter.key &&
          typeof value !== 'undefined' &&
          !(Array.isArray(value) && !value.length)
        ) {
          return this.formatFilterValueForQuery(acc, filter, value)
        }
        return acc
      },
      {},
    )
  }

  getOrderFilter() {
    if (this.state.order) return this.state.order
    const sColumn = this.props.columns.find(c => c.defaultSortOrder)
    if (sColumn) {
      return `${sColumn.field} ${this.parseDirection(sColumn.defaultSortOrder)}`
    }
  }

  getFieldsFilter() {
    return _fp.flow(
      _fp.map(c => c.fields || c.field),
      _fp.compact,
      _fp.flatten,
      _fp.map(f => f.split('.')[0]),
      _fp.concat('id'),
      _fp.concat(this.props.additionalFields),
    )(this.props.columns)
  }

  getCurrentPage() {
    return this.state.currentPage || this.props.currentPage
  }

  getTotalPages() {
    return this.state.totalPages || this.props.totalPages
  }

  getItemsPerPage() {
    return this.state.itemsPerPage || this.props.itemsPerPage
  }

  createQueryFilter() {
    const currentPage = this.getCurrentPage()
    const limit = this.getItemsPerPage()
    const skip = limit * (currentPage - 1)
    const order = this.getOrderFilter()
    const initialQuery = this.props.initialQuery
    const query = {
      filter: {
        limit,
        skip,
        order,
      },
    }

    if (this.props.fieldSelection) {
      query.filter.fields = this.getFieldsFilter()
    }

    return deepMerge({}, initialQuery, query, { filter: this.parseTableFilters() })
  }

  createCountQuery() {
    const initialQuery = this.props.initialQuery || {}
    return deepMerge({}, initialQuery.filter, this.parseTableFilters())
  }

  formatFilterValueForQuery(queryFilter, filterConfig, value) {
    if (filterConfig.createQueryFilter) {
      return deepMerge({}, queryFilter, filterConfig.createQueryFilter(value))
    }
    const filterSwitch = {
      search: () => {
        if (typeof value === 'undefined' || value === '') return queryFilter
        const val = filterConfig.formatValue ? filterConfig.formatValue(value) : _.trim(value)
        const orConditions = _.reduce(
          filterConfig.fields,
          (acc, field) => {
            return [
              ...acc,
              {
                or: _.compact([
                  typeof val === 'string'
                    ? {
                        [field]: {
                          like: val,
                          options: 'i',
                        },
                      }
                    : null,
                  { [field]: val },
                ]),
              },
            ]
          },
          [],
        )
        const existingAndConditions = _.get(queryFilter, 'where.and', [])
        const newAndConditions = [...existingAndConditions, { or: orConditions }]
        return _.merge({}, queryFilter, { where: { and: newAndConditions } })
        // return _.set(queryFilter, 'and', [...existingAndConditions, { or: orConditions }])
      },
      dateInterval: () => {
        if (_.isEmpty(value)) return queryFilter
        const existingAndConditions = _.get(queryFilter, 'and', [])
        return _.set(queryFilter, 'and', [
          ...existingAndConditions,
          { [filterConfig.key]: { gte: value[0] } },
          { [filterConfig.key]: { lte: value[1] } },
        ])
      },
    }

    if (filterSwitch[filterConfig.type]) return filterSwitch[filterConfig.type]()
    queryFilter[filterConfig.key] = value
    return queryFilter
  }

  handleFilterResetClick = e => {
    e.preventDefault()
    this.setState(
      {
        currentPage: 1,
        filters: {},
      },
      this.makeCall,
    )
  }

  handleFilterSaveClick = async e => {
    e.preventDefault()
    const result = await this.props.saveFiltersConfigurationModalControls.show()
    if (result) {
      this.props.onFilteredListCreate(result)
    }
  }

  fetchListFilterPreference(id) {
    const theId = id || this.props.listFilterPreferenceId
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          const data = await this.props.findPreferenceById({
            id: theId,
          })

          let value = { filters: {} }
          try {
            value = JSON.parse(data.value)
          } catch (err) {}

          this.setState(
            {
              currentPage: 1,
              filters: value.filters,
            },
            () => this.makeCall(),
          )
        } catch (err) {
          this.setState({
            loading: false,
          })
        }
      },
    )
  }

  render() {
    const { columns, data = [], count, paginationOpts, canSaveFilters } = this.props
    const { filtersConfig } = this.state

    const { filters } = this.state

    const pagination = {
      total: count,
      pageSize: this.state.itemsPerPage,
      showSizeChanger: true,
      showTotal: (total, range) => `${total} totali`,
      size: 'big',
      pageSizeOptions: ['10', '20', '50', '100'],
    }

    const hasFiltersSelected = _fp.flow(
      _fp.values,
      _fp.cond([
        [values => _fp.size(values) === 0, _fp.stubFalse],
        [_fp.some(_fp.negate(_fp.isEmpty)), _fp.stubTrue],
      ]),
    )(filters)

    return (
      <>
        <article className={cx('SmartTable', style)}>
          <section className="SmartTable-filters" style={{ marginBottom: 10 }}>
            {filtersConfig && filtersConfig.length > 0 && (
              <Reveal
                defaultIsExpanded={this.props.defaultFilterExpanded}
                expandedText={<span>Nascondi filtri</span>}
                expandedSlot={({ Icon: TheIcon, isExpanded, toggle }) => (
                  <>
                    <a onClick={toggle}>
                      <TheIcon /> {isExpanded ? 'Nascondi filtri' : 'Mostra Filtri'}
                    </a>
                    {isExpanded && (
                      <>
                        {hasFiltersSelected && [
                          <Divider type="vertical" />,
                          canSaveFilters && (
                            <a href="" onClick={this.handleFilterSaveClick}>
                              <Icon type="save" /> Salva Filtri
                            </a>
                          ),
                          <Divider type="vertical" />,
                          <a href="" onClick={this.handleFilterResetClick}>
                            <Icon type="filter" /> Reset
                          </a>,
                        ]}
                      </>
                    )}
                  </>
                )}
                collapsedText="Mostra filtri"
              >
                <Filters
                  filters={filtersConfig}
                  onChange={this.handleFilterChange}
                  values={filters}
                />
              </Reveal>
            )}
          </section>
          <div className="SmartTable-main">
            <SimpleTable
              {...{
                columns,
                data,
                ...this.props,
                locale: this.state.pagination,
                pagination,
                paginationOpts,
                loading: this.props.loading || this.state.loading,
                onChange: this.handleTableChange,
              }}
            />
          </div>
        </article>
        <SaveFiltersConfigurationModal
          {...this.props.saveFiltersConfigurationModalControls}
          filters={this.state.filters}
          type={this.props.identifier}
        />
      </>
    )
  }
}

export default compose(
  connect(
    () => {},
    dispatch => ({ findPreferenceById: dispatch.preferences.findById }),
  ),
  withModalControls('saveFiltersConfigurationModalControls'),
)(SmartTableClass)

SmartTableClass.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  // initialWhere: PropTypes.object,
  filtersConfig: PropTypes.array,
  findMethod: PropTypes.func.isRequired,
  countMethod: PropTypes.func,
  order: PropTypes.string,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  initialFetch: PropTypes.bool,
}
SmartTableClass.defaultProps = {
  data: [],
  onChange: () => {},
  onFilteredListCreate: () => {},
  initialFetch: true,
}
/*
  === Table config
    - columns
  === Table instance
    - filtering
    - ordering
    - currentPage
    - totalPages
 */
