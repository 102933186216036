import { injectGlobal } from 'emotion'

injectGlobal`
.u-minSizeViewport {
  width: 100vw;
  min-height: 100vh;
}

.u-bgWhite {
  background:white;
}

.u-container {
  background: white;
  padding: 15px;
  @media (max-width: 767px) {
    padding: 10px;
  }
}

.u-listStyleNone {
  list-style: none;
  padding: 0;
  margin: 0;
}

.u-cursorPointer {
  cursor: pointer;
}

.u-cursorMove {
  cursor: move;
}

.u-textCenter {
  text-align: center;
}

.u-textRight {
  text-align: right;
}

.u-overflowHidden {
  overflow: hidden;
}

.u-displayNone {
  display: none;
}

.u-noWrap {
  white-space: nowrap;
}
`
