import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, withHandlers, withPropsOnChange } from 'recompose'
import { Layout, Menu, Dropdown, Icon } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { select } from '@rematch/select'
import { cx, css } from 'emotion'
import { push } from 'react-router-redux'
import { getMenu, getActiveTopItem, getTopItemRoute } from '../../../store/modules/menu/selectors'
import Desktop from '../../layout/Responsive/Desktop'
import Mobile from '../../layout/Responsive/Mobile'
import Hamburger from '../Hamburger/index'
import style from './TopBar.style'
import UserDropdown from '../UserDropdown/UserDropdown'

const UserDropdownClass = css`
  & .UserDropdown-userLink {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const { Header } = Layout
const TopBar = props => {
  const {
    topItems = [],
    activeTopItem,
    logoSrc,
    accountName,
    isMobileMenuOpen,
    handleMobileMenuClick,
  } = props
  const menuItems = topItems.map((item, i) => (
    <Menu.Item style={item.style} key={i}>
      {item.path ? <Link to={item.path}>{item.title}</Link> : item.title}
    </Menu.Item>
  ))

  const selectedItemIndex = String(
    _.findIndex(topItems, item => activeTopItem && item.name === activeTopItem.name),
  )

  return (
    <Header
      className={cx(style, {
        'is-mobileMenuOpen': isMobileMenuOpen,
      })}
    >
      <div className="TopBar-wrapper">
        <section className="TopBar-logo">
          <Link to="/">
            {logoSrc && <img src={logoSrc} alt={accountName} />}
            {!logoSrc && accountName}
          </Link>
        </section>
        <section className="TopBar-items">
          <section className="TopBar-items-left">
            <Desktop>
              <Menu
                selectable={false}
                theme="dark"
                mode="horizontal"
                selectedKeys={[selectedItemIndex]}
              >
                {menuItems}
              </Menu>
            </Desktop>
          </section>
          <section className="TopBar-items-right">
            <Desktop>
              <UserDropdown>
                {({ Dropdown, username }) => (
                  <Menu className={UserDropdownClass} theme="dark" mode="horizontal">
                    <Menu.Item key={0} style={{ background: 'none !important' }}>
                      <Dropdown>
                        <a className="UserDropdown-userLink ant-dropdown-link">
                          <Icon type="user" /> {username}
                        </a>
                      </Dropdown>
                    </Menu.Item>
                  </Menu>
                )}
              </UserDropdown>
            </Desktop>
            <Mobile>
              <div className="TopBar-mobileMenu" onClick={handleMobileMenuClick}>
                <Hamburger isOpen={isMobileMenuOpen} />
              </div>
            </Mobile>
          </section>
        </section>
      </div>
    </Header>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      topItems: getMenu(state),
      activeTopItem: getActiveTopItem(state),
      topItemRoute: getTopItemRoute,
      me: select.agents.me(state),
      accounts: select.accounts.getAll(state),
      account: select.accounts.get(state),
      isMobileMenuOpen: select.layout.isMobileMenuOpen(state),
      accountSettings: select.accountSettings.get(state),
    }),
    dispatch => ({
      logout: dispatch.agents.logout,
      openMobileMenu: dispatch.layout.openMobileMenu,
      closeMobileMenu: dispatch.layout.closeMobileMenu,
      push: dispatch => props => dispatch(push(...props)),
    }),
  ),
  withPropsOnChange(['accountSettings'], ({ accountSettings }) => ({
    logoSrc: _.get(accountSettings, 'public.ui.logo.src'),
  })),
  withPropsOnChange(['account'], ({ account }) => ({
    accountName: (account || {}).name,
  })),
  withHandlers({
    handleLogoutClick: props => () => {
      props.logout()
    },
    handleMobileMenuClick: props => () => {
      const { isMobileMenuOpen, openMobileMenu, closeMobileMenu } = props
      if (isMobileMenuOpen) return closeMobileMenu()

      return openMobileMenu()
    },
  }),
)(TopBar)
