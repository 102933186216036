import { dispatch } from '@rematch/core'

export const openMobileMenu = () => {
  document.body.classList.toggle('u-overflowHidden', true)
  dispatch.layout.setMobileMenuOpen(true)
}
export const closeMobileMenu = state => {
  document.body.classList.toggle('u-overflowHidden', false)
  dispatch.layout.setMobileMenuOpen(false)
}
