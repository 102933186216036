import trimStart from 'lodash/trimStart'
import axios from 'axios'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/operator/share'
import 'rxjs/add/observable/timer'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/repeat'
import 'rxjs/add/operator/retry'
import _fp from 'lodash/fp'
import isEqual from 'lodash/isEqual'
import Promise from 'bluebird'

import BaseTransport from './BaseTransport'

export default class HttpProvider extends BaseTransport {
  constructor(config, httpClient = axios) {
    super()
    this.errorHandlers = []
    this.config = config
    this.httpClient = httpClient
  }

  find({ resourceName, resourcePluralName, query, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}`
    return this.makeRequest('GET', path, query, options)
  }

  count({ resourceName, resourcePluralName, query, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/count`
    return this.makeRequest('GET', path, query, options)
  }

  findOne({ resourceName, resourcePluralName, query, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/findOne`
    return this.makeRequest('GET', path, query, options)
  }

  findById({ resourceName, resourcePluralName, id, query, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/${id}`
    return this.makeRequest('GET', path, query, options)
  }

  create({ resourceName, resourcePluralName, data, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}`
    const payload = { data }
    return this.makeRequest('POST', path, payload, options)
  }

  update({ resourceName, resourcePluralName, data = {}, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/${data.id}`
    const payload = { data: _fp.omit('id', data) }
    return this.makeRequest('PATCH', path, payload, options)
  }

  updateAll({
    resourceName,
    resourcePluralName,
    where,
    data,
    basePath,
    options,
  }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/update`
    return this.makeRequest({
      method: 'POST',
      path,
      data,
      params: { where },
    })
  }

  delete({ resourceName, resourcePluralName, id, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/${id}`
    const payload = { id }
    return this.makeRequest('DELETE', path, payload, options)
  }

  exists({ resourceName, resourcePluralName, id, basePath, options }) {
    const path = `${this.getBasePath({
      resourceName,
      resourcePluralName,
      basePath,
    })}/${id}`
    const payload = { id }
    return this.makeRequest('HEAD', path, payload, options)
  }

  getBasePath({ resourceName, resourcePluralName, basePath }) {
    if (basePath) return basePath

    const resourcePluralN = resourcePluralName || `${resourceName}s`
    return resourcePluralN
  }

  async makeRequestOld(method, path = '', payload = {}, headers) {
    const args = { method, path, payload, headers }
    await this.execMiddlewares('pre', args)
    const url =
      path.indexOf('http') === 0
        ? path
        : `${this.config.apiBase}/api/${trimStart(path, '/')}`

    const request = {
      ...args,
      url,
      data: args.method === 'GET' ? undefined : args.payload.data,
      params: args.method !== 'GET' ? undefined : args.payload,
    }

    try {
      const result = await this.httpClient.request(request)
      await this.execMiddlewares('post', { args, result })
      return result
    } catch (error) {
      await this.execMiddlewares('post', { args, error })
      throw error
    }
  }

  async makeRequestNew({ method, path = '', data = {}, params, headers }) {
    const args = { method, path, data, params, headers }
    await this.execMiddlewares('pre', args)
    const url =
      path.indexOf('http') === 0
        ? path
        : `${this.config.apiBase}/api/${trimStart(path, '/')}`

    const request = {
      ...args,
      url,
      data,
      params,
    }

    try {
      const result = await this.httpClient.request(request)
      await this.execMiddlewares('post', { args, result })
      return result
    } catch (error) {
      await this.execMiddlewares('post', { args, error })
      throw error
    }
  }

  async makeRequest(...args) {
    if (typeof args[0] === 'object') {
      // new object method
      return this.makeRequestNew(args[0])
    }

    return this.makeRequestOld(...args)
  }

  observe({
    resourcePluralName,
    path,
    query,
    observeData = {},
    options /*= defaultOptions*/,
  }) {
    return Promise.try(() => {
      const observable = Observable.create(observer => {
        let previousData = null
        const doCheck = async () => {
          const newQuery = _fp.flow(
            _fp.omit('fields'),
            _fp.defaultsDeep({ filter: { fields: ['id', 'updatedAt'] } }),
          )(query)

          const { data } = await this.makeRequest(
            'GET',
            path || resourcePluralName,
            newQuery,
            {},
          )
          if (!isEqual(data, previousData)) {
            fetchData()
          }

          previousData = data
        }

        const fetchData = () =>
          this.makeRequest('GET', path || resourcePluralName, query, {}).then(
            data => {
              if (data.error) {
                // this.handleError(data.error, { query, options, observeData })
                return observer.error(data)
              }

              return observer.next(data)
            },
          )

        doCheck()
        // const interval = Observable.interval(2000).subscribe(() => doCheck())
        const interval = Observable.timer(2000)
          //.startWith(() => Observable.fromPromise(doCheck()))
          .flatMap(() => Observable.fromPromise(doCheck()))
          .repeat() // Repeat when we get a response
          .retry() // Retry if there is an error
          .share()
          .subscribe(() => {})

        return () => (interval ? interval.unsubscribe() : null)
      }).share()

      return { observable }
    })
  }

  observeOne({ ...rest }) {
    return this.observe({
      ...rest,
    })
  }
}
