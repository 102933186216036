import React from 'react'
import { Input, Row, Col } from 'antd'
import {
  compose,
  defaultProps,
  withProps,
  withPropsOnChange,
  withHandlers,
} from 'recompose'
import _ from 'lodash'
import Select from '../Select'
import PercentageInput from '../PercentageInput'

const IndexingParameterSelect = props => {
  const { value, configurationOptions, typeOptions, handleChange } = props
  return (
    <Row gutter={8}>
      <Col span={9}>
        <Select
          onChange={handleChange('configuration')}
          options={configurationOptions}
          value={value.configuration}
          allowClear={true}
        />
      </Col>
      <Col span={9}>
        <Select
          onChange={handleChange('type')}
          options={typeOptions}
          value={value.type}
          placeholder={'Es: Euribor 12 mesi'}
          allowClear={true}
        />
      </Col>
      <Col span={6}>
        <PercentageInput
          onChange={handleChange('value')}
          value={value.value}
          placeholder={'Es: 0 %'}
        />
      </Col>
    </Row>
  )
}

export default compose(
  defaultProps({
    onChange: () => {},
  }),
  withProps(props => {
    const configurationOptions = [
      { label: 'Nessuno', value: 'ignore' },
      { label: 'Personalizzato', value: 'custom' },
    ]

    if (props.type === 'product') {
      configurationOptions.push({ label: `Ereditato dall'instituto`, value: 'inherited' })
    }

    return { configurationOptions }
  }),
  withProps({
    typeOptions: [
      { value: 'euribor-12m', label: 'Euribor 12 mesi' },
      { value: 'euribor-11m', label: 'Euribor 11 mesi' },
      { value: 'euribor-10m', label: 'Euribor 10 mesi' },
      { value: 'euribor-9m', label: 'Euribor 9 mesi' },
      { value: 'euribor-8m', label: 'Euribor 8 mesi' },
      { value: 'euribor-7m', label: 'Euribor 7 mesi' },
      { value: 'euribor-6m', label: 'Euribor 6 mesi' },
      { value: 'euribor-5m', label: 'Euribor 5 mesi' },
      { value: 'euribor-4m', label: 'Euribor 4 mesi' },
      { value: 'euribor-3m', label: 'Euribor 3 mesi' },
      { value: 'euribor-2m', label: 'Euribor 2 mesi' },
      { value: 'euribor-1m', label: 'Euribor 1 mesi' },

      { value: 'euribor-12m-360', label: 'Euribor 360 12 mesi' },
      { value: 'euribor-11m-360', label: 'Euribor 360 11 mesi' },
      { value: 'euribor-10m-360', label: 'Euribor 360 10 mesi' },
      { value: 'euribor-9m-360', label: 'Euribor 360 9 mesi' },
      { value: 'euribor-8m-360', label: 'Euribor 360 8 mesi' },
      { value: 'euribor-7m-360', label: 'Euribor 360 7 mesi' },
      { value: 'euribor-6m-360', label: 'Euribor 360 6 mesi' },
      { value: 'euribor-5m-360', label: 'Euribor 360 5 mesi' },
      { value: 'euribor-4m-360', label: 'Euribor 360 4 mesi' },
      { value: 'euribor-3m-360', label: 'Euribor 360 3 mesi' },
      { value: 'euribor-2m-360', label: 'Euribor 360 2 mesi' },
      { value: 'euribor-1m-360', label: 'Euribor 360 1 mesi' },

      { value: 'irs-auto', label: 'IRS automatico' },

      { value: 'irs-50a', label: 'IRS 50 anni' },
      { value: 'irs-40a', label: 'IRS 40 anni' },
      { value: 'irs-30a', label: 'IRS 30 anni' },
      { value: 'irs-25a', label: 'IRS 25 anni' },
      { value: 'irs-20a', label: 'IRS 20 anni' },
      { value: 'irs-15a', label: 'IRS 15 anni' },
      { value: 'irs-12a', label: 'IRS 12 anni' },
      { value: 'irs-10a', label: 'IRS 10 anni' },
      { value: 'irs-9a', label: 'IRS 9 anni' },
      { value: 'irs-8a', label: 'IRS 8 anni' },
      { value: 'irs-7a', label: 'IRS 7 anni' },
      { value: 'irs-6a', label: 'IRS 6 anni' },
      { value: 'irs-5a', label: 'IRS 5 anni' },
      { value: 'irs-4a', label: 'IRS 4 anni' },
      { value: 'irs-3a', label: 'IRS 3 anni' },
      { value: 'irs-2a', label: 'IRS 2 anni' },
      { value: 'irs-1a', label: 'IRS 1 anni' },

      { value: 'BCE', label: 'bce' },
    ],
  }),
  withPropsOnChange(['value', 'input'], ({ value: theValue, input = {} }) => {
    return {
      value: _.merge({}, theValue, input.value),
    }
  }),
  withHandlers({
    handleChange: props => field => (v, e) => {
      const { value, onChange } = props
      let newValue = {
        ...value,
        [field]: v,
      }

      newValue =
        newValue.configuration === 'ignore' || !newValue.configuration
          ? _.omit(newValue, ['type', 'value'])
          : newValue

      return onChange(newValue)
    },
  }),
)(IndexingParameterSelect)
