import { injectGlobal } from 'emotion'

injectGlobal`
h3.TitleSection {
  display: block;
  margin-top: 0px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  font-size: 16px;
  &:after {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
    content: '';
    background: gray;
    height: 1px;
    width: 100px;
  }
}
`
