import React from 'react'
import { Collapse } from 'antd'
import { compose, withStateHandlers, withHandlers, lifecycle } from 'recompose'

export const BrCollapse = props => {
  const { handleChange, localActiveKey, children } = props

  return (
    <Collapse
      {...props}
      onChange={handleChange}
      activeKey={localActiveKey}
    >
      {children}
    </Collapse>
  )
}

export default compose(
  withStateHandlers(
    {
      localActiveKey: [],
    },
    {
      setActiveKey: props => activeKey => ({
        localActiveKey: activeKey,
      }),
    },
  ),
  withHandlers({
    handleChange: props => activeKey => {
      if (props.onChange) props.onChange(activeKey)

      return props.setActiveKey(activeKey)
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.setActiveKey([...this.props.defaultActiveKey, ...(this.props.activeKey || [])])
    },
    componentWillReceiveProps(nextProps = {}) {
      let keysToSet
      if (nextProps.activeKey !== this.props.localActiveKey) {
        keysToSet = nextProps.activeKey
      }

      if (nextProps.defaultActiveKey !== this.props.defaultActiveKey) {
        keysToSet = [...(keysToSet || []), ...(nextProps.defaultActiveKey || [])]
      }

      if (keysToSet) {
        this.props.setActiveKey(keysToSet)
      }
    },
  }),
)(BrCollapse)
