import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { Layout, Alert } from 'antd'
import { Icon, Input } from 'antd'
import { select } from '@rematch/select'
import withFormProps from 'src/components/form/withReduxFormProps'
import FormItem from 'src/components/form/FormItem/index'
import SubmitButton from 'src/components/form/SubmitButton/index'
import withConnectedForm from 'src/components/form/withConnectedForm'
import LoginStyle from '../Login.style'
import _ from 'lodash'

const { Content } = Layout

const UsernameInput = withFormProps(Input)

const ContactForm = props => {
  const { submitting, handleSubmit, isSent, logoSrc } = props

  return (
    <Layout className={LoginStyle}>
      <Content className="LoginPage-wrapper">
        <section className="LoginPage-logo">
          {logoSrc && <img src={logoSrc} alt=""/>}
        </section>
        <h3 className="TitleSection">Recupera password</h3>
        <form onSubmit={handleSubmit} className="LoginPage-form">
          {isSent && (
            <p>
              <Alert
                message="Fra pochi minuti riceverai l'email contenente le istruzioni per il recupero password"
                type="success"
                showIcon
              />
            </p>
          )}
          {!isSent && (
            <Fragment>
              <p>
                Inserisci il tuo username. Ti verrá inviata una email contenente
                le istruzioni per il recupero.
              </p>
              <FormItem name="username" component={UsernameInput}/>
            </Fragment>
          )}

          <SubmitButton
            submitting={submitting}
            disabled={isSent}
            className="LoginPage-submit"
          >
            Invia email
          </SubmitButton>

          <a href="#/login">
            <Icon type="left"/> vai indietro
          </a>
        </form>
      </Content>
    </Layout>
  )
}

const checkIfAlreadyLoggedIn = ({ isAuthenticated, history }) => {
  if (isAuthenticated) {
    history.push('#/')
  }
}

export default compose(
  connect(
    state => ({
      forgotPassword: select.agents.forgotPassword(state),
    }),
    dispatch => ({
      sendForgotPasswordEmail: dispatch.agents.sendForgotPasswordEmail,
      getAccountSettings: dispatch.accountSettings.getMine,
      setCurrentAccount: dispatch.accounts.setCurrent,
    }),
  ),
  withConnectedForm('forgotPassword'),
  lifecycle({
    componentWillMount() {
      checkIfAlreadyLoggedIn(this.props)
    },
    componentWillReceiveProps(props) {
      checkIfAlreadyLoggedIn(props)
    },
  }),
  withPropsOnChange(['forgotPassword'], ({ forgotPassword }) => ({
    isSent: forgotPassword.isSent,
  })),
  withPropsOnChange(['accountSettings'], ({ accountSettings }) => ({
    logoSrc: _.get(accountSettings, 'public.ui.logo.src'),
  })),
  withHandlers({
    fetchData: props => async () => {
      const accountSettings = await props.getAccountSettings()
      if (accountSettings) {
        return props.setCurrentAccount(accountSettings)
      }
    },
    handleSubmit: props => event => {
      event.preventDefault()
      const { dispatchSubmit, formValues, sendForgotPasswordEmail } = props
      dispatchSubmit(sendForgotPasswordEmail(formValues))
    },
  }),
)(ContactForm)
