import React from 'react'
import { Button } from 'antd'
import {
  compose,
  withPropsOnChange,
  withState,
  withProps,
  withHandlers,
} from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import Modal from 'src/components/modals/Modal'
import Spinner from 'src/components/Spinner'
import SimpleTable from 'src/components/tables/SimpleTable/SimpleTable'

const MoveIntoDocumentsModal = props => {
  const {
    visible,
    isLoading,
    onCancel,
    documentTypes,
    handleSelect,
    columns,
  } = props

  return (
    <Modal
      title="Copia in documento pratica"
      width="900px"
      footer={<Button onClick={onCancel}>Annulla</Button>}
      visible={visible}
    >
      <Spinner isLoading={isLoading}>
        <SimpleTable
          data={documentTypes}
          columns={columns}
          onSelect={handleSelect}
        />
      </Spinner>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      documentTypes: select.papers.getMandatoryDocumentTypes(state),
    }),
    dispatch => ({
      getMandatoryDocuments: dispatch.papers.getMandatoryDocuments,
    }),
  ),
  withState('isLoading', 'setLoading', true),
  withProps({
    columns: [
      {
        title: 'Nome',
        field: 'name',
        sortable: true,
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Descrizione',
        field: 'description',
      },
      {
        displayName: '',
        type: 'actions',
        itemsType: 'button',
        actions: [
          {
            label: 'Seleziona',
            props: {
              onClick: ({ record, props }) => e => {
                const { onSelect } = props
                return onSelect(record)
              },
            },
          },
        ],
      },
    ],
  }),
  withPropsOnChange(
    ['visible'],
    async ({ visible, getMandatoryDocuments, paperId, setLoading }) => {
      await setLoading(true)
      if (visible) {
        await getMandatoryDocuments(paperId)
        await setLoading(false)
      }
    },
  ),
  withHandlers({
    handleSelect: props => documentType => {
      const { onOk } = props
      return onOk(null, documentType)
    },
  }),
)(MoveIntoDocumentsModal)
