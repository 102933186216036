import React from 'react'
import { compose, withHandlers, withPropsOnChange, withProps } from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Icon, Menu, Dropdown, message } from 'antd'
import { select } from '@rematch/select'
import { bindActionCreators } from 'redux'
import SimpleTable from '../../../../../components/tables/SimpleTable/SimpleTable'
import QuotesForm from '../../../../../components/quotes/QuotesForm'
import style from './PreventivesTab.style'
import { Modal as AntdModal } from 'antd/lib/index'
import { openQuoteViewModal } from 'src/components/modals/QuoteViewModal'
import Spinner from 'src/components/Spinner'

const query = {
  filter: {
    include: {
      relation: 'product',
      scope: {
        include: {
          relation: 'financialInstitution',
        },
      },
    },
  },
}

export const PreventivesTab = props => {
  const {
    paper,
    preventives,
    actions,
    fetchData,
    handleDeleteQuoteBtnClick,
    handleSyncQuoteBtnClick,
    openQuoteViewModal,
    isFetchingQuotes,
    columns,
    formDisabled,
  } = props

  return (
    <section className={style}>
      <section className="PreventivesTab-list">
        <Spinner fetchData={fetchData} isLoading={isFetchingQuotes}>
          <SimpleTable
            {...props}
            data={preventives}
            columns={columns}
            pagination={false}
            handleDeleteQuoteBtnClick={handleDeleteQuoteBtnClick}
            handleSyncQuoteBtnClick={handleSyncQuoteBtnClick}
            openQuoteViewModal={openQuoteViewModal}
          />
        </Spinner>
      </section>

      <section className="PreventivesTab-form">
        <h3 className="TitleSection">Nuovo Preventivo</h3>
        <QuotesForm
          paper={paper}
          productType={paper.productType}
          actions={actions}
        />
      </section>
    </section>
  )
}

export default compose(
  connect(
    state => ({
      preventives: select.quotes.getAll(state),
      quotePaper: select.papers.get(state, { basePath: 'tabs.quotes' }),
      isFetchingQuotes: select.quotes.isFetching(state),
    }),
    dispatch => ({
      findPaper: dispatch.papers.findOne,
      findQuotes: dispatch.quotes.find,
      createQuote: dispatch.quotes.create,
      deleteQuote: dispatch.quotes.delete,
      syncWithQuote: dispatch.papers.syncWithQuote,
      openQuoteViewModal: bindActionCreators(openQuoteViewModal, dispatch),
      createLog: dispatch.actionLogs.create,
    }),
  ),
  withHandlers({
    fetchData: props => () => {
      const { findQuotes, findPaper, paperId } = props

      return Promise.all([
        findQuotes(
          _.merge(
            {
              filter: {
                where: { paperId },
                order: 'createdAt DESC',
                skipDeleted: true,
              },
            },
            query,
          ),
        ),
        findPaper(
          {
            filter: {
              where: { id: paperId },
              fields: ['id', 'quoteId'],
            },
          },
          { basePath: 'tabs.quotes' },
        ),
      ])
    },
  }),
  withPropsOnChange(['quotePaper', 'paper'], ({ paper, quotePaper }) => ({
    paper: { ...paper, ...quotePaper },
  })),
  withProps(props => {
    const productTypeName = _.get(props.paper, 'productType.name')
    const isMortgage = ['mortgage'].includes(productTypeName)
    const isMortgageOrLoan = ['mortgage', 'loan'].includes(productTypeName)
    const isInsurance = ['insurance'].includes(productTypeName)

    return {
      columns: _.compact([
        { displayName: 'ID', field: 'internalId' },
        { displayName: 'Data', field: 'createdAt', type: 'date' },
        {
          displayName: 'Prodotto',
          field: 'product',
          render(product) {
            if (!product) return null

            const instituteName = _.get(product, 'financialInstitution.name')
            return `${instituteName} - ${product.name}`
          },
        },
        isMortgage && {
          displayName: 'Importo',
          field: 'amount',
          type: 'currency',
        },
        isMortgageOrLoan && {
          displayName: 'Valore immobile',
          field: 'estate.value',
          type: 'currency',
        },
        ...(isMortgageOrLoan
          ? [
              { displayName: 'TAN', field: 'rateValue', type: 'percentage' },
              { displayName: 'TAEG', field: 'taeg', type: 'percentage' },
              {
                displayName: 'Rata',
                field: 'instalmentAmount',
                type: 'currency',
              },
            ]
          : []),
        ...(isInsurance
          ? [
              {
                displayName: 'Premio Lordo',
                field: 'insurance.grossPremium',
                type: 'currency',
              },
              {
                displayName: 'Premio Netto',
                field: 'insurance.netPremium',
                type: 'currency',
              },
            ]
          : []),
        { displayName: 'Durata', field: 'duration' },
        {
          displayName: 'Stato',
          render(ignored, preventive) {
            const { paper } = this.props
            if (paper && paper.quoteId === preventive.id) {
              return (
                <span className="ant-badge ant-badge-not-a-wrapper">
                  <sup
                    data-show="true"
                    className="ant-scroll-number ant-badge-count"
                    style={{ 'background-color': 'rgb(135, 208, 104)' }}
                  >
                    Sincronizzato
                  </sup>
                </span>
              )
            }

            return null
          },
        },
        {
          displayName: '',
          render(value, quote, ignored, props) {
            const {
              handleSyncQuoteBtnClick,
              handleDeleteQuoteBtnClick,
              openQuoteViewModal,
              formDisabled,
            } = props
            const actionsDropdown = (
              <Menu>
                <Menu.Item key="0">
                  <div onClick={() => openQuoteViewModal({ id: quote.id })}>
                    Dettagli
                  </div>
                </Menu.Item>
                {!formDisabled && [
                  <Menu.Item key="1">
                    <div onClick={() => handleSyncQuoteBtnClick(quote)}>
                      Sincronizza con la pratica
                    </div>
                  </Menu.Item>,
                  <Menu.Divider />,
                  <Menu.Item key="3">
                    <div onClick={() => handleDeleteQuoteBtnClick(quote)}>
                      Elimina
                    </div>
                  </Menu.Item>,
                ]}
              </Menu>
            )
            return (
              <Dropdown overlay={actionsDropdown} trigger={['click']}>
                <a>
                  <Icon type="setting" style={{ fontSize: 16 }} />
                </a>
              </Dropdown>
            )
          },
        },
      ]),
    }
  }),
  withHandlers({
    handleSaveQuoteBtnClick: props => async preventive => {
      const { createQuote, fetchData, paperId, createLog } = props
      const quote = await createQuote({
        ...preventive,
        paperId,
      })

      await createLog({
        context: 'paper',
        paperId,
        type: 'addedQuote',
        data: {
          quoteId: quote.id,
        },
      })
      message.success('Preventivo aggiunto alla pratica')
      return fetchData()
    },
    handleDeleteQuoteBtnClick: props => async quote => {
      const { fetchData, deleteQuote, paperId, createLog } = props
      AntdModal.confirm({
        title: 'Elimina Preventivo pratica',
        content: `Sei sicuro di voler eliminare il preventivo "${
          quote.internalId
        }"?`,
        onOk: async () => {
          await deleteQuote({ id: quote.id })

          await createLog({
            context: 'paper',
            paperId,
            type: 'deletedQuote',
            data: {
              quoteId: quote.id,
            },
          })
          message.success('Preventivo Eliminato')
          return fetchData()
        },
      })
    },
    handleSyncQuoteBtnClick: props => async quote => {
      const { fetchData, syncWithQuote, paperId, createLog } = props
      AntdModal.confirm({
        title: 'Sincronizza Preventivo pratica',
        content: `Sei sicuro di voler sincronizzare il preventivo "${
          quote.internalId
        }" con la pratica?`,
        onOk: async () => {
          await syncWithQuote({ paperId, quoteId: quote.id })

          await createLog({
            context: 'paper',
            paperId,
            type: 'syncedQuote',
            data: {
              quoteId: quote.id,
            },
          })
          message.success('Preventivo sincronizzato')
          return fetchData()
        },
      })
    },
  }),
  withProps(({ handleSaveQuoteBtnClick }) => {
    return {
      actions: ({ record }) => {
        return (
          <div className="u-noWrap">
            <a onClick={() => handleSaveQuoteBtnClick(record)}>Salva</a>
          </div>
        )
      },
    }
  }),
)(PreventivesTab)
