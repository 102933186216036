import _get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect/lib/index'
import { matchPath } from 'react-router'

const checkVisibility = state => item => {
  if (typeof item.visible === 'function') {
    return item.visible(state)
  }

  return true
}

export const getMenu = createSelector(
  state => _get(state, 'menu.menu', []),
  state => _get(state, 'menu.allRoutes', []),
  state => state,
  (items, routes, state) => {
    const routesHash = keyBy(routes, 'name')
    return items
      .map(topMenuItem => {
        const groupItems = (topMenuItem.items || [])
          .map(groupItem => {
            const items = (groupItem.items || [])
              .map(item => ({
                ...item,
                path: item.path || (routesHash[item.name] && routesHash[item.name].path),
              }))
              .filter(checkVisibility(state))

            return {
              ...groupItem,
              items,
            }
          })
          .filter(checkVisibility(state))
        return {
          ...topMenuItem,
          items: groupItems,
          path:
            topMenuItem.path || (routesHash[topMenuItem.name] && routesHash[topMenuItem.name].path),
        }
      })
      .filter(checkVisibility(state))
  },
)

export const getActiveTopItem = createSelector(
  state => _get(state, 'routing.location'),
  state => getMenu(state),
  (currentRouteLocation, topItems) => {
    if (!currentRouteLocation) return undefined

    return topItems.find(topItem => {
      const isDirectMatch = matchPath(topItem.path, {
        path: currentRouteLocation.pathname,
      })
      const isAddedMatch = (topItem.matches || []).some(
        m =>
          (m.name && new RegExp(m.name).test(currentRouteLocation.pathname)) ||
          (m.path && new RegExp(m.name).test(currentRouteLocation.pathname)),
      )
      if (isDirectMatch || isAddedMatch) return true

      return (topItem.items || []).find(groupItem =>
        (groupItem.items || []).find(item =>
          matchPath(item.path, { path: currentRouteLocation.pathname }),
        ),
      )
    })
  },
)

export const getActiveSubmenu = createSelector(
  state => getActiveTopItem(state),
  activeTopItem => {
    if (!activeTopItem) return []

    return activeTopItem.items
  },
)

export const getActiveSubmenuItem = state => {
  const activeSubMenu = getActiveSubmenu(state)
  const currentRouteLocation = _get(state, 'routing.location')

  if (!currentRouteLocation) return undefined
  if (!activeSubMenu) return []

  let activeSubmenuItem
  activeSubMenu.forEach(groupItem => {
    ;(groupItem.items || []).forEach(item => {
      const sameSearch = (item.search || '') === currentRouteLocation.search
      const isMatch =
        matchPath(item.path, {
          path: currentRouteLocation.pathname,
        }) && sameSearch

      if (isMatch) activeSubmenuItem = activeSubmenuItem || item
    })
  })

  return activeSubmenuItem
}

export const getTopItemRoute = topItem => {
  let firstItem
  let defaultItem
  ;(topItem.items || []).forEach(groupItem =>
    (groupItem.items || []).forEach(item => {
      firstItem = firstItem || item
      defaultItem = item.default ? item : defaultItem
    }),
  )

  return defaultItem || firstItem
}

export const isMobileMenuOpened = state => {
  return state.mobileMenuOpened
}
