import compose from 'async/compose'

export default class BaseTransport {
  constructor() {
    this.middlewares = {
      pre: [],
      post: [],
    }
  }

  addMiddleware(stage, fn) {
    this.middlewares[stage].push(fn)
  }

  /* eslint-disable class-methods-use-this */
  execMiddlewares(stage, args, allMiddlewares = this.middlewares) {
    const middlewares = allMiddlewares[stage] || []

    return new Promise((resolve, reject) => {
      compose(...middlewares)(args, (err, result) => {
        if (err) return reject(err)
        return resolve(result)
      })
    })
  }
}
