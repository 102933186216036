import diff from 'deep-diff'

export const createWithDiff = async function ({
  data,
  previous,
  current,
  skipIfUnchanged = true,
}) {
  const difference = diff(previous, current)
  if (!difference && skipIfUnchanged) return true

  return this.create({
    ...data,
    data: {
      diff: difference,
    },
  })
}
