import React from 'react'
import asyncImport from 'src/lib/asyncImport'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

let importList = []
const asyncImportWithOpts = fn => {
  const component = asyncImport(fn, {
    onLoad: () =>
      importList.forEach(c => {
        c.preload()
        importList.shift()
      }),
  })
  importList.push(component)
  return component
}
const AgentGroups = asyncImportWithOpts(props => import('./commissions/AgentGroups'))
const CommissionRules = asyncImportWithOpts(props => import('./commissions/CommissionRules'))
const CommissionRuleEdit = asyncImportWithOpts(props => import('./commissions/CommissionRuleEdit'))
const CommissionRuleLevels = asyncImportWithOpts(props =>
  import('./commissions/CommissionRuleLevels'),
)
const Permissions = asyncImportWithOpts(props => import('./agents/Permissions'))
const Administrators = asyncImportWithOpts(props => import('./agents/Administrators'))
const ProgressList = asyncImportWithOpts(props => import('./papers/ProgressList'))
const PapersPreferences = asyncImportWithOpts(props => import('./papers/Preferences'))
const DocumentTypes = asyncImportWithOpts(props => import('./documentTypes/DocumentTypeList'))
const DocumentTypeEdit = asyncImportWithOpts(props => import('./documentTypes/DocumentTypeEdit'))
const EmailTemplateList = asyncImportWithOpts(props => import('./emails/EmailTemplateList'))
const AccountEdit = asyncImportWithOpts(props => import('./account/AccountEdit'))
const DocumentTemplateList = asyncImportWithOpts(props =>
  import('./documentTemplates/DocumentTemplateList'),
)
const VariableList = asyncImportWithOpts(props => import('./other/VariableList'))
const OfficesList = asyncImportWithOpts(props => import('./agents/OfficesList'))
const ChannelList = asyncImportWithOpts(props => import('./other/ChannelList'))

const Hierarchy = asyncImportWithOpts(props => import('./agents/Hierarchy'))
const EmailTemplateEdit = asyncImportWithOpts(props => import('./emails/EmailTemplateEdit'))
const PaperEmailsEdit = asyncImportWithOpts(props => import('./emails/PaperEmailsEdit'))
const DocumentTemplateEdit = asyncImportWithOpts(props =>
  import('./documentTemplates/DocumentTemplateEdit'),
)

export const routes = [
  {
    name: 'setup.account',
    path: '/setup/account',
    component: withCheckAuthOnEnter(withPageTracker()(AccountEdit)),
  },
  {
    name: 'setup.commissions.groups',
    path: '/setup/commissions/groups',
    component: withCheckAuthOnEnter(withPageTracker()(AgentGroups)),
  },
  {
    name: 'setup.commissions.payInRules',
    path: '/setup/commissions/rules/in',
    component: withCheckAuthOnEnter(withPageTracker()(props => <CommissionRules {...props} type="in" />)),
  },
  {
    name: 'setup.commissions.payInRules.edit',
    path: '/setup/commissions/rules/in/edit/:id',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <CommissionRuleEdit {...props} type="in" />),
    ),
  },
  {
    name: 'setup.commissions.payInRules.add',
    path: '/setup/commissions/rules/in/add',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <CommissionRuleEdit {...props} type="in" />),
    ),
  },

  {
    name: 'setup.commissions.payOutRules',
    path: '/setup/commissions/rules/out',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <CommissionRules {...props} type="out" />),
    ),
  },
  {
    name: 'setup.commissions.payOutRules.edit',
    path: '/setup/commissions/rules/out/edit/:id',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <CommissionRuleEdit {...props} type="out" />),
    ),
  },
  {
    name: 'setup.commissions.payOutRules.add',
    path: '/setup/commissions/rules/out/add',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <CommissionRuleEdit {...props} type="out" />),
    ),
  },
  {
    name: 'setup.commissions.payOutRules.levels',
    path: '/setup/commissions/rules/out/:id/levels',
    component: withCheckAuthOnEnter(withPageTracker()(CommissionRuleLevels)),
  },
  {
    name: 'setup.agents.permissions',
    path: '/setup/agents/permissions',
    component: withCheckAuthOnEnter(withPageTracker()(Permissions)),
  },
  {
    name: 'setup.agents.administrators',
    path: '/setup/agents/administrators',
    component: withCheckAuthOnEnter(withPageTracker()(Administrators)),
  },
  {
    name: 'setup.agents.hierarchy',
    path: '/setup/agents/hierarchy',
    component: withCheckAuthOnEnter(withPageTracker()(Hierarchy)),
  },
  {
    name: 'setup.agents.offices',
    path: '/setup/agents/offices',
    component: withCheckAuthOnEnter(withPageTracker()(OfficesList)),
  },
  {
    name: 'setup.papers.progressList',
    path: '/setup/papers/progressList',
    component: withCheckAuthOnEnter(withPageTracker()(ProgressList)),
  },
  {
    name: 'setup.papers.preferences',
    path: '/setup/papers/preferences',
    component: withCheckAuthOnEnter(withPageTracker()(PapersPreferences)),
  },
  {
    name: 'setup.documentTypes.paper.list',
    path: '/setup/documentTypes/paper/list',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <DocumentTypes {...props} entity="paper" />),
    ),
  },
  {
    name: 'setup.documentTypes.paper.edit',
    path: '/setup/documentTypes/paper/edit/:id',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <DocumentTypeEdit {...props} entity="paper" />),
    ),
  },
  {
    name: 'setup.documentTypes.paper.add',
    path: '/setup/documentTypes/paper/add',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <DocumentTypeEdit {...props} entity="paper" />),
    ),
  },
  {
    name: 'setup.documentTypes.agent.list',
    path: '/setup/documentTypes/agent/list',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <DocumentTypes {...props} entity="agent" />),
    ),
  },
  {
    name: 'setup.documentTypes.agent.edit',
    path: '/setup/documentTypes/agent/edit/:id',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <DocumentTypeEdit {...props} entity="agent" />),
    ),
  },
  {
    name: 'setup.documentTypes.agent.add',
    path: '/setup/documentTypes/agent/add',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <DocumentTypeEdit {...props} entity="agent" />),
    ),
  },
  {
    name: 'setup.documentTemplates.list',
    path: '/setup/documentTemplates/list',
    component: withCheckAuthOnEnter(withPageTracker()(DocumentTemplateList)),
  },
  {
    name: 'setup.documentTemplates.edit',
    path: '/setup/documentTemplates/edit/:id',
    component: withCheckAuthOnEnter(withPageTracker()(DocumentTemplateEdit)),
  },
  {
    name: 'setup.documentTemplates.add',
    path: '/setup/documentTemplates/add',
    component: withCheckAuthOnEnter(withPageTracker()(DocumentTemplateEdit)),
  },

  {
    name: 'setup.emailTemplates.list',
    path: '/setup/emailTemplates/list',
    component: withCheckAuthOnEnter(withPageTracker()(EmailTemplateList)),
  },
  {
    name: 'setup.emailTemplates.add',
    path: '/setup/emailTemplates/add',
    component: withCheckAuthOnEnter(withPageTracker()(EmailTemplateEdit)),
  },
  {
    name: 'setup.emailTemplates.edit',
    path: '/setup/emailTemplates/edit/:id',
    component: withCheckAuthOnEnter(withPageTracker()(EmailTemplateEdit)),
  },
  {
    name: 'setup.paperEmails.edit',
    path: '/setup/paperEmails/edit',
    component: withCheckAuthOnEnter(withPageTracker()(PaperEmailsEdit)),
  },
  {
    name: 'setup.variables.list',
    path: '/setup/variables/list',
    component: withCheckAuthOnEnter(withPageTracker()(VariableList)),
  },
  {
    name: 'setup.channels.list',
    path: '/setup/channels/list',
    component: withCheckAuthOnEnter(withPageTracker()(ChannelList)),
  },
]
