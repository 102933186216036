import { css } from 'emotion'

export default css`
  & {
    .ant-table-body {
      overflow-x: auto;
      background: white;
    }
    .ant-table,
    .ant-table-content,
    table {
      /*display: inline-block;
      width: auto;*/
    }
    table {
      tr {
        td,
        th {
          max-width: 250px;
          .ant-form-item {
            margin-bottom: 0;
          }
          .ant-form-item-label {
            line-height: 1;
            font-size: 10px;
          }
          .ant-form-item-control {
            line-height: 1;
          }
        }
      }
    }
  }
`
