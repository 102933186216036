import * as effects from './effects'
import * as reducers from './reducers'
import * as selectors from './selectors'

export default () => ({
  state: {
    isMobileMenuOpen: false,
  },
  effects,
  reducers,
  selectors,
})

