import { createSelector } from 'reselect'
import _ from 'lodash'
import _fp from 'lodash/fp'

export const me = createSelector(
  state => state.me,
  data => data,
)

export const isAdmin = createSelector(
  state => _.get(state, 'me.me', {}),
  me => me.isSuperAdmin || !!_.find(me.roles, { name: 'admin' }),
)

export const isAdminOrBackoffice = createSelector(
  state => _.get(state, 'me.me.roles', []),
  roles => !!_.find(roles, role => ['admin', 'backoffice'].includes(role.name)),
)

export const isAuthenticated = createSelector(
  state => state.isAuthenticated,
  data => data,
)

export const token = createSelector(
  state => state.token,
  data => data,
)

export const forgotPassword = createSelector(
  state => state.forgotPassword,
  data => data,
)

export const changedPassword = createSelector(
  state => state.changedPassword,
  data => data,
)

export const documentTypes = createSelector(
  _fp.get('documentTypes.data'),
  _fp.identity,
)
