import httpProviderInstance from 'src/lib/httpProviderInstance'
import httpProvider from '../../../lib/httpProviderInstance'
import param from 'jquery-param'
import _ from 'lodash'

export const getDownloadUrl = ({ uuid, query }) => {
  return httpProviderInstance
    .makeRequest({
      method: 'GET',
      path: `files/${uuid}/downloadurl`,
      params: query,
    })
    .then(response => response.data.url)
}

export const getUploadUrl = ({ type } = {}) => {
  return httpProviderInstance
    .makeRequest('GET', `files/uploadurl`, { type })
    .then(response => response.data.url)
}


export const downloadFileFromUrl = ({ url: u, includeToken }, rootState) => {
  let url = u
  if (includeToken) {
    const token = rootState.agents.token
    if (url.indexOf('?') > -1) {
      url = url + `&access_token=${token}`
    } else {
      url = url + `?access_token=${token}`
    }

    if (url.indexOf('http') !== 0) {
      url = [
        process.env.REACT_APP_API_BASE,
        url,
      ]
        .map(p => _.trim(p, '/'))
        .join('/')
    }
  }

  const a = document.createElement('A')
  a.href = url
  a.target = '_blank'
  a.download = url.substr(url.lastIndexOf('/') + 1)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}


export const getByUUID = async function (args) {
  const { uuid, query } = args
  try {
    await this.GET_FILE_REQUESTED(args)
    const { data } = await httpProvider.makeRequest({
      method: 'GET',
      path: `/files/byUUID/${uuid}`,
      query,
    })
    await this.GET_FILE_FULFILLED(data)
    return data
  } catch (err) {
    await this.GET_FILE_FAILED(err)
    throw err
  }
}
