import throttle from 'lodash/throttle'
import { actionTypes } from '../store/modules/env'

const bindWindowResize = store => {
  const handleResize = () => {
    store.dispatch.env.windowResize()
  }

  const handleResizeThrottled = throttle(handleResize, 200)

  window.addEventListener('resize', handleResizeThrottled, false)
  window.addEventListener('orientationchange', handleResizeThrottled, false)
}

export default bindWindowResize
