import _ from 'lodash'
import { select } from '@rematch/select'

const checkIfAdmin = state => {
  const me = _.get(state, 'agents.me.me') || {}
  return me.isSuperAdmin || !!_.find(me.roles, { name: 'admin' })
}

const hasEmailsFeature = state => select.accountSettings.hasFeature(state, { feature: 'hasEmails' })

const menuStructure = [
  {
    title: 'home',
    name: 'home',
    style: { display: 'none' },
    showLeftPanel: false,
    items: [
      {
        title: '',
        items: [{ title: 'Home', name: 'home' }],
      },
    ],
  },
  {
    title: 'Pratiche',
    name: 'papers.list',
    matches: [{ name: 'papers.view' }],
    items: [
      {
        title: '',
        items: [
          { title: 'Lista Pratiche', name: 'papers.list' },
          { title: 'Nuova Pratica', name: 'papers.add' },
        ],
      },
    ],
  },
  {
    title: 'Preventivi',
    name: 'quotes.list',
    matches: [{ name: 'quotes.view' }],
    items: [
      {
        title: 'Preventivi',
        items: [{ title: 'Lista Preventivi', name: 'quotes.list' }],
      },
      {
        title: 'Nuovo Preventivo',
        items: [
          { title: 'Mutuo', name: 'quotes.add.mortgage' },
          { title: 'Prestito', name: 'quotes.add.loan' },
          { title: 'Assicurazione', name: 'quotes.add.insurance' },
        ],
      },
    ],
  },
  {
    title: 'Anagrafiche',
    name: 'clients.list',
    matches: [
      { name: 'clients.edit' },
      { name: 'agents.list' },
      { name: 'agents.edit' },
      { name: 'agents.add' },
    ],
    items: [
      {
        title: 'Clienti',
        items: [
          { title: 'Lista Clienti', name: 'clients.list' },
          { title: 'Nuovo Cliente', name: 'clients.add' },
          /*{ title: 'Nuovo Istituto ', name: 'financialInstitutions.create' },*/
        ],
      },
      {
        title: 'Agenti',
        items: [
          { title: 'Lista Agenti', name: 'agents.list' },
          { title: 'Nuovo Agente', name: 'agents.add', visible: checkIfAdmin },
          { title: 'Budget', name: 'budget.edit', visible: checkIfAdmin },
        ],
      },
    ],
  },
  {
    title: 'Prodotti',
    name: 'products.list',
    matches: [{ name: 'financialInstitutions' }, { name: 'products' }],
    items: [
      {
        title: 'Prodotti',
        items: [
          { title: 'Lista Prodotti', name: 'products.list' },
          {
            title: 'Nuovo Prodotto',
            name: 'products.add',
            visible: checkIfAdmin,
          },
        ],
      },
      {
        title: 'Istituti Finanziari',
        items: [
          { title: 'Lista Istituti', name: 'financialInstitutions.list' },
          {
            title: 'Nuovo Istituto',
            name: 'financialInstitutions.add',
            visible: checkIfAdmin,
          },
        ],
      },
    ],
  },
  {
    title: 'Reports',
    name: 'reportProfiles.list',
    visible: state => select.accountSettings.hasFeature(state, { feature: 'hasReports' }),
    matches: [{ name: 'report(.+)' }],
    items: [
      {
        title: 'Profili Report',
        items: [
          { title: 'Tutti i Profili', name: 'reportProfiles.list' },
          { title: 'Nuovo Profilo', name: 'reportProfiles.create' },
        ],
      },
      {
        title: 'Storico Report',
        items: [
          { title: 'Tutti i Reports', name: 'reports.list' },
          { title: 'Reports Pratiche', name: 'reports.listPapers' },
          /*{ title: 'Reports Agenti', name: 'reports.listAgents' },*/
          { title: 'Nuovo Report', name: 'reports.create' },
        ],
      },
    ],
  },
  {
    title: 'Inbox',
    path: '/inbox?pending=true',
    visible: hasEmailsFeature,
    matches: [{ name: 'inbox.*' }],
    items: [
      {
        title: '',
        items: [{ title: 'Tutte le email' }],
      },
    ],
  },
  {
    title: 'Documenti',
    name: 'browse',
    // matches: [{ path: '/browse(.+)' }],
    items: [
      {
        title: '',
        items: [{ title: 'Tutti i documenti', name: 'browse' }],
      },
    ],
  },
  {
    title: 'Impostazioni',
    visible: checkIfAdmin,
    name: 'setup.account',
    matches: [{ name: 'setup' }],
    items: [
      {
        title: 'Account',
        items: [{ title: 'Impostazioni account', name: 'setup.account' }],
      },
      {
        title: 'Pratiche',
        items: [
          { title: 'Preferenze', name: 'setup.papers.preferences' },
          { title: 'Stati', name: 'setup.papers.progressList' },
        ],
      },
      {
        title: 'Agenti',
        items: [
          { title: 'Sedi', name: 'setup.agents.offices' },
          { title: 'Gerarchia', name: 'setup.agents.hierarchy' },
          { title: 'Permessi utente', name: 'setup.agents.permissions' },
          { title: 'Amministratori', name: 'setup.agents.administrators' },
        ],
      },
      {
        title: 'Provvigioni',
        items: [
          { title: 'Gruppi Provvigionali', name: 'setup.commissions.groups' },
          { title: 'Regole Pay in', name: 'setup.commissions.payInRules' },
          { title: 'Regole Pay out', name: 'setup.commissions.payOutRules' },
        ],
      },
      {
        title: 'Documenti',
        matches: 'setup.documentTypes',
        items: [
          { title: 'Documenti Pratica', name: 'setup.documentTypes.paper.list' },
          { title: 'Documenti Agente', name: 'setup.documentTypes.agent.list' },
          { title: 'Template Documenti', name: 'setup.documentTemplates.list' },
        ],
      },
      {
        title: 'Email',
        visible: hasEmailsFeature,
        items: [
          { title: 'Modelli Email', name: 'setup.emailTemplates.list' },
          { title: 'Email pratica', name: 'setup.paperEmails.edit' },
        ],
      },
      {
        title: 'Altro',
        items: [
          { title: 'Lista Variabili', name: 'setup.variables.list' },
          { title: 'Lista Canali', name: 'setup.channels.list' },
        ],
      },
    ],
  },
]

export default menuStructure
