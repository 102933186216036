import React from 'react'
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import withEnhancedRouter from '../../components/withEnhancedRouter'
import _ from 'lodash'

export default compose(
  connect(
    (state) => ({
      paperCategories: select.emails.paperCategories(state),
    }),
    dispatch => ({
      fetchPaperCategories: dispatch.emails.fetchPaperCategories,
      newComposeEmail: dispatch.emails.newComposeEmail,
    }),
  ),
  withHandlers({
    onChange: props => () => {
      return props.fetchPaperCategories()
    },
  }),
  withEnhancedRouter,
  withPropsOnChange(['match'], ({ match }) => ({
    paperId: _.get(match, 'search.paperId'),
  })),
  lifecycle({
    componentWillMount() {
      this.props.fetchPaperCategories()
    },
  }),
)
