import ClientList from './ClientList'
import ClientView from './ClientEdit'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'clients.list',
    path: '/clients/list',
    component: withCheckAuthOnEnter(withPageTracker()(ClientList)),
  },
  {
    name: 'clients.edit',
    path: '/clients/edit/:id',
    component: withCheckAuthOnEnter(withPageTracker()(ClientView)),
  },
  {
    name: 'clients.add',
    path: '/clients/add',
    component: withCheckAuthOnEnter(withPageTracker()(ClientView)),
  },
]
