import React from 'react'
import { cx } from 'emotion'

import './Hamburger.style'

const Hamburger = ({ isOpen }) => (
  <button
    className={cx('hamburger', 'hamburger--squeeze', {
      'is-active': isOpen,
    })}
    type="button"
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"/>
    </span>
  </button>
)

export default Hamburger
