import { css } from 'emotion'

export default css`
& {
  position: relative;
  .FormulaInput-textArea {

  }
  .FormulaInput-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

`
