import { createFactory, Component, PureComponent } from 'react'
import get from 'lodash/get'
import merge from 'lodash/merge'
// import _ from 'lodash'

const DEFAULT_OPTIONS = { immediate: false, listIterateeCondition: 'some' }

const withWatcher = (watchList, opts, cb) => {
  const options = merge({}, DEFAULT_OPTIONS, typeof opts === 'function' ? {} : opts)

  const callback = typeof opts === 'function' ? opts : cb
  return BaseComponent => {
    const factory = createFactory(BaseComponent)
    const hasChanged = (prevProps, props, theWatchList = watchList) => {
      if (Array.isArray(theWatchList))
        return theWatchList[options.listIterateeCondition](w => hasChanged(prevProps, props, w))

      return get(prevProps, theWatchList) !== get(props, theWatchList)
    }

    let first = true

    class WithWatcher extends PureComponent {
      state = {
        prevProps: this.props,
      }

      static getDerivedStateFromProps(nextProps, prevState) {
        const mustRunImmediate = options.immediate && first

        if (mustRunImmediate || hasChanged(prevState.prevProps, nextProps, watchList)) {
          callback(nextProps, prevState.prevProps)
          first = false
        }

        return {
          prevProps: nextProps,
        }
      }

      render() {
        return factory({
          ...this.props,
        })
      }
    }

    return WithWatcher
  }
}

export default withWatcher
