import { css } from 'emotion'

export default css`
.am-tabs-bar .am-tabs-tab {
  font-size: 12px;
  height: auto;
  min-height: 30px;
  line-height: 1.4;
}
.am-tabs-ink-bar {
  height: 2px;
}
.am-tabs-content {
  margin-top: 20px;
}

.am-tabs-default-bar-top {
  .am-tabs-default-bar-content {
    //overflow: scroll;
  }
  .am-tabs-default-bar-tab {
    //width: auto !important;
    white-space: nowrap;
    padding: 0 15px;
  }
}
`
