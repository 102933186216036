import mergeDeepRight from 'ramda/es/mergeDeepRight'

export const FETCH_BUDGET_STATS_REQUESTED = (state, payload) =>
  mergeDeepRight(state, {
    stats: {
      isFetching: true,
      isFailed: false,
    },
  })

export const FETCH_BUDGET_STATS_FULFILLED = (state, payload) =>
  mergeDeepRight(state, {
    stats: {
      data: payload,
      isFetching: false,
      isFailed: false,
    },
  })

export const FETCH_BUDGET_STATS_FAILED = (state, err) =>
  mergeDeepRight(state, {
    stats: {
      isFetching: false,
      isFailed: err,
    },
  })

export const CREATE_BUDGETS_REQUESTED = (state, payload) => state

export const CREATE_BUDGETS_FULFILLED = (state, payload) => state

export const CREATE_BUDGETS_FAILED = (state, err) => state
