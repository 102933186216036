import React from 'react'
import {
  compose,
  withHandlers,
  withStateHandlers,
  withPropsOnChange,
  withState,
  lifecycle,
} from 'recompose'
import { Divider, Button, Icon, Tooltip } from 'antd'
import { Element, scroller } from 'react-scroll'
import { connect } from 'react-redux'
import _ from 'lodash'
import Promise from 'bluebird'
import EmailEditor from './EmailEditor'
import AttachmentList from 'src/components/emails/AttachmentList'
import { withSafeTimeout } from '@hocs/safe-timers'
import { change as changeFormValue } from 'redux-form'
import style from './Email.style'

const Email = props => {
  const {
    email = {},
    paper,
    paperId,
    parentEmailId,
    isEditorVisible,
    handleEmailSent,
    handleReplyBtnClick,
    handleDeleteDraftBtnClick,
    newEmailInitialValues,
    attachmentActions,
    handleFormNameChange,
  } = props

  function createMarkup() {
    return { __html: email.body }
  }

  return (
    <article className={style}>
      <section className="Email-top">
        <div className="Email-top-info">
          destinatari: {email.to.map(recipient => recipient.email).join(', ')}
        </div>
        <div className="Email-top-actions">
          <Tooltip title="Rispondi">
            <Button shape="circle" size="large" onClick={handleReplyBtnClick}>
              <Icon type="message" theme="outlined" />
            </Button>
          </Tooltip>
          {/*<Button shape="circle">
            <i className="fa fa-ellipsis-v"/>
          </Button>*/}
        </div>
      </section>
      <div className="Email-content">
        <span dangerouslySetInnerHTML={createMarkup()} />
      </div>
      <AttachmentList
        canView={true}
        attachments={email.attachments}
        attachmentActions={attachmentActions}
      />
      <Divider />
      <Element name="emailEditor">
        {isEditorVisible && (
          <EmailEditor
            initialValues={newEmailInitialValues}
            paper={paper}
            paperId={paperId}
            parentEmailId={parentEmailId}
            onEmailSent={handleEmailSent}
            onDeleteDraftBtnClick={handleDeleteDraftBtnClick}
            onFormNameChange={handleFormNameChange}
          />
        )}
      </Element>
    </article>
  )
}

export default compose(
  withSafeTimeout,
  connect(
    state => {},
    dispatch => ({
      markAsRead: dispatch.emails.markAsRead,
      changeFormValue,
    }),
  ),
  withState('emailEditorFormName', 'setEmailEditorFormName', null),
  withPropsOnChange(['email', 'paper'], ({ email, paper }) => {
    const { from, cc, bcc, subject } = email
    return {
      newEmailInitialValues: {
        to: _.compact([{ ...from }]),
        cc: cc,
        bcc: bcc,
        subject: `Re: ${subject}`,
      },
    }
  }),
  withStateHandlers(
    {},
    {
      setEditorVisibility: props => isEditorVisible => ({ isEditorVisible }),
    },
  ),
  withHandlers({
    handleReplyBtnClick: props => async e => {
      e.preventDefault()
      e.stopPropagation()
      const {
        emailEditorFormName,
        newEmailInitialValues,
        setEditorVisibility,
        changeFormValue,
      } = props
      await Promise.map(_.keys(newEmailInitialValues), fieldName => {
        const fieldValue = newEmailInitialValues[fieldName]
        return changeFormValue(emailEditorFormName, fieldName, fieldValue)
      })
      setEditorVisibility(true)
      process.nextTick(() => {
        scroller.scrollTo('emailEditor', {
          smooth: true,
          duration: 500,
          containerId: 'DefaultLayout-content',
        })
      })
    },
    handleDeleteDraftBtnClick: props => () => {
      props.setEditorVisibility(false)
    },
    handleEmailSent: props => result => {
      props.setEditorVisibility(false)
      if (props.onEmailSent) {
        props.onEmailSent(result)
      }
    },
    handleFormNameChange: props => formName => {
      props.setEmailEditorFormName(formName)
    },
  }),
  lifecycle({
    componentDidMount() {
      return this.props.markAsRead({ emailIds: [this.props.email.id] })
    },
  }),
)(Email)
