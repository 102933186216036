import React, { Fragment } from 'react'
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withProps,
  withState,
  defaultProps,
} from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { select } from '@rematch/select'
import _ from 'lodash'
import {
  Table,
  Icon,
  Spin,
  Breadcrumb,
  Button,
  Dropdown,
  Menu,
  Modal,
  message,
} from 'antd'
import { cx } from 'emotion'
import $ from 'jquery'
import Promise from 'bluebird'
import DocumentViewModal from 'src/components/modals/DocumentViewModal'
import NewFolderModal from './fileBrowser/NewFolderModal'
import withModalControls from 'src/components/modals/withModalControls'
import UploadModal, { openUploadModal } from 'src/components/modals/UploadModal'
import FormattedDate from 'src/components/format/FormattedDate'
import style from './FileBrowser.style'

export const FileBrowser = props => {
  const {
    path: topPath,
    content,
    columns,
    modality,
    onRow,
    isFetching,
    breadcrumbsItems,
    documentPreviewModal,
    documentPreviewItems,
    documentPreviewActiveItemIndex,
    newFolderModal,
    handlePathClick,
    handleActionClick,
    handleTableChange,
    context,
    productId,
    financialInstitutionId,
  } = props
  return (
    <section className={cx('FileBrowser', style)}>
      <div className="FileBrowser-top">
        <Breadcrumb className="FileBrowser-breadcrumbs">
          {breadcrumbsItems.map(p => (
            <Breadcrumb.Item>
              <a
                className="ant-breadcrumb-link"
                onClick={e => handlePathClick(e, p)}
              >
                {p.isRoot && (
                  <span>
                    <Icon type="home"/>
                  </span>
                )}
                {!p.isRoot && p.name}
              </a>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        {modality === 'modify' && (
          <div className="FileBrowser-actions">
            <Button
              size="small"
              icon="upload"
              onClick={handleActionClick('uploadFiles')}
            >
              Upload file
            </Button>
            <Button
              size="small"
              icon="folder-add"
              onClick={handleActionClick('newFolder')}
            >
              Nuova cartella
            </Button>
          </div>
        )}
      </div>
      <div
        className={cx('FileBrowser-tableWrapper', { 'is-loading': isFetching })}
      >
        <Spin spinning={isFetching}>
          <Table
            columns={columns}
            dataSource={content}
            pagination={false}
            onRow={onRow}
            onChange={handleTableChange}
            locale={{
              emptyText: !isFetching && 'Nessun file o cartella',
            }}
            size="small"
          />
        </Spin>
      </div>

      {documentPreviewModal.visible && (
        <DocumentViewModal
          {...documentPreviewModal}
          activeItemIndex={documentPreviewActiveItemIndex}
          items={documentPreviewItems}
          footer={false}
        />
      )}

      {modality === 'modify' && topPath && (
        <Fragment>
          <NewFolderModal
            {...newFolderModal}
            initialValues={{
              parentId: topPath.id,
              type: 'folder',
              context: context || 'generic',
              productId,
              financialInstitutionId,
            }}
          />
        </Fragment>
      )}
    </section>
  )
}

export default compose(
  connect(
    state => ({
      browse: select.paths.browse(state),
    }),
    dispatch => ({
      fetchPath: dispatch.paths.fetchPath,
      createPath: dispatch.paths.create,
      deletePath: dispatch.paths.delete,
      getDownloadUrl: dispatch.files.getDownloadUrl,
      downloadFileFromUrl: dispatch.files.downloadFileFromUrl,
      openUploadModal: bindActionCreators(openUploadModal, dispatch),
      createFile: dispatch.files.create,
    }),
  ),
  defaultProps({
    onPathClick: props => () => {},
    modality: 'view',
  }),
  withState('currentPathId', 'setCurrentPathId', props => props.pathId),
  withModalControls('documentPreviewModal'),
  withModalControls('newFolderModal'),
  withState('documentPreviewItems', 'setDocumentPreviewItems', []),
  withState('contentFilter', 'setContentFilter', { order: 'name ASC' }),
  withState(
    'documentPreviewActiveItemIndex',
    'setDocumentPreviewActiveItemIndex',
  ),
  withPropsOnChange(['pathId'], ({ pathId, setCurrentPathId }) =>
    setCurrentPathId(pathId),
  ),
  withPropsOnChange(['browse'], ({ browse }) => {
    const path = _.get(browse, 'path', [])
    const ancestors = _.get(browse, 'ancestors', [])
    return {
      path,
      ancestors,
      content: _.get(browse, 'content', []),
      breadcrumbsItems: _.compact([...ancestors, path]),
      isFetching: browse.isFetching,
      isFailed: browse.isFailed,
    }
  }),
  withHandlers({
    fetchData: props => () => {
      const {
        fetchPath,
        currentPathId,
        context,
        financialInstitutionId,
        productId,
        contentFilter,
      } = props

      return fetchPath({
        pathId: currentPathId,
        context,
        financialInstitutionId,
        productId,
        contentFilter,
      })
    },
  }),
  withHandlers({
    handleTableChange: props => async (pagination, filters, sorter) => {
      const { setContentFilter, fetchData } = props
      if (sorter) {
        const { field, order } = sorter
        const direction = order === 'ascend' ? 'asc' : order === 'descend' && 'desc'
        await setContentFilter({ order: `${field} ${direction || 'asc'}` })
        return fetchData()
      }
    },
    navigateTo: props => ({ pathId }) => {
      const { setCurrentPathId } = props
      return setCurrentPathId(pathId)
    },
    handleUploadModalSave: props => async modalProps => {
      const {
        createFile,
        createPath,
        path: topPath,
        fetchData,
        context,
        productId,
        financialInstitutionId,
      } = props
      const { files } = modalProps
      try {
        await Promise.map(files, async fileItem => {
          const fileData = {
            name: fileItem.name,
            type: fileItem.type,
            url: fileItem.response.url,
            context: 'generic',
          }
          const file = await createFile(fileData)
          const path = await createPath({
            name: file.name,
            fileId: file.id,
            parentId: topPath.id,
            type: 'file',
            context: context || 'generic',
            productId,
            financialInstitutionId,
          })

          return path
        })
        message.success('File caricati con successo')
        return fetchData()
      } catch (err) {
        console.log(err)
        message.error(`Errore durante l'upload dei file`)
      }
    },
  }),
  withHandlers({
    handlePathClick: props => async (e, path) => {
      const {
        onPathClick,
        navigateTo,
        setDocumentPreviewItems,
        content,
        documentPreviewModal,
        setDocumentPreviewActiveItemIndex,
      } = props
      onPathClick(e, path)
      if (e.isDefaultPrevented()) return null

      if (path.type === 'folder') return navigateTo({ pathId: path.id })
      if (path.type === 'file') {
        const filesContent = _.filter(content, { type: 'file' })
        setDocumentPreviewItems(filesContent)
        setDocumentPreviewActiveItemIndex(filesContent.indexOf(path))

        documentPreviewModal.show()
      }
    },
    handleActionClick: props => actionType => async () => {
      const {
        newFolderModal,
        fetchData,
        openUploadModal,
        handleUploadModalSave,
      } = props
      if (actionType === 'newFolder') {
        const result = await newFolderModal.show()
        if (result) {
          return fetchData()
        }
      }

      if (actionType === 'uploadFiles') {
        return openUploadModal({
          onSave: handleUploadModalSave,
        })
      }
    },
    handleDownloadBtnClick: props => async path => {
      const url = await props.getDownloadUrl({
        uuid: path.file.uuid,
        query: { disposition: 'attachment' },
      })
      return props.downloadFileFromUrl({ url })
    },
    handleDeleteBtnClick: props => path => {
      const { deletePath } = props
      const { type, name } = path
      Modal.confirm({
        title: `Elimina ${type === 'file' ? 'file' : 'cartella'}`,
        content: (
          <div>
            Sei sicuro di voler eliminare &nbsp;
            {type === 'file' ? 'il file' : 'la cartella'}: <br/>
            <b>{name}</b>?
          </div>
        ),
        onOk: async () => {
          await deletePath({ id: path.id })
          return [props.fetchData(), message.success('Eliminazione effettuata')]
        },
      })
    },
  }),
  withProps(props => {
    const { modality } = props
    const columns = [
      {
        title: '',
        dataIndex: 'type',
        className: 'FileBrowser-row-icon',
        render(type) {
          return <Icon type={type}/>
        },
      },
      {
        title: 'Nome',
        className: 'FileBrowser-row-name',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Data caricamento',
        className: 'FileBrowser-row-date',
        dataIndex: 'createdAt',
        sorter: true,
        render: date => <FormattedDate date={date}/>,
      },
      {
        title: '',
        className: 'FileBrowser-row-actions',
        dataIndex: 'id',
        render(id, record) {
          if (record.type === 'folder' && modality === 'view') return null
          const menu = (
            <Menu>
              {record.type === 'file' && (
                <Menu.Item>
                  <div onClick={() => props.handleDownloadBtnClick(record)}>
                    <Icon type="download"/> Scarica
                  </div>
                </Menu.Item>
              )}
              {modality === 'modify' && (
                <Menu.Item>
                  <div onClick={() => props.handleDeleteBtnClick(record)}>
                    <Icon type="delete"/> Elimina
                  </div>
                </Menu.Item>
              )}
            </Menu>
          )
          return (
            <Dropdown overlay={menu} trigger="click" placement="bottomRight">
              <Button shape="circle" icon="ellipsis"/>
            </Dropdown>
          )
        },
      },
    ]

    return { columns }
  }),
  withProps(props => ({
    onRow(record) {
      return {
        onClick(e) {
          const $td = $(e.target).is('td')
            ? $(e.target)
            : $(e.target).parent('td')
          if ($td.is('.FileBrowser-row-actions') || !$td.length) return null

          props.handlePathClick(e, record)
        },
      }
    },
  })),
  withPropsOnChange(['currentPathId'], ({ fetchData }) => fetchData()),
  lifecycle({
    componentDidMount() {
      UploadModal.preload()
    },
  }),
)(FileBrowser)
