import React, { Fragment } from 'react'
import { compose, lifecycle, withHandlers, pure, withPropsOnChange, withState } from 'recompose'
import { Row, Col } from 'antd'
import _ from 'lodash'
import { Input, Select, Checkbox, Form, Button } from 'antd'
import { connect } from 'react-redux'
import { Element, scroller } from 'react-scroll'
import withFormProps from '../../form/withReduxFormProps'
import withConnectedForm from '../../form/withConnectedForm'
import CurrencyInput from '../../form/CurrencyInput/index'
import FinancialInstitutionSelect from 'src/components/form/FinancialInstitutionSelect'
import PurposesSelect from 'src/components/form/PurposesSelect'
import { default as TheFormItem } from '../../form/FormItem/index'
import SubmitButton from '../../form/SubmitButton/index'
import { ExpensesRow } from '../../expenses'
import SimpleTable from '../../tables/SimpleTable/SimpleTable'
import Currency from '../../format/Currency/index'
import PercentageInput from '../../form/PercentageInput'
import Percentage from '../../format/Percentage/index'
import Duration from '../../form/Duration'
import RateTypeSelect from '../../form/RateTypeSelect'
import withWatcher from '../../withWatcher'
import { openChooseQuotesHistoryModal } from '../../modals/ChooseQuotesHistoryModal'

const columns = [
  {
    displayName: 'Prodotto',
    field: 'product',
    sortable: true,
    render(product) {
      if (!product) return null
      return `${product.internalId} - ${product.name}`
    },
  },
  {
    displayName: 'Rata',
    field: 'instalmentAmount',
    sortable: true,
    defaultSortOrder: 'ascend',
    type: 'currency',
  },
  {
    displayName: 'Imp. Netto',
    field: 'netAmount',
    sortable: true,
    type: 'currency',
  },
  {
    displayName: 'Tan/Premio Lordo',
    render(ignored, record) {
      const grossPremium = _.get(record, 'insurance.grossPremium')
      const rateValue = record.rateValue

      if (grossPremium) return <Currency value={grossPremium} />
      if (rateValue) return <Percentage value={rateValue} />
    },
  },
  {
    displayName: 'Taeg/Premio Netto',
    render(ignored, record) {
      const netPremium = _.get(record, 'insurance.netPremium')
      const taeg = record.taeg

      if (netPremium) return <Currency value={netPremium} />
      if (taeg) return <Percentage value={taeg} />
    },
  },
  {
    displayName: 'Spese',
    field: 'expenses',
    render(ignore, record) {
      const expenses = record.expenses || []
      const indexingParameter = record.indexingParameter

      return (
        <Fragment>
          {Boolean(indexingParameter) && (
            <b>Par. indic.: {<Percentage value={indexingParameter} />}</b>
          )}
          <ul className="u-listStyleNone">
            {expenses.map(expense => (
              <li>
                <b>{_.get(expense, 'expenseType.displayName', expense.description)}:</b>&nbsp;
                <Currency value={expense.value} />
              </li>
            ))}
          </ul>
          <b>Totale</b>:&nbsp;
          <Currency value={record.totalExpensesAmountAffectingAmount} />
        </Fragment>
      )
    },
  },
]

const Option = Select.Option

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 8,
    },
  },
}

const onlyValueFormItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24, offset: 4 },
  },
  style: { marginBottom: 0 },
}

const FormItem = compose(pure)(props => (
  <TheFormItem {...formItemLayout} {...props}>
    {props.children}
  </TheFormItem>
))

const ApplicantAge = withFormProps(Input)

const ImportProductExpenses = withFormProps(Checkbox, {
  children: 'Importa spese da scheda prodotto',
})

const ImportProductInsurances = withFormProps(Checkbox, {
  children: 'Importa polizze da scheda prodotto',
})

export const QuotesForm = props => {
  const {
    handleSubmit,
    purposes,
    columns,
    financialInstitutions,
    formValues = {},
    results,
    formError,
    submitting,
    productType = {},
    handleExpensesChange,
    onSavePreventiveBtnClick,
    handleOpenHistoryModalBtnClick,
  } = props

  const productTypeName = productType.name
  const isMortgage = productTypeName === 'mortgage'
  return (
    <article className="PreventiveForm">
      <form onSubmit={handleSubmit} className="form">
        <Row gutter={16}>
          <Col xs={24} sm={20} md={12}>
            <FormItem
              label=" "
              className="ant-form-item--hideLabel"
              component={() => (
                <Button onClick={handleOpenHistoryModalBtnClick}>
                  Cronologia ricerca preventivi
                </Button>
              )}
            />
            {productType && (
              <FormItem
                label="Finalitá"
                name="purposeIds"
                purposes={purposes}
                component={PurposesSelect}
                initialQuery={{
                  filter: {
                    order: 'name ASC',
                    skipDeleted: true,
                    where: { productTypeId: productType.id },
                  },
                }}
                withReduxFormProps={true}
                mode="multiple"
                errors={formError}
              />
            )}

            <FormItem
              label="Importo"
              name="amount"
              component={CurrencyInput}
              withReduxFormProps={true}
              errors={formError}
              required
            />

            <FormItem
              label="Durata"
              name="duration"
              component={Duration}
              withReduxFormProps={true}
              errors={formError}
              required
            />

            {isMortgage && (
              <FormItem
                label="Valore Immobile"
                name="estate.value"
                component={CurrencyInput}
                withReduxFormProps={true}
                errors={formError}
                required
              />
            )}
          </Col>

          <Col xs={24} sm={20} md={12}>
            <FormItem
              label="Istituto Finanziario"
              name="financialInstitutionIds"
              component={FinancialInstitutionSelect}
              mode="multiple"
              withReduxFormProps={true}
              errors={formError}
            />

            <FormItem
              label="Etá richiedente"
              name="applicantAge"
              component={ApplicantAge}
              withReduxFormProps={true}
              errors={formError}
            />

            <FormItem
              label="Tipo tasso"
              name="rateTypes"
              component={RateTypeSelect}
              withReduxFormProps={true}
              mode="multiple"
              errors={formError}
            />

            <FormItem label="LTV" name="ltv" readOnly={true} component={PercentageInput} />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={20} md={12}>
            <FormItem
              {...tailFormItemLayout}
              name="withProductExpenses"
              component={ImportProductExpenses}
              withReduxFormProps={true}
              errors={formError}
            />

            <FormItem
              {...tailFormItemLayout}
              name="withProductInsurances"
              component={ImportProductInsurances}
              withReduxFormProps={true}
              errors={formError}
            />
          </Col>
        </Row>
        <Row>
          <Form.Item {...onlyValueFormItemLayout}>
            <ExpensesRow
              type="generic"
              addLabel="Aggiungi spesa"
              expenses={formValues.expenses}
              onValuesChange={handleExpensesChange}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item {...onlyValueFormItemLayout}>
            <ExpensesRow
              type="insurance"
              addLabel="Aggiungi assicurazione"
              expenses={formValues.expenses}
              onValuesChange={handleExpensesChange}
              amount={formValues.amount}
              duration={formValues.duration}
              estate={formValues.estate}
            />
          </Form.Item>
        </Row>
        <div className="FormActions" {...tailFormItemLayout}>
          <SubmitButton size="large">Cerca</SubmitButton>
        </div>
      </form>
      <Element name="quoteResults">
        {results && <SimpleTable data={results} columns={columns} />}
      </Element>
    </article>
  )
}

export default compose(
  connect(
    () => ({
      initialValues: {
        withProductExpenses: true,
        withProductInsurances: true,
      },
    }),
    dispatch => ({
      searchPreventives: dispatch.quotes.searchPreventives,
    }),
  ),
  connect(
    () => {},
    {
      openChooseQuotesHistoryModal,
    },
  ),
  withConnectedForm('searchPreventives'),
  withState('results', 'setResults', null),
  withWatcher(['productType'], ({ productType, initialize }, { productType: oldProductType }) => {
    initialize({
      withProductExpenses: true,
      withProductInsurances: true,
    })
  }),
  withPropsOnChange(['actions'], ({ actions }) => {
    const theColumns = [...columns]
    if (actions)
      theColumns.push({
        displayName: '',
        render: function(ignore, record, ignored, props) {
          return actions({ ...props, record })
        },
      })

    return { columns: theColumns }
  }),
  withPropsOnChange(['formValues'], ({ formValues = {}, change }) => {
    const amount = formValues.amount
    const estateValue = _.get(formValues, 'estate.value')
    const ltv = (amount / estateValue) * 100

    if (!isNaN(ltv) && ltv > 0 && ltv <= 100) {
      change('ltv', Number(ltv.toFixed(2)))
    }
  }),
  withHandlers({
    handleSubmit: props => async event => {
      event.preventDefault()
      const { formValues, dispatchSubmit, searchPreventives, setResults, productType } = props
      const results = await dispatchSubmit(
        searchPreventives({ ...formValues, productTypeId: productType.id }),
      )
      await setResults(results)
      process.nextTick(() => {
        scroller.scrollTo('quoteResults', {
          smooth: true,
          duration: 500,
          containerId: 'DefaultLayout-content',
        })
      })
    },
    handleExpensesChange: ({ formValues: { expenses = [] } = {}, change }) => newExpenses => {
      change('expenses', newExpenses)
    },
    handleOpenHistoryModalBtnClick: props => async () => {
      const { productType } = props
      const data = await props.openChooseQuotesHistoryModal({
        filter: { where: { productTypeId: productType.id } },
      })
      if (data) {
        return props.initialize({ ...data })
      }
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { initialize, initialized, paper, formValues, initialValues } = this.props
      if (!nextProps.paper || initialized) return

      initialize({
        ...initialValues,
        ...formValues,
        amount: paper.amount,
        duration: paper.duration,
        estate: paper.estate,
        productTypeId: paper.productTypeId,
      })
    },
  }),
)(QuotesForm)
