import React from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { select } from '@rematch/select'
import DefaultLayout from '../../components/layout/DefaultLayout'
import FileBrowser from '../../components/FileBrowser'

export const Browser = props => {
  const { pathId, modality, handlePathClick } = props
  return (
    <DefaultLayout>
      <FileBrowser
        context="generic"
        pathId={pathId}
        modality={modality}
        onPathClick={handlePathClick}
      />
    </DefaultLayout>
  )
}

export default compose(
  connect(state => ({ isAdmin: select.agents.isAdmin(state) }), { push }),
  withPropsOnChange(['match'], ({ match }) => {
    const pathId = _.get(match, 'params.pathId')
    return {
      pathId: pathId === ':pathId' ? null : pathId,
    }
  }),
  withPropsOnChange(['isAdmin'], ({ isAdmin }) => ({
    modality: isAdmin ? 'modify' : 'view',
  })),
  withHandlers({
    handlePathClick: props => (e, path) => {
      const { push } = props
      if (path.type === 'folder') return push(`/browse/${path.id}`)
    },
  }),
)(Browser)
