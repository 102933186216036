import React from 'react'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import _get from 'lodash/get'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { select } from '@rematch/select'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import PageTitle from '../../components/layout/PageTitle'

const columns = [
  {
    displayName: 'Nome',
    field: 'name',
  },
  { displayName: 'Partita Iva', field: 'vat' },
  {
    displayName: '',
    render: function(ignore, record) {
      return <Link to={`/financialInstitutions/view/${record.id}`}>Visualizza</Link>
    },
  },
]

const initialQuery = {
  filter: {
    include: [],
    order: 'name ASC',
    skipDeleted: true,
  },
}

const filtersConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['name'],
  },
]

export const FinancialInstitutionsList = props => {
  const { financialInstitutions, findInstitutions, countInstitutions, count } = props
  return (
    <DefaultLayout>
      <PageTitle title="Lista Istituti Finanziari" />
      <div className="u-bgWhite">
        <SmartTable
          data={financialInstitutions}
          columns={columns}
          initialQuery={initialQuery}
          filtersConfig={filtersConfig}
          count={count}
          countMethod={countInstitutions}
          findMethod={findInstitutions}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      financialInstitutions: select.financialInstitutions.getAll(state),
      count: select.financialInstitutions.count(state),
    }),
    dispatch => ({
      findInstitutions: dispatch.financialInstitutions.find,
      countInstitutions: dispatch.financialInstitutions.count,
    }),
  ),
  withRouter,
)(FinancialInstitutionsList)
