import _get from 'lodash/get'
import { createSelector } from 'reselect'


export const getFormErrors = () => createSelector(
  [(state, props = {}) => {
    const formName = props.form || props.formName
    return _get(state, `form.${formName}.submitErrors`, {})
  }],
  errors => errors,
)

export default getFormErrors
