import React from 'react'
import { Select as TheSelect } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { compose, lifecycle, withHandlers } from 'recompose'
import withFormProps from 'src/components/form/withReduxFormProps'
import { openChoosePaperModal } from 'src/components/modals/ChoosePaperModal/index'
import { getPath } from 'src/store/selectors/index'

const Option = TheSelect.Option
const Select = withFormProps(TheSelect)

const PaperSelect = props => {
  const { handleClick, input = {}, papers = [] } = props
  if (['multiple', 'tags', 'combobox'].includes(props.mode) && !Array.isArray(input.value)) {
    input.value = _.compact([input.value])
  }

  return (
    <div onClick={handleClick}>
      <Select {...props} value={input.value} dropdownStyle={{ display: 'none' }}>
        {papers.map(paper => {
          const client = paper.client || {}
          return (
            <Option
              key={paper.id}
              value={paper.id}>{paper.id} - {client.firstname} {client.lastname}</Option>
          )
        })}
      </Select>
    </div>
  )
}

export default compose(
  connect(
    state => ({
      papers: getPath(state)('papers.papersSelect.papers'),
    }),
    dispatch => ({
      find: dispatch.papers.find,
      openChoosePaperModal: bindActionCreators(openChoosePaperModal, dispatch),
    }),
  ),
  withHandlers({
    fetchData: props => () => {
      if (Array.isArray(props.input.value) && _.isEmpty(props.input.value)) {
        return false
      }
      const where = Array.isArray(props.input.value)
        ? { id: { inq: props.input.value } }
        : { id: { inq: [props.input.value] } }

      props.find({
        filter: {
          where,
          fields: ['id', 'internalId', 'client'],
          order: 'lastname ASC',
        },
      }, { basePath: 'papersSelect' })
    },
    handleClick: props => async e => {
      /*const $target = $(e.target)
      if (!$target.is('.ant-select-selection__rendered, .ant-select-selection,.ant-select-search__field')) {
        return false
      }*/
      const { openChoosePaperModal, papers, input = {} } = props

      return openChoosePaperModal().then(paper => {
        if (paper) {
          const theValue = _.uniq([...input.value, paper.id])
          input.onChange(theValue)
        }
      })
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchData()
    },
    componentWillReceiveProps(nextProps) {
      if (this.props.input.value !== nextProps.input.value) {
        this.props.fetchData()
      }
    },
  }),
)(PaperSelect)
