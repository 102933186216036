import React from 'react'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { select } from '@rematch/select'
import FinancialInstitutionSelect from 'src/components/form/FinancialInstitutionSelect'
import ProductTypeSelect from 'src/components/form/ProductTypeSelect'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import PageTitle from '../../components/layout/PageTitle'

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
  },
  {
    displayName: 'Nome',
    field: 'name',
    sortable: true,
    defaultSortOrder: 'ascend',
  },
  { displayName: 'Tipo prodotto', field: 'productType.displayName' },
  { displayName: 'Instituto', field: 'financialInstitution.name' },
  {
    displayName: 'Ultima modifica',
    field: 'updatedAt',
    type: 'date',
    sortable: true,
  },
  {
    displayName: '',
    render: function(ignore, record) {
      return <Link to={`/products/view/${record.id}`}>Visualizza</Link>
    },
  },
]

const initialQuery = {
  filter: {
    skipDeleted: true,
    order: 'name ASC',
    include: [
      {
        relation: 'productType',
        scope: {
          fields: ['displayName'],
        },
      },
      {
        relation: 'financialInstitution',
        scope: {
          fields: ['name'],
        },
      },
    ],
  },
}

const filtersConfig = [
  {
    label: 'Ricerca',
    key: 'search',
    type: 'search',
    fields: ['internalId', 'name'],
  },
  {
    key: 'financialInstitutionId',
    label: 'Istituto',
    createQueryFilter: value => ({ where: { financialInstitutionId: { inq: value } } }),
    component: props => <FinancialInstitutionSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'productTypeId',
    label: 'Tipologia',
    createQueryFilter: value => ({ where: { productTypeId: { inq: value } } }),
    component: props => <ProductTypeSelect {...props} mode="multiple" allowClear={true} />,
  },
  'includeDeleted',
  // not working due to bugged loopback-data-juggler data coercing
  /*{
    key: 'progressList.date',
    label: 'Data creazione',
    type: 'dateInterval',
    component: props => <RangePicker {...props}/>,
  },*/
]

export const ProductList = props => {
  const { products, findProducts, countProducts, count } = props
  return (
    <DefaultLayout>
      <PageTitle title="Lista Prodotti" />
      <div className="u-bgWhite">
        <SmartTable
          data={products}
          columns={columns}
          initialQuery={initialQuery}
          filtersConfig={filtersConfig}
          count={count}
          countMethod={countProducts}
          findMethod={findProducts}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      products: select.products.getAll(state),
      count: select.products.count(state),
    }),
    dispatch => ({
      findProducts: dispatch.products.find,
      countProducts: dispatch.products.count,
    }),
  ),
  withRouter,
)(ProductList)
