import mergeDeepRight from 'ramda/es/mergeDeepRight'

export const GET_PARSED_EMAIL_REQUESTED = state =>
  mergeDeepRight(state, {
    parsed: {
      isFetching: true,
      isFailed: false,
    },
  })

export const GET_PARSED_EMAIL_FULFILLED = (state, data) =>
  mergeDeepRight(state, {
    parsed: {
      isFetching: false,
      isFailed: false,
      item: data,
    },
  })

export const GET_PARSED_EMAIL_FAILED = (state, err) =>
  mergeDeepRight(state, {
    parsed: {
      isFetching: false,
      isFailed: err,
    },
  })
