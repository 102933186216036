import React from 'react'
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withStateHandlers,
  withHandlers,
} from 'recompose'
import { connect } from 'react-redux'
import { Form } from 'redux-form'
import { bindActionCreators } from 'redux'
import { Input as TheInput } from 'antd'
import _ from 'lodash'
import { push } from 'react-router-redux'
import FormItem from 'src/components/form/FormItem'
import SubmitButton from 'src/components/form/SubmitButton'
import withConnectedForm from 'src/components/form/withConnectedForm'
import withReduxFormProps from 'src/components/form/withReduxFormProps'
import Modal from 'src/components/modals/Modal'
import NameInput from 'src/components/form/NameInput'

const Input = withReduxFormProps(TheInput)

export const SaveQuoteModal = props => {
  const {
    visible,
    closeModal,
    formError,
    handleSubmit,
    handleCreateQuoteBtnClick,
    handleCreateQuoteAndPaperBtnClick,
  } = props

  const Footer = (
    <div>
      <SubmitButton size="medium" onClick={handleCreateQuoteBtnClick}>
        Salva Preventivo
      </SubmitButton>
      <SubmitButton size="medium" onClick={handleCreateQuoteAndPaperBtnClick}>
        Salva Preventivo e crea Pratica
      </SubmitButton>
    </div>
  )

  return (
    <Modal
      title="Salva Preventivo"
      className="SaveQuoteModal"
      onCancel={() => closeModal()}
      footer={Footer}
      visible={visible}
    >
      <Form onSubmit={() => {}}>
        <FormItem
          name="client.firstname"
          label="Nome Cliente"
          component={NameInput}
          withReduxFormProps={true}
          errors={formError}
        />
        <FormItem
          name="client.lastname"
          label="Cognome Cliente"
          component={NameInput}
          withReduxFormProps={true}
          errors={formError}
        />
        <FormItem
          name="client.email"
          label="Email Cliente"
          type="email"
          component={Input}
          withReduxFormProps={true}
          errors={formError}
        />
      </Form>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      createQuote: dispatch.quotes.create,
      saveAndCreatePaper: dispatch.quotes.saveAndCreatePaper,
      push: bindActionCreators(push, dispatch),
    }),
  ),
  withConnectedForm('saveQuoteModal'),
  withHandlers({
    handleCreateQuoteBtnClick: props => async e => {
      e.preventDefault()
      const {
        createQuote,
        quote,
        formValues,
        dispatchSubmit,
        closeModal,
      } = props
      try {
        await dispatchSubmit(createQuote(_.merge({}, quote, formValues)))
        closeModal()
      } catch (err) {}
    },
    handleCreateQuoteAndPaperBtnClick: props => async e => {
      e.preventDefault()
      const {
        quote,
        formValues,
        dispatchSubmit,
        push,
        closeModal,
        saveAndCreatePaper,
      } = props

      const { paper } = await dispatchSubmit(
        saveAndCreatePaper(_.merge({}, quote, formValues)),
      )

      push(`/papers/view/${paper.id}`)
      return closeModal()
    },
  }),
)(SaveQuoteModal)
