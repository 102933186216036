import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { Layout, Icon, Input, Alert } from 'antd'
import _ from 'lodash'
import { select } from '@rematch/select'
import withFormProps from 'src/components/form/withReduxFormProps'
import FormItem from 'src/components/form/FormItem/index'
import SubmitButton from 'src/components/form/SubmitButton/index'
import withConnectedForm from 'src/components/form/withConnectedForm'
import LoginStyle from '../Login.style'

const { Content } = Layout

const PasswordInput = withFormProps(Input, {
  prefix: <Icon type="lock"/>,
  type: 'password',
  placeholder: 'Password',
  // size: 'large',
})

const ContactForm = props => {
  const { submitting, handleSubmit, formError, isSent } = props

  return (
    <Layout className={LoginStyle}>
      <Content className="LoginPage-wrapper">
        <h3 className="TitleSection">Inserisci la nuova password</h3>
        <form onSubmit={handleSubmit} className="LoginPage-form">
          {isSent && (
            <Fragment>
              <p>
                <Alert
                  message="Password cambiata correttamente"
                  type="success"
                  showIcon
                />
              </p>
              <a href="#/login"><Icon type="left"/> Effettua Login</a>
            </Fragment>
          )}
          {!isSent && (
            <Fragment>
              <p>
                <Alert
                  message={
                    <div>
                      La password deve avere is seguenti requisiti: <br/>
                      <ul>
                        <li>Lunghezza minima 6 caratteri</li>
                        <li>Contenere almeno un carattere maiuscolo oppure un numero</li>
                      </ul>
                    </div>
                  }
                  type="info"
                  showIcon/>
              </p>
              <FormItem
                name="newPassword"
                errors={formError}
                showErrorLabel={false}
                component={PasswordInput}/>
              <SubmitButton submitting={submitting} className="LoginPage-submit">
                Cambia Password
              </SubmitButton>
            </Fragment>
          )}
        </form>
      </Content>
    </Layout>
  )
}

const checkIfAlreadyLoggedIn = ({ isAuthenticated, history }) => {
  if (isAuthenticated) {
    history.push('/')
  }
}

export default compose(
  connect(
    state => ({
      changedPassword: select.agents.changedPassword(state),
    }),
    dispatch => ({
      changePassword: dispatch.agents.changePassword,
      logout: dispatch.agents.logout,
    }),
  ),
  withConnectedForm('resetPassword'),
  lifecycle({
    async componentWillMount() {
      await this.props.logout({ withRedirect: false })
    },
  }),
  withPropsOnChange(['match'], ({ match }) => ({
    token: _.get(match, 'params.token'),
  })),
  withPropsOnChange(['changedPassword'], ({ changedPassword }) => ({
    isSent: changedPassword.isSent,
  })),
  withHandlers({
    handleSubmit: props => event => {
      event.preventDefault()
      const { dispatchSubmit, formValues, changePassword, token } = props
      dispatchSubmit(changePassword({ data: formValues, token }))
    },
  }),
)(ContactForm)
