import React from 'react'
import { Button } from 'antd'
import { getContext, compose, withPropsOnChange } from 'recompose'

export const SubmitButton = props => {
  const { children, loadingText, submitting, isDisabled } = props

  const content = children || 'Salva'
  return (
    <Button
      type="primary"
      htmlType="submit"
      loading={submitting}
      size="large"
      disabled={isDisabled}
      {...props}
    >
      {submitting ? loadingText || content : content}
    </Button>
  )
}

export default compose(
  withPropsOnChange(
    ['disabledOnClean', 'dirty'],
    ({ disabledOnClean, dirty }) => ({
      isDisabled: disabledOnClean && !dirty,
    }),
  ),
  getContext({
    submitting: Boolean,
    dirty: Boolean,
  }),
)(SubmitButton)
