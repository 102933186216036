import React from 'react'
import { Card } from 'antd'
import { css } from 'emotion'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import _ from 'lodash'
import { Form, Row, Col, Select } from 'antd'
import moment from 'moment'
import { compose, pure, lifecycle, withPropsOnChange, withHandlers, withState } from 'recompose'
import DefaultLayout from '../../components/layout/DefaultLayout'
import SimpleTable from '../../components/tables/SimpleTable/SimpleTable'
import { budgetEditStyle } from './BudgetEdit.style'
import YearSelect from './YearSelect'
import AgentsSelect from '../../components/form/AgentsSelectNew'
// import Select from '../../components/form/Select'
import Currency from '../../components/format/Currency'
import Percentage from '../../components/format/Percentage'
import CurrencyInput from '../../components/form/CurrencyInput'
import FormItem from '../../components/form/FormItem'
import withConnectedForm from '../../components/form/withConnectedForm'
import SubmitButton from '../../components/form/SubmitButton'
import withWatcher from '../../components/withWatcher'
import PageTitle from '../../components/layout/PageTitle'

const { OptGroup, Option } = Select

const cardStyle = css`
  margin-bottom: 15px;
  .ant-card-body {
    padding: 14px 22px;
  }

  .ant-card-head {
    padding: 0 22px;
  }
`

/*const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    lg: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
    lg: { span: 16 },
  },
}*/

const PeriodSelect = props => {
  const { period, onChange } = props
  return (
    <Select
      value={period}
      style={{ minWidth: 200 }}
      onChange={onChange}
      placeholder="Periodo"
      allowClear
    >
      <OptGroup label="Trimestri">
        <Option value="q1">1° Trimestre</Option>
        <Option value="q2">2° Trimestre</Option>
        <Option value="q3">3° Trimestre</Option>
        <Option value="q4">4° Trimestre</Option>
      </OptGroup>
      <OptGroup label="Mesi">
        <Option value="1">Gennaio</Option>
        <Option value="2">Febbraio</Option>
        <Option value="3">Marzo</Option>
        <Option value="4">Aprile</Option>
        <Option value="5">Maggio</Option>
        <Option value="6">Giugno</Option>
        <Option value="7">Luglio</Option>
        <Option value="8">Agosto</Option>
        <Option value="9">Settembre</Option>
        <Option value="10">Ottobre</Option>
        <Option value="11">Novembre</Option>
        <Option value="12">Dicembre</Option>
      </OptGroup>
    </Select>
  )
}

const agentSelectAdditionalOptions = [{ id: 'all', firstname: 'Tutti gli agenti' }]

export const BudgetEdit = props => {
  const {
    year,
    period,
    columns,
    data,
    rows,
    isFetchingStats,
    isAdmin,
    agentId,
    readOnly,
    handleAgentChange,
    handleYearChange,
    handleQuarterChange,
    handleSubmit,
  } = props

  return (
    <DefaultLayout>
      <section>
        <PageTitle title="Budget" />
        <Card className={cardStyle}>
          <budgetEditStyle.Top>
            <budgetEditStyle.Top.Left>
              {isAdmin && (
                <Form.Item>
                  <AgentsSelect
                    style={{ minWidth: 200 }}
                    onChange={handleAgentChange}
                    value={agentId}
                    additionalOptions={agentSelectAdditionalOptions}
                  />
                </Form.Item>
              )}
            </budgetEditStyle.Top.Left>
            <budgetEditStyle.Top.Right>
              <budgetEditStyle.Top.Right.Filters>
                <div>
                  <Form.Item>
                    <PeriodSelect period={period} onChange={handleQuarterChange} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item>
                    <YearSelect year={year} onChange={handleYearChange} />
                  </Form.Item>
                </div>
              </budgetEditStyle.Top.Right.Filters>
            </budgetEditStyle.Top.Right>
          </budgetEditStyle.Top>

          <form onSubmit={handleSubmit}>
            <SimpleTable
              className={budgetEditStyle.tableStyle}
              data={rows}
              columns={columns}
              loading={isFetchingStats}
              pagination={false}
            />
            <div className="FormActions">
              <SubmitButton disabled={readOnly} />
            </div>
          </form>
        </Card>
      </section>
    </DefaultLayout>
  )
}

const formItemCellTypes = {
  mortgages: [
    { type: 'financialInstitution', showValue: true, showInput: false },
    { type: 'bancheNonConvenzionate', showValue: true, showInput: false },
    { type: 'total', showValue: true, showInput: true },
  ],
  mediationExpenses: [{ type: 'total', showValue: true, showInput: true }],
  ingInquiriesExpenses: [{ type: 'total', showValue: true, showInput: true }],
  loans: [{ type: 'total', showValue: true, showInput: true }],
  cessioniDelQuintoDeleghe: [{ type: 'total', showValue: true, showInput: true }],
  prodottoSegnalazione: [{ type: 'total', showValue: true, showInput: true }],
}

export default compose(
  pure,
  connect(
    state => ({
      stats: select.budgets.stats(state),
      isFetchingStats: select.budgets.isFetchingStats(state),
      me: _.get(select.agents.me(state), 'me'),
      isAdmin: select.agents.isAdmin(state),
      budgets: select.budgets.getAll(state),
    }),
    dispatch => ({
      fetchStats: dispatch.budgets.fetchStats,
      create: dispatch.budgets.create,
      find: dispatch.budgets.find,
    }),
  ),
  withState('year', 'setYear', () => moment().format('YYYY')),
  withState('period', 'setQuarter'),
  withState('agentId', 'setAgentId', ({ me = {} }) => me.id),
  withPropsOnChange(['agentId', 'period'], ({ agentId, period }) => {
    const isAllAgents = agentId === 'all'
    return {
      readOnly: isAllAgents || !period,
    }
  }),
  withConnectedForm('budget'),
  withPropsOnChange(['stats'], ({ stats }) => ({
    data: _.get(stats, 'data'),
  })),
  withPropsOnChange(['stats'], ({ stats }) => ({
    rows: _.get(stats, 'rows'),
  })),
  withPropsOnChange(
    ['stats', 'data', 'formValues', 'agentId', 'period', 'readOnly'],
    ({ stats, data, formValues, agentId, period, readOnly }) => {
      const rows = _.get(stats, 'rows', [])
      const displayNameSwitch = {
        financialInstitution: ({ name }) => name,
        bancheNonConvenzionate: () => 'Banche non convenzionate',
        total: () => 'Totale',
      }

      const isAllAgents = agentId === 'all'

      const columns = [
        {
          field: '',
          render(ignore, ignore2, index) {
            return {
              mortgages: 'Mutui',
              mediationExpenses: 'Mediazioni',
              ingInquiriesExpenses: 'Spese Ing',
              loans: 'Prestiti',
              cessioniDelQuintoDeleghe: 'Cessioni del quinto e deleghe',
              prodottoSegnalazione: 'Prodotto Segnalazione',
            }[rows[index]]
          },
        },
        ..._.map(_.get(stats, 'cols'), (col, colIndex) => {
          const [colType, colData] = col
          const displayName = displayNameSwitch[colType](colData)

          return {
            displayName,
            field: null,
            className: 'u-textCenter',
            render(ignore, ignore2, rowIndex) {
              const rowName = rows[rowIndex]
              const record =
                _.find(data, {
                  field: rowName,
                  type: colType,
                  ...(colType === 'financialInstitution'
                    ? { financialInstitutionId: colData.id }
                    : {}),
                }) || {}

              // const showValue = record.value !== null && typeof record !== 'undefined'
              const { showValue, showInput } =
                _.find(formItemCellTypes[rows[rowIndex]] || [], { type: colType }) || {}

              const inputName = _.compact([rowName, colType, colData.id]).join('.')

              return (
                <>
                  {showValue && (
                    <div>
                      <Currency value={record.value} />
                    </div>
                  )}
                  {showInput && (
                    <FormItem
                      name={inputName}
                      component={CurrencyInput}
                      withReduxFormProps={true}
                      readOnly={readOnly}
                    />
                  )}
                </>
              )
            },
          }
        }),
        {
          displayName: '% Raggiungimento',
          className: 'u-textCenter',
          render(ignore, rowName) {
            const record =
              _.find(data, {
                field: rowName,
                type: 'total',
              }) || {}

            const currentValue = _.get(record, 'value', 0)
            const formValue = _.get(formValues, `${rowName}.total`, 0)

            let percentage = (currentValue * 100) / formValue
            percentage = percentage === Infinity ? 0 : percentage

            return <Percentage value={percentage} />
          },
        },
      ]

      return { columns }
    },
  ),
  withHandlers({
    fetchData: props => () => {
      const { fetchStats, find, year, period, agentId: aId } = props

      const isAllAgents = aId === 'all'
      const agentId = isAllAgents ? undefined : aId

      return Promise.all([
        fetchStats({ year, period: period || 'year', agentId }),
        find({ filter: { skipDeleted: true, where: { year, period, agentId } } }),
      ])
    },
  }),
  withWatcher(['budgets'], { immediate: true }, ({ budgets, initialize }) => {
    const initialData = _.reduce(
      budgets,
      (acc, { field, type, financialInstitutionId, value }) => {
        const path = _.compact([field, type, financialInstitutionId])
        const currentValue = _.get(acc, path, 0)
        return _.set(acc, path, value + currentValue)
      },
      {},
    )

    return initialize(initialData)
  }),
  withHandlers({
    handleYearChange: ({ setYear, fetchData }) => year => setYear(year, fetchData),
    handleQuarterChange: ({ setQuarter, fetchData }) => period => setQuarter(period, fetchData),
    handleAgentChange: ({ setAgentId, fetchData }) => agentId => setAgentId(agentId, fetchData),
    handleSubmit: props => e => {
      e.preventDefault()
      const { formValues, agentId, year, period, dispatchSubmit, create } = props
      const budgets = _.reduce(
        formValues,
        (acc, fieldData, field) => {
          _.map(fieldData, (value, type) => {
            if (type === 'total' || type === 'bancheNonConvenzionate') {
              return acc.push({
                field,
                type,
                value,
              })
            }

            if (type === 'financialInstitution') {
              _.map(value, (val, financialInstitutionId) => {
                return acc.push({
                  field,
                  type,
                  value: val,
                  financialInstitutionId,
                })
              })
            }
          })

          return acc
        },
        [],
      )

      const data = {
        budgets,
        agentId,
        year,
        period: period || 'year',
      }

      return dispatchSubmit(create(data))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchData()
    },
  }),
)(BudgetEdit)
