import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'

export default () => {
  return createModel({
    name: 'expenseType',
    provider: httpProvider,
    state: {
      composeEmails: [],
    },
  })
}
