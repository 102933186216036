import { css } from 'emotion'

export default css`
& {
  .PreventivesTab-list {
    margin-bottom: 20px;
  }
}

`
