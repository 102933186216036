import React from 'react'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { select } from '@rematch/select'
import { Card } from 'antd'
import PageTitle from '../../components/layout/PageTitle'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import _ from 'lodash'

const columns = [
  {
    displayName: 'Nome',
    field: 'firstname',
  },
  {
    displayName: 'Cognome',
    field: 'lastname',
  },
  {
    displayName: 'Contatti',
    field: 'id',
    render(ignore, client) {
      const email = client.email
      const phone = (() => {
        if (!client.phones || !client.phones.length) return null

        const clientPhone = _.find(client.phones, { isPrimary: true }) || client.phones[0]
        return clientPhone.phone
      })()

      return _.compact([
        email && (
          <span>
            <b>Email:</b> {email}
          </span>
        ),
        email && <br />,
        phone && (
          <span>
            <b>Telefono:</b> {phone}
          </span>
        ),
      ])
    },
  },
  {
    displayName: 'Data di aggiunta',
    field: 'createdAt',
    type: 'date',
    sortable: true,
  },
  {
    displayName: '',
    render: function(ignore, record) {
      return <Link to={`/clients/edit/${record.id}`}>Visualizza</Link>
    },
  },
]

const initialQuery = {
  filter: {},
}

const filtersConfig = [
  {
    label: 'Ricerca',
    key: 'search',
    type: 'search',
    fields: [
      'firstname',
      'lastname',
      'fiscalCode',
      'identityCard',
      'address',
      'email',
      'phones.phone',
    ],
  },
]

export const ClientList = props => {
  const { clients, findMethod, countMethod, count } = props
  return (
    <DefaultLayout>
      <Helmet>
        <title>Clienti, elenco</title>
      </Helmet>
      <PageTitle title="Lista Clienti" />
      <div className="u-bgWhite">
        <SmartTable
          data={clients}
          columns={columns}
          initialQuery={initialQuery}
          filtersConfig={filtersConfig}
          count={count}
          countMethod={countMethod}
          findMethod={findMethod}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      clients: select.clients.getAll(state),
      count: select.clients.count(state),
    }),
    dispatch => ({
      findMethod: dispatch.clients.find,
      countMethod: dispatch.clients.count,
    }),
  ),
)(ClientList)
