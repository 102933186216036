import React from 'react'
import { Button, Row, Col } from 'antd'
import { css } from 'emotion'
import DocumentsTable from './DocumentsTable'

const documentGroupHeaderStyle = css`
  background: whitesmoke;
  padding: 5px;
`

export const GroupedDocumentsTable = ({
  documentsGroups = [],
  onDocumentViewBtnClick,
  onAddDocumentBtnClick,
  onDeleteDocumentBtnClick,
  CheckInput,
  showVerifications,
  readOnly,
  isAdmin,
}) => {
  return (
    <div className="GroupedDocumentsTable ant-table ant-table-middle ant-table-scroll-position-left">
      {documentsGroups.length ? (
        <table>
          <tbody className="ant-table-tbody">
            {documentsGroups.map(documentGroup => (
              <tr id={documentGroup.id}>
                <td>
                  <Row className={documentGroupHeaderStyle} type="flex">
                    {!readOnly && (
                      <Col style={{ marginRight: 5 }}>
                        <Button
                          size="small"
                          icon="plus"
                          onClick={() =>
                            onAddDocumentBtnClick({
                              documentType: documentGroup,
                            })
                          }
                        />
                      </Col>
                    )}
                    <Col>
                      <div>{documentGroup.name}</div>
                    </Col>
                  </Row>
                  {documentGroup.documents && documentGroup.documents.length > 0 && (
                    <DocumentsTable
                      documents={documentGroup.documents}
                      onDocumentViewBtnClick={onDocumentViewBtnClick}
                      onDeleteDocumentBtnClick={onDeleteDocumentBtnClick}
                      CheckInput={CheckInput}
                      showVerifications={showVerifications}
                      readOnly={readOnly}
                      isAdmin={isAdmin}
                    />
                  )}
                  {(!documentGroup.documents || documentGroup.documents.length === 0) && (
                    <p>Nessun documento</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table>
          <tr>
            <td>Nessun record</td>
          </tr>
        </table>
      )}
    </div>
  )
}

export default GroupedDocumentsTable
