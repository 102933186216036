import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import _get from 'lodash/get'
import { withRouter } from 'react-router'
import { select } from '@rematch/select'
import { Progress, DatePicker } from 'antd'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import ProductTypeSelect from '../../components/form/ProductTypeSelect'
import OfficeSelect from '../../components/form/OfficeSelect'
import AgentSelect from '../../components/form/AgentsSelect'
import ProgressSelect from '../../components/form/ProgressSelect'
import FinancialInstitutionSelect from '../../components/form/FinancialInstitutionSelect'
import ChannelSelect from '../../components/form/ChannelSelect'
import { useListPreferences } from '../tables/useListPreferences'

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
    sortable: true,
  },
  {
    displayName: 'Ultimo Cambio Stato',
    field: 'currentProgress.date',
    type: 'date',
    sortable: true,
    defaultSortOrder: 'descend',
  },
  {
    displayName: 'Cliente',
    field: 'client',
    render(client = {}, record) {
      return client && `${client.firstname} ${client.lastname}`
    },
  },
  {
    displayName: 'Agente',
    field: 'agent',
    fields: ['agents'],
    render(ignored, record) {
      const paperAgent = (record.agents || []).find(a => {
        return a.role && a.role.name === 'agent'
      })
      if (!paperAgent) return
      const { agent = {} } = paperAgent

      return `${agent.firstname} ${agent.lastname}`
    },
  },
  {
    displayName: 'Stato pratica',
    field: 'currentProgress',
    render(ignored, paper) {
      return (
        <div>
          <span>{_get(paper, 'currentProgress.progress.displayName')}</span>
          <br />
          <Progress percent={_get(paper, 'currentProgress.progress.value')} strokeWidth={5} />
        </div>
      )
    },
  },
  {
    displayName: 'Importo',
    field: 'amount',
    type: 'currency',
    sortable: true,
  },
  { displayName: 'Tipo prodotto', field: 'productType.displayName' },
  { displayName: 'Istituto', field: 'product.financialInstitution.name' },
  { displayName: 'Prodotto', field: 'product.name' },
  {
    displayName: '',
    type: 'actions',
    itemsType: 'link',
    actions: [
      {
        label: 'Visualizza',
        props: {
          href: ({ record }) => `/#/papers/view/${record.id}`,
        },
      },
    ],
  },
]

const initialQuery = {
  filter: {
    fields: ['id', 'financialInstitutionId', 'productId', 'productTypeId'],
    skipDeleted: true,
    include: [
      'progress',
      {
        relation: 'product',
        scope: {
          //fields: ['name', 'financialInstitution'],
          include: {
            relation: 'financialInstitution',
            scope: {
              fields: ['name'],
            },
          },
        },
      },
      'productType',
    ],
    populate: [
      {
        model: 'Role',
        property: 'agents.roleId',
        on: 'role',
      },
      {
        model: 'Agent',
        property: 'agents.agentId',
        on: 'agent',
      },
      {
        property: 'currentProgress.progressId',
        model: 'Progress',
        on: 'progress',
      },
    ],
  },
}

const filtersConfig = [
  {
    label: 'Ricerca',
    key: 'search',
    type: 'search',
    fields: ['internalId', 'client.firstname', 'client.lastname', 'client.email'],
  },
  {
    key: 'productTypeId',
    label: 'Tipologia Prodotto',
    createQueryFilter: value => ({ where: { productTypeId: { inq: value } } }),
    component: props => <ProductTypeSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'financialInstitutionId',
    label: 'Istituto finanziario',
    createQueryFilter: value => ({ where: { financialInstitutionId: { inq: value } } }),
    component: props => <FinancialInstitutionSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'currentProgress.progressId',
    label: 'Stato',
    createQueryFilter: value => ({ where: { 'currentProgress.progressId': { inq: value } } }),
    component: props => <ProgressSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'agents.agentId',
    label: 'Agente',
    /*createQueryFilter: value => ({
      where: { or: _.map(value, agentId => ({ agents: { elemMatch: { agentId } } })) },
    }),*/
    createQueryFilter: value => ({ where: { 'agents.agentId': { inq: value } } }),
    component: props => <AgentSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'officeId',
    label: 'Sede',
    createQueryFilter: value => ({ where: { officeId: { inq: value } } }),
    component: props => <OfficeSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'channelId',
    label: 'Canale',
    createQueryFilter: value => ({ where: { channelId: { inq: value } } }),
    component: props => <ChannelSelect {...props} mode="multiple" allowClear={true} />,
  },
  'includeDeleted',
]

export const PapersList = props => {
  const { papers, findPapers, countPapers, count, canSaveFilters, tableProps } = props

  const { fetchPreferences } = useListPreferences({
    listName: 'papersList',
    menuPath: '1.items.0.items',
  })

  const handleFilteredListCreate = useCallback(() => {
    fetchPreferences()
  }, [])

  return (
    <>
      <SmartTable
        data={papers}
        columns={columns}
        initialQuery={initialQuery}
        filtersConfig={filtersConfig}
        count={count}
        countMethod={countPapers}
        fieldSelection={true}
        findMethod={findPapers}
        canSaveFilters={canSaveFilters}
        identifier="papersList"
        onFilteredListCreate={handleFilteredListCreate}
        {...tableProps}
      />
    </>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      papers: select.papers.getAll(state),
      count: select.papers.count(state),
      preferences: select.preferences.getAll(state),
    }),
    dispatch => ({
      findPapers: dispatch.papers.find,
      countPapers: dispatch.papers.count,
      findPreferences: dispatch.preferences.find,
    }),
  ),
  connect(
    state => ({
      papers: select.papers.getAll(state),
      count: select.papers.count(state),
      preferences: select.preferences.getAll(state),
    }),
    dispatch => ({
      findPapers: dispatch.papers.find,
      countPapers: dispatch.papers.count,
      findPreferences: dispatch.preferences.find,
    }),
  ),
  withRouter,
)(PapersList)
