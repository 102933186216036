import React from 'react'
import Select from '../Select'

const options = [
  { label: 'Fisso', value: 'fixed' },
  { label: 'Variabile', value: 'variable' },
  { label: 'Misto', value: 'mixed' },
]
const RateType = props => (
  <Select
    options={options}
    {...props}
  />
)


export default RateType
