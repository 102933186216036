import httpProvider from '../../../lib/httpProviderInstance'

export const fetchStats = async function({ filter, year, period, agentId } = {}) {
  await this.FETCH_BUDGET_STATS_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'GET',
      path: '/budgets/stats',
      params: { filter, year, period, agentId },
    })
    await this.FETCH_BUDGET_STATS_FULFILLED(data)
    return data
  } catch (err) {
    await this.FETCH_BUDGET_STATS_FAILED(err)
    throw err
  }
}

export const create = async function(payload) {
  await this.CREATE_BUDGETS_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'POST',
      path: '/budgets/create',
      data: payload,
    })
    await this.CREATE_BUDGETS_FULFILLED(data)
    return data
  } catch (err) {
    await this.CREATE_BUDGETS_FAILED(err)
    throw err
  }
}
