import React from 'react'
import { Button } from 'antd'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import Modal from 'src/components/modals/Modal'
import SimpleTable from 'src/components/tables/SimpleTable/SimpleTable'
import Spinner from 'src/components/Spinner'

const columns = [
  {
    displayName: 'Nome',
    field: 'name',
    sortable: true,
    defaultSortOrder: 'ascend',
  },
  {
    displayName: 'Ultima modifica',
    type: 'date',
    field: 'updatedAt',
    sortable: true,
  },
  {
    displayName: '',
    render: function (ignore, record) {
      const { onChoose = () => {} } = this.props
      return (
        <Button size="small" onClick={() => onChoose(record)}>Seleziona</Button>
      )
    },
  },
]

const DocumentTemplatesModal = props => {
  const {
    visible,
    closeModal,
    fetchData,
    templates,
  } = props

  return (
    <Modal
      title="Elenco modelli Documento"
      width="900px"
      footer={<Button onClick={() => closeModal()}>Chiudi</Button>}
      visible={visible}
    >
      <Spinner fetchData={fetchData}>
        <SimpleTable
          data={templates}
          columns={columns}
          onChoose={closeModal}
        />
      </Spinner>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      templates: select.papers.validPaperTemplates(state),
    }),
    dispatch => ({
      getValidTemplates: dispatch.papers.getValidTemplates,
    }),
  ),
  withHandlers({
    fetchData: props => () => {
      const { paperId, getValidTemplates } = props
      return getValidTemplates(paperId)
    },
  }),
)(DocumentTemplatesModal)
