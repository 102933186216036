import React from 'react'
import { compose, withPropsOnChange } from 'recompose'

const checkIfCanBeFormattedAsYears = val => !val || val % 12 === 0

const Duration = ({ formattedDuration, format }) =>
  formattedDuration && (
    <>
      {formattedDuration}&nbsp;
      {format === 'years' && 'anni'}
      {format === 'months' && 'mesi'}
    </>
  )

export default compose(
  withPropsOnChange(['value'], ({ value }) => ({
    canBeFormattedAsYears: checkIfCanBeFormattedAsYears(value),
  })),
  withPropsOnChange(['value', 'canBeFormattedAsYears'], ({ value, canBeFormattedAsYears }) => {
    if (!value) return
    if (canBeFormattedAsYears) {
      return {
        formattedDuration: value / 12,
        format: 'years',
      }
    }

    return { formattedDuration: value, format: 'months' }
  }),
)(Duration)
