import React from 'react'
import moment from 'moment'

const FormattedDate = (props) => {
  if (props.date) return moment(props.date).format(props.format)

  return null
}

FormattedDate.defaultProps = {
  format: 'DD/MM/YYYY - HH:mm:ss',
}

export default FormattedDate
