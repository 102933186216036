import React from 'react'
import MortgageLoanQuoteForm from './MortgageLoanQuoteForm'
import InsuranceQuoteForm from './InsuranceQuoteForm'

export const QuotesForm = props => {
  const { productType = {} } = props
  const productTypeName = productType.name
  return (
    <article>
      {['mortgage', 'loan'].includes(productTypeName) && (
        <MortgageLoanQuoteForm {...props} />
      )}
      {['insurance'].includes(productTypeName) && (
        <InsuranceQuoteForm {...props} />
      )}
    </article>
  )
}

export default QuotesForm
