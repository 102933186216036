import _get from 'lodash/get'
import { createSelector } from 'reselect'


export const getPath = state => path => createSelector(
  state => _get(state, path),
  data => data,
)(state)

export default getPath
