import { css } from 'emotion'

export default css`
& {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

`
