import React, { Fragment } from 'react'
import { connect, } from 'react-redux'
import { compose, pure } from 'recompose'

const Desktop = props => {
  const { children, isDesktop } = props
  if (!isDesktop) return null

  return <Fragment>{children}</Fragment>
}


export default compose(
  connect(
    state => ({
      isDesktop: state.env.size.isDesktop,
    }),
  ),
  pure,
)(Desktop)
