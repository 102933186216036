import React, { Fragment } from 'react'
import { Input, Menu, Dropdown, Icon } from 'antd'
import {
  compose,
  withHandlers,
  withStateHandlers,
  withState,
  withPropsOnChange,
} from 'recompose'
import { Radio } from 'antd'

const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const AddonAfter = props => {
  const { handleFormatChange, canBeFormattedAsYears, format } = props
  const menu = (
    <Menu>
      <Menu.Item>
        Mostra in:<br />
        <RadioGroup onChange={handleFormatChange} value={format}>
          <RadioButton value="years" disabled={!canBeFormattedAsYears}>
            Anni
          </RadioButton>
          <RadioButton value="months">Mesi</RadioButton>
        </RadioGroup>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="u-cursorPointer">
      <Dropdown overlay={menu} trigger={['click']}>
        <div>
          {format === 'years' && 'anni'}
          {format === 'months' && 'mesi'}
          &nbsp;
          <Icon type="setting" />
        </div>
      </Dropdown>
    </div>
  )
}

const Duration = props => {
  const {
    handleChange,
    format,
    handleFormatChange,
    formattedValue,
    canBeFormattedAsYears,
    monthsValue,
    value,
  } = props
  return (
    <Fragment>
      {/*monthsValue: {JSON.stringify(monthsValue)}
      <br />
      value: {JSON.stringify(value)}
      <br />
      formattedValue: {JSON.stringify(formattedValue)}
      <br />
      format: {JSON.stringify(format)}*/}
      <Input
        {...props}
        onChange={handleChange}
        value={formattedValue}
        onBlur={e => e.preventDefault()}
        addonAfter={
          <AddonAfter
            {...{ format, canBeFormattedAsYears, handleFormatChange }}
          />
        }
      />
    </Fragment>
  )
}

const checkIfCanBeFormattedAsYears = val => !val || val % 12 === 0

export default compose(
  withStateHandlers(
    {
      format: 'years',
    },
    {
      setFormat: props => format => ({ format }),
    },
  ),
  withState('monthsValue', 'setMonthsValue', props => props.value),
  withHandlers({
    handleFormatChange: props => e => {
      props.setFormat(e.target.value)
    },
    handleChange: props => e => {
      /*e && e.persist && e.persist()
      let val = typeof e === 'undefined' ? null : e
      if (props.format === 'years') {
        val.target.value = format(val.target.value)
      }*/
      let val = e.target.value
      if (props.format === 'years' && !isNaN(val)) {
        val = val * 12
      }
      props.setMonthsValue(val)
      return props.onChange(val)
    },
  }),
  withPropsOnChange(['value'], ({ value }) => ({
    canBeFormattedAsYears: checkIfCanBeFormattedAsYears(value),
  })),
  withPropsOnChange(
    ['value', 'format', 'canBeFormattedAsYears'],
    ({ value, format, setFormat, canBeFormattedAsYears }) => {
      if (!value) return
      if (format === 'years') {
        if (canBeFormattedAsYears) {
          return {
            formattedValue: value / 12,
          }
        } else {
          return setFormat('months')
        }
      }

      return { formattedValue: value }
    },
  ),
)(Duration)
