import _ from 'lodash'
import { Icon, Menu } from 'antd'
import Dropdown from 'antd/lib/dropdown'
import { Link } from 'react-router-dom'
import React from 'react'
import { css } from 'emotion'
import { compose, defaultProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'

const UserDropdown = props => {
  const { handleLogoutClick, me, accounts, onClick, children } = props

  const username = _.get(me, 'me.username')
  const id = _.get(me, 'me.id')
  const actionsDropdown = (
    <Menu>
      <Menu.Item key="1">
        <Link onClick={onClick} to={`/agents/edit/${id}`}>
          Profilo
        </Link>
      </Menu.Item>
      {accounts &&
        accounts.length > 1 && (
          <Menu.Item key="1">
            <Link onClick={onClick} to={`/accountSwitcher`}>
              Cambia account
            </Link>
          </Menu.Item>
        )}
      <Menu.Item key="2">
        <div
          onClick={(...args) => {
            onClick(...args)
            handleLogoutClick(...args)
          }}
        >
          <Icon type="logout" /> Logout
        </div>
      </Menu.Item>
    </Menu>
  )

  return children({
    Dropdown: props => (
      <Dropdown
        style={{ zIndex: 10000 }}
        overlay={actionsDropdown}
        trigger={['click']}
        {...props}
      />
    ),
    username,
  })
}

export default compose(
  connect(
    state => ({
      me: select.agents.me(state),
      accounts: select.accounts.getAll(state),
      account: select.accounts.get(state),
    }),
    dispatch => ({
      logout: dispatch.agents.logout,
    }),
  ),
  defaultProps({
    onClick: () => {},
  }),
  withHandlers({
    handleLogoutClick: props => () => {
      props.logout()
    },
  }),
)(UserDropdown)
