import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import {
  compose,
  withHandlers,
  withPropsOnChange,
  withProps,
  defaultProps,
  withState,
} from 'recompose'
import { select } from '@rematch/select'
import { Button, Row, Col, Input } from 'antd'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import Spinner from '../../components/Spinner'
import TheFormItem from '../../components/form/FormItem'
import withConnectedForm from '../../components/form/withConnectedForm'
import DatePicker from '../../components/form/BrDatePicker'
import CurrencyInput from '../../components/form/CurrencyInput'
import PercentageInput from '../../components/form/PercentageInput'
import ExpensesRow from '../../components/expenses/ExpensesRows'
import RateTypeSelect from 'src/components/form/RateTypeSelect'
import Link from 'src/components/navigation/Link'
import DocumentViewModal, {
  openDocumentViewModal,
} from 'src/components/modals/DocumentViewModal'
import withModalControls from 'src/components/modals/withModalControls'
import withSubscription from 'src/components/withSubscription'
import { clearStateAtPath } from 'src/store/actions/index'

const FormItem = props => <TheFormItem layout="horizontal" {...props} />

const query = {
  filter: {
    include: [
      {
        relation: 'product',
        scope: {
          //fields: ['name', 'financialInstitution'],
          include: {
            relation: 'financialInstitution',
            scope: {
              fields: ['name'],
            },
          },
        },
      },
      'productType',
      'agent',
      'paper',
    ],
    populate: [
      {
        property: 'expenses.insuranceId',
        model: 'Product',
        on: 'insurance',
      },
    ],
  },
}

export const PreventiveView = props => {
  const {
    quote = {},
    fetchData,
    formValues = {},
    paperValue,
    agentValue,
    productType,
    isPaperLinkVisible,
    expensesLayout,
    handleOpenDocumentViewModal,
    documentPreviewModal,
    documentViewItems,
  } = props
  return (
    <Spinner fetchData={fetchData}>
      {quote && (
        <Fragment>
          <Row type="flex" gutter={16}>
            <Col xs={24}>
              <FormItem
                label=" "
                className="ant-form-item--hideLabel"
                component={() => (
                  <Button icon="file-pdf" onClick={handleOpenDocumentViewModal}>
                    Visualizza PDF
                  </Button>
                )}
              />
              <FormItem
                label="Data"
                name="createdAt"
                component={DatePicker}
                withReduxFormProps={true}
              />
              <FormItem
                label="Creato da"
                input={{ value: agentValue }}
                component={Input}
                withReduxFormProps={true}
              />
              <FormItem
                label="Tipologia prodotto"
                name="productType.displayName"
                component={Input}
                withReduxFormProps={true}
              />
              <FormItem
                label="Prodotto"
                name="product"
                component={({ input: { value = {} } }) => (
                  <Input
                    value={`${value.internalId} - ${value.name}`}
                    readOnly={true}
                  />
                )}
                withReduxFormProps={true}
              />
              {productType === 'mortgage' && (
                <Fragment>
                  <FormItem
                    label="Importo"
                    name="amount"
                    component={CurrencyInput}
                    withReduxFormProps={true}
                  />
                  <FormItem
                    label="Importo Erogato"
                    name="netAmount"
                    component={CurrencyInput}
                    withReduxFormProps={true}
                  />
                  <FormItem
                    label="Tan"
                    name="rateValue"
                    component={PercentageInput}
                    withReduxFormProps={true}
                  />
                  <FormItem
                    label="Taeg"
                    name="taeg"
                    component={PercentageInput}
                    withReduxFormProps={true}
                  />
                  <FormItem
                    label="Importo Rata"
                    name="instalmentAmount"
                    component={CurrencyInput}
                    withReduxFormProps={true}
                  />
                </Fragment>
              )}

              {productType === 'insurance' && (
                <Fragment>
                  <FormItem
                    label="Importo"
                    name="amount"
                    component={CurrencyInput}
                    withReduxFormProps={true}
                  />
                  <FormItem
                    label="Premio netto"
                    name="insurance.netPremium"
                    component={CurrencyInput}
                    withReduxFormProps={true}
                  />
                  <FormItem
                    label="Premio lordo"
                    name="insurance.grossPremium"
                    component={CurrencyInput}
                    withReduxFormProps={true}
                  />
                </Fragment>
              )}
              <FormItem
                label="Tasso"
                name="rateType"
                component={RateTypeSelect}
                withReduxFormProps={true}
              />
              <FormItem
                label="Pratica"
                name="paperId"
                component={props => (
                  <Fragment>
                    {props.input.value && (
                      <Link to={`/papers/view/${props.input.value}`}>
                        {paperValue}
                      </Link>
                    )}
                  </Fragment>
                )}
                withReduxFormProps={true}
              />
            </Col>
          </Row>

          <section className="PapersView-main-section">
            <h3>Spese</h3>
            <ExpensesRow
              layout={expensesLayout}
              expenses={formValues.expenses}
              type="generic"
            />
          </section>

          {productType !== 'insurance' && (
            <section className="PapersView-main-section">
              <h3>Assicurazioni</h3>
              <ExpensesRow
                layout={expensesLayout}
                expenses={formValues.expenses}
                type="insurance"
              />
            </section>
          )}
        </Fragment>
      )}
      <DocumentViewModal {...documentPreviewModal} items={documentViewItems} />
    </Spinner>
  )
}

export default compose(
  withSubscription,
  connect(
    (state, { quote }) => ({
      quote: quote || select.quotes.get(state),
      initialValues: quote || select.quotes.get(state),
      generate: select.documentTemplates.generate(state),
      task: select.queues.task(state),
      file: select.files.get(state),
    }),
    dispatch => ({
      getPreventive: dispatch.quotes.findById,
      openDocumentViewModal: bindActionCreators(
        openDocumentViewModal,
        dispatch,
      ),
      findSuitableDocumentTemplate: dispatch.documentTemplates.findSuitable,
      generateDocumentTemplate: dispatch.documentTemplates.generate,
      observeTask: dispatch.queues.observeTask,
      getFileByUUID: dispatch.files.getByUUID,
      clearStateAtPath: bindActionCreators(clearStateAtPath, dispatch),
    }),
  ),
  withProps({ readOnly: true }),
  defaultProps({
    isPaperLinkVisible: true,
    expensesLayout: null,
  }),
  withConnectedForm('preventiveView'),
  withState('documentViewItems', 'setDocumentViewItems', null),
  withModalControls('documentPreviewModal', props => ({
    onCancel: () =>
      Promise.all([
        props.setDocumentViewItems([]),
        props.clearStateAtPath('queues.task'),
        props.clearStateAtPath('files.file'),
        props.unsubscribeAll(),
      ]),
  })),
  withPropsOnChange(['quote'], ({ quote }) => {
    if (!quote || !quote.paper) return null
    const client = quote.client || {}
    return {
      paperValue: _.compact([
        `ID: ${quote.paper.internalId}`,
        client && [client.firstname, client.lastname].join(' '),
      ]).join(' - '),
    }
  }),
  withPropsOnChange(['quote'], ({ quote }) => {
    if (!quote || !quote.agent) return null
    const agent = quote.agent
    return {
      agentValue: `${agent.internalId} - ${agent.firstname} ${agent.lastname}`,
    }
  }),
  withPropsOnChange(['quote'], ({ quote }) => {
    if (!quote || !quote.productType) return null
    return { productType: quote.productType.name }
  }),
  withHandlers({
    fetchData: props => async () => {
      const { getPreventive, id, onLoaded } = props
      if (id) {
        const quote = await getPreventive({ id, query })
        if (onLoaded) {
          return onLoaded(quote)
        }
      }
    },
    handleOpenDocumentViewModal: props => async e => {
      e.preventDefault()
      const {
        findSuitableDocumentTemplate,
        quote,
        quoteId,
        setDocumentViewItems,
        generateDocumentTemplate,
        observeTask,
        addSubscription,
        documentPreviewModal,
      } = props
      const documentTemplate = await findSuitableDocumentTemplate({
        quote,
        quoteId,
      })
      if (documentTemplate) {
        setDocumentViewItems([
          {
            name: documentTemplate.name,
            isLoading: true,
            spinnerProps: {
              tip: 'Generazione documento in corso',
            },
          },
        ])
        const result = await generateDocumentTemplate({
          templateId: documentTemplate.id,
          quote,
          quoteId,
        })
        const { uuid } = result
        const observeResult = await observeTask({
          queueName: 'documentTemplatesQueue',
          uuid,
        })
        addSubscription(observeResult)

        documentPreviewModal.show()
      }
    },
  }),
  withPropsOnChange(['task'], async props => {
    const { task, getFileByUUID, unsubscribeAll } = props
    const uuid = _.get(task, 'result.file.uuid')
    if (task && uuid) {
      const uuid = task.result.file.uuid
      await getFileByUUID({ uuid })
      unsubscribeAll()
    }
  }),
  withPropsOnChange(['file'], async props => {
    const { file, setDocumentViewItems, documentViewItems = [] } = props
    if (file) {
      const item = documentViewItems[0] || {}
      setDocumentViewItems([
        {
          ...item,
          file: {
            ...props.file,
            name: `${props.file.name}.pdf`,
          },
          isLoading: false,
        },
      ])
    }
  }),
)(PreventiveView)
