import httpProvider from '../../../lib/httpProviderInstance'

export const searchInsurancePreventives = async function(payload) {
  await this.SEARCH_INSURANCE_PREVENTIVES_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      '/quotes/getInsuranceEstimations',
      {
        query: payload,
      },
    )

    await this.SEARCH_INSURANCE_PREVENTIVES_FULFILLED(data)
    return data
  } catch (err) {
    await this.SEARCH_INSURANCE_PREVENTIVES_FAILED(err)
    throw err
  }
}

export const saveAndCreatePaper = async function(payload) {
  // await this.SEARCH_INSURANCE_PREVENTIVES_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest(
      'POST',
      `/quotes/save-and-create-paper`,
      { data: payload },
    )

    return data
  } catch (err) {
    // await this.SEARCH_INSURANCE_PREVENTIVES_FAILED(err)
    throw err
  }
}

export const createPaperFromQuote = async function({ quoteId }) {
  // await this.SEARCH_INSURANCE_PREVENTIVES_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest(
      'POST',
      `/quotes/${quoteId}/createPaper`,
    )

    return data
  } catch (err) {
    // await this.SEARCH_INSURANCE_PREVENTIVES_FAILED(err)
    throw err
  }
}

export const searchPreventives = async function(query) {
  return (await httpProvider.makeRequest('GET', '/quotes/getEstimations', {
    query,
  })).data
}
