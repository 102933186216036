import React from 'react'
import PreventivesList from './PreventivesList'
import PreventiveAdd from './PreventiveAdd'
import PreventiveView from './PreventiveView'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'quotes.list',
    path: '/quotes/list',
    component: withCheckAuthOnEnter(withPageTracker()(PreventivesList)),
  },
  {
    name: 'quotes.add.mortgage',
    path: '/quotes/add/mortgage',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <PreventiveAdd {...props} productTypeName="mortgage" />),
    ),
  },
  {
    name: 'quotes.add.loan',
    path: '/quotes/add/loan',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <PreventiveAdd {...props} productTypeName="loan" />),
    ),
  },
  {
    name: 'quotes.add.insurance',
    path: '/quotes/add/insurance',
    component: withCheckAuthOnEnter(
      withPageTracker()(props => <PreventiveAdd {...props} productTypeName="insurance" />),
    ),
  },
  {
    name: 'quotes.view',
    path: '/quotes/view/:id',
    component: withCheckAuthOnEnter(withPageTracker()(PreventiveView)),
  },
]
