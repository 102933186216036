import React from 'react'
import DefaultLayout from 'src/components/layout/DefaultLayout'
import {
  compose,
  lifecycle,
  withHandlers,
  withPropsOnChange,
  withState,
  withStateHandlers,
  withProps,
} from 'recompose'
import { isSubmitting } from 'redux-form'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Card, Form, Input } from 'antd'
import _ from 'lodash'
import { select } from '@rematch/select'
import { push } from 'react-router-redux'
import SpreadsTable from './SpreadsTable'
import PremiumsTable from './PremiumsTable'
import Spinner from 'src/components/Spinner'
import { startSubmit } from '../../store/actions'
import SubmitButton from 'src/components/form/SubmitButton'
import PageTitle from '../../components/layout/PageTitle'

const readOnlyItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const ProductSpreadsPremiums = props => {
  const {
    fetchData,
    id,
    form,
    product,
    features,
    isSubmitting,
    handleTableValuesChange,
    handleSubmit,
    readOnly,
  } = props

  const productTitle = (product && `${product.internalId} - ${product.name}`) || ''
  const financialInstitution = (product && product.financialInstitution) || {}
  const financialInstitutionTitle = financialInstitution.name
  const title = (() => {
    if (features.hasRates) return `Inserisci spreads`
    if (features.hasInsurancePremiums) return `Inserisci premi assicurativi`
  })()
  return (
    <DefaultLayout>
      <Spinner fetchData={fetchData}>
        {({ loaded, error }) => {
          // if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>
          return (
            <>
              <PageTitle title={title} />
              <Card>
                <Form.Item label="Scheda Prodotto" {...readOnlyItemLayout}>
                  <Input.TextArea autosize={true} rows="1" value={productTitle} />
                </Form.Item>
                <Form.Item label="Istituto finanziario" {...readOnlyItemLayout}>
                  <Input.TextArea autosize={true} rows="1" value={financialInstitutionTitle} />
                </Form.Item>

                {features.hasRates && (
                  <SpreadsTable
                    form={form}
                    readOnly={readOnly}
                    productId={id}
                    onChange={handleTableValuesChange}
                  />
                )}
                {features.hasInsurancePremiums && (
                  <PremiumsTable
                    form={form}
                    readOnly={readOnly}
                    productId={id}
                    onChange={handleTableValuesChange}
                  />
                )}
                <section className="FormActions">
                  <SubmitButton onClick={handleSubmit} loading={isSubmitting} />
                </section>
              </Card>
            </>
          )
        }}
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  withPropsOnChange(['match'], ({ match }) => ({
    id: _.get(match, 'params.id'),
  })),
  withState('tableValues', 'setTableValues', {}),
  withProps({
    form: 'productTable',
  }),
  connect(
    (state, props) => ({
      product: select.products.get(state),
      isSubmitting: isSubmitting(_.get(props, 'tableValues.formName'))(state),
    }),
    dispatch => ({
      findOne: dispatch.products.findOne,
      updateRates: dispatch.products.updateRates,
      updatePremiums: dispatch.products.updatePremiums,
      push: (...props) => dispatch(push(...props)),
      dispatchSubmit: bindActionCreators(startSubmit, dispatch),
    }),
  ),
  withPropsOnChange(['product'], ({ product }) => ({
    features: _.get(product, 'productType.features', {}),
  })),
  withHandlers({
    fetchData: props => () => {
      const { findOne, id } = props
      return findOne({
        filter: {
          where: { id },
          fields: ['id', 'internalId', 'name', 'productTypeId', 'financialInstitutionId'],
          include: [
            {
              relation: 'productType',
              scope: {
                fields: ['id', 'name', 'features'],
              },
            },
            {
              relation: 'financialInstitution',
              scope: {
                fields: ['id', 'name'],
              },
            },
          ],
        },
      })
    },
    handleTableValuesChange: ({ setTableValues }) => args => {
      setTableValues(args)
    },
    handleSubmit: props => () => {
      const { dispatchSubmit, tableValues, updateRates, updatePremiums, features, id } = props
      const { formName, values } = tableValues

      const updateFn = (() => {
        if (features.hasRates) return updateRates
        if (features.hasInsurancePremiums) return updatePremiums
      })()
      return dispatchSubmit(formName, updateFn({ id, data: values }))
    },
  }),
)(ProductSpreadsPremiums)
