import { css } from 'emotion'

export default css`
  & {
    .PapersView--404 {
      .DefaultLayout-content-inner {
        display: flex;
        min-height: calc(100vh - 50px);
        align-items: center;
        justify-content: center;
      }
    }
    .PapersViewTop {
    }
    .PapersView-main {
      h3 {
        display: block;
        margin-top: 0px;
        margin-bottom: 20px;
        width: 100%;
        position: relative;
        font-size: 16px;
        &:after {
          position: absolute;
          left: 0;
          top: calc(100% + 5px);
          content: '';
          background: gray;
          height: 1px;
          width: 100px;
        }
      }
      form {
        label {
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .PapersView-main-section {
        margin-bottom: 20px;
      }

      .PapersView-main-tabs {
        > .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
          margin: 0;
          font-size: 13px;
        }
      }
    }

    .PapersView-404 {
      .PapersView-404-icon {
        text-align: center;
        margin-bottom: 20px;
        i {
          font-size: 100px;
        }
      }
      .PapersView-404-content {
        text-align: center;
      }
    }
  }
`
