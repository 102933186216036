import React from 'react'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import { pushModalWindow, popModalWindow } from 'redux-promising-modals'
import ErrorBoundary from '../../core/ErrorBoundary/index'
import ChooseAgentModal, { OPEN_CHOOSE_AGENT_MODAL } from '../ChooseAgentModal/index'
import ChooseProductModal, { OPEN_CHOOSE_PRODUCT_MODAL } from '../ChooseProductModal/index'
import ChooseInsuranceQuoteModal, {
  OPEN_CHOOSE_INSURANCE_QUOTE_MODAL,
} from '../ChooseInsuranceQuoteModal'
import ChooseInsuranceModal, { OPEN_CHOOSE_INSURANCE_MODAL } from '../ChooseInsuranceModal'
import AddClientToPaperModal, {
  OPEN_ADD_CLIENT_TO_PAPER_MODAL,
} from '../AddClientToPaperModal/index'
import AddClientModal, { OPEN_ADD_CLIENT_MODAL } from '../../clients/EditClientModal/index'
import ChooseClientModal, { OPEN_CHOOSE_CLIENT_MODAL } from '../../clients/ChooseClientModal/index'
import DocumentViewModal, { OPEN_DOCUMENT_VIEW_MODAL } from '../DocumentViewModal/index'
import ChangePaperProgressModal, {
  OPEN_CHANGE_PAPER_PROGRESS_MODAL,
} from '../../../containers/Papers/ChangePaperProgressModal/index'
import EditFormulaModal, {
  OPEN_EDIT_FORMULA_MODAL,
} from 'src/components/modals/EditFormulaModal/index'
import ChoosePaperModal, {
  OPEN_CHOOSE_PAPER_MODAL,
} from 'src/components/modals/ChoosePaperModal/index'
import UploadModal, { OPEN_UPLOAD_MODAL } from 'src/components/modals/UploadModal'
import AddAttachmentModal, {
  OPEN_ADD_ATTACHMENTS_MODAL,
} from 'src/components/modals/AddAttachmentsModal'
import ChooseEmailTemplateModal, {
  OPEN_CHOOSE_EMAIL_TEMPLATE_MODAL,
} from 'src/components/modals/ChooseEmailTemplateModal'
import QuoteViewModal, { OPEN_QUOTE_VIEW_MODAL } from 'src/components/modals/QuoteViewModal'
import ChooseAgentDocumentTypeModal, {
  OPEN_CHOOSE_AGENT_DOCUMENTTYPE_MODAL,
} from 'src/components/modals/ChooseAgentDocumentTypeModal'
import ChooseQuotesHistoryModal, {
  OPEN_CHOOSE_QUOTES_HISTORY_MODAL,
} from 'src/components/modals/ChooseQuotesHistoryModal'

const MODAL_COMPONENTS = {
  [OPEN_CHOOSE_AGENT_MODAL]: ChooseAgentModal,
  [OPEN_CHOOSE_PRODUCT_MODAL]: ChooseProductModal,
  [OPEN_CHOOSE_INSURANCE_QUOTE_MODAL]: ChooseInsuranceQuoteModal,
  [OPEN_CHOOSE_INSURANCE_MODAL]: ChooseInsuranceModal,
  [OPEN_ADD_CLIENT_TO_PAPER_MODAL]: AddClientToPaperModal,
  [OPEN_CHOOSE_CLIENT_MODAL]: ChooseClientModal,
  [OPEN_ADD_CLIENT_MODAL]: AddClientModal,
  [OPEN_DOCUMENT_VIEW_MODAL]: DocumentViewModal,
  [OPEN_CHANGE_PAPER_PROGRESS_MODAL]: ChangePaperProgressModal,
  [OPEN_EDIT_FORMULA_MODAL]: EditFormulaModal,
  [OPEN_CHOOSE_PAPER_MODAL]: ChoosePaperModal,
  [OPEN_UPLOAD_MODAL]: UploadModal,
  [OPEN_ADD_ATTACHMENTS_MODAL]: AddAttachmentModal,
  [OPEN_CHOOSE_EMAIL_TEMPLATE_MODAL]: ChooseEmailTemplateModal,
  [OPEN_QUOTE_VIEW_MODAL]: QuoteViewModal,
  [OPEN_CHOOSE_AGENT_DOCUMENTTYPE_MODAL]: ChooseAgentDocumentTypeModal,
  [OPEN_CHOOSE_QUOTES_HISTORY_MODAL]: ChooseQuotesHistoryModal,
}

const ModalRoot = ({ reusableModals = [], closeModal }) => {
  const { types = [], props = [] } = reusableModals

  const rModals = types.reduce((acc, type, index) => {
    const modalProps = props[index]
    const modalComponent = MODAL_COMPONENTS[type] || (modalProps || {}).Component
    if (modalComponent) {
      acc.push({
        Component: modalComponent,
        props: modalProps,
      })
    }
    return acc
  }, [])

  return (
    <ErrorBoundary>
      {rModals.map(({ Component, props }, index) => {
        return (
          <Component
            key={index}
            {...props}
            closeModal={closeModal}
            hide={closeModal}
            visible={true}
          />
        )
      })}
    </ErrorBoundary>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      reusableModals: state.modals,
    }),

    {
      closeModal: result => dispatch => dispatch(popModalWindow(result)),
    },
  ),
)(ModalRoot)
