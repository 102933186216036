import _get from 'lodash/get'
import { createSelector } from 'reselect'


export const getFormResult = () => createSelector(
  [(state, props = {}) => {
    const formName = props.form || props.formName
    return _get(state, `formResult.${formName}`)
  }],
  result => result,
)

export default getFormResult
