import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, defaultProps } from 'recompose'
import { select } from '@rematch/select'
import AsyncSelect from '../AsyncSelect'

export const ChannelSelect = props => {
  return <AsyncSelect valueField="id" labelField="name" {...props} />
}

export default compose(
  connect(
    state => ({
      options: select.channels.getAll(state),
    }),
    dispatch => ({
      fetchMethod: dispatch.channels.find,
    }),
  ),
  defaultProps({
    initialQuery: {
      filter: { order: 'name ASC', skipDeleted: true },
    },
  }),
)(ChannelSelect)
