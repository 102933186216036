import { css } from 'react-emotion'

const BrDatePicker = css`
.BrDatePicker-inputGroup {
  display: flex;
  .BrDatePicker-inputGroup-left {
    flex-grow:1;
  }
  .BrDatePicker-inputGroup-right {
    flex-shrink:1;
  }
}
`


export default BrDatePicker
