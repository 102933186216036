import { css } from 'emotion'

export default css`
  & .CommissionsTab-section-commission {
    h5 {
      margin: 0;
      line-height: 1.3;
    }
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }

`
