import React, { Fragment } from 'react'
import DefaultLayout from 'src/components/layout/DefaultLayout'
import { compose, lifecycle, withHandlers, withProps, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { Input, Card, Divider } from 'antd'
import { Link } from 'react-router-dom'
import { select } from '@rematch/select'
import _ from 'lodash'
import { default as TheFormItem } from 'src/components/form/FormItem'
import withConnectedForm from '../../components/form/withConnectedForm'
import SubmitButton from 'src/components/form/SubmitButton'
import SmartTable from 'src/components/tables/SmartTable/SmartTableClass'
import FileBrowser from 'src/components/FileBrowser'
import Spinner from 'src/components/Spinner'
import IndexingParameterSelect from '../../components/form/IndexingParameterSelect'
import { push } from 'react-router-redux'
import LazyLoad from 'src/components/LazyLoad'
import PageTitle from '../../components/layout/PageTitle'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const FormItem = props => <TheFormItem {...formItemLayout} {...props} />

const productsTableColumns = [
  {
    displayName: 'Nome',
    field: 'name',
  },
  {
    displayName: 'Tipologia prodotto',
    field: 'productType.displayName',
  },
  {
    displayName: '',
    render: function(ignore, record) {
      return <Link to={`/products/view/${record.id}`}>Visualizza</Link>
    },
  },
]
const productsTableFilterConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['name'],
  },
]

const FinancialInstitutionsEdit = props => {
  const {
    financialInstitution = {},
    id,
    formError,
    handleSubmit,
    products,
    findProducts,
    countProducts,
    productsCount,
    fetchData,
    productsTableInitialQuery,
    isAdmin,
    modality,
  } = props

  return (
    <DefaultLayout>
      <Spinner fetchData={fetchData}>
        <PageTitle title={financialInstitution && financialInstitution.name} />
        <Card style={{ marginBottom: 20 }}>
          <form onSubmit={handleSubmit}>
            <FormItem
              label="Nome"
              name="name"
              component={Input.TextArea}
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Partita Iva"
              name="vat"
              component={Input}
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Email"
              type="email"
              name="email"
              component={Input}
              withReduxFormProps={true}
              errors={formError}
            />
            <FormItem
              label="Telefono"
              name="phone"
              component={Input}
              withReduxFormProps={true}
              errors={formError}
            />
            <Divider />
            <FormItem
              label="Parametro indicizzazione"
              name="indexingParameter"
              component={IndexingParameterSelect}
              withReduxFormProps={true}
              errors={formError}
            />

            {isAdmin && (
              <div className="FormActions">
                <SubmitButton>Salva</SubmitButton>
              </div>
            )}
          </form>
        </Card>
        {id && (
          <Fragment>
            <Card title="Prodotti" style={{ marginBottom: 20 }}>
              <LazyLoad containerSelector={'.DefaultLayout-content'}>
                <SmartTable
                  initialQuery={productsTableInitialQuery}
                  filtersConfig={productsTableFilterConfig}
                  data={products}
                  columns={productsTableColumns}
                  count={productsCount}
                  countMethod={countProducts}
                  findMethod={findProducts}
                />
              </LazyLoad>
            </Card>

            <Card title="Documenti">
              <LazyLoad containerSelector={'.DefaultLayout-content'}>
                <FileBrowser
                  context="financialInstitution"
                  financialInstitutionId={id}
                  modality={modality}
                />
              </LazyLoad>
            </Card>
          </Fragment>
        )}
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  connect(
    state => ({
      financialInstitution: select.financialInstitutions.get(state),
      initialValues: select.financialInstitutions.get(state),
      productsCount: select.products.count(state),
      products: select.products.getAll(state),
      isAdmin: select.agents.isAdmin(state),
    }),
    dispatch => ({
      findOne: dispatch.financialInstitutions.findOne,
      create: dispatch.financialInstitutions.create,
      update: dispatch.financialInstitutions.update,
      findProducts: dispatch.products.find,
      countProducts: dispatch.products.count,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withPropsOnChange(['match'], ({ match }) => ({
    id: _.get(match, 'params.id'),
  })),
  withPropsOnChange(['isAdmin'], ({ isAdmin }) => ({
    readOnly: !isAdmin,
  })),
  withPropsOnChange(['isAdmin'], ({ isAdmin }) => ({
    modality: isAdmin ? 'modify' : 'view',
  })),
  withConnectedForm('financialInstitution'),
  withProps(props => ({
    productsTableInitialQuery: {
      filter: {
        skipDeleted: true,
        where: { financialInstitutionId: props.id },
        order: 'name ASC',
        include: [
          {
            relation: 'productType',
            scope: {
              fields: ['id', 'displayName'],
            },
          },
        ],
      },
    },
  })),
  withHandlers({
    fetchData: props => () => {
      const { findOne, id } = props
      if (!id) return null
      return findOne({
        filter: {
          where: { id },
        },
      })
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const { dispatchSubmit, formValues, update, create, fetchData, id, push } = props
      if (id) {
        await dispatchSubmit(update({ ...formValues, id }))
        return fetchData()
      }

      const { id: newId } = await dispatchSubmit(create(formValues))
      return push(`/financialInstitutions/view/${newId}`)
    },
  }),
  lifecycle({}),
)(FinancialInstitutionsEdit)
