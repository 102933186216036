import httpProvider from '../../../lib/httpProviderInstance'

export const getParsed = async function ({ id, data }) {
  await this.GET_PARSED_EMAIL_REQUESTED()
  try {
    const { data: result } = await httpProvider.makeRequest(
      'GET',
      `/emailTemplates/${id}/parse`,
      { data },
    )
    await this.GET_PARSED_EMAIL_FULFILLED(data)
    return result
  } catch (err) {
    await this.GET_PARSED_EMAIL_FAILED(err)
    throw err
  }
}
