import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { Helmet } from 'react-helmet'
import { Card } from 'antd'
import { Input } from 'antd'
import { push } from 'react-router-redux'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import FormItem from '../../components/form/FormItem'
import withConnectedForm from '../../components/form/withConnectedForm'
import SubmitButton from '../../components/form/SubmitButton'
import ProductTypeSelect from '../../components/form/ProductTypeSelect'
import NameInput from '../../components/form/NameInput'
import PageTitle from '../../components/layout/PageTitle'

const PaperCreate = props => {
  const { handleSubmit, formError } = props
  return (
    <DefaultLayout>
      <Helmet>
        <title>Nuova pratica</title>
      </Helmet>
      <PageTitle title="Nuova Pratica" />
      <Card>
        <form onSubmit={handleSubmit} noValidate>
          <FormItem
            label="Tipo prodotto"
            name="productTypeId"
            component={ProductTypeSelect}
            withReduxFormProps={true}
            layout="horizontal"
            errors={formError}
          />
          <FormItem
            label="Nome Cliente"
            name="client.firstname"
            component={NameInput}
            withReduxFormProps={true}
            layout="horizontal"
            errors={formError}
          />
          <FormItem
            label="Cognome cliente"
            name="client.lastname"
            component={NameInput}
            withReduxFormProps={true}
            layout="horizontal"
            errors={formError}
          />
          <FormItem
            name="client.email"
            label="Email Cliente"
            type="email"
            component={Input}
            withReduxFormProps={true}
            layout="horizontal"
            errors={formError}
          />
          <FormItem
            name="client.phone"
            label="Telefono Cliente"
            component={Input}
            withReduxFormProps={true}
            layout="horizontal"
            errors={formError}
          />
          <section className="FormActions">
            <SubmitButton>Crea</SubmitButton>
          </section>
        </form>
      </Card>
    </DefaultLayout>
  )
}

export default compose(
  connect(
    state => ({}),
    dispatch => ({
      create: dispatch.papers.create,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withConnectedForm('paperAdd'),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const { dispatchSubmit, formValues, create, push } = props

      const { id: newId } = await dispatchSubmit(create(formValues))
      return push(`/papers/view/${newId}`)
    },
  }),
)(PaperCreate)
