import _get from 'lodash/get'
import _ from 'lodash'
import mergeDeepRight from 'ramda/es/mergeDeepRight'

export const GET_PROGRESS_LIST_PAPER_CAN_ADVANCE_TO_REQUESTED = (state, payload) =>
  mergeDeepRight(state, {
    progressListPaperCanAdvanceTo: {
      isFetching: true,
      isFailed: false,
    },
  })

export const GET_PROGRESS_LIST_PAPER_CAN_ADVANCE_TO_FULFILLED = (state, payload) =>
  mergeDeepRight(state, {
    progressListPaperCanAdvanceTo: {
      list: payload,
      isFetching: false,
      isFailed: false,
    },
  })

export const GET_PROGRESS_LIST_PAPER_CAN_ADVANCE_TO_FAILED = (state, err) =>
  mergeDeepRight(state, {
    progressListPaperCanAdvanceTo: {
      isFetching: false,
      isFailed: err,
    },
  })

export const RECALC_PAPER_MANDATORY_DOCUMENTS_REQUESTED = (state, payload) =>
  mergeDeepRight(
    state,
    {
      documentsTab: {
        newMandatoryDocumentTypes: {
          isFetching: true,
        },
      },
    },
    {},
  )

export const RECALC_PAPER_MANDATORY_DOCUMENTS_FULFILLED = (state, payload) =>
  mergeDeepRight(
    state,
    {
      documentsTab: {
        newMandatoryDocumentTypes: {
          isFetching: false,
          items: payload,
        },
      },
    },
    {},
  )

export const RECALC_PAPER_MANDATORY_DOCUMENTS_FAILED = (state, payload) =>
  mergeDeepRight(
    state,
    {
      documentsTab: {
        newMandatoryDocumentTypes: {
          isFetching: false,
          isFailed: _get(payload, 'response.data.error'),
        },
      },
    },
    {},
  )

export const GET_PAPER_MANDATORY_DOCUMENTS_REQUESTED = (state, payload) =>
  mergeDeepRight(
    state,
    {
      documentsTab: {
        mandatoryDocumentTypes: {
          isFetching: true,
        },
      },
    },
    {},
  )

export const GET_PAPER_MANDATORY_DOCUMENTS_FULFILLED = (state, payload) =>
  mergeDeepRight(state, {
    documentsTab: {
      mandatoryDocumentTypes: {
        isFetching: false,
        items: payload,
      },
    },
  })

export const GET_PAPER_MANDATORY_DOCUMENTS_FAILED = (state, payload) =>
  mergeDeepRight(state, {
    documentsTab: {
      mandatoryDocumentTypes: {
        isFetching: false,
        isFailed: _get(payload, 'response.data.error'),
      },
    },
  })

export const GET_PAPER_COMMISSIONS_ESTIMATIONS_REQUESTED = (state, payload) =>
  mergeDeepRight(state, {
    commissionsTab: {
      commissionsEstimations: {
        isFetching: true,
        items: null,
      },
    },
  })

export const GET_PAPER_COMMISSIONS_ESTIMATIONS_FULFILLED = (state, payload) =>
  mergeDeepRight(state, {
    commissionsTab: {
      commissionsEstimations: {
        isFetching: false,
        items: payload,
      },
    },
  })

export const GET_PAPER_COMMISSIONS_ESTIMATIONS_FAILED = (state, payload) =>
  mergeDeepRight(state, {
    commissionsTab: {
      commissionsEstimations: {
        isFetching: false,
        isFailed: _get(payload, 'response.data.error'),
      },
    },
  })

export const GET_PAPER_EMAILS_REQUESTED = _.identity

export const GET_PAPER_EMAILS_FULFILLED = (state, payload) =>
  mergeDeepRight({
    emails: payload,
  })

export const GET_PAPER_EMAILS_FAILED = _.identity

export const GET_VALID_TEMPLATES_REQUESTED = (state, payload) =>
  mergeDeepRight(state, {
    documentsTab: {
      validTemplates: {
        isFetching: true,
      },
    },
  })

export const GET_VALID_TEMPLATES_FULFILLED = (state, payload) =>
  mergeDeepRight(state, {
    documentsTab: {
      validTemplates: {
        isFetching: false,
        items: payload,
      },
    },
  })

export const GET_VALID_TEMPLATES_FAILED = (state, payload) =>
  mergeDeepRight(state, {
    documentsTab: {
      validTemplates: {
        isFetching: false,
        isFailed: _get(payload, 'response.data.error'),
      },
    },
  })

export const SYNC_WITH_QUOTE_REQUESTED = _.identity
export const SYNC_WITH_QUOTE_FULFILLED = _.identity
export const SYNC_WITH_QUOTE_FAILED = _.identity
