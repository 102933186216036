import React from 'react'
import { compose, withPropsOnChange, defaultProps } from 'recompose'


export const TruncateText = ({ truncatedText, shortText, ellipsis, text, isTruncated, children }) => {
  const childrenAsFn = typeof children === 'function'
  if (childrenAsFn) return children({
    truncatedText,
    shortText,
    ellipsis,
    text,
    isTruncated,
    children,
  })

  return truncatedText
}

export default compose(
  defaultProps({
    ellipsis: '...',
  }),
  withPropsOnChange(['text', 'maxLength'], ({ text = '', maxLength = 30, ellipsis }) => {
    const isTooLong = text.length > (maxLength + ellipsis.length)
    const shortText = isTooLong ? text.substr(0, maxLength - ellipsis.length) : text
    const truncatedText = `${shortText}${isTooLong ? ellipsis : ''}`
    return {
      shortText,
      truncatedText,
      isTruncated: isTooLong,
    }
  }),
)(TruncateText)
