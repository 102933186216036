import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'

export default () => {
  return createModel({
    name: 'progress',
    pluralName: 'progressList',
    httpPath: 'progress',
    provider: httpProvider,
  })
}
