import React, { Fragment } from 'react'
import { Spin } from 'antd'
import { css } from 'react-emotion'
import { compose, lifecycle, withState, withPropsOnChange } from 'recompose'

const spinnerContainerClass = css`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`
const Spinner = ({ children, loaded, error, isLoading, spinProps }) => (
  <Fragment>
    {(!loaded && !error) && (
      <div className={spinnerContainerClass}>
        <Spin size="large" {...spinProps}/>
      </div>
    )}
    {(loaded || error) && (typeof children === 'function' ? children({
      loaded,
      error,
      isLoading: !loaded,
    }) : children)}
  </Fragment>
)

export default compose(
  withState('loaded', 'setLoadedState', ({ isLoading }) => !isLoading),
  withState('error', 'setError', null),
  withPropsOnChange(['isLoading'], ({ isLoading }) => {
    if (typeof isLoading !== 'undefined') {
      return { loaded: !isLoading }
    }
  }),
  lifecycle({
    async componentDidMount() {
      if (this.props.fetchData) {
        try {
          this.props.setLoadedState(false)
          await this.props.fetchData()
          this.props.setLoadedState(true)
        } catch (err) {
          if (this.props.onError) {
            this.props.onError(err)
          }
          this.props.setError(err)
        }
      }
    },
  }),
)(Spinner)
