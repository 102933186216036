import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { getActiveSubmenu, getActiveSubmenuItem } from '../../../store/modules/menu/selectors'

import style from './LeftPanel.style'

const { Sider } = Layout

export const LeftMenu = ({ subMenu, activeItem }) => {
  let activeItemIndex
  const items = (subMenu || []).map((group, i) => {
    const items = (group.items || []).map((item, j) => {
      const sameName = activeItem && item.name && item.name === activeItem.name
      const sameKey = activeItem && item.key && item.key === activeItem.key
      const sameSearch = activeItem && activeItem.search === item.search

      if (activeItem && sameSearch && (sameName || sameKey)) {
        activeItemIndex = item.key || `${i + 1}${j}`
      }

      if (item.render)
        return item.render({
          ...item,
          key: item.key || `${i + 1}${j}`,
        })

      return (
        <Menu.Item key={item.key || `${i + 1}${j}`}>
          {item.path ? (
            <Link to={{ ...item, pathname: item.path }} title={item.title}>
              {item.title}
            </Link>
          ) : (
            item.title
          )}
        </Menu.Item>
      )
    })

    const renderGroup = () => {
      if (group.title) {
        return (
          <Menu.ItemGroup key={i} title={group.title}>
            {items}
          </Menu.ItemGroup>
        )
      }

      return items
    }

    return renderGroup()
  })

  return (
    <Menu mode="inline" selectedKeys={[activeItemIndex]} style={{ height: '100%', borderRight: 0 }}>
      {items}
    </Menu>
  )
}

class LeftPanel extends PureComponent {
  render() {
    const { subMenu, activeItem } = this.props

    return (
      <Sider className={style}>
        <LeftMenu {...{ subMenu, activeItem }} />
      </Sider>
    )
  }
}

const mapStateToProps = state => ({
  subMenu: getActiveSubmenu(state),
  activeItem: getActiveSubmenuItem(state),
})

export default connect(mapStateToProps)(LeftPanel)
