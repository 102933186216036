import React, { Fragment } from 'react'


const Percentage = ({ value }) => {
  const theValue = !isNaN(value) ? `${Math.round(value * 100) / 100}%` : null
  return (
    <Fragment>
      {theValue}
    </Fragment>
  )
}

export default Percentage
