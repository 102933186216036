import ReportsList from './ReportsList'
import ReportCreate from './ReportCreate'
import ReportView from './ReportView'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'reports.list',
    path: '/reports/all',
    component: withCheckAuthOnEnter(withPageTracker()(ReportsList)),
  },
  {
    name: 'reports.listPapers',
    path: '/reports/papers',
    component: withCheckAuthOnEnter(withPageTracker()(ReportsList)),
  },
  {
    name: 'reports.listAgents',
    path: '/reports/agents',
    component: withCheckAuthOnEnter(withPageTracker()(ReportsList)),
  },
  {
    name: 'reports.create',
    path: '/reports/create',
    component: withCheckAuthOnEnter(withPageTracker()(ReportCreate)),
  },
  {
    name: 'reports.view',
    path: '/reports/view/:id',
    component: withCheckAuthOnEnter(withPageTracker()(ReportView)),
  },
]
