import { css } from 'emotion'

export default css`
padding: 5px;
background: #001529;
position: fixed;
z-index: 9000;
top: 50px;
bottom: 0;
left: 0;
right: 0;
width: 100vw;
height: 100vh;
overflow: auto;
padding: 0 20px 100px 20px;
display: flex;
flex-direction: column;
& .MobileMenu-top {
  padding: 20px;
  text-align: center;
  position: relative;
  h2 {
    color:rgba(255, 255, 255, 0.65);
  }
}
& .ant-menu-submenu {
  .ant-menu-sub {
    background: transparent;
  }
  .ant-menu-submenu-title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: white;
    padding: 0 !important;
  }
  
  .ant-menu-submenu-arrow {
    opacity: 1;
  }
}
`
