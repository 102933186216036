import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'
import * as effects from './effects'
import * as reducers from './reducers'

export default () => {
  return createModel({
    name: 'quote',
    provider: httpProvider,
    state: {
      searchInsurancePreventives: {
        results: null,
      },
    },
    effects,
    reducers,
  })
}
