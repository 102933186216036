import React from 'react'
import { compose, pure } from 'recompose'
import { css } from 'emotion'

const style = css`
  background: white;
  padding: 10px 30px;
  margin-bottom: 15px;
`
const h1style = css`
  font-size: 20px;
  margin: 0;
`

const PageTitle = ({ title }) => {
  return (
    <section className={style}>
      <h1 className={h1style}>{title}</h1>
    </section>
  )
}

export default compose(pure)(PageTitle)
