import React from 'react'
import {
  compose,
  lifecycle,
  withPropsOnChange,
  withStateHandlers,
  withHandlers,
} from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'
import _fp from 'lodash/fp'
import { select } from '@rematch/select'
import { Checkbox, Table, Spin } from 'antd'
import FormItem from 'src/components/form/FormItem'
import SubmitButton from 'src/components/form/SubmitButton'
import withConnectedForm from 'src/components/form/withConnectedForm'
import Modal from 'src/components/modals/Modal'
import Currency from 'src/components/format/Currency'
import style from './AddCommissionModal.style'
import Spinner from 'src/components/Spinner'

const PayOutCommission = props => {}

export const AddCommissionModal = props => {
  const {
    visible,
    closeModal,
    areCommissionsEstimationsFetching,
    handleSubmit,
    hasTranches,
    payIns,
    groupedPayOuts,
    handlePayInsRowSelectionChange,
    handlePayOutsRowSelectionChange,
    payInsSelectedRowKeysArray,
    payOutsSelectedRowKeysArray,
    fetchData,
  } = props

  const payInsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'InfoColumn',
      render(ignored, record) {
        return _.get(record, 'office.name')
      },
    },
    {
      title: 'Name',
      dataIndex: 'value',
      className: 'ValueColumn',
      render(value, record) {
        return <Currency value={value} />
      },
    },
  ]

  const payOutColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'InfoColumn',
      render(ignored, record) {
        if (!record || !record.agent) return null
        return `${record.agent.firstname} ${record.agent.lastname}`
      },
    },
    {
      title: 'Name',
      dataIndex: 'value',
      className: 'ValueColumn',
      render(value, record) {
        return <Currency value={value} />
      },
    },
  ]

  const List = () => (
    <div>
      {hasTranches && (
        <p>Seleziona i record provvigionali che vuoi aggiungere alla tranche</p>
      )}
      <h3 className="TitleSection">Pay In</h3>
      {_.map(payIns, (commission, index) => {
        const rowSelection = hasTranches && {
          selectedRowKeys: payInsSelectedRowKeysArray[index] || [],
          onChange: srk => handlePayInsRowSelectionChange(index, srk),
        }
        return (
          <section className="AddCommissionModal-row">
            <h4>{commission.commissionRule.name}</h4>
            <Table
              rowSelection={rowSelection}
              columns={payInsColumns}
              dataSource={[commission]}
              size="small"
              pagination={false}
            />
          </section>
        )
      })}

      <h3 className="TitleSection">Pay Out</h3>
      {_.map(groupedPayOuts, ([, commissions], index) => {
        const rowSelection = hasTranches && {
          selectedRowKeys: payOutsSelectedRowKeysArray[index] || [],
          onChange: srk => handlePayOutsRowSelectionChange(index, srk),
        }
        return (
          <section className="AddCommissionModal-row">
            <h4>{commissions[0].commissionRule.name}</h4>
            <Table
              rowSelection={rowSelection}
              columns={payOutColumns}
              dataSource={commissions}
              size="small"
              pagination={false}
            />
          </section>
        )
      })}
    </div>
  )

  const modalTitle = hasTranches
    ? 'Aggiungi Provvigione'
    : 'Ricalcola provvigioni'
  const TheSubmitButton = hasTranches ? (
    <SubmitButton onClick={handleSubmit}>Aggiungi</SubmitButton>
  ) : (
    <SubmitButton onClick={handleSubmit}>Convalida</SubmitButton>
  )

  return (
    <Modal
      title={modalTitle}
      className={style}
      onCancel={() => closeModal()}
      footer={TheSubmitButton}
      visible={visible}
    >
      <Spinner fetchData={fetchData}>{() => <List />}</Spinner>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      commissionsEstimations: select.papers.commissionsEstimationsSelector(
        state,
      ),
    }),
    dispatch => ({
      getCommissionsEstimations: dispatch.papers.getCommissionsEstimations,
    }),
  ),
  withConnectedForm('addCommissionModal'),
  withStateHandlers(
    {
      payInsSelectedRowKeysArray: [],
      payOutsSelectedRowKeysArray: [],
    },
    {
      handlePayInsRowSelectionChange: ({ payInsSelectedRowKeysArray }) => (
        index,
        srk,
      ) => ({
        payInsSelectedRowKeysArray: _fp.set(
          index,
          srk,
          payInsSelectedRowKeysArray,
        ),
      }),
      handlePayOutsRowSelectionChange: ({ payOutsSelectedRowKeysArray }) => (
        index,
        srk,
      ) => ({
        payOutsSelectedRowKeysArray: _fp.set(
          index,
          srk,
          payOutsSelectedRowKeysArray,
        ),
      }),
    },
  ),
  withPropsOnChange(
    ['commissionsEstimations'],
    ({ commissionsEstimations, hasTranches }) => {
      const payIns = _.filter(commissionsEstimations, { inOrOut: 'in' })
      const payOuts = _.filter(commissionsEstimations, { inOrOut: 'out' })
      const groupedPayOuts = _.toPairs(_.groupBy(payOuts, 'commissionRuleId'))

      return {
        payIns,
        payOuts,
        groupedPayOuts,
        allCommissions: [...payIns, ...payOuts],
      }
    },
  ),
  withPropsOnChange(
    ['payInsSelectedRowKeysArray', 'payOutsSelectedRowKeysArray'],
    ({
      payInsSelectedRowKeysArray,
      payOutsSelectedRowKeysArray,
      payIns,
      groupedPayOuts,
      hasTranches,
    }) => {
      const selectedPayIns = payInsSelectedRowKeysArray.reduce(
        (acc, payInsArray, index) => {
          if (Array.isArray(payInsArray)) {
            acc.push(payIns[index])
          }
          return acc
        },
        [],
      )
      const selectedPayOuts = payOutsSelectedRowKeysArray.reduce(
        (acc, payoutArray, index) => {
          if (Array.isArray(payoutArray)) {
            payoutArray.forEach(payoutIndex =>
              acc.push(groupedPayOuts[index][1][payoutIndex]),
            )
          }
          return acc
        },
        [],
      )

      return { addedCommissions: [...selectedPayIns, ...selectedPayOuts] }
    },
  ),
  withHandlers({
    handleSubmit: props => e => {
      e.preventDefault()
      if (props.hasTranches) {
        return props.closeModal({ commissions: props.addedCommissions })
      }

      return props.closeModal({
        commissions: props.allCommissions,
        replace: true,
      })
    },
    fetchData: props => () => {
      const { amount, paperId: id } = props
      return props.getCommissionsEstimations({ id, query: { amount } })
    },
  }),
)(AddCommissionModal)
