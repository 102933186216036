import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'
import * as effects from './effects'
import * as selectors from './selectors'
import * as reducers from './reducers'

export default () => {
  return createModel({
    name: 'budget',
    pluralName: 'budgets',
    provider: httpProvider,
    effects,
    selectors,
    reducers,
  })
}
