import httpProvider from 'src/lib/httpProviderInstance'

export const getTree = async function (payload) {
  await this.SAVE_HIERARCHY_TREE_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      `/agentsHierarchy/tree`,
      payload,
    )
    return await this.GET_HIERARCHY_TREE_FULFILLED(data)
  } catch (err) {
    await this.GET_HIERARCHY_TREE_FAILED(err)
    throw err
  }
}

export const saveTree = async function (payload) {
  await this.SAVE_HIERARCHY_TREE_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest(
      'POST',
      `/agentsHierarchy/tree`,
      { data: payload },
    )
    return await this.SAVE_HIERARCHY_TREE_FULFILLED(data)
  } catch (err) {
    await this.SAVE_HIERARCHY_TREE_FAILED(err)
    throw err
  }
}
