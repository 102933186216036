import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Card, Divider } from 'antd'
import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { pushModal, pushModalWindow } from 'redux-promising-modals'
import { select } from '@rematch/select'
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux'
import DefaultLayout from 'src/components/layout/DefaultLayout/DefaultLayout'
import QuotesForm from 'src/components/quotes/QuotesForm'
import SaveQuoteModal from './SaveQuoteModal'
import { openQuoteViewModal } from 'src/components/modals/QuoteViewModal'
import Spinner from 'src/components/Spinner'
import PageTitle from '../../components/layout/PageTitle'

const PreventiveAdd = props => {
  const { productType, actions } = props
  const titleSuffix = (productType || {}).displayName
  return (
    <DefaultLayout className="PreventiveAdd">
      <Helmet>
        <title>Nuovo preventivo - {titleSuffix || ''}</title>
      </Helmet>
      <Spinner isLoading={!productType}>
        <PageTitle title={`Nuovo preventivo - ${titleSuffix || ''}`} />
        <Card>
          {productType && <QuotesForm {...props} productType={productType} actions={actions} />}
        </Card>
      </Spinner>
    </DefaultLayout>
  )
}
export default compose(
  connect(
    state => ({
      productType: select.productTypes.get(state),
    }),
    dispatch => ({
      findProductType: dispatch.productTypes.findOne,
      openSaveQuoteModal: bindActionCreators(
        props =>
          pushModalWindow('OPEN_SAVE_QUOTE_MODAL', {
            Component: SaveQuoteModal,
            ...props,
          }),
        dispatch,
      ),
      openQuoteViewModal: bindActionCreators(openQuoteViewModal, dispatch),
    }),
  ),
  withHandlers({
    handleSavePreventiveBtnClick: props => async quote => {
      const { openSaveQuoteModal, productType } = props
      return openSaveQuoteModal({
        quote: { ...quote, productTypeId: productType.id },
      })
    },
  }),
  lifecycle({
    async componentWillMount() {
      const { findProductType, productTypeName } = this.props
      return findProductType({ filter: { where: { name: productTypeName } } })
    },
  }),
  withProps(({ handleSavePreventiveBtnClick, openQuoteViewModal }) => {
    return {
      actions: ({ record }) => {
        return (
          <div className="u-noWrap">
            <a
              href=""
              onClick={e => {
                e.preventDefault()
                return openQuoteViewModal({ quote: record })
              }}
            >
              Visualizza
            </a>
            <Divider type="vertical" />
            <a onClick={() => handleSavePreventiveBtnClick(record)}>Salva</a>
          </div>
        )
      },
    }
  }),
)(PreventiveAdd)
