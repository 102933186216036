import { css } from 'emotion'

export default css`
  position: fixed;
  max-width: 600px;
  width: 100%;
  background: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  z-index: 100;
  @media (max-width: 767px) {
    max-height: 100vh;
  }
  .ComposeEmail-header {
    padding: 5px 15px;
    height: 40px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    * {
      color: white;
    }
    .ComposeEmail-header-left {
      h4 {
        margin: 0;
      }
    }
    .ComposeEmail-header-right {
    }
  }
  .ComposeEmail-body {
    padding: 5px 15px;
    overflow: auto;
    .ql-editor {
      min-height: 200px;
    }
  }
`
