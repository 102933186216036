import React, { Fragment } from 'react'
import { connect, } from 'react-redux'
import { compose, pure } from 'recompose'
import ConditionalWrap from '../../ConditionalWrap'


const Mobile = props => {
  const { children, isMobile } = props
  if (!isMobile) return null

  return <Fragment>{children}</Fragment>
}

export default compose(
  connect(
    state => ({
      isMobile: state.env.size.isMobile,
    }),
  ),
  pure,
)(Mobile)
