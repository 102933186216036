import React from 'react'
import Modal from 'src/components/modals/Modal/index'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Form } from 'redux-form'
import withConnectedForm from 'src/components/form/withConnectedForm'
import { select } from '@rematch/select'
import Input from 'src/components/form/Input'
import FormItem from 'src/components/form/FormItem'
import SubmitButton from 'src/components/form/SubmitButton'

const ReportProfileChooseModal = props => {
  const {
    visible,
    closeModal,
    formError,
    submitting,
    handleSubmit,
    formValues: fv,
  } = props

  const formValues = fv || {}
  const id = formValues.id
  return (

    <Modal
      title="Salva Profilo Report"
      onCancel={() => closeModal()}
      visible={visible}
      footer={<SubmitButton onClick={handleSubmit} submitting={submitting}>Salva</SubmitButton>}>
      <Form onSubmit={handleSubmit}>
        {id && (
          <p>
            Sei sicuro di voler salvare le modifiche al profilo: <br/>
            <b>{id} - {formValues.name}</b>
          </p>
        )}
        {!id && (
          <FormItem
            label="Nome Profilo"
            name="name"
            component={Input}
            style={{ marginBottom: 5 }}
            errors={formError}/>
        )}
      </Form>
    </Modal>
  )
}

export default compose(
  connect(
    (state, props) => ({
      initialValues: props.values,
    }),
    dispatch => ({
      create: dispatch.reportProfiles.create,
      update: dispatch.reportProfiles.update,
    }),
  ),
  withConnectedForm('createReportProfile'),
  withHandlers({
    handleSubmit: props => async (e) => {
      e.preventDefault()
      const { dispatchSubmit, formValues, create, update } = props
      const fn = formValues.id ? update : create
      await dispatchSubmit(fn({ ...formValues }))
      props.closeModal()
    },
  }),
)(ReportProfileChooseModal)
