import React, { Component } from 'react'
import { compose, withPropsOnChange, flattenProp, lifecycle, pure } from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import ReactGA from 'react-ga'
import LogRocket from 'logrocket'
import _ from 'lodash'
import smartlookClient from 'smartlook-client'
import withWatcher from 'src/components/withWatcher'

ReactGA.initialize(process.env.REACT_APP_GA_TRACKID)

if (process.env.REACT_APP_SMARTLOOK_KEY) {
  smartlookClient.init(process.env.REACT_APP_SMARTLOOK_KEY)
}

const withPageTracker = () => WrappedComponent => {
  return compose(
    pure,
    connect(state => ({
      isAuthenticated: select.agents.isAuthenticated(state),
      me: select.agents.me(state),
      account: select.accounts.get(state),
    })),
    pure,
    flattenProp('me'),

    withPropsOnChange(['me'], ({ me }) => {
      if (!me || !me.id) return null

      return ReactGA.set({ userId: me.id })
    }),

    withPropsOnChange(['account', 'me'], ({ account, me }) => {
      if (!account || !me || !me.id) return null

      const logRocketArgs = [
        `${me.username}-${me.id}`,
        {
          accountId: account.id,
          accountName: account.name,
        },
      ]

      LogRocket.identify(...logRocketArgs)
    }),

    withPropsOnChange(['me'], ({ me }) => {
      if (!me || !me.id) return null

      const smartlookArgs = [
        `${me.username}-${me.id}`,
        { ..._.pick(me, ['firstname', 'lastname']) },
      ]

      try {
        smartlookClient.identify(...smartlookArgs)
      } catch (err) {}
    }),
  )(WrappedComponent)
}

export default withPageTracker
