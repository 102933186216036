import React from 'react'
import { Icon, Menu, Dropdown, Popover } from 'antd'
import { css } from 'emotion'
import Name from '../../../../../../components/format/Name'
import FormattedDate from '../../../../../../components/format/FormattedDate'

const verificationsStyle = css`
  display: flex;
  align-items: center;
`

export const DocumentsTable = ({
  documents = [],
  onDocumentViewBtnClick,
  handleAddDocumentBtnClick,
  showVerifications,
  CheckInput,
  onDeleteDocumentBtnClick,
  readOnly,
  isAdmin,
}) => {
  return (
    <div className="DocumentsTable ant-table ant-table-middle ant-table-scroll-position-left">
      <table>
        <tbody className="ant-table-tbody">
          {documents.map(document => (
            <tr key={document.id}>
              <td>
                <a onClick={() => onDocumentViewBtnClick(document)}>{document.name}</a>
              </td>
              {showVerifications && (
                <td>
                  <div className={verificationsStyle}>
                    {isAdmin && CheckInput && <CheckInput document={document} />}
                    {document.checks && document.checks.length ? (
                      <Popover
                        content={
                          <ul className="u-listStyleNone">
                            {document.checks.map(check => (
                              <li style={{ marginBottom: 10 }}>
                                Da: <Name person={check.agent} /> <br />
                                Il: <FormattedDate date={check.date} />
                              </li>
                            ))}
                          </ul>
                        }
                        title="Verifiche"
                      >
                        <span>({`${document.checks.length} verif.`})</span>
                      </Popover>
                    ) : (
                      <span>non verificato</span>
                    )}
                  </div>
                </td>
              )}
              {!readOnly && (
                <td>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          <div onClick={() => onDeleteDocumentBtnClick(document)}>Elimina</div>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a>
                      <Icon type="setting" style={{ fontSize: 16 }} />
                    </a>
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default DocumentsTable
