import styled from 'react-emotion'
import { css } from 'emotion'

const Top = styled('div')`
  margin: 20px 0 30px;
  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }
  .ant-form-item-control {
    line-height: 1 !important;
    @media (max-width: 767px) {
      width: 100%;
    }
  }
`

Top.Left = styled('div')``

Top.Right = styled('div')``
Top.Right.Filters = styled('div')`
  margin: 0 -10px;
  > div {
    padding: 0 10px;
  }
  @media (min-width: 992px) {
    display: flex;
  }
`

const tableStyle = css`
  tr td input {
    text-align: center;
  }
`

export const budgetEditStyle = { Top, tableStyle }
