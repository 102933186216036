import { Observable } from 'rxjs/Observable'
import 'rxjs/add/operator/share'
import 'rxjs/add/observable/timer'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/repeat'
import 'rxjs/add/operator/retry'
import Promise from 'bluebird'
import _fp from 'lodash/fp'
import isEqual from 'lodash/isEqual'
import httpProvider from 'src/lib/httpProviderInstance'
import { OBSERVE_ENDPOINT } from '../actions/observeEndpoint'

export default function formResultMiddleware({ dispatch, getState }) {
  return next => {
    return (action = {}) => {
      if (action.type === OBSERVE_ENDPOINT) {
        return Promise.try(() => {
          const { path, query, interval, lifecycle = [] } = action.payload
          const observable = Observable.create(observer => {
            let previousData = null
            const doCheck = async () => {
              const newQuery = _fp.flow(
                _fp.omit('fields'),
                _fp.defaultsDeep({ filter: { fields: ['id', 'updatedAt'] } }),
              )(query)

              const { data } = await httpProvider.makeRequest('GET', path, newQuery, {})
              if (!isEqual(data, previousData)) {
                fetchData()
              }

              previousData = data
            }

            const fetchData = () =>
              httpProvider.makeRequest('GET', path, query, {}).then(data => {
                if (data.error) {
                  // this.handleError(data.error, { query, options, observeData })
                  return observer.error(data)
                }

                return observer.next(data)
              })

            doCheck()
            let isFetching = false
            const intv = setInterval(async () => {
              if (!isFetching) {
                isFetching = true
                try {
                  await doCheck()
                } finally {
                  isFetching = false
                }
              }
            }, interval)

            return () => clearInterval(intv)
          }).share()

          dispatch({ type: lifecycle[0] })

          const onData = response =>
            dispatch({
              type: lifecycle[3] || lifecycle[1],
              payload: response.data,
            })

          const onError = error => dispatch({ type: lifecycle[2], payload: error })

          const subscription = observable.subscribe(onData, onError)
          return { observable, subscription }
        })
      }

      return next(action)
    }
  }
}
