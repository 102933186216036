import createHistory from 'history/createHashHistory'
import { routerMiddleware } from 'react-router-redux'
import { modalsMiddleware } from 'redux-promising-modals'
import thunk from 'redux-thunk'
import dissocPath from 'ramda/src/dissocPath'
import { init } from '@rematch/core'
import createSelectorsPlugin from '@rematch/select'
import createLoadingPlugin from '@rematch/loading'
import createRematchPersist from '@rematch/persist'
import subscriptionsPlugin from '@rematch/subscriptions'
import { createFilter } from 'redux-persist-transform-filter'
import { formMiddleware, crudEpicToPromise, observeEndpointMiddleware } from './middlewares'
import { reducers } from './reducers'
import createModels from './createModels'

const select = createSelectorsPlugin()
const loading = createLoadingPlugin()
const subscriptions = subscriptionsPlugin()
const persistAuthFilter = createFilter('agents', ['token', 'isAuthenticated'])

const persistPlugin = createRematchPersist({
  key: 'br2',
  // transforms: [persistAuthFilter],
  whitelist: ['agents', 'accounts', 'accountSettings', 'ui'],
  throttle: 1000,
  version: 1,
})

const configureStore = () => {
  const history = createHistory()
  const models = createModels()

  const store2 = init({
    models,
    plugins: [select, subscriptions, /*loading,*/ persistPlugin],
    redux: {
      rootReducers: {
        CLEAR_STATE_AT_PATH: (state, { payload: { path } = {} }) =>
          dissocPath(path.split('.'), state),
      },
      reducers: { ...reducers },
      middlewares: [
        thunk,
        routerMiddleware(history),
        modalsMiddleware,
        formMiddleware,
        crudEpicToPromise,
        observeEndpointMiddleware,
      ],
    },
  })

  return { store: store2, history }
}
export default configureStore
