import { css } from 'emotion'

export default css`
  & {
    height: 100%;
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 700px;
    @media (max-width: 767px) {
      max-width: none;
    }
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 0;
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      .SelectModal-body {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .SelectModal-search {
        margin-bottom: 10px;
      }
      .SelectModal-list {
        overflow: auto;
        flex: 1;
      }
    }
    .SelectModal-item {
      cursor: pointer;
      &-checkbox {
        margin-right: 20px;
      }
    }
  }
`
