import { OPEN_ADD_CLIENT_TO_PAPER_MODAL } from './actionTypes'
import { pushModalWindow, popModalWindow } from 'redux-promising-modals'

export const openAddClientToPaperModal = props => dispatch => {
  return dispatch(pushModalWindow(OPEN_ADD_CLIENT_TO_PAPER_MODAL))
}

export const closeAddClientToPaperModal = props => dispatch => {
  return dispatch(popModalWindow(props))
}
