import { css } from 'emotion'

export default css`
& {
  margin: 0 -15px 0px;
  .Filters-col {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 5px;
    @media (min-width: 768px) {
      width: calc(100% / 3);
    }
    @media (min-width: 1024px) {
      width: 25%;
    }
    > div {
      width: 100%;
    }
    .ant-select {
      width: 100%;
    }
  }
}

`
