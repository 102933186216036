import { createSelector } from 'reselect'

export const threadedEmails = createSelector(
  state => state.threadedEmails,
  data => data,
)

export const paperCategories = createSelector(
  state => state.paperCategories,
  data => data,
)

export const composeEmails = createSelector(
  state => state.composeEmails,
  data => data,
)

export const isFetchingThreadedEmails = createSelector(
  state => state.isFetchingThreadedEmails,
  data => data,
)
