import React, { createFactory } from 'react'
import {
  compose,
  pure,
  withHandlers,
  withStateHandlers,
  withProps,
  defaultProps,
} from 'recompose'
import _fp from 'lodash/fp'
import { EventEmitter } from 'events'

const mapProps = propsMapper => BaseComponent => {
  const factory = createFactory(BaseComponent)
  const MapProps = props => factory(propsMapper(props))
  return MapProps
}

const withModalControls = (
  modalName,
  argsFactory = () => ({}),
) => WrappedComponent => {
  const Component = props => {
    return (
      <WrappedComponent
        {...{
          ...props,
          [modalName]: {
            visible: props.visible,
            show: props.show,
            hide: props.hide,
            onCancel: props.handleCancel,
            onOk: props.handleOk,
          },
        }}
      />
    )
  }
  const eventBus = new EventEmitter()
  return compose(
    pure,
    withProps(props => {
      const { initialState, onCancel, onOk } = argsFactory(props) || {}
      return { initialState, onCancel, onOk, eventBus }
    }),
    defaultProps({ onOk: () => {}, onCancel: () => {} }),
    withStateHandlers(
      props =>
        _fp.merge(props.initialState, {
          visible: false,
        }),
      {
        setVisibility: state => visible => _fp.merge(state, { visible }),
      },
    ),
    mapProps(props => ({
      ...props,
      ...(typeof argsFactory === 'function' ? argsFactory(props) : argsFactory),
    })),
    withHandlers({
      hide: props => async args => {
        props.eventBus.emit('hide', args)
        return props.setVisibility(false)
      },
    }),
    withHandlers({
      show: props => async data => {
        props.setVisibility(true)
        return new Promise(resolve => {
          props.eventBus.once('hide', args => {
            return resolve(args)
          })
        })
      },
      handleOk: props => async (evt, dt) => {
        const hasEvent = evt && evt.hasOwnProperty('target')
        const event = hasEvent ? evt : null
        const data = hasEvent || dt ? dt : evt

        if (event) {
          props.onOk(evt)
        }
        if (!event || !event.defaultPrevented) {
          return props.hide(data)
        }
      },
      handleCancel: props => async event => {
        props.onCancel(event)
        if (!event || !event.defaultPrevented) {
          return props.hide()
        }
      },
    }),
  )(Component)
}

export default withModalControls
