import { routes as auth } from './containers/Auth'
import { routes as home } from './containers/Home'
import { routes as papers } from './containers/Papers'
import { routes as preventives } from './containers/Quotes'
import { routes as products } from './containers/Products'
import { routes as financialInstitutions } from './containers/FinancialInstitutions'
import { routes as reports } from './containers/Reports'
import { routes as reportProfiles } from './containers/ReportProfiles'
import { routes as setup } from './containers/Setup'
import { routes as inbox } from './containers/Inbox'
import { routes as clients } from './containers/Clients'
import { routes as agents } from './containers/Agents'
import { routes as browser } from './containers/Browser'
import { routes as budget } from './containers/Budget'

const routes = [
  ...home,
  ...auth,
  ...papers,
  ...preventives,
  ...products,
  ...financialInstitutions,
  ...reports,
  ...setup,
  ...inbox,
  ...clients,
  ...agents,
  ...reportProfiles,
  ...browser,
  ...budget,
]

export default routes
