import { css } from 'emotion'

export default css`
& {
  .Email-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    &-info {
      font-size: 12px;
    }
    &-actions {
      padding: 0 0 0 20px;
      white-space: nowrap;
      button {
        margin-left: 5px;
      }
    }
  }
  &-content {
  }
}

`
