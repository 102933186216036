import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'

export default () => {
  return createModel({
    name: 'productType',
    provider: httpProvider,
  })
}

