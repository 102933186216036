import ProductList from './ProductList'
import ProductEdit from './ProductEdit'
import ProductSpreadsPremiums from './ProductSpreadsPremiums'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'products.list',
    path: '/products/list',
    component: withCheckAuthOnEnter(withPageTracker()(ProductList)),
  },
  {
    name: 'products.view',
    path: '/products/view/:id',
    component: withCheckAuthOnEnter(withPageTracker()(ProductEdit)),
  },
  {
    name: 'products.add',
    path: '/products/add',
    component: withCheckAuthOnEnter(withPageTracker()(ProductEdit)),
  },
  {
    name: 'products.spreads',
    path: '/products/spreads/:id',
    component: withCheckAuthOnEnter(withPageTracker()(ProductSpreadsPremiums)),
  },
  {
    name: 'products.premiums',
    path: '/products/premiums/:id',
    component: withCheckAuthOnEnter(withPageTracker()(ProductSpreadsPremiums)),
  },
]
