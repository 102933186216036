import React from 'react'
import { connect } from 'react-redux'
import { compose, defaultProps, lifecycle, withHandlers } from 'recompose'
import { select } from '@rematch/select'
import _ from 'lodash'
import AsyncSelect from '../AsyncSelect'

const PurposesSelect = props => {
  return <AsyncSelect labelField="name" valueField="id" {...props} />
}

export default compose(
  connect(
    (state, props) => ({
      options: select.purposes.getAll(state, props),
    }),
    (dispatch, { basePath }) => ({
      fetchMethod: (payload, meta) =>
        dispatch.purposes.find(payload, _.merge({}, meta, { basePath })),
    }),
  ),
  defaultProps({
    initialQuery: {
      filter: { order: 'name ASC', skipDeleted: true },
    },
  }),
)(PurposesSelect)
