import httpProvider from 'src/lib/httpProviderInstance'
import { dispatch } from '@rematch/core'

export const getProgressListPaperCanAdvanceTo = async function(payload) {
  await this.GET_PROGRESS_LIST_PAPER_CAN_ADVANCE_TO_REQUESTED(payload)
  try {
    const { id, query } = payload
    const { data: responseData } = await httpProvider.makeRequest({
      method: 'get',
      path: `/papers/${id}/progressListCanAdvanceTo`,
      params: query,
    })
    await this.GET_PROGRESS_LIST_PAPER_CAN_ADVANCE_TO_FULFILLED(responseData)
    return responseData
  } catch (err) {
    await this.GET_PROGRESS_LIST_PAPER_CAN_ADVANCE_TO_FAILED(err)
    throw err
  }
}

export const updatePaperDocuments = async payload => {
  const { id, data } = payload
  return httpProvider.makeRequest('PATCH', `/papers/${id}/updateDocuments`, {
    data,
  })
}

export const getMandatoryDocuments = async function(id, meta) {
  await this.GET_PAPER_MANDATORY_DOCUMENTS_REQUESTED(id)
  const paper = await this.findOne(
    {
      filter: {
        fields: ['mandatoryDocumentTypesIds'],
        where: { id },
      },
    },
    { ...meta, skipCrudReducers: true },
  )

  try {
    const data = await dispatch.documentTypes.find(
      {
        filter: {
          where: { id: { inq: paper.mandatoryDocumentTypesIds } },
          order: 'name ASC',
        },
      },
      { ...meta, skipCrudReducers: true },
    )

    await this.GET_PAPER_MANDATORY_DOCUMENTS_FULFILLED(data)
  } catch (err) {
    await this.GET_PAPER_MANDATORY_DOCUMENTS_FAILED(err)
    throw err
  }
}

export const recalcMandatoryDocuments = async function(id) {
  await this.RECALC_PAPER_MANDATORY_DOCUMENTS_REQUESTED(id)
  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      `/papers/${id}/newMandatoryDocumentTypes`,
    )
    this.RECALC_PAPER_MANDATORY_DOCUMENTS_FULFILLED(data)
  } catch (err) {
    this.RECALC_PAPER_MANDATORY_DOCUMENTS_FAILED(err)
    throw err
  }
}

export const saveMandatoryDocuments = async function(payload) {
  // await this.SAVE_PAPER_MANDATORY_DOCUMENTS_REQUESTED(payload)
  try {
    const { id, data } = payload
    const { data: responseData } = await httpProvider.makeRequest(
      'PUT',
      `/papers/${id}/mandatoryDocumentTypes`,
      { data },
    )
    // await this.SAVE_PAPER_MANDATORY_DOCUMENTS_FULFILLED(responseData)
    return responseData
  } catch (err) {
    // await this.SAVE_PAPER_MANDATORY_DOCUMENTS_FAILED(err)
    throw err
  }
}

export const changeProgress = async payload => {
  const { id, progress } = payload
  return httpProvider.makeRequest('POST', `/papers/${id}/changeProgress`, {
    data: progress,
  })
}

export const updateCommissions = async payload => {
  return httpProvider.makeRequest('PUT', `/papers/${payload.paperId}/commissions`, {
    data: payload.data,
  })
}

export const getCommissionsEstimations = async function(payload) {
  await this.GET_PAPER_COMMISSIONS_ESTIMATIONS_REQUESTED(payload)

  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      `/papers/${payload.id}/getCommissionsEstimations`,
      payload.query,
    )

    await this.GET_PAPER_COMMISSIONS_ESTIMATIONS_FULFILLED(data)
    return data
  } catch (err) {
    await this.GET_PAPER_COMMISSIONS_ESTIMATIONS_FAILED(err)
    throw err
  }
}

export const getPaperEmails = async function(payload) {
  await this.GET_PAPER_EMAILS_REQUESTED(payload)
  try {
    const { id } = payload
    const { data } = await httpProvider.makeRequest('GET', `/papers/${id}/emails`)
    await this.GET_PAPER_EMAILS_FULFILLED(data)
    return data
  } catch (err) {
    await this.GET_PAPER_EMAILS_FAILED(err)
    throw err
  }
}

export const getValidTemplates = async function(id) {
  await this.GET_VALID_TEMPLATES_REQUESTED(id)
  try {
    const { data } = await httpProvider.makeRequest('GET', `/papers/${id}/templates`)
    this.GET_VALID_TEMPLATES_FULFILLED(data)
  } catch (err) {
    this.GET_VALID_TEMPLATES_FAILED(err)
    throw err
  }
}

export const syncWithQuote = async function(payload) {
  const { paperId, quoteId } = payload
  await this.SYNC_WITH_QUOTE_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'POST',
      path: `/papers/${paperId}/syncWithQuote`,
      data: { quoteId },
    })
    await this.SYNC_WITH_QUOTE_FULFILLED(data)
    return data
  } catch (err) {
    this.SYNC_WITH_QUOTE_FAILED(err)
    throw err
  }
}
