import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import { select } from '@rematch/select'
import ComposeEmail from '../ComposeEmail'

const ComposeEmailRoot = ({ composeEmails = [] }) => {
  return (
    <Fragment>
      {composeEmails.map(
        composeEmail =>
          composeEmail.isVisible && <ComposeEmail composeEmail={composeEmail}/>,
      )}
    </Fragment>
  )
}

export default compose(
  connect(state => ({
    composeEmails: select.emails.composeEmails(state),
  })),
)(ComposeEmailRoot)
