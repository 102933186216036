import AgentList from './AgentList'
import AgentEdit from './AgentEdit'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'agents.list',
    path: '/agents/list',
    component: withCheckAuthOnEnter(withPageTracker()(AgentList)),
  },
  {
    name: 'agents.edit',
    path: '/agents/edit/:id',
    component: withCheckAuthOnEnter(withPageTracker()(AgentEdit)),
  },
  {
    name: 'agents.add',
    path: '/agents/add',
    component: withCheckAuthOnEnter(withPageTracker()(AgentEdit)),
  },
]
