import React, { Fragment } from 'react'
// import { Progress, Timeline } from 'antd'
import { compose, withProps, defaultProps, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import SmartTable from 'src/components/tables/SmartTable'
import _ from 'lodash'
import Name from '../format/Name'
import TruncateText from '../format/TruncateText'
import LogTypeSelect from './LogTypeSelect'
import Link from 'src/components/navigation/Link'

const typeSwitch = (type, log) => {
  const choice = {
    view: 'Pratica visualizzata',
    changeProgress: () => {
      const label = 'Stato pratica cambiato'
      const progress = _.get(log, 'data.progress')
      if (!progress) return label

      return `${label} in: ${progress.value}% - ${progress.displayName}`
    },
    requestDetailsTabForm: () => {
      const difference = _.get(log, 'data.diff') || []
      return `${difference.length} campi Dettagli Richiesta cambiati`
    },
    syncedQuote: () => {
      const label = 'Preventivo sincronizzato'
      const quote = _.get(log, 'data.quote')
      if (!quote) return label

      return `${label} con ID: ${quote.internalId}`
    },
    addedQuote: () => {
      const label = 'Preventivo aggiunto'
      const quote = _.get(log, 'data.quote')
      if (!quote) return label

      return `${label} con ID: ${quote.internalId}`
    },
    deletedQuote: () => {
      const label = 'Preventivo eliminato'
      const quote = _.get(log, 'data.quote')
      if (!quote) return label

      return `${label} con ID: ${quote.internalId}`
    },
    clientsTab: () => {
      const difference = _.get(log, 'data.diff') || []
      return difference.map(diffItem => {
        const { item, kind } = diffItem
        const [label, client] = (() => {
          if (kind === 'N') {
            return ['Modificata anagrafica cliente']
          }
          if (kind === 'A') {
            const itemKind = item.kind
            if (itemKind === 'D') {
              return ['Eliminata anagrafica cliente: ', _.get(item, 'lhs.client')]
            }
            if (itemKind === 'N') {
              return ['Aggiunta anagrafica cliente: ', _.get(item, 'rhs.client')]
            }
          }

          if (kind === 'E') {
            return ['Modificata anagrafica cliente']
          }

          return []
        })()

        return (
          <Fragment>
            {label} <Name person={client} />
          </Fragment>
        )
      })
    },
    addedNote: () => {
      const label = 'Nota aggiunta'
      const note = _.get(log, 'data.note')
      if (!note) return label

      return (
        <span>
          {label} con ID: {note.internalId} {note.title} -{' '}
          <TruncateText text={note.body} maxLength={20} />
        </span>
      )
    },
    deletedNote: () => {
      const label = 'Nota eliminata'
      const note = _.get(log, 'data.note')
      if (!note) return label

      return (
        <span>
          {label} con ID: {note.internalId} {note.title} -{' '}
          <TruncateText text={note.body} maxLength={20} />
        </span>
      )
    },
    addedDocument: () => {
      const label = 'Documento aggiunto'
      const document = _.get(log, 'data.document')
      if (!document) return label

      return `${label}: ${document.internalId} - ${document.name}`
    },
    deletedDocument: () => {
      const label = 'Documento eliminato'
      const document = _.get(log, 'data.document')
      if (!document) return label

      return `${label}: ${document.internalId} - ${document.name}`
    },
  }[type]

  if (typeof choice === 'function') return choice()

  return choice || type
}

const filtersConfig = [
  {
    key: 'type',
    label: 'Tipo',
    createQueryFilter: value => ({ where: { type: { inq: _.flatten(value) } } }),
    component: props => <LogTypeSelect {...props} mode="multiple" allowClear={true} />,
  },
]

export const LogsTab = props => {
  const {
    actionLogs,
    columns,
    findActionLogs,
    countActionLogs,
    actionLogsCount,
    query,
    tableProps,
  } = props

  return (
    <SmartTable
      data={actionLogs}
      columns={columns}
      initialQuery={query}
      filtersConfig={filtersConfig}
      count={actionLogsCount}
      countMethod={countActionLogs}
      findMethod={findActionLogs}
      {...tableProps}
    />
  )
}

export default compose(
  connect(
    state => ({
      actionLogs: select.actionLogs.getAll(state),
      actionLogsCount: select.actionLogs.count(state),
    }),
    dispatch => ({
      findActionLogs: dispatch.actionLogs.find,
      countActionLogs: dispatch.actionLogs.count,
    }),
  ),
  withProps({
    defaultInitialQuery: {
      filter: {
        include: [
          {
            relation: 'agent',
            scope: {
              fields: ['id', 'internalId', 'firstname', 'lastname'],
            },
          },
          {
            relation: 'paper',
            scope: {
              fields: ['id', 'internalId', 'client'],
            },
          },
        ],
        populate: [
          {
            property: 'data.progressId',
            model: 'Progress',
            on: 'progress',
          },
          {
            property: 'data.quoteId',
            model: 'Quote',
            on: 'quote',
          },
          {
            property: 'data.noteId',
            model: 'Note',
            on: 'note',
          },
          {
            property: 'data.documentId',
            model: 'Document',
            on: 'document',
          },
        ],
      },
    },
  }),
  withProps(props => {
    const { addPaperColumn } = props
    const columns = [
      {
        displayName: 'Data',
        field: 'createdAt',
        type: 'date',
        sortable: true,
        defaultSortOrder: 'descend',
      },
      {
        displayName: 'Tipo',
        field: 'type',
        render: typeSwitch,
      },
      {
        displayName: 'Agente',
        field: 'agent',
        render(agent) {
          return <Name person={agent} />
        },
      },
    ]

    if (addPaperColumn) {
      columns.unshift({
        displayName: 'Pratica',
        field: 'paperId',
        render(ignore, log) {
          if (!log.paper) return
          const client = _.get(log, 'paper.client', {})
          return (
            <div>
              Pratica:{' '}
              <Link to={`/papers/view/${log.paper.id}`}>
                {log.paper.internalId} - <Name person={client} />
              </Link>
            </div>
          )
        },
      })
    }
    return {
      columns,
    }
  }),
  withPropsOnChange(['initialQuery'], ({ defaultInitialQuery, initialQuery }) => ({
    query: _.merge({}, defaultInitialQuery, initialQuery),
  })),
)(LogsTab)
