import React from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Button, Checkbox, message, Modal } from 'antd'
import { push } from 'react-router-redux'
import { getPath } from 'src/store/selectors'
import withConnectedForm from 'src/components/form/withConnectedForm'
import Spinner from 'src/components/Spinner'
import FormItem from 'src/components/form/FormItem'
import SubmitButton from 'src/components/form/SubmitButton'

const query = {
  filter: {
    fields: ['isLocked', 'internalId'],
  },
}

export const SettingsTab = props => {
  const { fetchData, handleSubmit, handleDeleteBtnClick } = props
  return (
    <Spinner fetchData={fetchData}>
      <form onSubmit={handleSubmit}>
        <h3 className="TitleSection">Amministrazione</h3>
        <FormItem
          label=""
          name="isLocked"
          withReduxFormProps={true}
          component={props => (
            <Checkbox {...props} checked={props.input.value}>
              Blocca modifiche a collaboratori
            </Checkbox>
          )}
        />
        <section className="FormActions">
          <SubmitButton />
          <Button
            type="danger"
            icon="delete"
            size="large"
            onClick={handleDeleteBtnClick}
          >
            Elimina pratica
          </Button>
        </section>
      </form>
    </Spinner>
  )
}

export default compose(
  connect(
    state => ({
      initialValues: getPath(state)('papers.tabs.settings.paper'),
    }),
    dispatch => ({
      findById: dispatch.papers.findById,
      update: dispatch.papers.update,
      deletePaper: dispatch.papers.delete,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withConnectedForm('paperSettingsTab'),
  withHandlers({
    fetchData: props => () => {
      const { findById, paperId } = props
      return findById(
        {
          id: paperId,
          query,
        },
        { basePath: 'tabs.settings' },
      )
    },
    handleSubmit: props => async e => {
      e.preventDefault()
      const {
        update,
        paperId,
        dispatchSubmit,
        formValues,
        onPaperUpdate,
      } = props
      await dispatchSubmit(update({ ...formValues, id: paperId }))
      onPaperUpdate()
    },
    handleDeleteBtnClick: props => () => {
      const { paperId, deletePaper, push, formValues } = props
      Modal.confirm({
        title: `Elimina pratica ${formValues.internalId}`,
        content: 'Sei sicuro?',
        onOk: async () => {
          await deletePaper({ id: paperId })
          message.success('Pratica eliminata')
          await push('/papers/list')
        },
      })
    },
  }),
)(SettingsTab)
