import React from 'react'
import { compose, withHandlers, withPropsOnChange, defaultProps } from 'recompose'
import { Input, Divider } from 'antd'
import DatePicker from '../../form/BrDatePicker'
import { default as TheFormItem } from '../../form/FormItem/index'
import NameInput from 'src/components/form/NameInput'
import PlaceSearchInput from 'src/components/form/PlaceSearchInput'
import connect from 'react-redux/es/connect/connect'
import { select } from '@rematch/select'
import { push } from 'react-router-redux'
import withConnectedForm from '../../form/withConnectedForm'
import Spinner from '../../Spinner'
import SubmitButton from '../../form/SubmitButton'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const FormItem = props => (
  <TheFormItem {...formItemLayout} {...props}>
    {props.children}
  </TheFormItem>
)

export const EditClientForm = props => {
  const { formError, fetchData, handleSubmit, handleGmapSearchBoxChange } = props

  return (
    <article className="EditClientForm">
      <Spinner fetchData={fetchData}>
        <form noValidate onSubmit={handleSubmit}>
          <FormItem
            label="Nome"
            name="firstname"
            required
            component={NameInput}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Cognome"
            name="lastname"
            required
            component={NameInput}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Codice Fiscale"
            name="fiscalCode"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="N. Carta Identitá"
            name="identityCard"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="N. Patente"
            name="drivingLicense"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Passaporto"
            name="passport"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Email"
            name="email"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Telefono"
            name="phones[0].phone"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <Divider />
          <FormItem
            label="Data di nascita"
            name="birth.date"
            component={DatePicker}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Cittá di nascita"
            name="birth.city"
            component={PlaceSearchInput}
            onChange={handleGmapSearchBoxChange('birth')}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Provincia di nascita"
            name="birth.province"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Nazione di nascita"
            name="birth.country"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <Divider />
          <FormItem
            label="Indirizzo di residenza"
            name="home.address"
            component={PlaceSearchInput}
            onChange={handleGmapSearchBoxChange('home')}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Codice Postale"
            name="home.postalCode"
            component={PlaceSearchInput}
            onChange={handleGmapSearchBoxChange('home')}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Cittá di residenza"
            name="home.city"
            component={PlaceSearchInput}
            onChange={handleGmapSearchBoxChange('home')}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Provincia di residenza"
            name="home.province"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <FormItem
            label="Nazione di residenza"
            name="home.country"
            component={Input}
            withReduxFormProps={true}
            errors={formError}
          />
          <section className="FormActions">
            <SubmitButton />
          </section>
        </form>
      </Spinner>
    </article>
  )
}

export default compose(
  connect(
    (state, props) => ({
      initialValues: props.id ? select.clients.get(state) : {},
      client: select.clients.get(state),
    }),
    dispatch => ({
      findById: dispatch.clients.findById,
      create: dispatch.clients.create,
      update: dispatch.clients.update,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withConnectedForm('clientEdit', { destroyOnUnmount: true }),
  defaultProps({
    onSubmit: props => () => {},
    onFetched: props => () => {},
  }),
  withHandlers({
    fetchData: props => async () => {
      const { id, findById, onFetched } = props
      if (id) {
        await findById({ id })
        // props.initialize(props.client)
      }

      return onFetched()
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      e.stopPropagation()
      const { formValues, dispatchSubmit, create, update, id, fetchData, onSubmit } = props
      if (id) {
        const newData = await dispatchSubmit(update({ ...formValues, id }))
        await fetchData()
        return onSubmit(e, newData)
      }

      const newData = await dispatchSubmit(create(formValues))

      return onSubmit(e, newData)
    },
    handleGmapSearchBoxChange: props => context => (e, value) => {
      const { change, formValues = {} } = props
      if (value) {
        if (typeof value === 'string') return
        e.preventDefault()
        return change(context, {
          ...formValues[context],
          ...value.formatted,
          address: value.formatted.fullAddress,
        })
      }
    },
  }),
)(EditClientForm)
