import React from 'react'
import {
  compose,
  pure,
  withPropsOnChange,
  withProps,
  withHandlers,
  defaultProps,
} from 'recompose'
import { Table, Row, Col, Icon, InputNumber, Button } from 'antd'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import { dispatch } from '@rematch/core'
import _ from 'lodash'
import _fp from 'lodash/fp'
import debounceHandler from '@hocs/debounce-handler'
import Spinner from 'src/components/Spinner'
import FormItem from 'src/components/form/FormItem'
import PercentageInput from 'src/components/form/PercentageInput'
import CurrencyInput from 'src/components/form/CurrencyInput'
import withConnectedForm from 'src/components/form/withConnectedForm'
import getPath from 'src/store/selectors/getPath'
import SpreadTableStyle from './SpreadsPremiumsTable.style'
import Duration from '../../components/form/Duration'

export const SpreadsTable = props => {
  const {
    fetchData,
    formValues,
    isReady,
    handleAddAmountBtnClick,
    handleRemoveAmountBtnClick,
    handleAddDurationBtnClick,
    handleRemoveDurationBtnClick,
    formError,
    readOnly,
  } = props
  const { durations = [], amounts = [], grid = [] } = formValues || {}
  if (!isReady) return null

  return (
    <Spinner fetchData={fetchData}>
      <article className={SpreadTableStyle}>
        <div className="ant-table-wrapper">
          <div className="ant-spin-nested-loading">
            <div className="ant-spin-container">
              <div
                className="ant-table ant-table-small ant-table-bordered ant-table-scroll-position-left">
                <div className="ant-table-content">
                  <div className="ant-table-body">
                    <table className>
                      <thead className="ant-table-thead">
                      <tr>
                        <th>
                          <Icon type="arrow-down"/> Durate (mesi)
                        </th>
                        {amounts.map((amount, amountsIndex) => (
                          <th>
                            Range {amountsIndex + 1}
                            {!readOnly && (
                              <Button
                                type="link"
                                icon="delete"
                                style={{ marginLeft: 5 }}
                                onClick={() =>
                                  handleRemoveAmountBtnClick(amountsIndex)
                                }
                              />
                            )}
                          </th>
                        ))}
                        <th className="u-noWrap">
                          {!readOnly && (
                            <Button
                              type="link"
                              icon="plus"
                              size="small"
                              onClick={handleAddAmountBtnClick}
                            >
                              Aggiungi range
                            </Button>
                          )}
                        </th>
                      </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                      <tr className="ant-table-row">
                        <td/>
                        {amounts.map((amount, amountsIndex) => (
                          <td>
                            <Row
                              type="flex"
                              gutter={8}
                              style={{ flexFlow: 'row' }}
                            >
                              <Col span={12} style={{ minWidth: 90 }}>
                                <FormItem
                                  label="Da:"
                                  name={`amounts[${amountsIndex}].min`}
                                  component={CurrencyInput}
                                  withReduxFormProps={true}
                                  showErrorLabel={false}
                                  errors={formError}
                                />
                              </Col>
                              <Col span={12} style={{ minWidth: 90 }}>
                                <FormItem
                                  label="A:"
                                  name={`amounts[${amountsIndex}].max`}
                                  component={CurrencyInput}
                                  withReduxFormProps={true}
                                  showErrorLabel={false}
                                  errors={formError}
                                />
                              </Col>
                            </Row>
                          </td>
                        ))}
                      </tr>
                      {durations.map((duration, durationIndex) => (
                        <tr className="ant-table-row">
                          <td>
                            <Row
                              type="flex"
                              gutter={8}
                              style={{ flexFlow: 'row wrap' }}
                            >
                              <Col style={{ minWidth: 130, flex: '0 0 50%' }}>
                                <FormItem
                                  label="Da:"
                                  name={`durations[${durationIndex}].start`}
                                  component={Duration}
                                  withReduxFormProps={true}
                                  showErrorLabel={false}
                                  errors={formError}
                                />
                              </Col>
                              <Col style={{ minWidth: 130, flex: '0 0 50%' }}>
                                <FormItem
                                  label="A:"
                                  name={`durations[${durationIndex}].end`}
                                  component={Duration}
                                  withReduxFormProps={true}
                                  showErrorLabel={false}
                                  errors={formError}
                                />
                              </Col>
                            </Row>
                            {!readOnly && (
                              <Button
                                type="link"
                                icon="delete"
                                onClick={() =>
                                  handleRemoveDurationBtnClick(durationIndex)
                                }
                              />
                            )}
                          </td>
                          {amounts.map((amount, amountIndex) => {
                            const rate = _.get(
                              grid,
                              `${durationIndex}.${amountIndex}`,
                            )
                            return (
                              <td className="u-noWrap">
                                <Row
                                  type="flex"
                                  gutter={8}
                                  style={{ flexFlow: 'row' }}
                                >
                                  <Col span={12} style={{ minWidth: 90 }}>
                                    <FormItem
                                      label="Spread"
                                      name={`spreads[${durationIndex}][${amountIndex}].value`}
                                      component={PercentageInput}
                                      withReduxFormProps={true}
                                      showErrorLabel={false}
                                      errors={formError}
                                      size="small"
                                    />
                                  </Col>
                                  <Col span={12} style={{ minWidth: 90 }}>
                                    <FormItem
                                      label="Par. indicizzazione"
                                      name={`spreads[${durationIndex}][${amountIndex}].indexingParameter`}
                                      component={PercentageInput}
                                      withReduxFormProps={true}
                                      showErrorLabel={false}
                                      errors={formError}
                                      size="small"
                                    />
                                  </Col>
                                </Row>
                                <Row
                                  type="flex"
                                  gutter={8}
                                  style={{ flexFlow: 'row' }}
                                >
                                  <Col span={12} style={{ minWidth: 90 }}>
                                    <FormItem
                                      label="LTV min"
                                      name={`spreads[${durationIndex}][${amountIndex}].ltv.min`}
                                      component={PercentageInput}
                                      withReduxFormProps={true}
                                      showErrorLabel={false}
                                      errors={formError}
                                      size="small"
                                    />
                                  </Col>
                                  <Col span={12} style={{ minWidth: 90 }}>
                                    <FormItem
                                      label="LTV max"
                                      name={`spreads[${durationIndex}][${amountIndex}].ltv.max`}
                                      component={PercentageInput}
                                      withReduxFormProps={true}
                                      showErrorLabel={false}
                                      errors={formError}
                                      size="small"
                                    />
                                  </Col>
                                </Row>
                              </td>
                            )
                          })}
                        </tr>
                      ))}

                      {!readOnly && (
                        <tr className="ant-table-row">
                          <td className="u-noWrap">
                            <Button
                              type="link"
                              icon="plus"
                              size="small"
                              onClick={handleAddDurationBtnClick}
                            >
                              Aggiungi Durata
                            </Button>
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Spinner>
  )
}

export default compose(
  pure,
  withProps(props => ({
    ...props,
    uniqueId: `n-${_.uniqueId()}`,
  })),
  defaultProps({
    onChange: () => {},
  }),
  debounceHandler('onChange', 300),
  withPropsOnChange(['form'], ({ form, uniqueId }) => {
    return {
      form: form && form !== 'spreadsTable' ? form : `spreadsTable-${uniqueId}`,
      isReady: true,
    }
  }),
  withConnectedForm('spreadsTable-null', { destroyOnUnmount: true }),
  connect(
    (state, { form }) => ({
      rates: getPath(state)(`productRates.${form}.productRates`),
    }),
    dispatch => ({
      findOne: dispatch.products.findOne,
    }),
  ),
  withHandlers({
    fetchData: props => () => {
      const { productId, form } = props

      return dispatch({
        type: 'productRates/find',
        payload: {
          filter: {
            where: {
              productId,
              current: true,
            },
            include: ['durations', 'amounts'],
          },
        },
        meta: { basePath: form },
      })
    },
  }),
  withPropsOnChange(['rates'], ({ rates }) => ({
    durations: _fp.flow(
      _fp.reduce((acc, rate) => [...acc, rate.durations], []),
      _fp.sortBy('order'),
      _fp.uniqBy('id'),
    )(rates),
    amounts: _fp.flow(
      _fp.reduce((acc, rate) => [...acc, rate.amounts], []),
      _fp.sortBy('order'),
      _fp.uniqBy('id'),
    )(rates),
  })),
  withPropsOnChange(
    ['rates', 'durations', 'amounts'],
    ({ rates, durations, amounts }) => {
      const grid = []
      _.forEach(durations, (duration, durationIndex) => {
        _.forEach(amounts, (amount, amountIndex) => {
          const rate = _.find(rates, {
            durationsId: duration.id,
            amountsId: amount.id,
          })
          _.set(grid, `[${durationIndex}][${amountIndex}]`, rate)
        })
      })
      return { grid }
    },
  ),
  withPropsOnChange(['grid'], ({ grid, durations, amounts, initialize }) => {
    initialize({
      spreads: [...grid],
      durations: _.map(durations, el => _.pick(el, ['start', 'end'])),
      amounts: _.map(amounts, el => _.pick(el, ['min', 'max'])),
    })
  }),
  withHandlers({
    handleAddAmountBtnClick: props => () => {
      props.array.push('amounts', {})
    },
    handleRemoveAmountBtnClick: props => (
      indexToRemove = props.amounts.length,
    ) => {
      const { formValues: { amounts, spreads } } = props
      if (!amounts.length) return null
      props.array.remove('amounts', indexToRemove)
      const newSpreads = spreads.map(spread =>
        spread.filter((ignore, index) => index !== indexToRemove),
      )
      props.change('spreads', newSpreads)
    },
    handleAddDurationBtnClick: props => () => {
      props.array.push('durations', {})
    },
    handleRemoveDurationBtnClick: props => (indexToRemove = -1) => {
      const { formValues: { spreads } } = props
      props.array.remove('durations', indexToRemove)
      const newSpreads = spreads.filter(
        (ignore, index) => index !== indexToRemove,
      )
      props.change('spreads', newSpreads)
    },
  }),
  withPropsOnChange(
    ['formValues'],
    ({ formValues, form, isReady, onChange }) => {
      if (isReady) {
        onChange({ formName: form, values: formValues })
      }
    },
  ),
)(SpreadsTable)
