import httpProvider from '../../../lib/httpProviderInstance'

export const uploadDocument = async function (payload) {
  const { document, file, fileId } = payload
  await this.UPLOAD_DOCUMENT_REQUESTED(payload)
  try {
    const { data: responseData } = await httpProvider.makeRequest('post', `/documents/upload`, {
      data: {
        document,
        file,
        fileId,
      },
    })
    await this.UPLOAD_DOCUMENT_FULFILLED(responseData)
    return responseData
  } catch (err) {
    await this.UPLOAD_DOCUMENT_FAILED(err)
    throw err
  }
}
