import jqueryDeparam from 'jquery-deparam'
import { dispatch } from '@rematch/core'

const sso = async store =>
  process.nextTick(async () => {
    const search = window.location.href.split('?')[1]
    if (search) {
      const { token, accountId } = jqueryDeparam(search) || {}
      if (token) {
        await dispatch.agents.LOGIN_FULFILLED({ token })
      }
      if (accountId) {
        await dispatch.accounts.setCurrent({ accountId })
      }
    }
  })

export default sso
