import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Button } from 'antd'
import Modal from '../Modal/index'
import SmartTable from '../../tables/SmartTable/index'

const initialQuery = {
  filter: {
    order: 'lastname ASC',
    skipDeleted: true,
  },
}

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
  },
  {
    displayName: 'Nome',
    field: 'client.firstname',
  },
  {
    displayName: 'Cognome',
    field: 'client.lastname',
  },
  {
    displayName: '',
    render: function (ignore, record) {
      const { closeModal = () => {} } = this.props
      return (
        <Button size="small" onClick={() => closeModal(record)}>Seleziona</Button>
      )
    },
  },
]

const filtersConfig = [
  {
    key: 'search',
    type: 'search',
    fields: ['internalId', 'client.firstname', 'client.lastname'],
  },
]

export const ChoosePaperModal = (props) => {
  const { visible, closeModal, papers, count, findPapers, countPapers } = props

  return (
    <Modal
      title="Seleziona Pratica"
      onCancel={() => closeModal()}
      visible={visible}>
      <SmartTable
        closeModal={closeModal}
        initialQuery={initialQuery}
        filtersConfig={filtersConfig}
        data={papers}
        columns={columns}
        count={count}
        countMethod={countPapers}
        findMethod={findPapers}></SmartTable>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      papers: state.papers.papers,
      count: state.papers.count,
    }),
    dispatch => ({
      findPapers: dispatch.papers.find,
      countPapers: dispatch.papers.count,
    }),
  ),
)(ChoosePaperModal)

