import { css } from 'emotion'


export default css`
  & .ant-table {
    border: none;
    table {
      border: none;
      thead {
        display: none;
      }
      tbody {
        tr {
          td.InfoColumn {
            width: 100%;
          }
          td.ValueColumn {
            white-space: nowrap;
            text-align: right;
          }
        }
      }
    }
  }
  & .AddCommissionModal-row {
    margin-bottom: 20px;
    padding-left: 20px;
  }

`
