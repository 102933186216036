import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  pure,
  withHandlers,
  withPropsOnChange,
  setDisplayName,
  withStateHandlers,
} from 'recompose'
import { Menu, Avatar, Icon } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { select } from '@rematch/select'
import { getMenu, getActiveTopItem } from '../../../store/modules/menu/selectors'
import MobileMenuStyle from './MobileMenu.style'
import UserDropdown from '../UserDropdown/UserDropdown'

const SubMenu = Menu.SubMenu

const MobileMenu = props => {
  const {
    items = [],
    activeTopItem,
    handleItemClick,
    closeMobileMenu,
    isMobileMenuOpen,
    setRef,
    refs,
  } = props
  if (!isMobileMenuOpen) return null

  const selectedItemIndex = String(
    _.findIndex(items, item => activeTopItem && item.name === activeTopItem.name),
  )

  return (
    <article ref={setRef} className={MobileMenuStyle}>
      <UserDropdown onClick={closeMobileMenu}>
        {({ Dropdown, username }) => (
          <Dropdown getPopupContainer={() => refs.main}>
            <a className="ant-dropdown-link">
              <section className="MobileMenu-top">
                <Avatar size="large" icon="user" />
                <h2>{username}</h2>
              </section>
            </a>
          </Dropdown>
        )}
      </UserDropdown>

      <Menu selectable={false} theme="dark" selectedKeys={[selectedItemIndex]} mode="inline">
        {items.map((topItem, i) => (
          <SubMenu key={i} title={topItem.title} style={topItem.style}>
            {topItem.items.map((item, index) => (
              <Menu.Item>
                {item.path || topItem.path ? (
                  <Link
                    to={{ ...item, pathname: item.path || topItem.path }}
                    onClick={handleItemClick}
                  >
                    {item.title}
                  </Link>
                ) : (
                  item.title
                )}
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </article>
  )
}

export default compose(
  setDisplayName('MobileMenu'),
  pure,
  withStateHandlers(() => {}, {
    setRef: () => ref => ({
      refs: {
        main: ref,
      },
    }),
  }),
  connect(
    state => ({
      items: getMenu(state),
      activeTopItem: getActiveTopItem(state),
      me: select.agents.me(state),
      isMobileMenuOpen: select.layout.isMobileMenuOpen(state),
    }),
    dispatch => ({
      logout: dispatch.agents.logout,
      openMobileMenu: dispatch.layout.openMobileMenu,
      closeMobileMenu: dispatch.layout.closeMobileMenu,
    }),
  ),
  withPropsOnChange(['items'], ({ items }) => ({
    items: items.map(topItem => ({
      ...topItem,
      items: (topItem.items || []).reduce((acc, groupItem) => [...acc, ...groupItem.items], []),
    })),
  })),
  withPropsOnChange(['me'], ({ me }) => ({
    username: _.get(me, 'me.username'),
  })),
  withHandlers({
    handleLogoutClick: props => () => {
      props.logout()
    },
    handleItemClick: props => () => {
      const { closeMobileMenu } = props
      return closeMobileMenu()
    },
  }),
)(MobileMenu)
