import ReportProfileList from './ReportProfileList'
import ReportProfileEdit from './ReportProfileEdit'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'reportProfiles.list',
    path: '/reportProfiles/all',
    component: withCheckAuthOnEnter(withPageTracker()(ReportProfileList)),
  },
  {
    name: 'reportProfiles.create',
    path: '/reportProfiles/create',
    component: withCheckAuthOnEnter(withPageTracker()(ReportProfileEdit)),
  },
  {
    name: 'reportProfiles.edit',
    path: '/reportProfiles/edit/:id',
    component: withCheckAuthOnEnter(withPageTracker()(ReportProfileEdit)),
  },
]
