import React from 'react'
import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { select } from '@rematch/select'
import AsyncSelect from '../AsyncSelect'

const ProductTypeSelect = props => {
  const { options = [], find, initialQuery } = props

  return (
    <AsyncSelect
      {...props}
      options={options}
      valueField="id"
      labelField="displayName"
      fetchMethod={find}
      initialQuery={initialQuery}
    />
  )
}

export default compose(
  connect(
    state => ({
      options: select.productTypes.getAll(state),
    }),
    dispatch => ({
      find: dispatch.productTypes.find,
    }),
  ),
  withProps({
    initialQuery: {
      filter: { order: 'displayName ASC', skipDeleted: true },
    },
  }),
)(ProductTypeSelect)
