import React from 'react'
import { connect } from 'react-redux'
import { Layout, Tooltip, Menu, Button } from 'antd'
import Link from 'src/components/navigation/Link'
import { compose, withPropsOnChange } from 'recompose'
import { LeftMenu } from 'src/components/navigation/LeftPanel/LeftPanel'
import LeftPanelStyle from '../../components/navigation/LeftPanel/LeftPanel.style'
import withEnhancedRouter from '../../components/withEnhancedRouter'
import _ from 'lodash'

const { Sider } = Layout

const InboxLeftPanel = props => {
  const { subMenu = [], activeItem, newComposeEmail } = props
  return (
    <Sider width={180} className={LeftPanelStyle}>
      <div className="u-textCenter" style={{ marginTop: 15, marginBottom: 10 }}>
        <Button icon="plus" size="small" onClick={newComposeEmail}>
          Nuova Email
        </Button>
      </div>
      <LeftMenu {...{ subMenu, activeItem }} />
    </Sider>
  )
}

export default compose(
  connect(
    () => ({}),
    dispatch => ({
      newComposeEmail: dispatch.emails.newComposeEmail,
    }),
  ),
  withEnhancedRouter,
  withPropsOnChange(['match', 'location'], ({ match }) => {
    return {
      paperId: _.get(match, 'search.paperId'),
      pending: _.get(match, 'search.pending'),
    }
  }),
  withPropsOnChange(
    ['paperCategories', 'paperId', 'pending'],
    ({ paperCategories = [], paperId: activePaperId, pending }) => {
      const subMenu = [
        {
          items: [
            {
              key: 'pending',
              title: 'Da Lavorare',
              path: '/inbox',
              search: '?pending=true',
            },
            { key: 'all', title: 'Tutte le email', path: '/inbox' },
          ],
        },
        {
          items: paperCategories
            .map(({ paperId, messageThreadId, paper }) => {
              if (!paper) return null
              const client = paper.client
              const title = (
                <span>
                  Pratica {paper.internalId}
                  {client && (
                    <span>
                      <br/>
                      {client.firstname} {client.lastname}
                    </span>
                  )}
                </span>
              )
              return {
                key: paperId,
                render: () => (
                  <Menu.Item key={paperId}>
                    <Link
                      to={{ pathname: '/inbox', search: { paperId } }}
                      title={title}
                    >
                      {title}
                    </Link>
                  </Menu.Item>
                ),
              }
            })
            .filter(e => e),
        },
      ]

      let activeItem = subMenu[0].items[1]

      if (pending) {
        activeItem = subMenu[0].items[0]
      }

      if (activePaperId) {
        const [, ...subMenuWithoutAll] = subMenu
        activeItem = _.reduce(
          subMenuWithoutAll,
          (acc, subMenuItem) => {
            if (acc) return acc

            return _.find(subMenuItem.items, { key: activePaperId })
          },
          null,
        )
      }

      return { subMenu, activeItem }
    },
  ),
)(InboxLeftPanel)
