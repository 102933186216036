import React, { Fragment } from 'react'
import { Input, Row, Col } from 'antd'
import { compose, withHandlers } from 'recompose'
import moment from 'moment'
import _ from 'lodash'
import FormItem from 'src/components/form/FormItem'
import Select from 'src/components/form/Select'
import ProductTypeSelect from 'src/components/form/ProductTypeSelect'
import ProductSelect from 'src/components/form/ProductSelect'
import FinancialInstitutionSelect from 'src/components/form/FinancialInstitutionSelect'
import AgentSelect from 'src/components/form/AgentsSelect'
import OfficeSelect from 'src/components/form/OfficeSelect'
import ProgressSelect from 'src/components/form/ProgressSelect'
import TheDatePicker from 'src/components/form/BrDatePicker'
import RoleSelect from 'src/components/form/RoleSelect'

const DatePicker = props => (
  <TheDatePicker
    {...props}
    style={{ width: '100%' }}
  />
)
const dateConfigurationSelectOptions = [
  { label: 'Oggi', value: 'today' },
  { label: `Settimana corrente (Lunedí - Domenica)`, value: 'currentWeek' },
  { label: `Mese corrente`, value: 'currentMonth' },
  { label: `Anno corrente`, value: 'currentYear' },
  { label: 'Ieri', value: 'yesterday' },
  { label: `Settimana scorsa (Lunedí - Domenica)`, value: 'pastWeek' },
  { label: `Mese scorso`, value: 'pastMonth' },
  { label: `Anno scorso`, value: 'pastYear' },
]
const DateConfigurationSelect = props => (
  <Select options={dateConfigurationSelectOptions} {...props} />
)

const ReportFields = props => {
  const {
    report = {},
    formError,
    dataSource,
    normalizeStartOfTheDay,
    normalizeEndOfTheDay,
    handleDateFilterChange,
    areDateRangesVisible,
    areDateRangesConfigurationVisible,
  } = props

  const reportType = _.get(report, 'type')
  return (
    <div>
      <h3 className="TitleSection">Filtri</h3>
      {reportType === 'papers' && (
        <div>
          <Row type="flex" gutter={16}>
            <Col xs={24} md={8}>
              <FormItem
                label="Tipo Prodotto"
                name="criteria.productTypeId.inq"
                component={ProductTypeSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Prodotti"
                name="criteria.productId.inq"
                component={ProductSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Istituti Finanziari"
                name="criteria.financialInstitutionId.inq"
                component={FinancialInstitutionSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} md={8}>
              <FormItem
                label="Agente"
                name="criteria['agents.agentId'].inq"
                component={AgentSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Sede"
                name="criteria.officeId.inq"
                component={OfficeSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Stato Pratica"
                name="criteria['currentProgress.progressId'].inq"
                component={ProgressSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
          </Row>
          {areDateRangesVisible && (
            <Fragment>
              <Row type="flex" gutter={16}>
                <Col xs={24} md={8}>
                  <FormItem
                    label="Ultimo cambio stato - Data inizio"
                    name="criteria['currentProgress.date'].between[0]"
                    component={DatePicker}
                    withReduxFormProps={true}
                    normalize={normalizeStartOfTheDay}
                    onChange={handleDateFilterChange('currentProgress')}
                    errors={formError}
                    style={{ marginBottom: 5 }}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <FormItem
                    label="Ultimo cambio stato - Data fine"
                    name="criteria['currentProgress.date'].between[1]"
                    component={DatePicker}
                    withReduxFormProps={true}
                    normalize={normalizeEndOfTheDay}
                    onChange={handleDateFilterChange('currentProgress')}
                    errors={formError}
                    style={{ marginBottom: 5 }}
                  />
                </Col>
                <Col xs={24} md={8}/>
              </Row>
              <Row type="flex" gutter={16}>
                <Col xs={24} md={8}>
                  <FormItem
                    label="Data erogazione provvigione - Data inizio"
                    name="criteria['$commission.datePaid'].between[0]"
                    component={DatePicker}
                    withReduxFormProps={true}
                    normalize={normalizeStartOfTheDay}
                    onChange={handleDateFilterChange('commissionDatePaid')}
                    errors={formError}
                    style={{ marginBottom: 5 }}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <FormItem
                    label="Data erogazione provvigione - Data fine"
                    name="criteria['$commission.datePaid'].between[1]"
                    component={DatePicker}
                    withReduxFormProps={true}
                    normalize={normalizeEndOfTheDay}
                    onChange={handleDateFilterChange('commissionDatePaid')}
                    errors={formError}
                    style={{ marginBottom: 5 }}
                  />
                </Col>
                <Col xs={24} md={8}/>
              </Row>
            </Fragment>
          )}

          {areDateRangesConfigurationVisible && (
            <Fragment>
              <Row type="flex" gutter={16}>
                <Col xs={24} md={8}>
                  <FormItem
                    label="Intervallo date ultimo cambio stato"
                    name="configuration.currentProgressRange"
                    component={DateConfigurationSelect}
                    withReduxFormProps={true}
                    allowClear={true}
                    onChange={handleDateFilterChange('currentProgress')}
                    errors={formError}
                    style={{ marginBottom: 5 }}
                  />
                </Col>
              </Row>
              <Row type="flex" gutter={16}>
                <Col xs={24} md={8}>
                  <FormItem
                    label="Intervallo date erogazione provvigione"
                    name="configuration.commissionDatePaidRange"
                    component={DateConfigurationSelect}
                    withReduxFormProps={true}
                    allowClear={true}
                    onChange={handleDateFilterChange('commissionDatePaid')}
                    errors={formError}
                    style={{ marginBottom: 5 }}
                  />
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
      )}

      {reportType === 'agents' && (
        <div>
          <Row type="flex" gutter={16}>
            <Col xs={24} md={8}>
              <FormItem
                label="Agente"
                name="criteria.id.inq"
                component={AgentSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Sede"
                name="criteria.officeId.inq"
                component={OfficeSelect}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Ruolo"
                name="criteria.roleId.inq"
                component={RoleSelect}
                mode="multiple"
                allowClear={true}
                withReduxFormProps={true}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} md={8}>
              <FormItem
                label="Data inizio (provvigioni erogate)"
                name="criteria.$commissionsDatePaid.gte"
                component={DatePicker}
                withReduxFormProps={true}
                normalize={normalizeStartOfTheDay}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                label="Data fine (provvigioni erogate)"
                name="criteria.$commissionsDatePaid.lte"
                component={DatePicker}
                withReduxFormProps={true}
                normalize={normalizeEndOfTheDay}
                errors={formError}
                style={{ marginBottom: 5 }}
              />
            </Col>
          </Row>
        </div>
      )}
    </div>
  )
}

export default compose(
  withHandlers({
    normalizeStartOfTheDay: props => value => moment(value).startOf('day'),
    normalizeEndOfTheDay: props => value => moment(value).endOf('day'),
    handleDateFilterChange: ({ change }) => context => () => {
      if (context === 'currentProgress') {
        change(`criteria['$commission.datePaid']`, null)
        change(`configuration.commissionDatePaidRange`, null)
      }
      if (context === 'commissionDatePaid') {
        change(`criteria['currentProgress.date']`, null)
        change(`configuration.currentProgressRange`, null)
      }
    },
  }),
)(ReportFields)
