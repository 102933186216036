import React from 'react'
import jqueryParam from 'jquery-param'
import _ from 'lodash'
import { Link } from 'react-router-dom'

const QueryLink = (props) => {
  const to = _.isString(props.to) ? props.to : { ...props.to, search: jqueryParam(props.to.search) }
  return (
    <Link {...props} to={to}/>
  )
}

export default QueryLink
