import { createSelector } from 'reselect'
import _ from 'lodash'
import _fp from 'lodash/fp'
import { getFormValues } from 'redux-form'

export const progressListPaperCanAdvanceTo = createSelector(
  state => _.get(state, 'progressListPaperCanAdvanceTo.list'),
  _.identity,
)

export const getCurrentAgent = createSelector(
  [state => _.get(state, 'paper')],
  paper =>
    _.get(paper, 'agents', []).find(
      paperAgent => paperAgent.role && paperAgent.role.name === 'agent',
    ),
)

export const getPaperDocuments = createSelector(
  (state, { documents }) => documents,
  data => data,
)

export const getGroupedPaperDocuments = createSelector(
  getPaperDocuments,
  documents =>
    _fp.flow(
      _fp.reduce((acc, document) => {
        const documentType = (acc[document.documentTypeId] = acc[
          document.documentTypeId
        ] || {
          ...document.documentType,
          documents: [],
        })

        documentType.documents.push(document)
        return acc
      }, {}),
      _fp.values,
      _fp.sortBy('name'),
    )(documents),
)

export const getMandatoryDocumentTypes = createSelector(
  state => _.get(state, 'documentsTab.mandatoryDocumentTypes.items'),
  data => data,
)

export const getNewMandatoryDocumentTypes = createSelector(
  state => _.get(state, 'documentsTab.newMandatoryDocumentTypes.items'),
  data => data,
)

export const getGroupedMandatoryDocuments = createSelector(
  getPaperDocuments,
  getMandatoryDocumentTypes,
  (documents, mandatoryDocumentTypes) => {
    const allDocumentTypesIds = _.map(mandatoryDocumentTypes, 'id')
    const otherNotMandatoryDocumentTypes = _.uniqBy(
      _.filter(
        documents,
        ({ documentTypeId }) => !allDocumentTypesIds.includes(documentTypeId),
      ).map(d => d.documentType),
      'id',
    )

    const mandatory = _.filter(
      mandatoryDocumentTypes,
      d => d.blockSwitchToProgressId,
    ).map(dt => ({
      ...dt,
      documents: _.filter(documents, { documentTypeId: dt.id }),
    }))

    const notMandatory = _.orderBy(
      _.filter(mandatoryDocumentTypes, d => !d.blockSwitchToProgressId)
        .concat(otherNotMandatoryDocumentTypes)
        .map(dt => ({
          ...dt,
          documents: _.filter(documents, { documentTypeId: dt.id }),
        })),
      'name',
    )

    return {
      mandatory,
      notMandatory,
    }
  },
)

export const getGroupedNewMandatoryDocuments = createSelector(
  getPaperDocuments,
  getNewMandatoryDocumentTypes,
  (documents, newMandatoryDocumentTypes) => {
    const allDocumentTypesIds = _.map(newMandatoryDocumentTypes, 'id')
    const otherNotMandatoryDocumentTypes = _.uniqBy(
      _.filter(
        documents,
        ({ documentTypeId }) => !allDocumentTypesIds.includes(documentTypeId),
      ).map(d => d.documentType),
      'id',
    )

    const mandatory = _.filter(
      newMandatoryDocumentTypes,
      d => d.blockSwitchToProgressId,
    ).map(dt => ({
      ...dt,
      documents: _.filter(documents, { documentTypeId: dt.id }),
    }))

    const notMandatory = _.orderBy(
      _.filter(newMandatoryDocumentTypes, d => !d.blockSwitchToProgressId)
        .concat(otherNotMandatoryDocumentTypes)
        .map(dt => ({
          ...dt,
          documents: _.filter(documents, { documentTypeId: dt.id }),
        })),
      'name',
    )

    return {
      mandatory,
      notMandatory,
    }
  },
)

export const commissionsEstimationsSelector = createSelector(
  state => _.get(state, 'commissionsTab.commissionsEstimations.items'),
  data => data,
)

export const areCommissionsEstimationsFetching = createSelector(
  state => _.get(state, 'commissionsTab.commissionsEstimations.isFetching'),
  data => data,
)

export const paperEmails = createSelector(
  state => _.get(state, 'emails', []),
  data => data,
)

export const validPaperTemplates = createSelector(
  state => _.get(state, 'documentsTab.validTemplates.items'),
  data => data,
)
