import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { select } from '@rematch/select'
import EditClientForm from '../EditClientForm'
import Modal from '../../modals/Modal'
import withConnectedForm from '../../form/withConnectedForm'
import SubmitButton from 'src/components/form/SubmitButton'

export const EditClientModal = props => {
  const { id, visible, closeModal, handleSubmit } = props

  return (
    <Modal
      title={id ? 'Modifica cliente' : 'Aggiungi cliente'}
      onCancel={() => closeModal()}
      footer={false}
      visible={visible}
    >
      <form onSubmit={handleSubmit}>
        <EditClientForm {...props} onSubmit={handleSubmit} />
      </form>
    </Modal>
  )
}

export default compose(
  withHandlers({
    handleSubmit: props => async (e, client) => {
      e.preventDefault()
      props.closeModal(client)
    },
  }),
)(EditClientModal)
