import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import deparam from 'deparam'
import _ from 'lodash'
import { getPath } from '../store/selectors'

export default compose(
  withRouter,
  connect(state => ({
    location: getPath(state)('routing.location'),
  })),
  withPropsOnChange(['location', 'match'], ({ location, match }) => {
    return {
      id: _.get(match, 'params.id'),
      match: {
        ...match,
        search: deparam(location.search.replace(/^(\?)/, '')),
      },
    }
  }),
)
