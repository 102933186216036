import React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers, lifecycle } from 'recompose'
import { select } from '@rematch/select'
import { Alert, Button, Card, Checkbox, Divider } from 'antd'
import _ from 'lodash'
import Select from 'src/components/form/Select'
import Modal from 'src/components/modals/Modal'
import BrDatePicker from 'src/components/form/BrDatePicker'
import withConnectedForm from 'src/components/form/withConnectedForm'
import FormItem from 'src/components/form/FormItem'
import Spinner from 'src/components/Spinner'
// import { actionsCreators, selectors } from '../../../store/modules/Progress'
import SubmitButton from 'src/components/form/SubmitButton/SubmitButton'
import withWatcher from '../../../components/withWatcher'

const PaperProgressSelect = ({ progressList, ...rest }) => (
  <Select
    options={progressList}
    valueField="id"
    labelField={f => `${f.value}% - ${f.displayName}`}
    {...rest}
  />
)

export const ChangePaperProgressModal = props => {
  const {
    visible,
    closeModal,
    fetchData,
    handleSubmit,
    shouldSendEmails,
    emailTemplates,
    progressList,
    formValues,
  } = props

  return (
    <Modal
      title="Cambia stato pratica"
      onOk={handleSubmit}
      onCancel={() => closeModal()}
      footer={[
        <Button size="medium" onClick={closeModal}>
          Annulla
        </Button>,
        <SubmitButton type="primary" size="medium" onClick={handleSubmit}>
          Salva
        </SubmitButton>,
      ]}
      visible={visible}
    >
      <Spinner fetchData={fetchData}>
        <form className="form" onSubmit={handleSubmit}>
          <FormItem
            label="Stato pratica"
            name="progressId"
            progressList={progressList}
            withReduxFormProps={true}
            component={PaperProgressSelect}
          />
          <FormItem
            label="Data cambio stato"
            name="date"
            withReduxFormProps={true}
            component={BrDatePicker}
          />
          {shouldSendEmails && (
            <>
              <Divider />
              {formValues && formValues.sendEmail && (
                <Alert
                  message="Attenzione"
                  description={
                    <div>
                      Le seguenti email verranno inviate automaticamente al cliente:{' '}
                      <ul>
                        {_.map(emailTemplates, ({ name }) => (
                          <li>{name}</li>
                        ))}
                      </ul>
                    </div>
                  }
                  type="warning"
                  showIcon
                />
              )}
              <FormItem
                name="sendEmail"
                style={{
                  margin: '0 5px 0 0',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
                normalize={value => !value}
                format={value => !value}
                component={props => (
                  <label>
                    <Checkbox {...props} /> Non inviare email
                  </label>
                )}
              />
            </>
          )}
        </form>
      </Spinner>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      progressList: select.papers.progressListPaperCanAdvanceTo(state),
    }),
    dispatch => ({
      getProgressList: dispatch.papers.getProgressListPaperCanAdvanceTo,
      changeProgress: dispatch.papers.changeProgress,
      createLog: dispatch.actionLogs.create,
    }),
  ),
  withConnectedForm('changePaperProgress'),
  withHandlers({
    fetchData: props => () => {
      const { getProgressList, paper } = props
      getProgressList({
        id: paper.id,
        query: {
          filter: {
            order: 'value asc',
            populate: [
              {
                property: 'sendEmailWhenReached.emailTemplateIds',
                model: 'EmailTemplate',
                on: 'emailTemplates',
              },
            ],
          },
        },
      })
    },
    handleSubmit: props => async e => {
      e.preventDefault()
      const { dispatchSubmit, changeProgress, paper, formValues, closeModal, createLog } = props

      await dispatchSubmit(changeProgress({ id: paper.id, progress: formValues }))
      await createLog({
        paperId: paper.id,
        context: 'paper',
        type: 'changeProgress',
        data: { progressId: formValues.progressId },
      })
      return closeModal(true)
    },
  }),
  withPropsOnChange(['progressList', 'formValues'], ({ progressList, formValues }) => {
    const progressId = _.get(formValues, 'progressId')
    if (!progressId) return null

    const progress = _.find(progressList, { id: progressId })
    if (!progress) return

    const { active: isSendEmailActive, emailTemplates } = progress.sendEmailWhenReached || {}
    const shouldSendEmails = isSendEmailActive && !_.isEmpty(emailTemplates)

    return { shouldSendEmails, emailTemplates }
  }),
  withWatcher(
    ['formValues.progressId', 'pristineValues.progressId'],
    { immediate: true },
    ({ formValues, pristineValues, change }) => {
      const progressId = _.get(formValues, 'progressId')
      const previousProgressId = _.get(pristineValues, 'progressId')
      if (!progressId) return
      if (progressId === previousProgressId) {
        return change('sendEmail', false)
      }

      return change('sendEmail', true)
    },
  ),
)(ChangePaperProgressModal)
