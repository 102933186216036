import React, { Fragment } from 'react'
import {
  pure,
  compose,
  withProps,
  defaultProps,
  lifecycle,
  withHandlers,
  withState,
  withPropsOnChange,
} from 'recompose'
import _ from 'lodash'
import { Spin } from 'antd'
import Select from '../Select'
import withWatcher from '../../withWatcher'

const AsyncSelect = props => {
  const { handleFocus, formattedOptions, initialLoaded } = props
  return (
    <Fragment>
      <Spin spinning={initialLoaded}>
        <Select
          {...props}
          options={formattedOptions}
          labelField="label"
          valueField="value"
          onFocus={handleFocus}
        />
      </Spin>
    </Fragment>
  )
}

export default compose(
  pure,
  defaultProps({
    labelField: 'label',
    valueField: 'value',
    onFocus: () => {},
    forceRefetch: false,
  }),
  withState('loaded', 'setLoaded', false),
  withState('initialLoaded', 'setInitialLoaded', false),
  withProps(() => {
    return {
      loadingOptions: [
        {
          label: (
            <span>
              <Spin /> Caricamento...
            </span>
          ),
          value: String(Math.random()),
          disabled: true,
        },
      ],
    }
  }),
  withHandlers({
    getLabelField: ({ labelField }) => option => {
      const label = typeof labelField === 'function' ? labelField(option) : option[labelField]

      return _.compact([option.deletedAt ? '[ELIMINATO]' : '', label]).join(' ')
    },
    getValueField: ({ valueField }) => option => {
      if (typeof valueField === 'function') return valueField(option)

      return option[valueField]
    },
  }),

  withPropsOnChange(
    ['options', 'loaded'],
    ({ options = [], loaded, loadingOptions, getLabelField, getValueField }) => {
      let formattedOptions = options.map(option => ({
        label: getLabelField(option),
        value: getValueField(option),
      }))
      if (!loaded) {
        formattedOptions = [...loadingOptions, ...formattedOptions]
      }

      return { formattedOptions }
    },
  ),
  withHandlers({
    fetchInitialData: props => async () => {
      if (!props.value) return
      await props.setInitialLoaded(true)
      const { fetchMethod, initialQuery, valueField } = props
      const value = Array.isArray(props.value) ? props.value : [props.value]
      if (_.isEmpty(value)) {
        return await props.setInitialLoaded(false)
      }
      const query = _.merge({}, initialQuery, {
        filter: { where: { [valueField]: { inq: value } }, skipDeleted: false },
      })

      try {
        return await fetchMethod(query)
      } finally {
        await props.setInitialLoaded(false)
      }
    },

    fetchData: props => async () => {
      const { fetchMethod, initialQuery, setLoaded, loaded, forceRefetch } = props
      if (loaded && !forceRefetch) return
      const query = _.merge({}, initialQuery, {
        filter: { limit: 1000 },
      })

      await fetchMethod(query)
      return setLoaded(true)
    },
  }),
  withWatcher(['value'], ({ fetchInitialData, initialLoaded, loaded }) => {
    // console.log(loaded, initialLoaded)
    return !loaded && !initialLoaded && fetchInitialData()
  }),
  withHandlers({
    handleFocus: props => (...args) => {
      props.onFocus(...args)
      return props.fetchData()
    },
  }),
  lifecycle({
    componentDidMount() {
      return this.props.fetchInitialData()
    },
  }),
)(AsyncSelect)
