import React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange, withProps } from 'recompose'
import { Badge, Button } from 'antd'
import _ from 'lodash'
import Modal from '../Modal/index'
import SmartTable from '../../tables/SmartTable/index'
import FormattedDate from '../../../components/format/FormattedDate'

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
  },
  {
    displayName: 'Nome',
    field: 'firstname',
  },
  {
    displayName: 'Cognome',
    field: 'lastname',
  },
  {
    displayName: 'Ruolo',
    field: 'roles[0].displayName',
  },
  {
    displayName: 'Stato',
    field: 'deletedAt',
    type: 'date',
    sortable: true,
    render(deletedAt) {
      return (
        <>
          <Badge
            count={deletedAt ? 'Disattivato' : 'Attivo'}
            style={deletedAt ? {} : { backgroundColor: '#52c41a' }}
          />
          {deletedAt && (
            <>
              <br />
              <span>
                (<FormattedDate date={deletedAt} />)
              </span>
            </>
          )}
        </>
      )
    },
  },
  {
    displayName: '',
    render: function(ignore, record) {
      const { closeModal = () => {} } = this.props
      return (
        <Button size="small" onClick={() => closeModal(record)}>
          Seleziona
        </Button>
      )
    },
  },
]

const filtersConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['firstname', 'lastname'],
  },
  'includeDeleted',
]

export const ChooseAgentModal = props => {
  const { visible, closeModal, agents, count, query, findAgents, countAgents } = props

  return (
    <Modal title="Seleziona agente" onCancel={() => closeModal()} width={700} visible={visible}>
      <SmartTable
        closeModal={closeModal}
        initialQuery={query}
        data={agents}
        filtersConfig={filtersConfig}
        columns={columns}
        count={count}
        countMethod={countAgents}
        findMethod={findAgents}
      />
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      agents: state.agents.agents,
      count: state.agents.count,
    }),
    dispatch => ({
      findAgents: dispatch.agents.find,
      countAgents: dispatch.agents.count,
    }),
  ),
  withPropsOnChange(['initialQuery'], ({ initialQuery }) => ({
    query: _.assign(
      {},
      {
        filter: {
          order: 'lastname ASC',
          skipDeleted: true,
        },
      },
      initialQuery,
    ),
  })),
)(ChooseAgentModal)
