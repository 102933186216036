import React from 'react'
import { compose, pure } from 'recompose'
import _get from 'lodash/get'
import { Row, Col, Button, Progress, Alert } from 'antd'
import { cx } from 'emotion'
import FormattedDate from 'src/components/format/FormattedDate'
import Percentage from 'src/components/format/Percentage'
import styled from 'react-emotion'
import style from './PapersViewTop.style'

const Infos = styled('div')({
  display: 'inline-block',
  marginTop: 10,
})

export const PaperViewTop = props => {
  const { paper, currentAgent = {}, readOnly, isAdmin, onChangeStatusBtnClick } = props
  if (!paper) return null

  const columnsProps = {
    className: 'PapersViewTop-col',
    xs: { span: 12 },
    sm: { span: 4 },
  }

  const currentProgress = _get(paper, 'currentProgress.progress', {})
  const currentProgressDate = _get(paper, 'currentProgress.date')
  return (
    <section className={cx(style, 'u-container')}>
      <Row type="flex" justify="space-between" gutter={16}>
        <Col {...columnsProps} sm={2}>
          <b>ID</b>
          <p>{_get(paper, 'internalId')}</p>
        </Col>
        <Col {...columnsProps} sm={7}>
          <b>Stato Pratica</b>
          <p style={{ marginBottom: 3 }}>
            <Percentage value={currentProgress.value} /> {currentProgress.displayName}
          </p>
          <p style={{ marginBottom: 0, fontSize: '11px' }}>
            <FormattedDate date={currentProgressDate} />
          </p>
          <Row type="flex" justify="space-between" gutter={16} align="middle">
            <Col style={{ flexGrow: 1 }}>
              <Progress percent={currentProgress.value} strokeWidth={5} />
            </Col>
            <Col>
              {(isAdmin || !readOnly) && (
                <Button size="small" type="primary" onClick={onChangeStatusBtnClick}>
                  Cambia
                </Button>
              )}
            </Col>
          </Row>
        </Col>

        <Col {...columnsProps}>
          <b>Cliente</b>
          <p>
            {_get(paper, 'client.firstname')} {_get(paper, 'client.lastname')}
          </p>
        </Col>

        <Col {...columnsProps}>
          <b>Agente</b>
          <p style={{ marginBottom: 0 }}>
            {_get(currentAgent, 'firstname')} {_get(currentAgent, 'lastname')}
          </p>
        </Col>

        <Col {...columnsProps}>
          <b>Prodotto finanziario</b>
          <p style={{ marginBottom: 0 }}>{_get(paper, 'productType.displayName')}</p>
        </Col>
      </Row>

      {Boolean(paper.isLocked) && (
        <Infos>
          <Alert message="Modifiche bloccate ai collaboratori" type="warning" banner />
        </Infos>
      )}
    </section>
  )
}
export default pure(PaperViewTop)
