import React from 'react'
import { Button, Icon, Tooltip } from 'antd'
import { compose, withHandlers, defaultProps } from 'recompose'
import { connect } from 'react-redux'
import { openDocumentViewModal } from 'src/components/modals/DocumentViewModal'
import TruncateText from '../format/TruncateText'
import style from './AttachmentList.style'
import withModalControls from 'src/components/modals/withModalControls'
import _ from 'lodash'

export const AttachmentList = props => {
  const {
    attachments,
    canRemove,
    onRemove,
    attachmentActions,
    handleAttachmentClick,
    isEmptyAttachmentMessageVisible,
  } = props
  return (
    <section className={style}>
      {isEmptyAttachmentMessageVisible &&
        (!attachments || (!attachments.length && <p>Nessun allegato</p>))}
      {attachments &&
        attachments.length > 0 && (
          <ul className="AddAttachmentsModal-files-list u-listStyleNone">
            {attachments.map(attachment => (
              <li
                className="AddAttachmentsModal-file"
                onClick={() => handleAttachmentClick(attachment)}
              >
                <div className="AddAttachmentsModal-file-icon">
                  <Icon type="file" />
                </div>
                <div className="AddAttachmentsModal-file-name">
                  <TruncateText text={attachment.name} maxLength={40}>
                    {({ isTruncated, truncatedText, ellipsis }) => {
                      if (isTruncated) {
                        return (
                          <Tooltip title={attachment.name}>
                            {truncatedText}
                          </Tooltip>
                        )
                      }

                      return attachment.name
                    }}
                  </TruncateText>
                </div>

                <span
                  className="AddAttachmentsModal-file-actions"
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  {attachmentActions({ attachment })}
                </span>

                {canRemove && (
                  <span
                    className="AddAttachmentsModal-file-delete"
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  >
                    <Button
                      icon="delete"
                      shape="circle"
                      size="small"
                      type="danger"
                      onClick={() => onRemove(attachment)}
                    />
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
    </section>
  )
}

export default compose(
  connect(null, {
    openDocumentViewModal,
  }),
  defaultProps({
    attachmentActions: () => {},
  }),
  withModalControls('documentPreviewModal'),
  withHandlers({
    handleAttachmentClick: props => async attachment => {
      const { openDocumentViewModal, attachments } = props
      await openDocumentViewModal({
        items: attachments,
        activeItemIndex: _.findIndex(attachments, { id: attachment.id }),
      })
    },
  }),
)(AttachmentList)
