import PapersList from './PapersList'
import PapersCreate from './PapersCreate'
import PapersView from './PapersView/PapersView'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'papers.list',
    path: '/papers/list',
    component: withCheckAuthOnEnter(withPageTracker()(PapersList)),
  },
  {
    name: 'papers.add',
    path: '/papers/add',
    component: withCheckAuthOnEnter(withPageTracker()(PapersCreate)),
  },
  {
    name: 'papers.view',
    path: '/papers/view/:id',
    component: withCheckAuthOnEnter(withPageTracker()(PapersView)),
  },
]
