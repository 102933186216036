import _ from 'lodash'
import httpProvider from '../../../lib/httpProviderInstance'
import { toDataUrl } from '../../../lib/imageHelpers'

export const getMine = async function({ accountId } = {}) {
  await this.FIND_ACCOUNTSETTINGS_REQUESTED()
  const headers = accountId ? { 'x-br2-accountid': accountId } : {}
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'GET',
      path: '/accountSettings/mine',
      headers,
    })
    const logoSrc = _.get(data, 'public.ui.logo.src')
    if (logoSrc) {
      try {
        _.set(data, 'public.ui.logo.src', await toDataUrl(logoSrc))
      } catch (e) {}
    }
    await this.FIND_ACCOUNTSETTINGS_FULFILLED(data)
    return data
  } catch (err) {
    await this.FIND_ACCOUNTSETTINGS_FAILED(err)
    throw err
  }
}

export const updateMine = async function(payload) {
  // await this.FIND_ACCOUNTSETTINGS_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'PATCH',
      path: '/accountSettings/mine',
      data: payload,
    })
    // await this.FIND_ACCOUNTSETTINGS_FULFILLED(data)

    return data
  } catch (err) {
    // await this.FIND_ACCOUNTSETTINGS_FAILED(err)
    throw err
  }
}
