import React from 'react'
import { compose, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { Layout, Icon } from 'antd'
import _ from 'lodash'
import EmailEditor from './EmailEditor'
import style from './ComposeEmail.style'

const { Header } = Layout

const ComposeEmail = props => {
  const { composeEmail, handleCloseBtnClick, handleEmailSent } = props
  const { initialData = {} } = composeEmail || {}
  const { paperId, paper } = initialData
  const internalId = _.get(paper, 'internalId')

  return (
    <article className={style}>
      <Header className="ComposeEmail-header">
        <div className="ComposeEmail-header-left">
          {paperId && <h4>Pratica {internalId || paperId} - Nuova Email</h4>}
          {!paperId && <h4>Nuova Email</h4>}
        </div>
        <div className="ComposeEmail-header-right">
          <Icon
            className="u-cursorPointer"
            type="close-circle"
            onClick={() => handleCloseBtnClick()}
          />
        </div>
      </Header>
      <section className="ComposeEmail-body">
        <EmailEditor
          form={`emailEditor-${composeEmail.id}`}
          initialData={initialData}
          onEmailSent={handleEmailSent}
        />
      </section>
    </article>
  )
}

export default compose(
  connect(
    () => {},
    dispatch => ({
      hideComposeEmail: dispatch.emails.hideComposeEmail,
    }),
  ),
  withHandlers({
    handleEmailSent: props => () => {
      props.hideComposeEmail(props.composeEmail)
    },
    handleCloseBtnClick: props => () => {
      props.hideComposeEmail(props.composeEmail)
    },
  }),
)(ComposeEmail)
