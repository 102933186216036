import { css } from 'emotion'

export default css`
  & {
    .version-notification {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      max-width: 80%;
      margin: 0 auto;
      bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 20;
      padding: 5px 15px;
      background: white;
      box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
      animation: 0.5s fade ease-in;

      @media (max-width: 767px) {
        width: 100%;
        max-width: none;
      }
    }

    @keyframes fade {
      0% {
        opacity: 0;
        bottom: -5rem;
      }
      100% {
        opacity: 1;
        bottom: 0;
      }
    }

    .version-notification__iconText {
      display: flex;
      justify-content: center;
      padding: 0.5rem;
      &__icon {
        display: flex;
        align-items: center;
      }
    }

    .version-notification__iconText > p {
      padding: 0 1rem;
      margin: 0;
    }

    .version-notification__actions {
      display: flex;
    }

    .version-notification__actions > button {
      margin-left: 10px;
    }

    .version-notification__actions__close {
      display: flex;
    }

    .version-notification__actions__close > svg {
      width: 15px;
      height: 15px;
    }
  }
`
