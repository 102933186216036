import { css } from 'emotion'

export default css`
  & {
    h2 {
      margin-bottom: 10px;
    }
    .GroupedDocumentsTable {
      > table {
        > thead {
          > tr {
            > th {
              word-break: normal;
            }
          }
        }
        > tbody {
          > tr {
            &:hover {
              > td {
                background: white !important;
              }
            }
            > td {
              vertical-align: top;
              padding: 0;
              word-wrap: break-word;
            }
          }
        }
      }
    }
    .DocumentsTable {
      margin: 0 0 15px;
      > table {
        table-layout: fixed;
        > tbody {
          > tr {
            &:last-child {
              td {
                border-bottom: none;
              }
            }
            > td {
              padding: 5px 8px;
              font-size: 11px;
              .ant-form-item-control {
                line-height: 1;
              }
            }
            @media (max-width: 991px) {
              td {
                display: inline-block;
                width: 100%;
                border-bottom: none;
                &:last-child {
                  border-bottom: 1px solid #e8e8e8;
                }
              }
              &:last-child {
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
`
