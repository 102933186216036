import { css } from 'emotion'

export default css`
  & {
    background: white;
    //padding: 5px 10px;
    .ant-table {
      border: none;
      overflow-x: auto;
      table {
        padding: 0 !important;
      }
    }
    .ant-table-thead > tr > th {
      background: white;
      border-bottom: 1px solid #e9e9e9;
      // white-space: nowrap;
      word-break: initial;
      word-wrap: break-word;
    }

    .ant-table-tbody > tr > td {
      word-break: normal;
      // font-size: 11px;
      user-select: text;
      // min-width: 100px;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      // font-size: 13px;
      font-size: 11px;
      /*&:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }*/
    }
    .ant-table-thead > tr > th {
      /*padding-top: 2px;
    padding-bottom: 2px;*/
      position: relative;
      &.is-sortable {
        padding-right: 18px;
        .ant-table-column-sorter {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 3px;
          margin: 0;
        }
      }
    }

    .ant-table-pagination {
      padding: 0 0 0 15px;
      margin: 16px 0px 16px 0;
      // float: left !important;
      & .ant-pagination-options {
        > *:last-child {
          margin-right: 0;
        }
      }
    }

    @media (max-width: 480px) {
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > th,
      .ant-table-thead > tr > td,
      .ant-table-tbody > tr > td {
        display: table-cell;
        border: none;
        border-bottom-color: rgb(233, 233, 233);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-collapse: separate;
        border-right-color: rgb(232, 232, 232);
        border-right-style: solid;
        border-right-width: 1px;
      }
    }

    .SimpleTable-cell-actions {
      .ant-divider.ant-divider-horizontal {
        margin: 5px 0;
      }
    }
  }
`
