import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle } from 'recompose'
import { Layout } from 'antd'
import { Icon } from 'antd'
import { select } from '@rematch/select'
import _ from 'lodash'
import FormItem from 'src/components/form/FormItem/index'
import SubmitButton from 'src/components/form/SubmitButton/index'
import withConnectedForm from 'src/components/form/withConnectedForm'
import Select from 'src/components/form/Select'
import Spinner from 'src/components/Spinner'
import LoginStyle from '../Login.style'
import { push } from 'react-router-redux'

const { Content } = Layout

const AccountSwitcher = props => {
  const { handleSubmit, fetchData, accounts, formValues } = props

  return (
    <Layout className={LoginStyle}>
      <Content className="LoginPage-wrapper">
        <h3 className="TitleSection">Cambia account</h3>
        <Spinner fetchData={fetchData}>
          <form onSubmit={handleSubmit} className="LoginPage-form">
            <Fragment>
              <p>Seleziona l'account a cui vuoi accedere</p>
            </Fragment>
            <FormItem
              name="accountId"
              component={props => (
                <Select {...props} options={accounts} labelField="name" valueField="id" />
              )}
            />

            <SubmitButton
              disabled={!(formValues && formValues.accountId)}
              className="LoginPage-submit"
            />
          </form>
        </Spinner>
        <a href="/#/">
          <Icon type="left" /> vai indietro
        </a>
      </Content>
    </Layout>
  )
}

const IS_PRODUCTION = process.env.ENV === 'production'

export default compose(
  connect(
    state => ({
      accounts: select.accounts.getAll(state),
      accountSettings: select.accountSettings.get(state),
      token: select.agents.token(state),
    }),
    dispatch => ({
      fetchAccounts: dispatch.accounts.find,
      setCurrentAccount: dispatch.accounts.setCurrent,
      getAccountSettings: dispatch.accountSettings.getMine,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withConnectedForm('accountSwitcher'),
  withHandlers({
    fetchData: props => () => {
      return props.fetchAccounts({ filter: { limit: 1000 } })
    },
    handleSubmit: props => async event => {
      event.preventDefault()
      const { setCurrentAccount, formValues, getAccountSettings, push, token } = props
      const accountId = _.get(formValues, 'accountId')
      process.nextTick(async () => {
        const accountSettings = await getAccountSettings({ accountId })
        const domain = _.get(
          accountSettings,
          'public.ui.domain',
          process.env.REACT_APP_UI_BASE_DOMAIN,
        )
        if (domain) {
          const dmn = IS_PRODUCTION ? domain : window.location.host
          const protocol = IS_PRODUCTION ? 'https' : 'http'

          let href = `${protocol}://${dmn}/#/?token=${token}`
          href += `&accountId=${accountId}`

          if (IS_PRODUCTION) {
            return (window.location.href = href)
          } else {
            // localhost
            await setCurrentAccount({ accountId })
            /*IS_PRODUCTION && window.location.reload()*/
          }
        }
        await setCurrentAccount({ accountId })
        return push('/papers/list')
      })
    },
  }),
)(AccountSwitcher)
