import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'
import * as effects from './effects'
import * as reducers from './reducers'
import * as selectors from './selectors'

export default () => createModel({
  name: 'paper',
  provider: httpProvider,
  state: {
    progressListPaperCanAdvanceTo: {},
  },
  effects,
  reducers,
  selectors,
})
