import _ from 'lodash'
import { message } from 'antd'


export const CALL_API = 'CALL_API'
export const CALL_API_REQUESTED = 'CALL_API_REQUESTED'
export const CALL_API_FULFILLED = 'CALL_API_FULLFILLED'
export const CALL_API_FAILED = 'CALL_API_FAILED'

export function callApi({ method, path, data, headers } = {}, actionTypes) {
  return {
    type: CALL_API,
    payload: {
      method,
      path,
      data,
      headers,
      actionTypes,
    },
  }
}

export const FORM_SUBMIT_REQUESTED = 'FORM_SUBMIT_REQUESTED'
export const FORM_SUBMIT_FULFILLED = 'FORM_SUBMIT_FULFILLED'
export const FORM_SUBMIT_FAILED = 'FORM_SUBMIT_FAILED'

export function startSubmit(formName, payload = {}, meta = {}) {
  return {
    type: FORM_SUBMIT_REQUESTED,
    payload,
    meta: {
      form: formName,
      ...meta,
    },
  }
}

const formatError = (serverResponse) => {
  const responseError = _.get(serverResponse, 'response.data.error.inner')
  let errors
  if (responseError) {
    errors = responseError.reduce((acc, error) => {
      acc[error.path || error.type] = error.message
      return acc
    }, {})
  }

  return errors
}

export function stopSubmit(formName, error, result, { displayMessage = true, errorMessage, successMessage } = {}) {
  const action = {
    type: FORM_SUBMIT_FULFILLED,
    payload: result,
    meta: {
      form: formName,
    },
  }

  if (error) {
    action.type = FORM_SUBMIT_FAILED
    action.payload = formatError(error)
    action.error = true
    if (displayMessage) {
      message.error(errorMessage || 'Errore durante la richiesta')
    }
    return action
  }
  if (displayMessage) {
    message.success(successMessage || 'Modifiche effettuate')
  }
  return action
}

export const CLEAR_STATE_AT_PATH = 'CLEAR_STATE_AT_PATH'

export const clearStateAtPath = (path) => {
  return {
    type: CLEAR_STATE_AT_PATH,
    payload: { path },
  }
}
