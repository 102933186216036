import React from 'react'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import Promise from 'bluebird'
import { css } from 'emotion'
import _ from 'lodash'
import InboxLayout from './InboxLayout'
import EmailThreadList from 'src/components/emails/EmailThreadList'
import withInboxProps from './withInbox'
import withSubscription from 'src/components/withSubscription'
import withEnhancedRouter from 'src/components/withEnhancedRouter'

const className = css({
  '& .DefaultLayout-content-inner': {
    flexGrow: '1 !important',
  },
})

export const InboxThreadList = props => {
  const {
    paperId,
    match,
    paperCategories,
    onChange,
    onlyUnread,
    isUnreadCheckboxVisible,
  } = props

  return (
    <InboxLayout className={className} paperCategories={paperCategories}>
      <article>
        <EmailThreadList
          onChange={onChange}
          paperId={paperId}
          isUnreadCheckboxVisible={isUnreadCheckboxVisible}
          onlyUnread={onlyUnread}
          linkFactory={record => ({
            pathname: `inbox/${record.email.messageThreadId}`,
            search: match.search,
          })}
        />
      </article>
    </InboxLayout>
  )
}

export default compose(
  withInboxProps,
  withSubscription,
  withPropsOnChange(['match', 'location'], ({ match }) => {
    return {
      paperId: _.get(match, 'search.paperId'),
      pending: _.get(match, 'search.pending') === 'true' ? true : undefined,
    }
  }),
  withPropsOnChange(['pending'], ({ pending }) => ({
    isUnreadCheckboxVisible: !pending,
    onlyUnread: pending,
  })),
)(InboxThreadList)
