import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'
import * as effects from './effects'
import * as reducers from './reducers'
import * as selectors from './selectors'

export default () => {
  return createModel({
    name: 'path',
    provider: httpProvider,
    state: {
      browse: {
        path: null,
        isFetching: false,
        isFailed: false,
      },
    },
    effects,
    reducers,
    selectors,
  })
}
