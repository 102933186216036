import React from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import styled from 'react-emotion'
import { Button } from 'antd'
import _ from 'lodash'
import { EmailThreadList } from 'src/components/emails'
import withSubscription from 'src/components/withSubscription'


const NewEmailButton = styled(Button)`
  margin-bottom: 20px;
`

const emailListConfig = {
  isPaperIdVisible: false,
}

export const EmailsTabThreads = props => {
  const {
    linkFactory,
    handleNewEmailBtnClick,
    paperId,
  } = props
  return (
    <article className="EmailsTabThreads">
      <NewEmailButton icon="plus" size="small" onClick={handleNewEmailBtnClick}>
        Nuova Email
      </NewEmailButton>
      <EmailThreadList
        linkFactory={linkFactory}
        config={emailListConfig}
        paperId={paperId}
      />
    </article>
  )
}

export default compose(
  withSubscription,
  connect(
    state => ({}),
    dispatch => ({
      newComposeEmail: dispatch.emails.newComposeEmail,
    }),
  ),
  withHandlers({
    linkFactory: props => record => ({
      pathname: `/papers/view/${props.paperId}`,
      search: {
        tabs: 'emails',
        tabData: { messageThreadId: record.email.messageThreadId },
      },
    }),
    handleNewEmailBtnClick: props => () => {
      const { newComposeEmail, paper } = props
      const to = _.get(paper.client.email) ? [{ email: paper.client.email }] : []
      newComposeEmail({
        to,
        paperId: paper.id,
        paper,
      })
    },
  }),
)(EmailsTabThreads)
