import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, defaultProps } from 'recompose'
import { select } from '@rematch/select'
import Select from '../Select/index'

export const ExpenseTypeSelect = props => {
  const { options } = props
  return (
    <Select
      options={options}
      valueField="id"
      labelField="displayName"
      {...props}
    />
  )
}

export default compose(
  connect(
    state => ({
      options: select.expenseTypes.getAll(state),
    }),
    dispatch => ({
      find: dispatch.expenseTypes.find,
    }),
  ),
  defaultProps({
    query: {
      filter: { order: 'name ASC', skipDeleted: true },
    },
  }),
  withHandlers({
    fetchData: props => () => {
      return props.find(props.query)
    },
  }),
  lifecycle({
    componentDidMount() {
      return this.props.fetchData()
    },
  }),
)(ExpenseTypeSelect)
