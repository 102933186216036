import React from 'react'
import { Icon, Button } from 'antd'
import { compose, pure, withPropsOnChange } from 'recompose'
import { select } from '@rematch/select'
import { connect } from 'react-redux'
import style from './style'

export const VersionNotification = props => {
  const { isUpdateMessageVisible, setUpdateMessageVisibility, updateUI } = props

  if (!isUpdateMessageVisible) return null

  return (
    <div className={style}>
      <div className="version-notification">
        <div className="version-notification__iconText">
          <div className="version-notification__iconText__icon">
            <Icon type="reload" />
          </div>
          <p>
            E' stata rilasciata una nuova versione del gestionale. Perfavore clicca 'aggiorna' per
            utilizzare la versione aggiornata
          </p>
        </div>
        <div className="version-notification__actions">
          <Button type="primary" onClick={updateUI}>
            Aggiorna
          </Button>
          <Button
            className="version-notification__actions__close"
            icon="close"
            onClick={() => setUpdateMessageVisibility(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      isUpdateMessageVisible: select.ui.isUpdateMessageVisible(state),
    }),
    dispatch => ({
      setUpdateMessageVisibility: dispatch.ui.setUpdateMessageVisibility,
      updateUI: dispatch.ui.updateUI,
    }),
  ),
)(VersionNotification)
