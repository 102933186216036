import React from 'react'
import { Input } from 'antd'
import { compose, withHandlers, pure } from 'recompose'
import { css } from 'emotion'
import NumberFormat from 'react-number-format'
import withReduxFormProps from '../withReduxFormProps'

const className = css({
  width: '100%',
  '& .ant-input-number-handler-wrap': {
    display: 'none',
  },
})

const PercentageInput = props => {
  // todo: sanitize methods
  const {
    handleChange,
    checkIfAllowed,
    handleKeyPress,
    onChange,
    onBlur,
    onDragStart,
    onDrop,
    onFocus,
    ...rest
  } = props
  return (
    <NumberFormat
      className={className}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      customInput={Input}
      onValueChange={handleChange}
      suffix={' %'}
      isAllowed={checkIfAllowed}
      autoComplete="off"
      onKeyPress={handleKeyPress}
      {...rest}
    />
  )
}

export default compose(
  pure,
  withReduxFormProps,
  withHandlers({
    handleChange: props => ({ floatValue }, e) => {
      e.preventDefault()
      e.stopPropagation()
      props.onChange(floatValue)
    },
    checkIfAllowed: props => ({ floatValue, formattedValue }) => {
      return formattedValue === '' || (!isNaN(floatValue) && floatValue <= 100)
    },
  }),
  withHandlers({
    handleKeyPress: props => event => {
      if (event.key === '.' && event.target && event.target.value) {
        event.target.value += ','
        event.preventDefault()
      }
    },
  }),
)(PercentageInput)
