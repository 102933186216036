import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Button } from 'antd'
import Modal from '../../modals/Modal/index'
import SmartTable from '../../tables/SmartTable/index'

const initialQuery = {
  filter: {
    order: 'lastname ASC',
  },
}

const columns = [
  {
    displayName: 'Nome',
    field: 'firstname',
  },
  {
    displayName: 'Cognome',
    field: 'lastname',
  },
  {
    displayName: '',
    render: function (ignore, record) {
      const { closeModal = () => {} } = this.props
      return (
        <Button size="small" onClick={() => closeModal(record)}>Seleziona</Button>
      )
    },
  },
]

const filtersConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['firstname', 'lastname'],
  },
]

export const ChooseAgentModal = (props) => {
  const { visible, closeModal, clients, count, findClients, countClients } = props

  return (
    <Modal
      title="Seleziona cliente"
      onCancel={() => closeModal()}
      visible={visible}>
      <SmartTable
        closeModal={closeModal}
        initialQuery={initialQuery}
        filtersConfig={filtersConfig}
        data={clients}
        columns={columns}
        count={count}
        countMethod={countClients}
        findMethod={findClients}></SmartTable>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      clients: state.clients.clients,
      count: state.clients.count,
    }),
    dispatch => ({
      findClients: dispatch.clients.find,
      countClients: dispatch.clients.count,
    }),
  ),
)(ChooseAgentModal)

