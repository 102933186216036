import { push } from 'react-router-redux'
import { dispatch } from '@rematch/core'
import _ from 'lodash'
import httpProvider from 'src/lib/httpProviderInstance'

export const login = async function(payload) {
  await this.LOGIN_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest('POST', `/agents/doLogin`, {
      data: payload,
    })
    await this.LOGIN_FULFILLED(data)

    return data
  } catch (err) {
    await this.LOGIN_FAILED(err)
    throw err
  }
}

export const logout = async pyld => {
  const payload = _.merge({}, { withRedirect: true, logoutCall: true }, pyld)
  const { withRedirect, logoutCall } = payload
  await dispatch.agents.LOGOUT_REQUESTED(payload)
  try {
    if (logoutCall) {
      const { data } = await httpProvider.makeRequest('POST', `/agents/logout`)
      await dispatch.agents.LOGOUT_FULFILLED(data)
      return data
    }
  } catch (err) {
    await dispatch.agents.LOGOUT_FAILED(err)
    throw err
  } finally {
    await dispatch({
      type: 'CLEAR_STATE_AT_PATH',
      payload: { path: 'agents' },
    })
    await dispatch({
      type: 'CLEAR_STATE_AT_PATH',
      payload: { path: 'accounts' },
    })
    process.nextTick(() => {
      if (window.localStorage) {
        window.localStorage.removeItem('persist:br2')
      }
    })
    if (withRedirect) dispatch(push('/login'))
  }
}

export const fetchMe = async function(payload) {
  await this.GET_ME_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest('GET', '/agents/me', {
      filter: {
        include: ['roles'],
      },
    })
    await this.GET_ME_FULFILLED(data)

    return data
  } catch (err) {
    await this.GET_ME_FAILED(err)
    throw err
  }
}

export const sendForgotPasswordEmail = async payload => {
  await dispatch.agents.SEND_FORGOT_PASSWORD_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest('POST', `/agents/customReset`, {
      data: payload,
    })
    return await dispatch.agents.SEND_FORGOT_PASSWORD_FULFILLED(data)
  } catch (err) {
    await dispatch.agents.SEND_FORGOT_PASSWORD_FAILED(err)
    throw err
  }
}

export const changePassword = async payload => {
  const { data, token } = payload
  await dispatch.agents.CHANGE_PASSWORD_REQUESTED(payload)
  try {
    const { data: responseData } = await httpProvider.makeRequest(
      'POST',
      `/agents/customChangePassword`,
      { data },
      { Authorization: token },
    )
    return await dispatch.agents.CHANGE_PASSWORD_FULFILLED(responseData)
  } catch (err) {
    await dispatch.agents.CHANGE_PASSWORD_FAILED(err)
    throw err
  }
}

export const customCreate = async payload => {
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'POST',
      path: `/agents/create`,
      data: payload,
    })
    return data
  } catch (err) {
    throw err
  }
}

export const fetchValidAgentDocumentTypes = async function(payload) {
  const { id, params } = payload
  await this.FETCH_VALID_AGENT_DOCUMENT_TYPES_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'GET',
      path: `/agents/${id}/documentTypes`,
      params,
    })
    await this.FETCH_VALID_AGENT_DOCUMENT_TYPES_FULFILLED(data)

    return data
  } catch (err) {
    await this.FETCH_VALID_AGENT_DOCUMENT_TYPES_FAILED(err)
    throw err
  }
}
