import { css } from 'emotion'

export default css`
justify-content: center;
align-items: center;
background: url(${`${process.env.PUBLIC_URL}/images/bg.svg`}) !important;
width: 100%;
min-height: 100%;
background-repeat: no-repeat;
background-position: center;
background-size: 100%;
.LoginPage-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  padding: 15px;
  .LoginPage-logo {
    max-width: 300px;
    max-height: 200px;
    img {
      height: 100%;
      width: 100%;
    }
    margin-bottom: 10px;
  }
}
form.LoginPage-form {
  width: 100%;
}

input {
  height: 40px;
}
.LoginPage-submit {
  width: 100%;
  margin-bottom: 20px;
}
`
