import React, { Fragment } from 'react'
import { compose, pure } from 'recompose'
import GroupedDocumentsTable from './GroupedDocumentsTable'
import style from './GroupedDocumentsList.style'

export const GroupedDocumentsList = props => {
  const {
    title,
    showActions,
    groupedDocuments = {},
    onDocumentViewBtnClick,
    onAddDocumentBtnClick,
    onDeleteDocumentBtnClick,
    CheckInput,
    readOnly,
    isAdmin,
    areMandatoryVisible = true,
    areNotMandatoryVisible = true,
  } = props

  return (
    <section className={style}>
      {areMandatoryVisible && (
        <Fragment>
          <h2>Documenti Obbligatori</h2>
          <GroupedDocumentsTable
            showVerifications={true}
            documentsGroups={groupedDocuments.mandatory}
            onDocumentViewBtnClick={onDocumentViewBtnClick}
            onAddDocumentBtnClick={onAddDocumentBtnClick}
            onDeleteDocumentBtnClick={onDeleteDocumentBtnClick}
            CheckInput={CheckInput}
            readOnly={readOnly}
            isAdmin={isAdmin}
          />
          <div style={{ marginBottom: '40px' }} />
        </Fragment>
      )}

      {areNotMandatoryVisible && (
        <Fragment>
          <h2>Altri Documenti</h2>
          <GroupedDocumentsTable
            documentsGroups={groupedDocuments.notMandatory}
            onDocumentViewBtnClick={onDocumentViewBtnClick}
            onAddDocumentBtnClick={onAddDocumentBtnClick}
            onDeleteDocumentBtnClick={onDeleteDocumentBtnClick}
            readOnly={readOnly}
            isAdmin={isAdmin}
          />
        </Fragment>
      )}
    </section>
  )
}

export default compose(pure)(GroupedDocumentsList)
