import { useCallback, useEffect, useState } from 'react'
import { dispatch } from '@rematch/core'
import { select } from '@rematch/select'
import { store } from '../../store'
import { clearStateAtPath } from '../../store/actions'

const useListPreferences = ({ listName, menuPath }) => {
  const [localPreferences, setLocalPreferences] = useState(
    select.preferences.getAll(store.getState()),
  )
  useEffect(
    () => {
      const unsubscribe = store.subscribe(() => {
        setLocalPreferences(select.preferences.getAll(store.getState()))
      })

      return () => unsubscribe()
    },
    [listName],
  )

  useEffect(
    () => {
      // setLocalPreferences(preferences)
      dispatch.menu.addFilteredListToMenu({
        listName,
        data: localPreferences || [],
        path: menuPath,
      })
    },
    [localPreferences, listName],
  )
  const fetchPreferences = useCallback(() => {
    dispatch.preferences.find({
      filter: { where: { type: listName } },
    })
  }, [])

  useEffect(
    () => {
      fetchPreferences()

      return () => {
        store.dispatch(clearStateAtPath('preferences.preferences'))
      }
    },
    [listName],
  )

  return { preferences: localPreferences, fetchPreferences }
}

export { useListPreferences }
