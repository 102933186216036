import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import Promise from 'bluebird'
import debounceHandler from '@hocs/debounce-handler'
import { select } from '@rematch/select'
import { parseOneAddress } from 'email-addresses'
import Select from '../../form/Select'
import ErrorBoundary from 'src/components/core/ErrorBoundary'

const RecipientsSelect = props => {
  const { options = [], handleChange, values, handleSearch } = props

  return (
    <Fragment>
      <ErrorBoundary>
        <Select
          {...props}
          showSearch
          options={options}
          value={values}
          mode="tags"
          allowClear={true}
          onSearch={handleSearch}
          onChange={handleChange}
        />
      </ErrorBoundary>
    </Fragment>
  )
}

export default compose(
  connect(
    state => ({
      agents: select.agents.getAll(state),
      clients: select.clients.getAll(state),
    }),
    dispatch => ({
      findAgents: dispatch.agents.find,
      findClients: dispatch.clients.find,
    }),
  ),
  withHandlers({
    fetchData: props => filter => {
      const { findAgents, findClients } = props
      return Promise.all([
        findAgents(
          _.merge(
            {
              filter: {
                order: 'lastname ASC',
                limit: 50,
              },
            },
            { filter },
          ),
        ),
        findClients(
          _.merge(
            {
              filter: {
                order: 'lastname ASC',
                limit: 50,
              },
            },
            { filter },
          ),
        ),
      ])
    },
  }),
  withHandlers({
    handleSearch: props => value => {
      const { fetchData } = props
      if (!value) return
      const filter = {
        where: {
          or: [
            {
              firstname: {
                like: value,
              },
            },
            {
              lastname: {
                like: value,
              },
            },
            {
              email: {
                like: value,
              },
            },
          ],
        },
      }

      return fetchData(filter)
    },
    handleChange: props => values => {
      props.input.onChange(
        _.map(values, value => {
          if (value) {
            if (value.indexOf('|') > -1) {
              const { agents, clients } = props
              const [field, id] = value.split('|')
              let source
              if (field === 'agentId') {
                source = agents
              }
              if (field === 'clientId') {
                source = clients
              }
              const record = source.find(({ id: elId }) => elId == id)
              return { [field]: record.id, email: record.email, record }
            }

            const email = (parseOneAddress(value) || {}).address

            return { email }
          }
        }),
      )
    },
  }),
  debounceHandler('handleSearch', 300),
  withPropsOnChange(['agents', 'clients'], ({ agents, clients }) => {
    const agentsOptions = _.filter(agents, a => a.email).map(a => ({
      value: `agentId|${a.id}`,
      label: `${a.firstname} ${a.lastname} <${a.email}>`,
    }))
    const clientsOptions = _.filter(clients, a => a.email).map(a => ({
      value: `clientId|${a.id}`,
      label: `${a.firstname} ${a.lastname} <${a.email}>`,
    }))
    return {
      options: [...agentsOptions, ...clientsOptions],
    }
  }),
  withPropsOnChange(['value'], ({ value }) => {
    return {
      values: _.compact(
        _.map(value, v => {
          if (v.record) {
            return `${v.record.firstname} ${v.record.lastname} <${v.email}>`
          }

          return v.email
        }),
      ),
    }
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchData()
    },
  }),
)(RecipientsSelect)
