import React, { Fragment } from 'react'
import {
  compose,
  pure,
  withPropsOnChange,
  lifecycle,
  defaultProps,
  withHandlers,
  getContext,
} from 'recompose'
import { Row, Col, Button } from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Checkbox } from 'antd'
import { css, cx } from 'emotion'
import styled from 'react-emotion'
import { pushModalWindow, popModalWindow } from 'redux-promising-modals'
import { OPEN_CHOOSE_INSURANCE_QUOTE_MODAL } from 'src/components/modals/ChooseInsuranceQuoteModal'
import { OPEN_CHOOSE_INSURANCE_MODAL } from 'src/components/modals/ChooseInsuranceModal'
import CurrencyInput from '../../form/CurrencyInput/index'
import FormItem from '../../form/FormItem/index'
import FormulaInput from '../../form/FormulaInput'
import ExpenseTypeSelect from '../../form/ExpenseTypeSelect/index'
import withFormProps from '../../form/withReduxFormProps'
import withConnectedForm from '../../form/withConnectedForm'
import PropTypes from 'prop-types'

const checkboxStyle = css({
  margin: '0 5px 0 0 !important;',
  display: 'inline-block',
  verticalAlign: 'middle',
})

const ExpensesListUl = styled('ul')`
  ${({ layout }) =>
    layout === 'stack' &&
    `& > li {
      border-bottom: 1px solid #d9d9d9;
      padding: 15px 0;
      &:last-child {
        border-bottom: none;
      }
   }`};
`

const ExpenseAffectTaeg = props => (
  <Checkbox {...props}>Incide sul TAEG/ISC</Checkbox>
)

const ExpenseAffectAmount = props => (
  <Checkbox {...props}>Incide sull'importo</Checkbox>
)

const renderFields = props => {
  const {
    fields,
    addLabel,
    errors,
    type,
    expenses,
    layout,
    valuesType,
    formDisabled,
    readOnly,
    handleAddExpenseBtnClick,
    handleExpenseTypeIdChange,
  } = props
  const isStackLayout = layout === 'stack'
  const isGridLayout = layout === 'grid'

  return (
    <Fragment>
      {!formDisabled &&
        !readOnly && (
          <Button
            onClick={handleAddExpenseBtnClick(fields)}
            icon="plus"
            size="small"
            style={{ marginBottom: '10px' }}
          >
            {addLabel || 'Aggiungi'}
          </Button>
        )}
      <ExpensesListUl className="u-listStyleNone" {...props}>
        {fields.map((member, index, fields) => {
          const expense = _.get(expenses, `${index}`) || {}
          if (expense.type !== type) return null
          const ExpenseLabel =
            expense.type === 'insurance' ? (
              <div style={{ lineHeight: 1.2 }}>
                {_.get(expense, 'insurance.name')}
              </div>
            ) : (
              <FormItem
                name={`${member}.expenseTypeId`}
                placeholder="Spesa"
                showErrorLabel={false}
                style={{ 'margin-bottom': '5px' }}
                withReduxFormProps={true}
                component={ExpenseTypeSelect}
                onChange={handleExpenseTypeIdChange}
                errors={errors}
              />
            )
          return (
            <li>
              <Row type="flex" gutter={10}>
                {!formDisabled &&
                  !readOnly && (
                    <Col xs={3} lg={2}>
                      <Button
                        onClick={() => fields.remove(index)}
                        size="small"
                        shape="circle"
                        icon="delete"
                      />
                    </Col>
                  )}
                <Col xs={21} lg={20}>
                  <Row type="flex" align="middle" gutter={10}>
                    <Col xs={24} sm={24} md={isGridLayout && 8}>
                      {ExpenseLabel}
                    </Col>
                    <Col xs={24} sm={24} md={isGridLayout && 4}>
                      {valuesType === 'value' && (
                        <FormItem
                          style={{ 'margin-bottom': '5px' }}
                          key={index}
                          name={`${member}.value`}
                          placeholder="Importo"
                          showErrorLabel={false}
                          component={CurrencyInput}
                          withReduxFormProps={true}
                          errors={errors}
                        />
                      )}

                      {valuesType === 'formula' &&
                        expense.type !== 'insurance' && (
                          <FormItem
                            style={{ 'margin-bottom': '5px' }}
                            key={index}
                            name={`${member}.formula`}
                            placeholder="Formula Importo"
                            showErrorLabel={false}
                            component={FormulaInput}
                            withReduxFormProps={true}
                            errors={errors}
                          />
                        )}
                    </Col>
                    <Col xs={24} sm={24} md={isGridLayout && 12}>
                      <FormItem
                        className={checkboxStyle}
                        key={index}
                        name={`${member}.affectTaeg`}
                        showErrorLabel={false}
                        component={ExpenseAffectTaeg}
                        withReduxFormProps={true}
                        errors={errors}
                      />
                      <FormItem
                        className={checkboxStyle}
                        key={index}
                        name={`${member}.affectAmount`}
                        showErrorLabel={false}
                        component={ExpenseAffectAmount}
                        withReduxFormProps={true}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                  {valuesType === 'formula' &&
                    expense.type !== 'insurance' && (
                      <Row>
                        <Col xs={24} sm={isGridLayout && 12}>
                          <FormItem
                            style={{ 'margin-bottom': '5px' }}
                            key={index}
                            label="Valore min"
                            name={`${member}.valueMin`}
                            showErrorLabel={false}
                            component={FormulaInput}
                            withReduxFormProps={true}
                            errors={errors}
                          />
                        </Col>
                        <Col xs={24} sm={isGridLayout && 12}>
                          <FormItem
                            style={{ 'margin-bottom': '5px' }}
                            key={index}
                            label="Valore max"
                            name={`${member}.valueMax`}
                            showErrorLabel={false}
                            component={FormulaInput}
                            withReduxFormProps={true}
                            errors={errors}
                          />
                        </Col>
                      </Row>
                    )}
                </Col>
              </Row>
            </li>
          )
        })}
      </ExpensesListUl>
      {(!expenses || !expenses.length) && <p>Nessuna spesa.</p>}
    </Fragment>
  )
}

export const ExpensesRow = ({
  form,
  amount,
  errors,
  expenses,
  type,
  ...rest
}) => {
  return (
    <FormItem
      name={`expenses`}
      isArray={true}
      withReduxFormProps={true}
      component={renderFields}
      errors={errors}
      type={type}
      expenses={expenses}
      {...rest}
    />
  )
}

export default compose(
  pure,
  connect(null, {
    pushModalWindow,
  }),
  withPropsOnChange(['form'], ({ form }) => {
    return {
      form:
        form && form !== 'expensesRow' ? form : `expensesRow-${_.uniqueId()}`,
    }
  }),
  getContext({
    formDisabled: PropTypes.bool,
    readOnly: PropTypes.bool,
  }),
  withConnectedForm('expensesRow', { destroyOnUnmount: true }),
  defaultProps({
    onValuesChange: () => {},
    valuesType: 'value',
    layout: 'grid',
  }),
  withHandlers({
    handleAddExpenseBtnClick: props => fields => () => {
      const { type } = props
      const baseProps = {
        type,
        affectTaeg: true,
        affectAmount: true,
      }
      if (type === 'generic') return fields.push({ ...baseProps })

      const { pushModalWindow, valuesType, amount, duration, estate } = props

      if (valuesType === 'value') {
        return pushModalWindow(OPEN_CHOOSE_INSURANCE_QUOTE_MODAL, {
          amount,
          duration,
          estate,
        }).then(insurancePreventive => {
          if (insurancePreventive) {
            const expense = {
              ...baseProps,
              insuranceId: insurancePreventive.product.id,
              description: insurancePreventive.product.name,
              value: insurancePreventive.insurance.grossPremium,
              insurance: insurancePreventive.product,
            }

            fields.push(expense)
          }
        })
      }

      if (valuesType === 'formula')
        return pushModalWindow(OPEN_CHOOSE_INSURANCE_MODAL).then(product => {
          if (product) {
            const expense = {
              ...baseProps,
              insuranceId: product.id,
              description: product.name,
              insurance: product,
            }

            fields.push(expense)
          }
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.initialize({ expenses: this.props.expenses })
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.expenses && nextProps.expenses !== this.props.expenses) {
        this.props.initialize({ expenses: nextProps.expenses })
      } else if (
        _.get(nextProps, 'formValues.expenses') !==
        _.get(this, 'props.formValues.expenses')
      ) {
        this.props.onValuesChange(nextProps.formValues.expenses)
      }
    },
  }),
)(ExpensesRow)
