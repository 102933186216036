import _get from 'lodash/get'
import mergeDeepRight from 'ramda/es/mergeDeepRight'

export const GENERATE_TEMPLATE_REQUESTED = (state, payload) =>
  mergeDeepRight(state, {
    generate: {
      isSending: true,
    },
  })

export const GENERATE_TEMPLATE_FULFILLED = (state, payload) =>
  mergeDeepRight(state, {
    generate: {
      isSending: false,
      result: payload,
    },
  })

export const GENERATE_TEMPLATE_FAILED = (state, payload) =>
  mergeDeepRight(state, {
    generate: {
      isSending: false,
      isFailed: _get(payload, 'response.data.error'),
    },
  })
