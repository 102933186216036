import React from 'react'
import { compose, withHandlers } from 'recompose'
import { Button, Input } from 'antd'
import { connect } from 'react-redux'
import withConnectedForm from '../../form/withConnectedForm'
import FormItem from '../../form/FormItem'
import Modal from '../../modals/Modal'

export const NewFolderModal = props => {
  const { formError, handleSubmit, visible, handleCancel } = props

  return (
    <Modal
      title="Nuova Cartella"
      onCancel={handleCancel}
      onOk={handleSubmit}
      visible={visible}
    >
      <form onSubmit={handleSubmit}>
        <FormItem
          label="Nome cartella"
          name="name"
          component={Input}
          withReduxFormProps={true}
          errors={formError}
        />
      </form>
    </Modal>
  )
}

export default compose(
  connect(
    (state, props) => ({ initialValues: props.initialValues }),
    dispatch => ({
      create: dispatch.paths.create,
    }),
  ),
  withConnectedForm('newFolderModal'),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const { dispatchSubmit, create, formValues, onOk, change } = props
      const newPath = await dispatchSubmit(create(formValues))
      await change('name', null)
      return onOk(e, newPath)
    },
    handleCancel: props => () => [props.change('name', null), props.onCancel()],
  }),
)(NewFolderModal)
