import React from 'react'
import universal from 'react-universal-component'
import { Spin } from 'antd'
import { css } from 'emotion'

const style = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Loading = () => (
  <section className={style}>
    <Spin size="large" />
  </section>
)

const asyncImport = (fn, options = {}) => {
  const component = universal(fn, {
    loading: Loading,
    ...options,
  })

  if (options.preload) {
    setTimeout(() => component.preload(), options.preloadDelay || 1000)
  }
  return component
}

export default asyncImport
