import React, { Fragment } from 'react'
import { compose, lifecycle, withState, withProps, pure } from 'recompose'
import VisibilitySensor from 'react-visibility-sensor'
import { withSafeInterval } from '@hocs/safe-timers'
import { Spin } from 'antd'

export const LazyLoad = props => {
  const {
    container,
    containerSelector,
    children,
    setIsVisible,
    isVisible,
    offset,
  } = props

  if (containerSelector && !container) return null
  return (
    <Fragment>
      <VisibilitySensor
        scrollCheck
        scrollThrottle={100}
        containment={container}
        active={!isVisible}
        onChange={isVisible => setIsVisible(isVisible)}
      />
      {isVisible && children}
      {!isVisible && <Spin size="large" />}
    </Fragment>
  )
}

export default compose(
  pure,
  withSafeInterval,
  withState('container', 'setContainer', null),
  withState('isVisible', 'setIsVisible', false),
  withProps({
    offset: { top: 100, bottom: 100 },
  }),
  lifecycle({
    componentDidMount() {
      const interval = this.props.setSafeInterval(() => {
        const container = document.querySelector(this.props.containerSelector)
        if (container) {
          this.props.setContainer(container)
          interval()
        }
      }, 200)
    },
  }),
  pure,
)(LazyLoad)
