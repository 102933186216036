import httpProvider from 'src/lib/httpProviderInstance'

export const fetchPath = async function (payload) {
  await this.FETCH_PATH_REQUESTED(payload)
  try {
    const { context, pathId, ...rest } = payload
    const { data } = await httpProvider.makeRequest({
      method: 'GET',
      path: `/paths/browse/${pathId || ''}`,
      params: {
        context,
        ...rest,
      },
    })
    await this.FETCH_PATH_FULFILLED(data)
    return data
  } catch (err) {
    await this.FETCH_PATH_FAILED(err)
    throw err
  }
}
