import React, { Fragment } from 'react'
import { compose, withHandlers, withPropsOnChange, pure } from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Row, Col } from 'antd'
import { Input, Select, Checkbox, Button, Icon } from 'antd'
import { css } from 'emotion'
import withFormProps from 'src/components/form/withReduxFormProps'
import SubmitButton from 'src/components/form/SubmitButton'
import ResetButton from 'src/components/form/ResetButton'
import FormItem from 'src/components/form/FormItem'
import OverlayComponent from 'src/components/Overlay/Overlay'
import { openChooseAgentModal } from 'src/components/modals/ChooseAgentModal'
import { openChooseProductModal } from 'src/components/modals/ChooseProductModal'
import { openChooseInsuranceQuoteModal } from 'src/components/modals/ChooseInsuranceQuoteModal'
import { default as ThePaperSelect } from 'src/components/form/PaperSelect'
import DatePicker from 'src/components/form/BrDatePicker'
import NameInput from 'src/components/form/NameInput'
import RateTypeSelect from 'src/components/form/RateTypeSelect'
import PurposesSelect from 'src/components/form/PurposesSelect'
import ChannelSelect from 'src/components/form/ChannelSelect'
import ExpensesRow from '../../../../../components/expenses/ExpensesRows'
import OfficeSelect from '../../../../../components/form/OfficeSelect'
import FinancialInstitutionSelect from '../../../../../components/form/FinancialInstitutionSelect'
import Details from './requestDetailsTabForm/Details'
import Insurance from './requestDetailsTabForm/Insurance'
import withWatcher from '../../../../../components/withWatcher'

const Option = Select.Option
const InputGroup = Input.Group

const agentSelectionClass = css`
  display: flex;
  & .Overlay {
    position: relative;
    flex-grow: 1;
  }
`

const AgentSelection = props => {
  const { children, onRemoveBtnClick, onClick } = props
  return (
    <div className={agentSelectionClass}>
      <div className="Overlay" onClick={onClick}>
        <OverlayComponent {...props} style={{ width: '100%', display: 'inline-block' }}>
          <Select value="0">
            <Option key="0">{children}</Option>
          </Select>
        </OverlayComponent>
      </div>
      <Button icon="delete" onClick={onRemoveBtnClick} />
    </div>
  )
}

const ProductSelection = props => {
  const { product, onChange } = props

  let label
  let value
  if (product) {
    const instituteName = _.get(product, 'financialInstitution.name')
    label = `${product.internalId} - ${instituteName} - ${product.name}`
  }

  const handleChange = value => {
    return onChange(value)
  }

  return (
    <OverlayComponent style={{ width: '100%', display: 'inline-block' }} {...props}>
      <Select value="0" allowClear={true} onChange={handleChange}>
        <Option key="0">{label}</Option>
      </Select>
    </OverlayComponent>
  )
}

const ClientEmailInput = props => (
  <InputGroup compact>
    <Input {...props} />
    <Button icon="email" onClick={props.handleNewEmailBtnClick} />
  </InputGroup>
)

const annualInstalmentsOptions = [
  { value: 12, label: 'Mensile' },
  { value: 6, label: 'Bimestrale' },
  { value: 2, label: 'Semestrale' },
  { value: 1, label: 'Annuale' },
]
const AnnualInstalments = withFormProps(
  Select,
  {
    children: annualInstalmentsOptions.map(({ value, label }) => (
      <Option value={value}>{label}</Option>
    )),
  },
  input => {
    const { value, ...rest } = input
    return {
      ...rest,
      defaultValue: value,
    }
  },
)

const PaperSelect = withFormProps(ThePaperSelect)

export const RequestDetailsTabForm = props => {
  const {
    paper,
    currentAgent,
    groupManager,
    generalAgent,
    agentRole,
    groupManagerRole,
    generalAgentRole,
    handleSubmit,
    handleChooseAgent,
    handleChooseInitialAgent,
    handleChooseProduct,
    handleExpensesChange,
    handleRemoveAgentBtnClick,
    submitting,
    handleNewEmailBtnClick,
    formValues,
    formError,
    readOnly,
    productTypeName,
    isAdmin,
    features,
    productType,
  } = props

  if (!paper) return null

  return (
    <section>
      <form className="form" onSubmit={handleSubmit}>
        <h3>Cliente</h3>
        <Row className="PapersView-main-section" type="flex" gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <FormItem
              label="Nome"
              name="client.firstname"
              withReduxFormProps={true}
              errors={formError}
              component={NameInput}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <FormItem
              label="Cognome"
              name="client.lastname"
              withReduxFormProps={true}
              errors={formError}
              component={NameInput}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <FormItem
              label="Email"
              name="client.email"
              withReduxFormProps={true}
              errors={formError}
              component={Input}
              addonAfter={<Icon type="mail" onClick={handleNewEmailBtnClick} />}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <FormItem
              label="Telefono"
              withReduxFormProps={true}
              errors={formError}
              name="client.phone"
              component={Input}
            />
          </Col>
        </Row>

        {/*======= Agenti*/}
        <h3>Agenti</h3>
        <Row className="PapersView-main-section" type="flex" gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <label>Agente Attuale</label>
            <AgentSelection
              onClick={() => handleChooseAgent(agentRole.id)}
              onRemoveBtnClick={() => handleRemoveAgentBtnClick(agentRole)}
            >
              {currentAgent.firstname} {currentAgent.lastname}
            </AgentSelection>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <label>Group Manager</label>
            <AgentSelection
              onClick={() => handleChooseAgent(groupManagerRole.id)}
              onRemoveBtnClick={() => handleRemoveAgentBtnClick(groupManagerRole)}
            >
              {groupManager.firstname} {groupManager.lastname}
            </AgentSelection>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <label>Agente Generale</label>
            <AgentSelection
              onClick={() => handleChooseAgent(generalAgentRole.id)}
              onRemoveBtnClick={() => handleRemoveAgentBtnClick(generalAgentRole)}
            >
              {generalAgent.firstname} {generalAgent.lastname}
            </AgentSelection>
          </Col>
          <Col xs={24} sm={12} md={6} onClick={() => handleChooseInitialAgent()}>
            <label>Agente Iniziale</label>
            <AgentSelection>
              {_.get(paper, 'initialAgent.firstname')} {_.get(paper, 'initialAgent.lastname')}
            </AgentSelection>
          </Col>
        </Row>

        {/*======= Vari*/}
        <h3>Vari</h3>
        <section className="PapersView-main-section">
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Data consegna banca"
                name="bankDeliveryDate"
                withReduxFormProps={true}
                errors={formError}
                component={DatePicker}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Numero pratica Istituto"
                name="institutePaperId"
                withReduxFormProps={true}
                errors={formError}
                component={Input}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Canale"
                name="channelId"
                withReduxFormProps={true}
                errors={formError}
                component={ChannelSelect}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Rete"
                name="officeId"
                withReduxFormProps={true}
                errors={formError}
                component={OfficeSelect}
              />
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Pratica collegata"
                name="linkedPaperId"
                component={PaperSelect}
                errors={formError}
              />
            </Col>
            {isAdmin && [
              <Col xs={12} sm={12} md={6}>
                <FormItem
                  label="Check telefonico"
                  name="phoneCheck"
                  withReduxFormProps={true}
                  errors={formError}
                  component={Checkbox}
                />
              </Col>,
              <Col xs={12} sm={12} md={6}>
                <FormItem
                  label="Rientro documenti"
                  name="documentsReturn"
                  withReduxFormProps={true}
                  errors={formError}
                  component={Checkbox}
                />
              </Col>,
            ]}
          </Row>
        </section>

        {/*======= Details*/}
        {features.hasQuoteDetails && <Details {...props} />}

        {/*======= Insurance*/}
        {productTypeName === 'insurance' && <Insurance {...props} />}

        {/*======= Product*/}
        <section className="PapersView-main-section">
          <h3>Prodotto</h3>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={12} onClick={handleChooseProduct}>
              <FormItem
                label="Prodotto"
                name="productId"
                component={props => (
                  <ProductSelection
                    product={_.get(formValues, 'product')}
                    onChange={props.onChange}
                  />
                )}
              />
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                label="Istituto Finanziario"
                name="financialInstitutionId"
                component={FinancialInstitutionSelect}
                withReduxFormProps={true}
                errors={formError}
                disabled={!!_.get(formValues, 'productId')}
              />
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Finalitá"
                name="purposeId"
                withReduxFormProps={true}
                errors={formError}
                component={PurposesSelect}
                initialQuery={{
                  filter: {
                    order: 'name ASC',
                    skipDeleted: true,
                    where: { productTypeId: productType.id },
                  },
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Frequenza rate"
                name="annualInstalments"
                component={AnnualInstalments}
                errors={formError}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <FormItem
                label="Tasso"
                name="rateType"
                component={RateTypeSelect}
                errors={formError}
              />
            </Col>
          </Row>
        </section>

        <section className="PapersView-main-section">
          <h3>Spese</h3>
          <ExpensesRow
            expenses={paper.expenses}
            formError={formError}
            onValuesChange={handleExpensesChange}
            type="generic"
          />
        </section>

        {features.hasInsurances && (
          <section className="PapersView-main-section">
            <h3>Assicurazioni</h3>
            <ExpensesRow
              expenses={paper.expenses}
              formError={formError}
              onValuesChange={handleExpensesChange}
              type="insurance"
              amount={paper.amount}
              duration={paper.duration}
              estate={paper.estate}
            />
          </section>
        )}
        {!readOnly && (
          <section className="FormActions">
            <SubmitButton />
            <ResetButton />
          </section>
        )}
      </form>
    </section>
  )
}

export default compose(
  pure,
  connect(
    null,
    {
      openChooseAgentModal,
      openChooseProductModal,
      openChooseInsuranceQuoteModal,
    },
  ),
  connect(
    null,
    dispatch => ({
      update: dispatch.papers.update,
      newComposeEmail: dispatch.emails.newComposeEmail,
      createLogWithDiff: dispatch.actionLogs.createWithDiff,
    }),
  ),
  withPropsOnChange(['paper', 'roles'], ({ paper = {}, roles }) => ({
    initialAgent: paper.initialAgent,
    agentRole: _.find(roles, { name: 'agent' }) || {},
    groupManagerRole: _.find(roles, { name: 'groupManager' }) || {},
    generalAgentRole: _.find(roles, { name: 'generalAgent' }) || {},
  })),
  withPropsOnChange(['paper'], props => {
    return {
      productTypeName: _.get(props.paper, 'productType.name'),
      productType: _.get(props.paper, 'productType', {}),
    }
  }),
  withPropsOnChange(
    ['agentRole', 'groupManagerRole', 'generalAgentRole', 'paper'],
    ({ agentRole, groupManagerRole, generalAgentRole, paper }) => {
      const currentAgent =
        (_.find((paper || {}).agents, { roleId: agentRole.id }) || {} || {}).agent || {}
      const groupManager =
        (_.find((paper || {}).agents, { roleId: groupManagerRole.id }) || {} || {}).agent || {}
      const generalAgent =
        (_.find((paper || {}).agents, { roleId: generalAgentRole.id }) || {} || {}).agent || {}

      return {
        agentRole,
        groupManagerRole,
        generalAgentRole,
        currentAgent,
        groupManager,
        generalAgent,
      }
    },
  ),
  withWatcher('formValues.product', { immediate: true }, ({ formValues = {}, change }) => {
    if (!formValues.product) return
    const financialInstitutionId = _.get(formValues, 'product.financialInstitutionId')
    return change('financialInstitutionId', financialInstitutionId)
  }),
  withWatcher('formValues.productId', { immediate: true }, ({ formValues = {}, change }) => {
    if (!formValues.productId) return change('product', null)
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const {
        formValues,
        pristineValues,
        dispatchSubmit,
        update,
        paperId,
        createLogWithDiff,
      } = props
      await dispatchSubmit(update({ id: paperId, ...formValues }))

      return createLogWithDiff({
        data: {
          context: 'paper',
          type: 'requestDetailsTabForm',
          paperId,
        },
        previous: pristineValues,
        current: formValues,
      })
    },
    handleChooseAgent: props => roleId => {
      const { openChooseAgentModal, change, paper } = props
      const paperAgents = paper.agents || []
      let agentArrayIndex = _.findIndex(paperAgents, { roleId })
      agentArrayIndex = agentArrayIndex !== -1 ? agentArrayIndex : paperAgents.length

      return openChooseAgentModal({
        initialQuery: { filter: { include: ['roles'] } },
      }).then(agent => {
        if (agent) {
          change(`agents[${agentArrayIndex}].agentId`, agent.id)
          change(`agents[${agentArrayIndex}].roleId`, roleId)
          change(`agents[${agentArrayIndex}].agent`, agent)
        }
      })
    },
    handleChooseInitialAgent: props => () => {
      const { openChooseAgentModal, change } = props

      return openChooseAgentModal().then(agent => {
        if (agent) {
          change(`initialAgentId`, agent.id)
          change(`initialAgent`, agent)
        }
      })
    },
    handleChooseProduct: props => () => {
      const { openChooseProductModal, change, paper } = props

      return openChooseProductModal({
        productTypeId: paper.productTypeId,
      }).then(product => {
        if (product) {
          change('productId', product.id)
          change('product', product)
        }
      })
    },
    handleExpensesChange: ({ formValues: { expenses = [] } = {}, change }) => newExpenses => {
      const insuranceExpenses = expenses.filter(e => e.type === 'insurance')
      const mergedExpenses = _.merge([], insuranceExpenses, newExpenses)
      change('expenses', mergedExpenses)
    },
    handleAddNewInsuranceBtnClick: props => () => {
      const { openChooseInsuranceQuoteModal, array, paper } = props
      const { amount, duration, estate = {} } = paper || {}

      return openChooseInsuranceQuoteModal({
        amount,
        duration,
        estate,
      }).then(insurancePreventive => {
        if (insurancePreventive) {
          const expense = {
            type: 'insurance',
            insuranceId: insurancePreventive.product.id,
            description: insurancePreventive.product.name,
            value: _.get(insurancePreventive, 'insurance.grossPremium'),
            insurance: insurancePreventive.product,
            affectTaeg: true,
          }

          array.push('expenses', expense)
        }
      })
    },
    handleRemoveAgentBtnClick: props => role => {
      const { paper, change } = props
      const newPaperAgents = _.filter(
        (paper || {}).agents,
        paperAgent => paperAgent.roleId !== role.id,
      )
      change('agents', newPaperAgents)
    },
    handleNewEmailBtnClick: props => () => {
      const { newComposeEmail, formValues = {}, paper } = props
      const email = _.get(formValues, 'client.email')
      const to = email ? [{ email }] : []
      newComposeEmail({
        to,
        paperId: formValues.id,
        paper,
      })
    },
  }),
)(RequestDetailsTabForm)
