import { Col, InputNumber, Row } from 'antd'
import FormItem from '../../../../../../components/form/FormItem/index'
import CurrencyInput from '../../../../../../components/form/CurrencyInput/index'
import React from 'react'

const Insurance = props => {
  const { formError } = props
  return (
    <section className="PapersView-main-section">
      <h3>Dettagli</h3>
      <Row type="flex" gutter={16}>
        <Col xs={24} sm={6}>
          <FormItem
            label="Premio lordo"
            name="insurance.grossPremium"
            component={CurrencyInput}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
        <Col xs={24} sm={6}>
          <FormItem
            label="Premio netto"
            name="insurance.netPremium"
            component={CurrencyInput}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
        <Col xs={24} sm={6}>
          <FormItem
            label="Importo garantito"
            name="amount"
            component={CurrencyInput}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
        <Col xs={24} sm={6}>
          <FormItem
            label="Durata"
            name="duration"
            component={InputNumber}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
      </Row>
    </section>
  )
}

export default Insurance
