import React from 'react'
import { Icon } from 'antd'
import {
  compose,
  pure,
  withProps,
  defaultProps,
  withPropsOnChange,
  withState,
  withHandlers,
} from 'recompose'
import { css } from 'emotion'

const toggleStyle = css`
  margin-top: 5px;
`

const Reveal = ({
  className,
  text,
  isExpanded,
  expandedSlot,
  toggle,
  handleClick,
  children,
  additionalContent,
}) => {
  const TheIcon = () => <Icon type={isExpanded ? 'up' : 'down'} />
  return (
    <section className={className}>
      {isExpanded && children}
      {expandedSlot ? (
        expandedSlot({ text, isExpanded, toggle, Icon: TheIcon })
      ) : (
        <a className={toggleStyle} onClick={handleClick}>
          <TheIcon /> {text}
        </a>
      )}
      {additionalContent}
    </section>
  )
}

export default compose(
  pure,
  defaultProps({
    expandedText: 'Chiudi',
    collapsedText: 'Apri',
    defaultIsExpanded: true,
  }),
  withState('isExpanded', 'setIsExpanded', ({ defaultIsExpanded }) => defaultIsExpanded),
  withPropsOnChange(['isExpanded'], ({ isExpanded, expandedText, collapsedText }) => ({
    text: isExpanded ? expandedText : collapsedText,
  })),
  withHandlers({ toggle: ({ isExpanded, setIsExpanded }) => () => setIsExpanded(!isExpanded) }),
  withHandlers({
    handleClick: ({ toggle }) => () => toggle(),
  }),
)(Reveal)
