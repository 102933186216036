import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose'
import { Button } from 'antd'
import Modal from '../Modal/index'
import style from './ChooseInsuranceQuoteModal.style'
import QuotesForm from 'src/components/quotes/QuotesForm'
import { getPath } from '../../../store/selectors'

export const ChooseInsuranceQuoteModal = props => {
  const { visible, closeModal, productType, actions, amount, duration, purposeId } = props

  return (
    <Modal
      className={style}
      title="Aggiungi spesa prodotto assicurativo"
      onCancel={() => closeModal()}
      visible={visible}
    >
      {productType && (
        <QuotesForm
          actions={actions}
          productType={productType}
          fetchOnMount={true}
          initialValues={{ amount, duration, purposeId }}
          isQuotesHistoryVisible={false}
        />
      )}
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      productType: getPath(state)('productTypes.chooseInsuranceQuoteModal.productType'),
    }),
    dispatch => ({
      findProductType: dispatch.productTypes.findOne,
    }),
  ),
  withPropsOnChange(['productType'], ({ productType, closeModal }) => {
    return {
      actions: ({ record }) => {
        return (
          <Button
            size="small"
            onClick={() =>
              closeModal({
                ...record,
                expenseTypeId: productType,
              })
            }
          >
            Seleziona
          </Button>
        )
      },
    }
  }),
  withHandlers({
    fetchData: props => () => {
      const { findProductType } = props

      return findProductType(
        {
          filter: { where: { name: 'insurance' } },
        },
        { basePath: 'chooseInsuranceQuoteModal' },
      )
    },
  }),
  lifecycle({
    async componentDidMount() {
      return this.props.fetchData()
    },
  }),
)(ChooseInsuranceQuoteModal)
