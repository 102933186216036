import React from 'react'
import Modal from 'src/components/modals/Modal'
import { BrTabs, BrTabPane } from 'src/components/layout/BrTabs'
import GroupedDocumentsList from './GroupedDocumentsList'

const NewMandatoryDocumentsModal = props => {
  const {
    visible,
    closeModal,
    groupedNewMandatoryDocuments,
    groupedMandatoryDocuments,
  } = props

  return (
    <Modal
      title="Ricalcola Documenti"
      width="900px"
      okText="Salva"
      cancelText="Annulla"
      onOk={() => closeModal(true)}
      onCancel={() => closeModal()}
      visible={visible}
    >
      <BrTabs swipeable={true} defaultActiveKey={1}>
        {/*<BrTabPane tab="Attuali" key={0}>
          <GroupedDocumentsList groupedDocuments={groupedMandatoryDocuments} />
        </BrTabPane>*/}
        <BrTabPane tab="Nuovi" key={1}>
          <GroupedDocumentsList
            groupedDocuments={groupedNewMandatoryDocuments}
          />
        </BrTabPane>
      </BrTabs>
    </Modal>
  )
}

export default NewMandatoryDocumentsModal
