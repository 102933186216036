import _ from 'lodash'
import httpProvider from '../lib/httpProviderInstance'
import { dispatch } from '@rematch/core'

const checkAuth = store => {
  httpProvider.addMiddleware('pre', (args, next) => {
    args.headers = args.headers || {}
    const state = store.getState()

    const token = state.agents.token
    const currentAccountId =
      state.accounts.currentAccountId ||
      _.get(state, 'accountSettings.accountSettings.accountId')

    if (token) {
      args.headers.Authorization = token
    }

    if (currentAccountId && !args.headers['x-br2-accountid']) {
      args.headers['x-br2-accountid'] = currentAccountId
    }
    next()
  })

  httpProvider.addMiddleware('post', ({ args, result, error }, next) => {
    const excludedPaths = ['/agents/login', '/agents/logout']
    const isExcluded = error && excludedPaths.includes(args.path)

    if (!isExcluded && _.get(error, 'response.status') === 401) {
      process.nextTick(() => {
        dispatch.agents.logout({ logoutCall: false })
      })
    }
    next()
  })
}

export default checkAuth
