import httpProvider from '../../../lib/httpProviderInstance'
import { dispatch } from '@rematch/core'
import observeEndpoint from 'src/store/actions/observeEndpoint'

export const sendEmail = async payload => {
  // dispatch.emails.SEND_EMAIL_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest('POST', `/emails/send`, {
      data: payload,
    })
    // dispatch.emails.SEND_EMAIL_FULFILLED(data)
    return data
  } catch (err) {
    // await dispatch.emails.SEND_EMAIL_FAILED(err)
    throw err
  }
}

export const observeThreadedEmails = async query => {
  return dispatch(
    observeEndpoint({
      path: '/emails/grouped',
      query,
      interval: 8000,
      lifecycle: [
        `emails/OBSERVE_THREADED_EMAILS_REQUESTED`,
        `emails/OBSERVE_THREADED_EMAILS_FULFILLED`,
        `emails/OBSERVE_THREADED_EMAILS_FAILED`,
        `emails/OBSERVE_THREADED_EMAILS_RECEIVED`,
      ],
    }),
  )
}

export const fetchPaperCategories = async payload => {
  dispatch.emails.FETCH_EMAILS_PAPER_CATEGORIES_REQUESTED(payload)
  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      `/emails/categories/papers`,
      payload,
    )
    dispatch.emails.FETCH_EMAILS_PAPER_CATEGORIES_FULFILLED(data)
    return data
  } catch (err) {
    await dispatch.emails.FETCH_EMAILS_PAPER_CATEGORIES_FAILED(err)
    throw err
  }
}

export const newComposeEmail = async initialData => {
  dispatch.emails.NEW_COMPOSE_EMAIL({ isVisible: true, initialData })
}

export const hideComposeEmail = async composeEmail => {
  dispatch.emails.HIDE_COMPOSE_EMAIL(composeEmail)
}

export const getEmailThreads = async (paperId, filter) => {
  dispatch.emails.GET_PAPER_EMAILS_REQUESTED()
  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      `/papers/${paperId}/emails`,
      filter,
    )
    dispatch.emails.GET_PAPER_EMAILS_FULFILLED(data)
    return data
  } catch (err) {
    await dispatch.emails.GET_PAPER_EMAILS_FAILED(err)
    throw err
  }
}

export const markAsRead = async function ({ emailIds }) {
  // await this.GET_PARSED_EMAIL_REQUESTED()
  try {
    const { data: result } = await httpProvider.makeRequest({
      method: 'POST',
      path: '/emails/markAsRead',
      data: emailIds,
    })
    // await this.GET_PARSED_EMAIL_FULFILLED(data)
    return result
  } catch (err) {
    // await this.GET_PARSED_EMAIL_FAILED(err)
    throw err
  }
}

export const markAsUnread = async function ({ emailIds }) {
  // await this.GET_PARSED_EMAIL_REQUESTED()
  try {
    const { data: result } = await httpProvider.makeRequest({
      method: 'POST',
      path: '/emails/markAsUnread',
      data: emailIds,
    })
    // await this.GET_PARSED_EMAIL_FULFILLED(data)
    return result
  } catch (err) {
    // await this.GET_PARSED_EMAIL_FAILED(err)
    throw err
  }
}
