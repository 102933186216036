import React from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Card, Input, Form, Badge, Button } from 'antd'
import { withRouter } from 'react-router'
import { select } from '@rematch/select'
import DefaultLayout from 'src/components/layout/DefaultLayout'
import withSubscription from 'src/components/withSubscription'
import withClearStateOnUnmount from 'src/components/withClearStateOnUnmount'
import Spinner from 'src/components/Spinner'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const WhiteBadge = props => (
  <Badge
    style={{
      backgroundColor: '#fff',
      color: '#999',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }}
    {...props}
  />
)

const ReportView = ({
  report: theReport,
  handleDownloadBtnClick,
  isFetchingItem,
}) => {
  const report = theReport || {}

  const reportTypeLabel = (() => {
    if (report.type === 'papers') return 'Pratiche'
    if (report.type === 'agents') return 'Agenti'
  })()
  return (
    <DefaultLayout>
      <Spinner isLoading={isFetchingItem}>
        <Card title={`Report: ${report.internalId} - ${report.name}`}>
          <div style={{ maxWidth: '540px' }}>
            <Form.Item label="Nome" name="name" {...formItemLayout}>
              <Input disabled value={report.name} />
            </Form.Item>

            <Form.Item label="Tipologia Report" {...formItemLayout}>
              <Input disabled value={reportTypeLabel} />
            </Form.Item>
            <Form.Item label="Stato" {...formItemLayout}>
              {report.status === 'idle' && <WhiteBadge count={'In attesa'} />}
              {report.status === 'processing' && (
                <WhiteBadge count={'In lavorazione'} />
              )}
              {report.status === 'done' && (
                <Button
                  type="primary"
                  size="small"
                  onClick={handleDownloadBtnClick}
                >
                  Download
                </Button>
              )}
            </Form.Item>
          </div>
        </Card>
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  connect(
    state => ({
      report: select.reports.get(state),
      isFetchingItem: select.reports.isFetchingItem(state),
    }),
    dispatch => ({
      observeOne: dispatch.reports.observeOne,
      downloadFromUrl: dispatch.files.downloadFileFromUrl,
    }),
  ),
  withSubscription,
  withClearStateOnUnmount('reports.report'),
  withHandlers({
    fetchData: props => () => {
      const { match: { params: { id } } } = props
      return props.addSubscription(
        props.observeOne({
          filter: {
            fields: ['id', 'status', 'agent', 'name', 'type', 'internalId'],
            where: { id },
            include: [
              {
                relation: 'agent',
                scope: {
                  fields: ['firstname', 'lastname'],
                },
              },
            ],
          },
        }),
      )
    },
    handleDownloadBtnClick: props => e => {
      e.preventDefault()
      props.downloadFromUrl({ url: `api/reports/${props.report.id}/download`, includeToken: true })
    },
  }),
  lifecycle({
    componentDidMount() {
      return this.props.fetchData()
    },
  }),
  withRouter,
)(ReportView)
