import React from 'react'
import { connect } from 'react-redux'
import { compose, withProps, defaultProps } from 'recompose'
import { select } from '@rematch/select'
import AsyncSelect from '../AsyncSelect'

const ProgressSelect = props => {
  const { options = [], find, labelFieldFn, initialQuery } = props

  return (
    <AsyncSelect
      options={options}
      valueField="id"
      labelField={labelFieldFn}
      fetchMethod={find}
      initialQuery={initialQuery}
      {...props}
    />
  )
}

export default compose(
  connect(
    state => ({
      options: select.progress.getAll(state),
    }),
    dispatch => ({
      find: dispatch.progress.find,
    }),
  ),
  defaultProps({
    initialQuery: {
      filter: { order: 'value ASC', skipDeleted: true },
    },
  }),
  withProps({
    labelFieldFn: progress => {
      return `${progress.value}% - ${progress.displayName}`
    },
  }),
)(ProgressSelect)
