import Home from './Home'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'home',
    path: '/',
    component: withCheckAuthOnEnter(withPageTracker()(Home)),
  },
]
