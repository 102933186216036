import { css } from 'emotion'

export default css`
& {
  background: white;
  margin-bottom: 30px;
  p {
    line-height: 1.3;
  }
  .PapersViewTop-col {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}
`
