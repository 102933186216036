import _get from 'lodash/get'
import {
  FORM_SUBMIT_REQUESTED,
  FORM_SUBMIT_FULFILLED,
  FORM_SUBMIT_FAILED,
} from '../actions/index'


export default (state = {}, { type, payload, meta } = {}) => {
  switch (type) {
    case FORM_SUBMIT_REQUESTED:
      return {
        ...state,
        [meta.form]: null,
      }
    case FORM_SUBMIT_FULFILLED:
      return {
        ...state,
        [meta.form]: payload,
      }
    case FORM_SUBMIT_FAILED:
      return {
        ...state,
        [meta.form]: null,
      }
    default:
      return state
  }
}
