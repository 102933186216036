import React, { Fragment } from 'react'
import {
  compose,
  withHandlers,
  withProps,
  lifecycle,
  withState,
  defaultProps,
  pure,
} from 'recompose'
import { DatePicker, Input, Col, Button } from 'antd'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import style from './Datepicker.style'

const InputGroup = Input.Group

const isValidDate = value => moment(value, 'DD-MM-YYYY').isValid()
const createDate = value => moment(value, 'DD-MM-YYYY')
const isValidInput = value => {
  const stringValue = value || ''
  return (
    !stringValue.includes('D') &&
    !stringValue.includes('M') &&
    !stringValue.includes('Y')
  )
}

const TheDatePicker = props => {
  const {
    handleChange,
    handleDateSelection,
    onDatePickerRef,
    triggerClick,
    disabled,
    stringValue,
    inputProps,
    readOnly,
  } = props

  return (
    <article className={style}>
      <div style={{ height: 0, overflow: 'hidden' }}>
        <DatePicker ref={onDatePickerRef} onChange={handleDateSelection} />
      </div>
      <InputGroup className="BrDatePicker-inputGroup" compact>
        <Col className="BrDatePicker-inputGroup-left">
          <NumberFormat
            format={'##/##/####'}
            placeholder={'GG/MM/AAAA'}
            mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y', 'Y']}
            customInput={Input}
            onValueChange={handleChange}
            value={stringValue}
            disabled={disabled}
            readOnly={readOnly}
            {...inputProps}
          />
        </Col>

        {!readOnly &&
          !disabled && (
            <Col className="BrDatePicker-inputGroup-right">
              <Button
                icon="calendar"
                onClick={triggerClick}
                disabled={disabled}
                tabindex="-1"
              />
            </Col>
          )}
      </InputGroup>
    </article>
  )
}

export default compose(
  pure,
  defaultProps({
    isPickerButtonVisible: true,
  }),
  withProps({
    datePickerRef: React.createRef(),
  }),
  withHandlers(() => {
    let datePickerRef = null

    return {
      onDatePickerRef: () => ref => (datePickerRef = ref),
      triggerClick: () => () => datePickerRef.picker.input.click(),
    }
  }),
  withHandlers({
    handleChange: props => ({ formattedValue }, e) => {
      e.preventDefault()
      e.stopPropagation()
      if (props.onChange) {
        if (!formattedValue) {
          return props.onChange(null)
        }
        if (isValidInput(formattedValue) && isValidDate(formattedValue)) {
          return props.onChange(createDate(formattedValue))
        }
      }
    },
    handleDateSelection: props => value => {
      if (props.onChange) {
        props.onChange(value)
      }
    },
  }),
  withState('stringValue', 'setStringValue', ''),
  lifecycle({
    componentDidMount(nextProps) {
      const stringValue = isValidDate(this.props.value)
        ? moment(this.props.value).format('DD/MM/YYYY')
        : ''
      this.props.setStringValue(stringValue)
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.value !== this.props.value) {
        const stringValue = isValidDate(nextProps.value)
          ? moment(nextProps.value).format('DD/MM/YYYY')
          : ''
        this.props.setStringValue(stringValue)
      }
    },
  }),
)(TheDatePicker)
