import InboxThreadList from './InboxThreadList'
import InboxThread from './InboxThread'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'inbox',
    path: '/inbox',
    component: withCheckAuthOnEnter(withPageTracker()(InboxThreadList)),
  },
  {
    name: 'inbox.thread',
    path: '/inbox/:messageThreadId',
    component: withCheckAuthOnEnter(withPageTracker()(InboxThread)),
  },
]
