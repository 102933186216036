import React from 'react'
import { Input, Checkbox, Menu, Dropdown, Icon } from 'antd'
import { compose, withHandlers, withStateHandlers } from 'recompose'

const AddonAfter = props => {
  const { autoformatEnabled, setAutoformat } = props
  const menu = (
    <Menu>
      <Menu.Item>
        <label>
          <Checkbox
            size="small"
            checked={autoformatEnabled}
            onChange={() => setAutoformat(!autoformatEnabled)}/>&nbsp;
          Abilita formattazione automatica
        </label>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="u-cursorPointer">
      <Dropdown overlay={menu} trigger={['click']}>
        <Icon type="setting"/>
      </Dropdown>
    </div>
  )
}

const NameInput = (props) => {
  const { handleChange, autoformatEnabled, setAutoformat } = props
  return (
    <Input
      {...props}
      onChange={handleChange}
      addonAfter={<AddonAfter {...{ autoformatEnabled, setAutoformat }}/>}
    />
  )
}

const format = str => str.toLowerCase()
  .replace(/(^|[^a-z0-9-])([a-z])/g,
    function (m, m1, m2, p) {
      return m1 + m2.toUpperCase()
    })

export default compose(
  withStateHandlers({
    autoformatEnabled: true,
  }, {
    setAutoformat: props => autoformatEnabled => ({ autoformatEnabled }),
  }),
  withHandlers({
    handleChange: props => (e) => {
      e && e.persist && e.persist()
      let val = typeof e === 'undefined' ? null : e
      if (props.autoformatEnabled) {
        val.target.value = format(val.target.value)
      }
      props.onChange(val)
    },
  }),
)(NameInput)
