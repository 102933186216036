import React, { Fragment } from 'react'
import {
  compose,
  withHandlers,
  lifecycle,
  pure,
  withStateHandlers,
  withPropsOnChange,
  withState,
} from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { cx, css } from 'emotion'
import { Button, Checkbox, message, Modal as AntdModal, Row, Col, Alert } from 'antd'
import { select } from '@rematch/select'
import Promise from 'bluebird'
import { pushModalWindow } from 'redux-promising-modals'
import withConnectedForm from 'src/components/form/withConnectedForm'
import GroupedDocumentsList from './documentsTab/GroupedDocumentsList'
import FormItem from 'src/components/form/FormItem'
import { openUploadModal } from 'src/components/modals/UploadModal'
import style from './DocumentsTab.style'
import SubmitButton from 'src/components/form/SubmitButton'
import ResetButton from 'src/components/form/ResetButton'
import Spinner from 'src/components/Spinner'
import NewMandatoryDocumentsModal from './documentsTab/NewMandatoryDocumentsModal'
import DocumentTemplatesModal from './documentsTab/DocumentTemplatesModal'
import DocumentViewModal from 'src/components/modals/DocumentViewModal'
import withModalControls from 'src/components/modals/withModalControls'
import withSubscription from 'src/components/withSubscription'
import { clearStateAtPath } from 'src/store/actions/index'

const checkInputStyle = css`
  & {
    * {
      color: orange;
    }
    &.is-verified * {
      color: initial;
    }
  }
`

const CheckInput = compose(
  connect(state => ({
    me: _.get(select.agents.me(state), 'me'),
  })),
  withConnectedForm('documentsTab', { destroyOnUnmount: false }),
  withPropsOnChange(['document', 'me'], ({ document, me }) => {
    let checked = false
    let checkIndex = -1
    if (document && me) {
      checkIndex = _.findIndex(document.checks, { agentId: me.id })
      checked = checkIndex > -1
    }

    return { checked, checkIndex }
  }),
  withPropsOnChange(['document', 'formValues'], ({ document, formValues }) => {
    if (!document || !formValues) return { documentIndex: -1 }
    return { documentIndex: _.findIndex(formValues, { id: document.id }) }
  }),

  withHandlers({
    handleChange: props => e => {
      const { document, documentIndex, me, checked, checkIndex, array } = props
      const path = `${documentIndex}.checks`

      if (checked) {
        array.remove(path, checkIndex)
      } else {
        array.unshift(path, { agentId: me.id, agent: me, date: new Date() })
      }
    },
  }),
)(props => {
  const { handleChange, document, checked } = props

  return (
    <>
      <FormItem
        className={cx(checkInputStyle, {
          'is-verified': document.checks && document.checks.length > 0,
        })}
        style={{ marginBottom: 0 }}
        childrenAsFunction={true}
      >
        {props => (
          <Checkbox onChange={handleChange} checked={checked}>
            Verificato
          </Checkbox>
        )}
      </FormItem>
    </>
  )
})

const TopActions = compose(
  connect(state => ({
    me: select.agents.me(state),
  })),
)(({ document, me, array, documents }) => {
  return (
    <div>
      <CheckInput document={document} />
    </div>
  )
})

export const MandatoryDocumentsTab = props => {
  const {
    fetchData,
    groupedMandatoryDocuments,
    groupedNewMandatoryDocuments,
    isNewMandatoryDocumentsModalOpen,
    handleAddDocumentBtnClick,
    handleRecalcMandatoryDocumentsBtnClick,
    handleDisplayDocumentTemplatesBtnClick,
    handleNewMandatoryDocumentsModalClose,
    handleDocumentViewBtnClick,
    handleDeleteDocumentBtnClick,
    handleSubmit,
    submitting,
    readOnly,
    documentViewModalControl,
    missingCount,
    notCheckedCount,
    isAdmin,
    documentViewModalProps,
    formValues,
  } = props

  return (
    <section className={style}>
      <Spinner fetchData={fetchData}>
        <div className="DocumentsTab-topActions">
          <Row type="flex" style={{ justifyContent: 'space-between' }}>
            {!readOnly && (
              <Col>
                <Button onClick={handleRecalcMandatoryDocumentsBtnClick} icon="plus" size="small">
                  Ricalcola documenti
                </Button>
              </Col>
            )}
            {/*<Col>
              <Button
                onClick={handleDisplayDocumentTemplatesBtnClick}
                icon="plus"
                size="small"
              >
                Modelli documento
              </Button>
            </Col>*/}
          </Row>
        </div>
        <form onSubmit={handleSubmit}>
          {isAdmin && notCheckedCount + missingCount > 0 && (
            <Alert
              style={{ marginBottom: 20 }}
              type="warning"
              showIcon
              message={
                <Fragment>
                  {missingCount > 0 && <b>{missingCount} mancanti</b>}
                  {missingCount > 0 && notCheckedCount > 0 && <span>, &nbsp;</span>}
                  {notCheckedCount > 0 && <b>{notCheckedCount} non verificati</b>}
                </Fragment>
              }
            />
          )}
          <section className="DocumentsTab-list">
            <GroupedDocumentsList
              enabledActions={{}}
              groupedDocuments={groupedMandatoryDocuments}
              onDocumentViewBtnClick={handleDocumentViewBtnClick}
              onAddDocumentBtnClick={handleAddDocumentBtnClick}
              onDeleteDocumentBtnClick={handleDeleteDocumentBtnClick}
              CheckInput={CheckInput}
              readOnly={readOnly}
              isAdmin={isAdmin}
              areNotMandatoryVisible={false}
            />
          </section>
          {!readOnly && (
            <section className="FormActions">
              <SubmitButton submitting={submitting} />
              <ResetButton />
            </section>
          )}
        </form>
        <NewMandatoryDocumentsModal
          visible={isNewMandatoryDocumentsModalOpen}
          closeModal={handleNewMandatoryDocumentsModalClose}
          groupedMandatoryDocuments={groupedMandatoryDocuments}
          groupedNewMandatoryDocuments={groupedNewMandatoryDocuments}
        />
      </Spinner>
      {/*{documentViewModalControl.visible && (
        <DocumentViewModal
          {...documentViewModalControl}
          {...documentViewModalProps}
          TopActions={({ currentItem }) => {
            if (!currentItem) return null

            const formDocument = _.find(formValues, { id: currentItem.id })

            return <TopActions document={formDocument} />
          }}
        />
      )}*/}
      <DocumentViewModal
        {...documentViewModalControl}
        {...documentViewModalProps}
        TopActions={({ currentItem }) => {
          if (!currentItem || !isAdmin) return null

          const formDocument = _.find(formValues, { id: currentItem.id })

          return <TopActions document={formDocument} />
        }}
      />
    </section>
  )
}

export default compose(
  pure,
  withSubscription,
  connect(
    (state, props) => ({
      // groupedMandatoryDocuments: select.papers.getGroupedMandatoryDocuments(state, { documents: props.formValues }),
      // groupedNewMandatoryDocuments: select.papers.getGroupedNewMandatoryDocuments(state, { documents: props.formValues }),
      initialValues: select.documents.getAll(state),
      documentTypes: select.documentTypes.getAll(state),
      generate: select.documentTemplates.generate(state),
      task: select.queues.task(state),
      file: select.files.get(state),
    }),
    dispatch => ({
      openDocumentTemplatesModal: props =>
        dispatch(
          pushModalWindow('DOCUMENTS_TEMPLATE_MODAL', {
            Component: DocumentTemplatesModal,
            ...props,
          }),
        ),
      openUploadModal: bindActionCreators(openUploadModal, dispatch),
      saveMandatoryDocuments: dispatch.papers.saveMandatoryDocuments,
      recalcMandatoryDocuments: dispatch.papers.recalcMandatoryDocuments,
      getMandatoryDocuments: dispatch.papers.getMandatoryDocuments,
      findPaper: dispatch.papers.findOne,
      findDocumentTypes: dispatch.documentTypes.find,
      findDocuments: dispatch.documents.find,
      deleteDocument: dispatch.documents.delete,
      updatePaperDocuments: dispatch.papers.updatePaperDocuments,
      uploadDocument: dispatch.documents.uploadDocument,
      generateDocumentTemplate: dispatch.documentTemplates.generate,
      observeTask: dispatch.queues.observeTask,
      getFileByUUID: dispatch.files.getByUUID,
      clearStateAtPath: bindActionCreators(clearStateAtPath, dispatch),
      createLog: dispatch.actionLogs.create,
    }),
  ),
  withStateHandlers(
    { documentViewModalProps: { items: [], activeItemIndex: null } },
    {
      setDocumentViewModalProps: ({ documentViewModalProps }) => newProps => ({
        documentViewModalProps: _.merge({}, documentViewModalProps, newProps),
      }),
    },
  ),
  withModalControls('documentViewModalControl', props => ({
    onCancel: () =>
      Promise.all([
        props.setDocumentViewModalProps({ items: [] }),
        props.clearStateAtPath('queues.task'),
        props.clearStateAtPath('files.file'),
        props.unsubscribeAll(),
      ]),
  })),
  withConnectedForm('documentsTab', { destroyOnUnmount: false }),
  connect((state, props) => ({
    groupedMandatoryDocuments: select.papers.getGroupedMandatoryDocuments(state, {
      documents: props.formValues,
    }),
    groupedNewMandatoryDocuments: select.papers.getGroupedNewMandatoryDocuments(state, {
      documents: props.formValues,
    }),
  })),
  withPropsOnChange(['groupedMandatoryDocuments'], ({ groupedMandatoryDocuments }) => {
    return {
      missingCount: _.reduce(
        groupedMandatoryDocuments.mandatory,
        (acc, group) => {
          if (!group.documents.length) {
            acc += 1
          }
          return acc
        },
        0,
      ),
      notCheckedCount: _.reduce(
        groupedMandatoryDocuments.mandatory,
        (acc, group) => {
          const notChecked = _.filter(group.documents, d => !d.checks || d.checks.length === 0)
          if (notChecked.length) {
            acc += notChecked.length
          }
          return acc
        },
        0,
      ),
    }
  }),
  withStateHandlers(() => ({ isNewMandatoryDocumentsModalOpen: false }), {
    openNewMandatoryDocumentsModal: () => () => ({
      isNewMandatoryDocumentsModalOpen: true,
    }),
    closeNewMandatoryDocumentsModal: () => () => ({
      isNewMandatoryDocumentsModalOpen: false,
    }),
  }),
  withPropsOnChange(['formValues'], ({ formValues }) => {
    return {
      documents: formValues,
    }
  }),
  withHandlers({
    fetchData: props => async () => {
      const { findDocuments, getMandatoryDocuments, paperId } = props

      await findDocuments({
        filter: {
          skipDeleted: true,
          where: { paperId },
          include: [
            {
              relation: 'documentType',
              scope: {
                fields: ['id', 'name'],
              },
            },
            {
              relation: 'file',
              scope: {
                fields: ['id', 'uuid'],
              },
            },
          ],
          populate: [
            {
              property: 'checks.agentId',
              on: 'agent',
              model: 'Agent',
            },
          ],
        },
      })

      await getMandatoryDocuments(paperId)
    },
  }),
  withHandlers({
    handleUploadModalSave: props => async modalProps => {
      const { uploadDocument, paperId, fetchData, createLog } = props
      const { files, documentType } = modalProps
      try {
        await Promise.map(files, async fileItem => {
          const file = {
            name: fileItem.name,
            type: fileItem.type,
            url: fileItem.response.url,
          }
          const uploadedDocument = await uploadDocument({
            file,
            document: {
              name: file.name,
              paperId,
              documentTypeId: documentType.id,
            },
          })

          await createLog({
            context: 'paper',
            paperId,
            type: 'addedDocument',
            data: {
              documentId: uploadedDocument.id,
            },
          })

          return uploadedDocument
        })
        message.success('Documenti caricati con successo')
      } catch (err) {
        message.error(`Errore durante l'upload dei documenti`)
      }

      await fetchData()
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const { formValues = {}, dispatchSubmit, updatePaperDocuments, fetchData, paper } = props
      await dispatchSubmit(updatePaperDocuments({ id: paper.id, data: formValues }))
      fetchData()
    },
    handleRecalcMandatoryDocumentsBtnClick: props => async e => {
      const { recalcMandatoryDocuments, openNewMandatoryDocumentsModal, paperId } = props

      recalcMandatoryDocuments(paperId)
      return openNewMandatoryDocumentsModal()
    },
    handleDisplayDocumentTemplatesBtnClick: props => async e => {
      const {
        paperId,
        openDocumentTemplatesModal,
        generateDocumentTemplate,
        observeTask,
        addSubscription,
        documentViewModalControl,
        setDocumentViewModalProps,
      } = props
      const documentTemplate = await openDocumentTemplatesModal({ paperId })
      if (documentTemplate) {
        setDocumentViewModalProps({
          items: [
            {
              name: documentTemplate.name,
              isLoading: true,
              spinnerProps: {
                tip: 'Generazione documento in corso',
              },
            },
          ],
        })
        const result = await generateDocumentTemplate({
          templateId: documentTemplate.id,
          paperId,
        })
        const { uuid } = result
        const observeResult = await observeTask({
          queueName: 'documentTemplates',
          uuid,
        })
        addSubscription(observeResult)

        documentViewModalControl.show()
      }
    },
    handleNewMandatoryDocumentsModalClose: props => async save => {
      const {
        closeNewMandatoryDocumentsModal,
        saveMandatoryDocuments,
        groupedNewMandatoryDocuments,
        paperId,
        fetchData,
      } = props
      if (save) {
        await saveMandatoryDocuments({
          id: paperId,
          data: [
            ...groupedNewMandatoryDocuments.mandatory,
            ...groupedNewMandatoryDocuments.notMandatory,
          ].map(g => g.id),
        })
      }
      await closeNewMandatoryDocumentsModal()
      return fetchData()
    },
    handleDocumentViewBtnClick: props => async document => {
      const {
        documentViewModalControl,
        setDocumentViewModalProps,
        groupedMandatoryDocuments,
      } = props
      const documents = _.reduce(
        [...groupedMandatoryDocuments.mandatory, ...groupedMandatoryDocuments.notMandatory],
        (acc, documentType) => acc.concat(documentType.documents),
        [],
      )

      const activeItemIndex = _.findIndex(documents, { id: document.id })
      await setDocumentViewModalProps({
        items: documents,
        activeItemIndex,
        // TopActions: props => <TopActions {...props} document={document}/>,
      })
      await documentViewModalControl.show()
    },
    handleAddDocumentBtnClick: props => async ({ documentType }) => {
      const { openUploadModal, paperId, handleUploadModalSave } = props
      await openUploadModal({
        title: `Upload: ${documentType.name}`,
        documentType,
        paperId,
        onSave: handleUploadModalSave,
      })
    },
    handleDeleteDocumentBtnClick: props => document => {
      const { deleteDocument, fetchData, paperId, createLog } = props
      AntdModal.confirm({
        title: 'Elimina documento pratica',
        content: (
          <div>
            <div>Sei sicuro di voler eliminare il documento:</div>
            {_.compact([document.id, document.name, document.description]).join(' - ')}
          </div>
        ),
        onOk: async () => {
          try {
            await deleteDocument({ id: document.id })
            message.success('Documento eliminato')
            await createLog({
              context: 'paper',
              paperId,
              type: 'deletedDocument',
              data: {
                documentId: document.id,
              },
            })
            return fetchData()
          } catch (err) {
            message.error(`Errore durante l'eliminazione del documento`)
          }
        },
      })
    },
  }),
  withPropsOnChange(['task'], async props => {
    const { task, getFileByUUID, unsubscribeAll } = props
    const uuid = _.get(task, 'result.file.uuid')
    if (task && uuid) {
      await getFileByUUID({ uuid })
      unsubscribeAll()
    }
  }),
  withPropsOnChange(['file'], async props => {
    const { file, setDocumentViewModalProps, documentViewModalProps = { items: [] } } = props
    if (file) {
      const item = documentViewModalProps.items[0] || {}
      setDocumentViewModalProps({
        items: [
          {
            ...item,
            file: props.file,
            isLoading: false,
          },
        ],
      })
    }
  }),
  lifecycle({
    componentDidMount() {
      DocumentViewModal.preload()
    },
  }),
)(MandatoryDocumentsTab)
