import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, withHandlers, lifecycle } from 'recompose'
import Promise from 'bluebird'
import { select } from '@rematch/select'
import RequestDetailsTabForm from './RequestDetailsTabForm'
import withConnectedForm from '../../../../../components/form/withConnectedForm'
import { getPath } from '../../../../../store/selectors'
import Spinner from 'src/components/Spinner'

const query = {
  filter: {
    include: [
      {
        relation: 'product',
        scope: {
          //fields: ['name', 'financialInstitution'],
          include: {
            relation: 'financialInstitution',
            scope: {
              fields: ['name'],
            },
          },
        },
      },
      {
        relation: 'initialAgent',
        scope: {
          fields: ['id', 'firstname', 'lastname'],
        },
      },
      {
        relation: 'productType',
      },
    ],
    populate: [
      {
        property: 'agents.agentId',
        model: 'Agent',
        on: 'agent',
      },
      {
        property: 'agents.roleId',
        model: 'Role',
        on: 'role',
      },
      {
        property: 'expenses.insuranceId',
        model: 'Product',
        on: 'insurance',
      },
      {
        property: 'clients.clientId',
        model: 'Client',
        on: 'client',
      },
    ],
  },
}

export const RequestDetailsTab = props => {
  return (
    <Spinner fetchData={props.fetchData}>
      <RequestDetailsTabForm {...props} paper={props.formValues} />
    </Spinner>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      roles: select.roles.getAll(state),
    }),
    dispatch => ({
      findRoles: dispatch.roles.find,
    }),
  ),
  connect(
    state => ({
      // paper: state.papers.paper,
      currentAgent: select.papers.getCurrentAgent(state),
      initialValues: getPath(state)('papers.tabs.requestDetails.paper'),
      //paper: getFormValues('paperView')(state),
    }),
    dispatch => ({
      findPaper: dispatch.papers.findOne,
      findOffices: dispatch.offices.find,
      findPurposes: dispatch.purposes.find,
    }),
  ),
  withHandlers({
    fetchData: props => () => {
      const { findPaper, findRoles, findOffices, findPurposes, paperId } = props

      return Promise.all([
        findPaper(
          {
            ...query,
            filter: {
              ...query.filter,
              where: { id: paperId },
            },
          },
          { basePath: 'tabs.requestDetails' },
        ),

        findRoles(),
        findOffices({ filter: { order: 'name ASC' } }),
        findPurposes({ filter: { order: 'name ASC' } }),
      ])
    },
  }),
  withConnectedForm('requestDetailsTab'),
)(RequestDetailsTab)
