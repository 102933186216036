import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import { select } from '@rematch/select'
import AsyncSelect from '../AsyncSelect'

const OfficeSelect = props => {
  return <AsyncSelect {...props} valueField="id" labelField="name" />
}

export default compose(
  connect(
    state => ({
      options: select.offices.getAll(state),
    }),
    dispatch => ({
      fetchMethod: dispatch.offices.find,
    }),
  ),
  withProps({
    query: {
      filter: { order: 'name ASC', skipDeleted: true },
    },
  }),
)(OfficeSelect)
