import Promise from 'bluebird'

export function toDataUrl(url) {
  const xhr = new XMLHttpRequest()
  return new Promise(resolve => {
    xhr.onload = function() {
      const reader = new FileReader()
      reader.onloadend = function() {
        resolve(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  })
}
