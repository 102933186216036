import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Button } from 'antd'
import defaultsDeep from 'lodash/defaultsDeep'
import Modal from '../Modal/index'
import SmartTable from '../../tables/SmartTable/index'
import style from './ChooseProductModal.style'

const initialQuery = {
  filter: {
    order: 'financialInstitution.name ASC',
    include: ['financialInstitution'],
    skipDeleted: true,
  },
}

const columns = [
  {
    displayName: 'Instituto finanziario',
    field: 'financialInstitution.name',
  },
  {
    displayName: 'Nome Prodotto',
    field: 'name',
  },
  {
    displayName: '',
    render: function (ignore, record) {
      const { closeModal = () => {} } = this.props
      return (
        <Button size="small" onClick={() => closeModal(record)}>Seleziona</Button>
      )
    },
  },
]

export const ChooseAgentModal = (props) => {
  const { visible, closeModal, products, count, findProducts, countProducts, productTypeId } = props
  const query = defaultsDeep({}, initialQuery, { filter: { where: { productTypeId } } })

  return (
    <Modal
      className={style}
      title="Seleziona prodotto"
      onCancel={() => closeModal()}
      visible={visible}>
      <SmartTable
        closeModal={closeModal}
        initialQuery={query}
        data={products}
        columns={columns}
        count={count}
        countMethod={countProducts}
        findMethod={findProducts}></SmartTable>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      products: state.products.products,
      count: state.products.count,
    }),
    dispatch => ({
      findProducts: dispatch.products.find,
      countProducts: dispatch.products.count,
    }),
  ),
)(ChooseAgentModal)

