import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { Layout } from 'antd'
import { Icon, Input } from 'antd'
import { select } from '@rematch/select'
import { push } from 'react-router-redux'
import _ from 'lodash'
import Spinner from 'src/components/Spinner'
import withFormProps from '../../../components/form/withReduxFormProps'
import FormItem from 'src/components/form/FormItem/index'
import SubmitButton from 'src/components/form/SubmitButton/index'
import withConnectedForm from 'src/components/form/withConnectedForm'
import LoginStyle from '../Login.style'

const { Content } = Layout

const UsernameInput = withFormProps(Input, {
  prefix: <Icon type="user" />,
  placeholder: 'Email o Username',
  // size: 'large',
})

const PasswordInput = withFormProps(Input, {
  prefix: <Icon type="lock" />,
  type: 'password',
  placeholder: 'Password',
  // size: 'large',
})

/*const RememberInput = withFormProps(Checkbox, { children: ['test'] })*/

const ContactForm = props => {
  const { submitting, handleSubmit, fetchData, logoSrc } = props

  return (
    <Layout className={LoginStyle}>
      <Content className="LoginPage-wrapper">
        <section className="LoginPage-logo">{logoSrc && <img src={logoSrc} alt="" />}</section>
        <form onSubmit={handleSubmit} className="LoginPage-form">
          <FormItem name="username" type="text" component={UsernameInput} />
          <FormItem name="password" component={PasswordInput} />
          {/*<FormItem
            name="remember"
            component={RememberInput}/>*/}
          <SubmitButton submitting={submitting} className="LoginPage-submit">
            Log in
          </SubmitButton>
          <div className="LoginPage-forgot">
            problemi con il login? <a href="/#/forgotPassword">recupera password</a>
          </div>
          {/*Or <a href="">register now!</a>*/}
        </form>
      </Content>
    </Layout>
  )
}

const checkIfAlreadyLoggedIn = ({ isAuthenticated, history }) => {
  if (isAuthenticated) {
    history.push('/papers/list')
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.agents.isAuthenticated,
      accountSettings: select.accountSettings.get(state),
    }),
    dispatch => ({
      login: dispatch.agents.login,
      getAccountSettings: dispatch.accountSettings.getMine,
      setCurrentAccount: dispatch.accounts.setCurrent,
      push: (...props) => dispatch(push(...props)),
    }),
  ),
  withConnectedForm('login'),
  withPropsOnChange(['accountSettings'], ({ accountSettings }) => ({
    logoSrc: _.get(accountSettings, 'public.ui.logo.src'),
  })),
  lifecycle({
    async componentWillMount() {
      checkIfAlreadyLoggedIn(this.props)
    },
    /*componentWillReceiveProps(props) {
      checkIfAlreadyLoggedIn(props)
    },*/
  }),
  withHandlers({
    handleSubmit: props => async event => {
      event.preventDefault()
      const { dispatchSubmit, formValues, login, push, accountSettings, getAccountSettings } = props
      await dispatchSubmit(login(formValues))
      if (!accountSettings) {
        return push('/accountSwitcher')
      }
      // refetch account settings with more data
      await getAccountSettings()
      return push('/papers/list')
    },
  }),
)(ContactForm)
