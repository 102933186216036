import React from 'react'
import { Input, Card, Select, Table, Button } from 'antd'
import _ from 'lodash'
import { css } from 'emotion'
import FormItem from 'src/components/form/FormItem'
import withFormProps from 'src/components/form/withReduxFormProps'

const className = css({
  '& .ant-table-body': {
    overflowX: 'auto',
  },
})
const Column = Table.Column
const InputGroup = Input.Group

const InputText = withFormProps(Input)

const CellValue = props => {
  const { record, index } = props
  const column = record[index] || {}
  const key = column.key
  const selectBtnLabel = key ? 'cambia' : 'seleziona'

  return (
    <InputGroup compact>
      <Button onClick={props.onSelect} icon="select">{selectBtnLabel}</Button>
      <Input style={{ width: 'auto' }} value={key} disabled/>
    </InputGroup>
  )
}

const ReportFields = (props) => {
  const {
    report = {},
    formError,
    dataSource,
    handleAddColumnBtnClick,
    handleKeySelectBtnClick,
    handleDeleteColumnBtnClick,
  } = props

  const columns = _.get(report, 'columns', [])

  return (
    <section className={className}>
      <h3 className="TitleSection">Campi</h3>
      <Table bordered dataSource={dataSource} pagination={false}>
        {(columns || []).map((column, index) =>
          <Column
            title={
              <FormItem
                style={{ marginBottom: 0 }}
                name={`columns.${index}.label`}
                addonAfter={
                  <Button
                    onClick={() => handleDeleteColumnBtnClick(index)}
                    size="small"
                    shape="circle"
                    icon="delete"/>
                }
                component={InputText}
                errors={formError}>
              </FormItem>
            }
            dataIndex={`${index}.key`}
            key={index}
            render={(value, record) => {
              return (
                <CellValue
                  index={index}
                  record={record}
                  onSelect={() => handleKeySelectBtnClick({ index, column })}
                />
              )
            }}
          />,
        )}
        <Column
          title={
            <Button onClick={handleAddColumnBtnClick} icon="plus" size="small">
              Aggiungi Colonna
            </Button>
          }
        />
      </Table>
    </section>
  )
}

export default ReportFields
