import React from 'react'
import style from './Overlay.style'

export const Overlay = props => {
  const { children, style = {} } = props
  const styles = {
    cursor: 'pointer',
    ...style,
  }
  return (<div className={style} style={styles}>{children}</div>)
}

export default Overlay
