import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, withProps } from 'recompose'
import { Link } from 'react-router-dom'
import { select } from '@rematch/select'
import { Badge } from 'antd'
import _fp from 'lodash/fp'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import PageTitle from '../../components/layout/PageTitle'
import OfficeSelect from '../../components/form/OfficeSelect'
import FormattedDate from '../../components/format/FormattedDate'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
  },
  {
    displayName: 'Nome',
    field: 'firstname',
    sortable: true,
  },
  {
    displayName: 'Cognome',
    field: 'lastname',
    sortable: true,
  },
  {
    displayName: 'Email',
    field: 'email',
    sortable: true,
  },
  {
    displayName: 'Sede',
    field: 'account.office.name',
  },
  {
    displayName: 'Ruoli',
    field: 'roles',
    render: function(roles = []) {
      return _fp.flow(
        // _fp.filter(r => r.name !== 'admin'),
        _fp.map('displayName'),
        _fp.join(', '),
      )(roles)
    },
  },
  {
    displayName: 'Data di aggiunta',
    field: 'createdAt',
    type: 'date',
    sortable: true,
  },
  {
    displayName: 'Stato',
    field: 'deletedAt',
    type: 'date',
    sortable: true,
    render(deletedAt) {
      return (
        <>
          <Badge
            count={deletedAt ? 'Disattivato' : 'Attivo'}
            style={deletedAt ? {} : { backgroundColor: '#52c41a' }}
          />
          {deletedAt && (
            <>
              <br />
              <span>
                (<FormattedDate date={deletedAt} />)
              </span>
            </>
          )}
        </>
      )
    },
  },
  {
    displayName: '',
    render: function(ignore, record) {
      return <Link to={`/agents/edit/${record.id}`}>Visualizza</Link>
    },
  },
]

const filtersConfig = [
  {
    label: 'Ricerca',
    key: 'search',
    type: 'search',
    fields: ['internalId', 'firstname', 'lastname', 'fiscalCode', 'identityCard', 'address'],
  },
  {
    key: 'accounts.officeId',
    label: 'Sede',
    createQueryFilter: value => ({ where: { 'accounts.officeId': { inq: value } } }),
    component: props => <OfficeSelect {...props} mode="multiple" allowClear={true} />,
  },
  'includeDeleted',
]

export const AgentList = props => {
  const { agents, initialQuery, findMethod, countMethod, count } = props
  return (
    <DefaultLayout>
      <Helmet>
        <title>Agenti, elenco</title>
      </Helmet>
      <PageTitle title="Lista Agenti" />
      <div className="u-bgWhite">
        <SmartTable
          data={agents}
          columns={columns}
          initialQuery={initialQuery}
          filtersConfig={filtersConfig}
          count={count}
          countMethod={countMethod}
          findMethod={findMethod}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      agents: select.agents.getAll(state),
      count: select.agents.count(state),
    }),
    dispatch => ({
      findMethod: dispatch.agents.find,
      countMethod: dispatch.agents.count,
    }),
  ),
  withProps({
    initialQuery: {
      filter: {
        include: [
          {
            relation: 'roles',
            scope: {
              fields: ['id', 'displayName', 'name'],
            },
          },
        ],
        populate: [
          {
            model: 'Office',
            on: 'office',
            property: 'account.officeId',
          },
        ],
        skipDeleted: true,
      },
    },
  }),
)(AgentList)
