import { css } from 'emotion'

export default css`
  & {
    .FormActions {
      button {
        margin-right: 10px;
      }
    }
    .EmailEditor-body {
      .EmailEditor-body-content {
        margin-bottom: 5px;
      }
    }

    .EmailEditor-body-actions {
      button {
        margin-right: 5px;
      }
    }

    .EmailEditor-attachments {
      margin: 10px 0;
      &-list {
        .EmailEditor-attachment {
          display: flex;
          padding: 5px;
          &:hover {
            background: whitesmoke;
          }
          &-icon {
            margin-right: 5px;
          }
          &-name {
            flex-grow: 1;
          }
          &-delete {
          }
        }
      }
    }
  }
`
