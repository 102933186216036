import React, { Fragment } from 'react'
import { compose, withHandlers, lifecycle, withPropsOnChange, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Form } from 'redux-form'
import { Select, Input as TheInput, Button, Tooltip, Icon, message } from 'antd'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import TextEditor from '../TextEditor'
import { dispatch } from '@rematch/core'
import { select } from '@rematch/select'
import withReduxFormProps from '../form/withReduxFormProps'
import withConnectedForm from '../form/withConnectedForm'
import TruncateText from 'src/components/format/TruncateText'
import FormItem from '../form/FormItem'
import SubmitButton from '../form/SubmitButton'
import style from './EmailEditor.style'
import AddAttachmentsModal, {
  openAddAttachmentsModal,
} from 'src/components/modals/AddAttachmentsModal'
import { openChooseEmailTemplateModal } from 'src/components/modals/ChooseEmailTemplateModal'
import RecipientsSelect from './emailEditor/RecipientsSelect'
import Spinner from 'src/components/Spinner'
import withWatcher from '../withWatcher'

const Option = Select.Option
const To = props => {
  const emails = _.map(props.input.value, 'email')
  const children = emails.map(email => (
    <Option key={email} value={email}>
      {email}
    </Option>
  ))
  return (
    <Select style={{ width: '100%' }} {...props} mode="tags" value={emails}>
      {children}
    </Select>
  )
}
const Input = withReduxFormProps(TheInput)

const EmailEditor = props => {
  const {
    email = {},
    formValues = {},
    handleEditorChange,
    handleSubmit,
    formatTo,
    submitting,
    onDeleteDraftBtnClick,
    handleAddAttachmentsBtnClick,
    handleEmailTemplateBtnClick,
    paper,
    paperId,
    parsed,
    attachments,
    removeAttachment,
    formError = {},
  } = props

  return (
    <article className={style}>
      <Spinner isLoading={parsed.isFetching}>
        <Form onSubmit={handleSubmit}>
          <div className="EmailEditor-top">
            <FormItem
              style={{ marginBottom: 5 }}
              label="Destinatari"
              name="to"
              component={RecipientsSelect}
              onBlur={e => e.preventDefault()}
              withReduxFormProps={true}
              error={formError.to || formError.hasRecipient}
            />
            <FormItem
              style={{ marginBottom: 5 }}
              label="Oggetto"
              name="subject"
              component={Input}
              withReduxFormProps={true}
            />
          </div>
          <section className="EmailEditor-body">
            <div className="EmailEditor-body-content">
              <TextEditor value={formValues.body || ''} onChange={handleEditorChange} />
            </div>
            <div className="EmailEditor-body-actions">
              <Tooltip title="Aggiungi allegati">
                <Button icon="file-add" onClick={handleAddAttachmentsBtnClick} />
              </Tooltip>
              <Tooltip title="Scegli template email">
                <Button icon="bars" onClick={handleEmailTemplateBtnClick} />
              </Tooltip>
            </div>
          </section>
          <div className="EmailEditor-attachments">
            {attachments.length > 0 && (
              <Fragment>
                <h3 className="TitleSection">Allegati</h3>
                <ul className="EmailEditor-attachments-list u-listStyleNone">
                  {attachments.map(attachment => (
                    <li className="EmailEditor-attachment">
                      <div className="EmailEditor-attachment-icon">
                        <Icon type="file" />
                      </div>
                      <div className="EmailEditor-attachment-name">
                        <TruncateText text={attachment.name} maxLength={40}>
                          {({ isTruncated, truncatedText, ellipsis }) => {
                            if (isTruncated) {
                              return <Tooltip title={attachment.name}>{truncatedText}</Tooltip>
                            }

                            return attachment.name
                          }}
                        </TruncateText>
                      </div>

                      <span className="EmailEditor-attachment-delete">
                        <Button
                          icon="delete"
                          shape="circle"
                          size="small"
                          type="danger"
                          onClick={() => removeAttachment(attachment)}
                        />
                      </span>
                    </li>
                  ))}
                </ul>
              </Fragment>
            )}
          </div>

          <section className="FormActions">
            <SubmitButton submitting={submitting}>Invia</SubmitButton>
            <Button size="large" icon="delete" onClick={onDeleteDraftBtnClick} />
          </section>
        </Form>
      </Spinner>
    </article>
  )
}

export default compose(
  connect(
    (state, props) => ({
      initialValues: props.initialValues,
      parsed: select.emailTemplates.parsed(state),
    }),
    dispatch => ({
      openAddAttachmentsModal: bindActionCreators(openAddAttachmentsModal, dispatch),
      openChooseEmailTemplateModal: bindActionCreators(openChooseEmailTemplateModal, dispatch),
      getParsedEmailTemplate: dispatch.emailTemplates.getParsed,
      sendEmail: dispatch.emails.sendEmail,
    }),
  ),
  withPropsOnChange(['form'], ({ form }) => {
    return {
      form: form && form !== 'emailEditor' ? form : `emailEditor-${_.uniqueId()}`,
    }
  }),
  withWatcher(['form'], { immediate: true }, ({ form, onFormNameChange }) => {
    if (onFormNameChange) {
      onFormNameChange(form)
    }
  }),
  withConnectedForm('emailEditor', { destroyOnUnmount: true }),
  withPropsOnChange(['initialData'], ({ paperId, initialData = {} }) => ({
    paperId: initialData.paperId || paperId,
  })),
  withPropsOnChange(['formValues'], ({ formValues = {} }) => ({
    attachments: formValues.attachments || [],
  })),
  withHandlers({
    addAttachments: ({ change, formValues: { attachments = [] } }) => async newAttachments =>
      change('attachments', _.uniq([...attachments, ...newAttachments])),
    removeAttachment: ({ formValues, change }) => attachment => {
      const { attachments = [] } = formValues
      return change('attachments', _.without(attachments, attachment))
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const {
        dispatchSubmit,
        formValues,
        sendEmail,
        paper = {},
        parentEmailId: parentId,
        onEmailSent,
        paperId,
      } = props

      const result = await dispatchSubmit(
        sendEmail({
          paperId: _.get(paper, 'id', paperId),
          ...formValues,
          parentId,
        }),
      )
      if (onEmailSent) {
        onEmailSent(result)
      }
      return message.info('Invio email in corso')
    },
    handleEditorChange: props => body => {
      props.change('body', body)
    },
    handleAddAttachmentsBtnClick: props => async () => {
      const { openAddAttachmentsModal, paperId, paper = {}, addAttachments } = props
      const attachments = await openAddAttachmentsModal({
        paperId: paperId || (paper || {}).id,
      })
      if (Array.isArray(attachments)) {
        return addAttachments(attachments)
      }
    },
    handleEmailTemplateBtnClick: props => async () => {
      const {
        openChooseEmailTemplateModal,
        getParsedEmailTemplate,
        paper = {},
        paperId,
        change,
      } = props

      const emailTemplate = await openChooseEmailTemplateModal()
      if (emailTemplate) {
        const { id } = emailTemplate
        const { body, subject } = await getParsedEmailTemplate({
          id,
          data: {
            paperId: paperId || paper.id,
          },
        })
        change('body', body)
        change('subject', subject)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      AddAttachmentsModal.preload()
      if (this.props.initialData) {
        this.props.initialize(this.props.initialData)
      }
    },
  }),
)(EmailEditor)
