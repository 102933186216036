import React from 'react'
import { List, Checkbox, Button, Input } from 'antd'
import _ from 'lodash'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'
import Modal from '../../modals/Modal'
import style from './SelectModal.style'

const Search = Input.Search

export const SelectModal = props => {
  const {
    visible,
    onCancel,
    options,
    isMultiple,
    isChecked,
    handleClick,
    handleOk,
    setSearch,
    search,
  } = props

  return (
    <Modal
      className={style}
      title="Seleziona"
      onCancel={handleOk}
      footer={<Button onClick={handleOk}>Chiudi</Button>}
      visible={visible}
    >
      <div className="SelectModal-body">
        <div className="SelectModal-search">
          <Search
            placeholder="Cerca"
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <div className="SelectModal-list">
          <List
            dataSource={options}
            renderItem={item => (
              <List.Item
                onClick={() => handleClick(item)}
                className="SelectModal-item"
              >
                {isMultiple && (
                  <Checkbox
                    className="SelectModal-item-checkbox"
                    checked={isChecked(item)}
                  />
                )}
                {item.label}
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  )
}

export default compose(
  withState('localValue', 'setLocalValue', null),
  withState('search', 'setSearch', null),
  withPropsOnChange(['value'], ({ value, setLocalValue }) => {
    return setLocalValue(value)
  }),
  withPropsOnChange(['options', 'search'], ({ options, search: s }) => {
    const search = _.trim(s, ' ')
    if (!search) return { options }
    const searchRegex = new RegExp(search, 'ig')
    return {
      options: _.filter(
        options,
        ({ label, value }) =>
          searchRegex.test(label) || searchRegex.test(value),
      ),
    }
  }),
  withHandlers({
    isChecked: props => item => {
      const { localValue } = props
      return !!_.includes(localValue, item.value)
    },
  }),
  withHandlers({
    handleClick: props => item => {
      const { isMultiple, setLocalValue, localValue, onOk, isChecked } = props
      if (!isMultiple) return onOk(item.value)
      if (isChecked(item)) {
        return setLocalValue(_.pull(localValue, item.value))
      }

      return setLocalValue([...localValue, item.value])
    },
    handleOk: props => () => {
      const { onOk, localValue } = props

      return onOk(localValue)
    },
    handleSearch: props => (e, value) => {},
  }),
)(SelectModal)
