import React, { Fragment } from 'react'
import { Form } from 'antd'
import { Field, FieldArray } from 'redux-form'
import { compose, withPropsOnChange, pure, getContext, defaultProps } from 'recompose'
import _ from 'lodash'
import PropTypes from 'prop-types'
import withReduxFormProps from '../withReduxFormProps'

export const FormItem = props => {
  const {
    error,
    errors,
    formError,
    name,
    childrenAsFunction,
    showErrorLabel,
    children,
    FieldComponent,
    disabledStatus,
    formItemLayout,
    help,
  } = props
  const theError = error || _.get(errors || formError, name)
  const validateStatus = theError ? 'error' : null

  return (
    <Fragment>
      <Form.Item
        style={{ marginBottom: 10 }}
        validateStatus={validateStatus}
        {...props}
        {...formItemLayout}
        help={(showErrorLabel && theError) || help}
        disabled={disabledStatus}
      >
        {childrenAsFunction ? (
          children(props)
        ) : (
          <FieldComponent {...props} disabled={disabledStatus}>
            {children}
          </FieldComponent>
        )}
      </Form.Item>
    </Fragment>
  )
}

export default compose(
  pure,
  defaultProps({
    withReduxFormField: true,
    withReduxFormProps: true,
    showErrorLabel: true,
  }),
  getContext({
    submitting: PropTypes.bool,
    readOnly: PropTypes.bool,
    formDisabled: PropTypes.bool,
    formError: PropTypes.object,
  }),
  withPropsOnChange(['layout'], ({ layout }) => ({
    formItemLayout:
      layout === 'horizontal'
        ? {
            labelCol: {
              xs: { span: 24 },
              sm: { span: 8 },
            },
            wrapperCol: {
              xs: { span: 24 },
              sm: { span: 16 },
              /*sm: { span: 12 },
        md: { span: 10 },*/
            },
          }
        : null,
  })),
  withPropsOnChange(['component'], ({ component, withReduxFormProps: wrap }) => ({
    component: wrap ? withReduxFormProps(component) : component,
  })),
  withPropsOnChange(['formDisabled', 'disabled'], ({ formDisabled, disabled, name }) => {
    return {
      disabledStatus: typeof disabled === 'boolean' ? disabled : formDisabled,
    }
  }),
  withPropsOnChange(
    ['isArray', 'withReduxFormField'],
    ({ isArray, withReduxFormField, component }) => ({
      FieldComponent: isArray ? FieldArray : withReduxFormField ? Field : component,
    }),
  ),
)(FormItem)
