import { injectGlobal } from 'emotion'

injectGlobal`
  form.form {
    overflow: hidden;
  }

  .FormActions {
    margin-top: 10px;
    border-top: 1px solid #e9e9e9;
    padding: 10px 0px 10px 0px;
    //text-align: right;
    border-radius: 0 0 4px 4px;
    button {
      margin-right: 10px;
    }
  }

  .ant-form-item--hideLabel .ant-form-item-label {
    visibility: hidden;
  }

  .ant-form-item-label {
    white-space: initial;
  }
`
