import React, { Fragment } from 'react'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  Form,
  Select,
  Button,
  Input,
  InputNumber,
  DatePicker,
  Checkbox,
  Divider,
  Row,
  Col,
  Alert,
} from 'antd'
import { select } from '@rematch/select'
import _ from 'lodash'
import _fp from 'lodash/fp'
import AddCommissionModal from './AddCommissionModal'
import { pushModalWindow } from 'redux-promising-modals'
import withConnectedForm from '../../../../../components/form/withConnectedForm'
import { default as TheFormItem } from 'src/components/form/FormItem'
import withFormProps from 'src/components/form/withReduxFormProps'
import OverlayComponent from 'src/components/Overlay'
import SubmitButton from 'src/components/form/SubmitButton'
import ResetButton from 'src/components/form/ResetButton'
import { openChooseAgentModal } from 'src/components/modals/ChooseAgentModal'
import CommissionsTabTranches from './CommissionsTabTranches'
import CommissionsTabList from './CommissionsTabList'
import { getPath } from 'src/store/selectors'
import CurrencyInput from 'src/components/form/CurrencyInput'
import BrDatePicker from 'src/components/form/BrDatePicker'
import Spinner from 'src/components/Spinner'

import style from './CommissionsTab.style'

const Option = Select.Option

const query = {
  filter: {
    fields: ['paperCommissions', 'hasCommissionsTranches', 'id'],
    populate: [
      {
        property: 'paperCommissions.commissionIds',
        model: 'Commission',
        on: 'commissions',
        filter: {
          include: [
            {
              relation: 'commissionRule',
              scope: {
                fields: ['id', 'name'],
              },
            },
            {
              relation: 'agent',
              scope: {
                fields: ['id', 'internalId', 'firstname', 'lastname'],
              },
            },
            {
              relation: 'office',
              scope: {
                fields: ['id', 'name'],
              },
            },
          ],
          populate: [
            {
              property: 'commissionRuleLevelValue.roleId',
              model: 'Role',
              on: 'role',
            },
            {
              property: 'commissionRuleLevelValue.groupId',
              model: 'AgentGroup',
              on: 'group',
              filter: {
                include: 'applicableToRole',
              },
            },
          ],
        },
      },
    ],
  },
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const FormItem = props => (
  <TheFormItem {...formItemLayout} {...props}>
    {props.children}
  </TheFormItem>
)

const HasCommissionsTranches = withFormProps(Checkbox, {
  children: 'Abilita tranches',
})

const HasVariableCommissionsBaseAmount = withFormProps(Checkbox, {
  children: 'Specifica importo base provvigioni',
})

const TrancheName = withFormProps(props => <Input {...props} size="medium" />)
const TrancheAmount = withFormProps(props => <CurrencyInput {...props} size="medium" />)
const CommissionValue = withFormProps(props => <CurrencyInput {...props} size="medium" />)
const CommissionStatus = withFormProps(
  Select,
  {
    size: 'medium',
    children: [
      <Option value="pending">In Lavorazione</Option>,
      <Option value="canceled">Annullato</Option>,
      <Option value="paid">Erogato</Option>,
    ],
  },
  input => {
    const { value, ...rest } = input
    return {
      ...rest,
      defaultValue: value,
    }
  },
)
const AgentSelection = props => {
  const { children } = props
  return (
    <OverlayComponent style={{ width: '100%', display: 'inline-block' }} {...props}>
      <Select value="0">
        <Option key="0">{children}</Option>
      </Select>
    </OverlayComponent>
  )
}
const DatePaid = BrDatePicker

export const CommissionsTab = props => {
  const {
    handleRemoveCommissionBtnClick,
    handleAddTrancheBtnClick,
    handleDeleteTrancheBtnClick,
    handleAddCommissionBtnClick,
    handleChooseAgent,
    submitting,
    handleSubmit,
    formValues = {},
    handleHasCommissionsTranchesChange,
    handleHasVariableCommissionsBaseAmountChange,
    formError,
    fetchData,
    handleMarkAsPaidBtnClick,
    isAdmin,
    readOnly,
    isPristine,
    isDirty,
    hasTranches,
    showManualChangesAlert,
    hasManualChanges,
  } = props

  return (
    <section className={style}>
      {/*isPristine: {JSON.stringify(isPristine)}
      <br/>
      isDirty: {JSON.stringify(isDirty)}
      <br/>
      hasManualChanges: {JSON.stringify(hasManualChanges)}*/}
      <Spinner fetchData={fetchData}>
        {showManualChangesAlert && (
          <Alert
            style={{ marginBottom: 20 }}
            type="info"
            showIcon
            message={
              <Fragment>
                Le seguenti provvigioni contengono modifiche manuali, il sistema non provvederá alla
                generazione automatica delle provvigioni
              </Fragment>
            }
          />
        )}
        <Form className="form" onSubmit={handleSubmit}>
          <Row type="flex" align="middle" justify="space-between">
            {isAdmin && (
              <Fragment>
                <Col>
                  {!hasTranches && (
                    <Button
                      onClick={handleAddCommissionBtnClick(0, {
                        isBase: true,
                        hasTranches,
                      })}
                      icon="retweet"
                      size="small"
                    >
                      Ricalcolo provvigioni
                    </Button>
                  )}
                  {hasTranches && (
                    <Button onClick={handleAddTrancheBtnClick} icon="plus" size="small">
                      Aggiungi tranche
                    </Button>
                  )}
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <FormItem
                        name={`hasCommissionsTranches`}
                        style={{ marginBottom: 0, whiteSpace: 'nowrap' }}
                        component={HasCommissionsTranches}
                        errors={formError}
                        onChange={handleHasCommissionsTranchesChange}
                      />
                    </Col>
                    <Col>
                      <FormItem
                        disabled={hasTranches}
                        name={`hasVariableCommissionsBaseAmount`}
                        style={{ marginBottom: 0, whiteSpace: 'nowrap' }}
                        component={HasVariableCommissionsBaseAmount}
                        onChange={handleHasVariableCommissionsBaseAmountChange}
                        errors={formError}
                      />
                    </Col>
                  </Row>
                </Col>
              </Fragment>
            )}
          </Row>

          <Divider />

          {!hasTranches && (
            <CommissionsTabList
              {...props}
              paper={formValues}
              isAdmin={isAdmin}
              readOnly={readOnly}
              onMarkAsPaidBtnClick={() => handleMarkAsPaidBtnClick(0)}
              Components={{
                TrancheName,
                TrancheAmount,
                AgentSelection,
                CommissionValue,
                CommissionStatus,
                DatePaid,
              }}
            />
          )}

          {hasTranches && (
            <CommissionsTabTranches
              {...props}
              paper={formValues}
              isAdmin={isAdmin}
              readOnly={readOnly}
              onMarkAsPaidBtnClick={handleMarkAsPaidBtnClick}
              onDeleteTrancheBtnClick={handleDeleteTrancheBtnClick}
              Components={{
                TrancheName,
                TrancheAmount,
                AgentSelection,
                CommissionValue,
                CommissionStatus,
                DatePaid,
              }}
            />
          )}

          {isAdmin && (
            <section className="FormActions">
              <SubmitButton />
              <ResetButton />
            </section>
          )}
        </Form>
      </Spinner>
    </section>
  )
}

export default compose(
  connect(
    state => ({
      initialValues: getPath(state)('papers.tabs.commissions.paper'),
      accountSettings: select.accountSettings.get(state),
      isAdmin: select.agents.isAdmin(state),
    }),
    {
      // openAddClientToPaperModal,
      openAddCommissionModal: props => dispatch =>
        dispatch(
          pushModalWindow('ADD_COMMISSION', {
            Component: AddCommissionModal,
            ...props,
          }),
        ),
      openChooseAgentModal,
    },
  ),
  connect(null, dispatch => ({
    updateCommissions: dispatch.papers.updateCommissions,
  })),
  withConnectedForm('commissionsTab'),
  withPropsOnChange(
    ['formValues', 'isAdmin', 'isDirty'],
    ({ formValues = {}, isAdmin, isDirty }) => {
      const hasTranches = formValues.hasCommissionsTranches
      const hasManualChanges =
        !hasTranches && (_.get(formValues, 'paperCommissions[0].hasManualChanges') || isDirty)
      const showManualChangesAlert = isAdmin && hasManualChanges

      return {
        hasTranches,
        showManualChangesAlert,
        hasManualChanges,
      }
    },
  ),
  withHandlers({
    fetchData: props => () => {
      const { findPaper, paperId } = props

      if (!paperId) return
      return findPaper(
        {
          ...query,
          filter: {
            ...query.filter,
            where: { id: paperId },
          },
        },
        { basePath: 'tabs.commissions' },
      )
    },
  }),
  withHandlers({
    handleRemoveCommissionBtnClick: props => (
      paperCommissionIndex,
      commissionIndex,
    ) => async () => {
      const { array } = props
      array.splice(`paperCommissions.${paperCommissionIndex}.commissions`, commissionIndex, 1)
    },
    handleAddTrancheBtnClick: props => async e => {
      const { array, paper } = props
      array.push('paperCommissions', { paperId: paper.id })
    },
    handleAddCommissionBtnClick: props => (
      paperCommissionIndex,
      { isBase, hasTranches = false } = {},
    ) => async () => {
      const { paper, openAddCommissionModal, array, change, formValues, initialize } = props
      const paperCommission = ((formValues || {}).paperCommissions || [])[paperCommissionIndex]
      let amount = paper.amount

      if (!isBase && paperCommission) {
        amount = paperCommission.amount
      }
      const result = await openAddCommissionModal({
        paperId: paper.id,
        amount,
        hasTranches,
      })

      const { commissions: cs, replace } = result || {}

      let commissions = [...cs]
      // work with commission status
      const markAsPaidProgressIds = _.get(
        props.accountSettings,
        'public.papers.commissions.markAsPaidProgressIds',
      )
      if (
        !_.isEmpty(markAsPaidProgressIds) &&
        markAsPaidProgressIds.includes(paper.currentProgress.progressId)
      ) {
        commissions = commissions.map(c => ({
          ...c,
          status: 'paid',
          datePaid: paper.currentProgress.date || new Date(),
        }))
      }

      if (Array.isArray(commissions)) {
        if (replace) {
          const newFormValues = _fp.set(
            'paperCommissions',
            [
              {
                commissions,
              },
            ],
            formValues,
          )
          return initialize(newFormValues)
        } else {
          commissions.forEach(ac =>
            array.push(`paperCommissions.${paperCommissionIndex}.commissions`, ac),
          )
        }
      }
    },
    handleSubmit: props => async e => {
      e.preventDefault()
      const {
        paperCommissions,
        formValues,
        dispatchSubmit,
        fetchData,
        updateCommissions,
        paper,
        hasTranches,
        hasManualChanges,
      } = props

      const data = (() => {
        if (!hasTranches && hasManualChanges) {
          return _fp.set('paperCommissions[0].hasManualChanges', true, formValues)
        }
        return formValues
      })()
      console.log(data)

      await dispatchSubmit(
        updateCommissions({
          paperId: paper.id,
          data,
        }),
      )
      fetchData()
    },
    handleChooseAgent: props => (paperCommissionIndex, commissionIndex) => {
      const { openChooseAgentModal, change, paper } = props

      return openChooseAgentModal().then(agent => {
        if (agent) {
          change(
            `paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].agentId`,
            agent.id,
          )
          change(
            `paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].agent`,
            agent,
          )
        }
      })
    },
    handleHasVariableCommissionsBaseAmountChange: props => (e, value) => {
      const { change } = props
      if (value) change('hasVariableCommissionsBaseAmount', false)
    },
    handleDeleteTrancheBtnClick: props => (paperCommission, paperCommissionIndex) => {
      const { array } = props
      return array.remove('paperCommissions', paperCommissionIndex)
    },
    handleMarkAsPaidBtnClick: props => paperCommissionIndex => {
      const { formValues, change } = props
      const paperCommission = (formValues.paperCommissions || [])[paperCommissionIndex] || {}
      const datePaid = new Date()
      const newCommissions = _.map(paperCommission.commissions, c => ({
        ...c,
        status: 'paid',
        datePaid: c.datePaid || datePaid,
      }))

      if (newCommissions.length) {
        change(`paperCommissions[${paperCommissionIndex}].commissions`, newCommissions)
      }
    },
  }),
)(CommissionsTab)
