import { css } from 'emotion'

export default css`
  & {
    .AddAttachmentsModal-files-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15p;
      .AddAttachmentsModal-file {
        position: relative;
        text-align: center;
        width: 140px;
        padding: 0 15px;
        margin-bottom: 10px;
        .AddAttachmentsModal-file-icon {
          font-size: 40px;
        }
        .AddAttachmentsModal-file-name {
          font-size: 10px;
        }
         .AddAttachmentsModal-file-actions {
          position: absolute;
          top: 0;
          left: 20px;
        }
        .AddAttachmentsModal-file-delete {
          position: absolute;
          top: 0;
          right: 20px;
        }
      }
    }
  }
`
