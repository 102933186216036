import React from 'react'
import DefaultLayout from 'src/components/layout/DefaultLayout/index'
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { pushModalWindow } from 'redux-promising-modals'
import { Input, Card, Select, Button, Row, Col, Menu, Dropdown, Icon } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import _fp from 'lodash/fp'
import withConnectedForm from 'src/components/form/withConnectedForm'
import SubmitButton from 'src/components/form/SubmitButton/index'
import ReportProfileChooseModal from './reportCreate/ReportProfileChooseModal'
import ReportProfileCreateModal from './reportCreate/ReportProfileCreateModal'
import ReportForm from '../../components/reports/ReportForm'
import withEnhancedRouter from '../../components/withEnhancedRouter'
import Spinner from '../../components/Spinner'
import withWatcher from '../../components/withWatcher'
import PageTitle from '../../components/layout/PageTitle'

const papersReportInitialValues = {
  type: 'papers',
  columns: [],
  criteria: {
    'agents.agentId': { inq: [] },
    productTypeId: { inq: [] },
    productId: { inq: [] },
    financialInstitutionId: { inq: [] },
    officeId: { inq: [] },
    progressId: { inq: [] },
    and: [
      {
        'currentProgress.date': {},
      },
      {
        'currentProgress.date': {},
      },
    ],
    // 'and[0].currentProgress.date': {},
    // 'and[1].currentProgress.date': {},
  },
}

const agentsReportInitialValues = {
  columns: [],
  criteria: {
    id: { inq: [] },
    officeId: { inq: [] },
    roleId: { inq: [] },
  },
}

const ReportProfileDropdown = props => {
  const { handleOpenReportProfileChooseModal, handleCreateReportProfileModal } = props
  const actionsDropdown = (
    <Menu>
      <Menu.Item key="0">
        <span onClick={handleOpenReportProfileChooseModal}>Carica Profilo</span>
      </Menu.Item>
      <Menu.Item key="1">
        <span onClick={handleCreateReportProfileModal}>Salva Profilo</span>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={actionsDropdown} trigger={['click']}>
      <a>
        <Icon type="setting" /> Profili Report
      </a>
    </Dropdown>
  )
}

const ReportsCreate = props => {
  const { submitting, formError, handleSubmit, fetchData, formValues } = props

  return (
    <DefaultLayout>
      <Spinner fetchData={fetchData}>
        <PageTitle title="Nuovo Report" extra={<ReportProfileDropdown {...props} />} />
        <Card>
          <form onSubmit={handleSubmit}>
            <ReportForm formError={formError} formType="report" {...props} />
            <div className="FormActions">
              <SubmitButton submitting={submitting}>Crea Report</SubmitButton>
            </div>
          </form>
        </Card>
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  withEnhancedRouter,
  connect(
    state => ({
      initialValues: {
        columns: [],
        criteria: {},
      },
    }),
    {
      openReportProfileChooseModal: props => dispatch =>
        dispatch(
          pushModalWindow('SELECT_REPORT_PROFILES', {
            Component: ReportProfileChooseModal,
            ...props,
          }),
        ),
      openCreateReportProfileModal: props => dispatch =>
        dispatch(
          pushModalWindow('CREATE_REPORT_PROFILE', {
            Component: ReportProfileCreateModal,
            ...props,
          }),
        ),
      push,
    },
  ),
  connect(
    null,
    dispatch => ({
      getReportProfile: dispatch.reportProfiles.findById,
      getReport: dispatch.reports.findById,
      create: dispatch.reports.create,
    }),
  ),
  withConnectedForm('createReport'),
  withPropsOnChange(['formValues'], ({ formValues }) => ({
    report: formValues,
  })),
  withPropsOnChange(['report'], ({ report }) => {
    if (!report) return {}
    const columns = report.columns || []
    const dataSource = [
      columns.reduce((acc, column, index) => {
        acc[index] = column
        return acc
      }, {}),
    ]

    return {
      dataSource,
    }
  }),
  withHandlers({
    initializeReportProfileData: props => reportProfile => {
      const report = reportProfile
      let criteriaPath
      let startDate
      let endDate
      const currentProgressRange = _.get(report, 'configuration.currentProgressRange')
      const commissionDatePaidRange = _.get(report, 'configuration.commissionDatePaidRange')

      if (currentProgressRange) criteriaPath = 'currentProgress.date'
      if (commissionDatePaidRange) criteriaPath = '$commission.datePaid'

      const dateRange = currentProgressRange || commissionDatePaidRange
      if (dateRange) {
        if (dateRange === 'today') {
          startDate = moment()
            .startOf('day')
            .toDate()
          endDate = moment()
            .endOf('day')
            .toDate()
        } else if (dateRange === 'currentWeek') {
          startDate = moment()
            .startOf('week')
            .toDate()
          endDate = moment()
            .endOf('week')
            .toDate()
        } else if (dateRange === 'currentMonth') {
          startDate = moment()
            .startOf('month')
            .toDate()
          endDate = moment()
            .endOf('month')
            .toDate()
        } else if (dateRange === 'currentYear') {
          startDate = moment()
            .startOf('year')
            .toDate()
          endDate = moment()
            .endOf('year')
            .toDate()
        } else if (dateRange === 'yesterday') {
          startDate = moment()
            .subtract(1, 'day')
            .startOf('day')
            .toDate()
          endDate = moment()
            .subtract(1, 'day')
            .endOf('day')
            .toDate()
        } else if (dateRange === 'pastWeek') {
          startDate = moment()
            .subtract(1, 'week')
            .startOf('week')
            .toDate()
          endDate = moment()
            .subtract(1, 'week')
            .endOf('week')
            .toDate()
        } else if (dateRange === 'pastMonth') {
          startDate = moment()
            .subtract(1, 'month')
            .startOf('month')
            .toDate()
          endDate = moment()
            .subtract(1, 'month')
            .endOf('month')
            .toDate()
        } else if (dateRange === 'pastYear') {
          startDate = moment()
            .subtract(1, 'year')
            .startOf('year')
            .toDate()
          endDate = moment()
            .subtract(1, 'year')
            .endOf('year')
            .toDate()
        }

        _.set(report, `criteria['${criteriaPath}'].between[0]`, startDate)
        _.set(report, `criteria['${criteriaPath}'].between[1]`, endDate)
      }

      return props.initialize({ ...report, name: reportProfile.name })
    },
  }),
  withHandlers({
    sanitiseReport: prop => report => {
      return _fp.flow(report => ({
        ...report,
        criteria: _.mapValues(report.criteria, cri => {
          if (cri.inq) {
            return {
              ...cri,
              inq: _.map(cri.inq, id => (_.isString(id) ? id : null)).filter(_.identity),
            }
          }

          return cri
        }),
      }))(report)
    },
  }),
  withHandlers({
    fetchData: props => async () => {
      const {
        match: { search: { reportProfileId, reportId } = {} },
        getReportProfile,
        getReport,
        initializeReportProfileData,
        initialize,
        sanitiseReport,
      } = props
      if (reportProfileId) {
        const reportProfile = await getReportProfile({ id: reportProfileId })
        return initializeReportProfileData(reportProfile)
      }
      if (reportId) {
        const report = await getReport({ id: reportId })
        const reportData = sanitiseReport(_.pick(report, ['type', 'name', 'criteria', 'columns']))

        return initialize(reportData)
      }
    },
    handleSubmit: props => async e => {
      e.preventDefault()
      const { dispatchSubmit, formValues, create, push } = props
      const report = await dispatchSubmit(create({ ...formValues, id: null }))
      if (!report.error) {
        return push(`/reports/view/${report.id}`)
      }
    },
    handleOpenReportProfileChooseModal: props => async () => {
      const reportProfile = await props.openReportProfileChooseModal()
      // if (reportProfile) return props.initializeReportProfileData(reportProfile)
      if (reportProfile) return props.push(`/reports/create?reportProfileId=${reportProfile.id}`)
    },
    handleCreateReportProfileModal: props => async () => {
      const {
        openCreateReportProfileModal,
        formValues,
        match: { search: { reportProfileId } = {} },
      } = props
      const reportProfile = await openCreateReportProfileModal({
        values: { ...formValues, id: reportProfileId },
      })
    },
  }),
  withWatcher(
    ['match.search.reportProfileId'],
    ({ match: { search: { reportProfileId } = {} }, fetchData }) => {
      if (reportProfileId) {
        return fetchData()
      }
    },
  ),
  lifecycle({
    componentWillMount() {
      this.props.initialize(papersReportInitialValues)
    },
  }),
)(ReportsCreate)
