import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { select } from '@rematch/select'
import { Button, Input, message } from 'antd'
import Modal from '../../../../../components/modals/Modal'
import withConnectedForm from '../../../../../components/form/withConnectedForm'
import FormItem from '../../../../../components/form/FormItem'
import SubmitButton from 'src/components/form/SubmitButton'

export const AddNoteModal = props => {
  const { visible, onCancel, handleSubmit, formError } = props

  return (
    <form onSubmit={handleSubmit}>
      <Modal
        title="Aggiungi Nota"
        onCancel={onCancel}
        footer={[
          <Button size="large" onClick={onCancel}>
            Annulla
          </Button>,
          <SubmitButton onClick={handleSubmit} />,
        ]}
        visible={visible}
      >
        <form onSubmit={handleSubmit}>
          <FormItem
            name="title"
            label="Titolo"
            component={Input}
            errors={formError}
            withReduxFormProps={true}
          />
          <FormItem
            name="body"
            label="Nota"
            component={Input.TextArea}
            errors={formError}
            withReduxFormProps={true}
          />
        </form>
      </Modal>
    </form>
  )
}

export default compose(
  connect(
    state => {},
    dispatch => ({
      create: dispatch.notes.create,
      createLog: dispatch.actionLogs.create,
    }),
  ),
  withConnectedForm('addNoteModal', { destroyOnUnmount: true }),
  withHandlers({
    handleSubmit: props => async e => {
      e.preventDefault()
      const {
        formValues,
        dispatchSubmit,
        create,
        paperId,
        createLog,
        onCancel,
      } = props
      const note = await dispatchSubmit(create({ ...formValues, paperId }))
      await createLog({
        context: 'paper',
        paperId,
        type: 'addedNote',
        data: {
          noteId: note.id,
        },
      })
      await onCancel()
      return true
    },
  }),
)(AddNoteModal)
