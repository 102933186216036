import React from 'react'
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose'
import { Card } from 'antd'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import withEnhancedRouter from '../../components/withEnhancedRouter'
import QuoteView from '../../components/quotes/QuoteView'
import { formatName } from '../../components/format/Name'
import PageTitle from '../../components/layout/PageTitle'

export const PreventiveView = props => {
  const { id, quote, pageTitle, internalId, handleLoaded } = props
  return (
    <DefaultLayout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PageTitle title={`Preventivo ID: ${internalId || ''}`} />
      <Card>
        <QuoteView id={id} onLoaded={handleLoaded} />
      </Card>
    </DefaultLayout>
  )
}

export default compose(
  withEnhancedRouter,
  withState('quote', 'setQuote', null),
  withHandlers({
    handleLoaded: props => quote => props.setQuote(quote),
  }),
  withPropsOnChange(['quote'], ({ quote }) => {
    if (!quote) return

    return {
      pageTitle: `Preventivo ${quote.internalId} - ${formatName(quote.client)}`,
      internalId: quote.internalId,
    }
  }),
)(PreventiveView)
