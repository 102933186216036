import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { compose, setDisplayName, withProps, defaultProps, withPropsOnChange } from 'recompose'
import { openChooseAgentModal } from 'src/components/modals/ChooseAgentModal/index'
import { select } from '@rematch/select'
import AsyncSelect from '../AsyncSelect'
import { formatName } from '../../format/Name'

const AgentSelect = props => {
  const { initialQuery, find, labelFieldFn, options, children } = props

  return (
    <>
      <AsyncSelect
        {...props}
        options={options}
        fetchMethod={find}
        initialQuery={initialQuery}
        labelField={labelFieldFn}
        valueField="id"
      />
    </>
  )
}

export default compose(
  setDisplayName('AgentSelect'),
  connect(
    state => ({
      agents: select.agents.getAll(state),
    }),
    dispatch => ({
      find: dispatch.agents.find,
      openChooseAgentModal: bindActionCreators(openChooseAgentModal, dispatch),
    }),
  ),
  withPropsOnChange(['agents', 'additionalOptions'], ({ agents, additionalOptions }) => ({
    options: _.concat(additionalOptions, agents),
  })),
  withProps({
    labelFieldFn: formatName,
  }),
  defaultProps({
    initialQuery: {
      filter: {
        fields: ['id', 'internalId', 'firstname', 'lastname', 'deletedAt'],
        order: 'lastname ASC',
        skipDeleted: true,
      },
    },
  }),
)(AgentSelect)
