import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, withPropsOnChange, withProps, withHandlers } from 'recompose'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import { message, Modal as AntdModal } from 'antd'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { select } from '@rematch/select'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import PageTitle from '../../components/layout/PageTitle'

const columns = [
  {
    displayName: 'Nome',
    field: 'name',
  },
  {
    displayName: 'Creato da',
    render(ignored, record) {
      if (!record || !record.agent) return null
      return `${record.agent.firstname} ${record.agent.lastname}`
    },
  },
  {
    displayName: 'Data',
    field: 'createdAt',
    type: 'date',
    sortable: true,
    defaultSortOrder: 'descend',
  },
  {
    displayName: '',
    type: 'actions',
    itemsType: 'link',
    actions: [
      {
        label: 'Genera report',
        props: {
          onClick: ({ record, props }) => () => {
            return props.push(`/reports/create?reportProfileId=${record.id}`)
          },
        },
      },
      {
        label: 'Modifica',
        visible: ({ record, props }) => () => props.isAdmin || record.agentId === props.me.id,
        props: {
          onClick: ({ record, props }) => () => {
            return props.push(`/reportProfiles/edit/${record.id}`)
          },
        },
      },
      {
        label: 'Elimina',
        visible: ({ record, props }) => () => props.isAdmin || record.agentId === props.me.id,
        props: {
          onClick: ({ record, props }) => () => {
            return props.handleDeletButtonClick(record)
          },
        },
      },
    ],
  },
]

const filtersConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['name'],
  },
]

export const ReportProfileList = props => {
  const {
    reportProfiles,
    findReportProfiles,
    countReportProfiles,
    handleDeletButtonClick,
    count,
    initialQuery,
    push,
    me,
    isAdmin,
  } = props
  return (
    <DefaultLayout>
      <PageTitle
        title="Profili reports"
      />
      <div className="u-bgWhite">
        <SmartTable
          data={reportProfiles}
          columns={columns}
          initialQuery={initialQuery}
          filtersConfig={filtersConfig}
          count={count}
          countMethod={countReportProfiles}
          findMethod={findReportProfiles}
          handleDeletButtonClick={handleDeletButtonClick}
          push={push}
          me={me}
          isAdmin={isAdmin}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      reportProfiles: select.reportProfiles.getAll(state),
      count: select.reportProfiles.count(state),
      me: _.get(select.agents.me(state), 'me', {}),
      isAdmin: select.agents.isAdmin(state),
    }),
    dispatch => ({
      findReportProfiles: dispatch.reportProfiles.find,
      countReportProfiles: dispatch.reportProfiles.count,
      deleteReportProfile: dispatch.reportProfiles.delete,
      push: bindActionCreators(push, dispatch),
    }),
  ),
  withProps({
    initialQuery: {
      filter: {
        include: ['agent'],
        skipDeleted: true,
      },
    },
  }),
  withHandlers({
    handleDeletButtonClick: props => reportProfile => {
      AntdModal.confirm({
        title: 'Elimina Profilo Report',
        content: `Sei sicuro di voler eliminare il Profilo Report "${reportProfile.name}"?`,
        onOk: async () => {
          await props.deleteReportProfile({ id: reportProfile.id })
          await props.findReportProfiles(props.initialQuery)
          message.success('Profilo eliminato')
        },
      })
    },
  }),
  withRouter,
)(ReportProfileList)
