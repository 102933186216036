import React from 'react'
import { Select as TheSelect } from 'antd'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import { compose, lifecycle, withHandlers, defaultProps } from 'recompose'
import withFormProps from 'src/components/form/withReduxFormProps'

const Option = TheSelect.Option
const Select = withFormProps(TheSelect)
const RoleSelect = props => {
  const { roles = [] } = props

  return (
    <Select {...props}>
      {roles.map(role => (
        <Option key={role.id} value={role.id}>{role.displayName}</Option>
      ))}
    </Select>
  )
}


export default compose(
  connect(
    state => ({
      roles: select.roles.getAll(state),
    }),
    dispatch => ({
      find: dispatch.roles.find,
    }),
  ),
  defaultProps({
    query: {
      filter: { order: 'name ASC', skipDeleted: true },
    },
  }),
  withHandlers({
    fetchData: props => () => {
      props.find(props.query)
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchData()
    },
  }),
)(RoleSelect)
