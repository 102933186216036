import _get from 'lodash/get'
import _fp from 'lodash/fp'

export const LOGIN_REQUESTED = (state, payload) => ({
  ...state,
  token: null,
  isLoginPending: true,
  isAuthenticated: false,
  isLoginFailed: false,
})

export const LOGIN_FULFILLED = (state, payload) => ({
  ...state,
  token: payload.id || payload.token,
  isLoginPending: false,
  isAuthenticated: true,
  isLoginFailed: false,
})

export const LOGIN_FAILED = (state, payload) => ({
  ...state,
  token: null,
  isLoginPending: false,
  isAuthenticated: false,
  isLoginFailed: _get(payload, 'response.data.error'),
})

export const LOGOUT_REQUESTED = (state, payload) => ({
  ...state,
  isLogoutPending: true,
  isLogoutFailed: false,
})

export const LOGOUT_FULFILLED = (state, payload) => ({
  ...state,
  token: null,
  isLogoutPending: false,
  isAuthenticated: false,
  isLogoutFailed: false,
})

export const LOGOUT_FAILED = (state, payload) => ({
  ...state,
  token: null,
  isLogoutPending: false,
  isAuthenticated: false,
  isLogoutFailed: _get(payload, 'response.data.error'),
})

export const GET_ME_REQUESTED = (state, payload) => ({
  ...state,
  me: {
    ...state.me,
    isFetching: true,
  },
})

export const GET_ME_FULFILLED = (state, payload) => ({
  ...state,
  me: {
    ...state.me,
    isFetching: false,
    isFailed: false,
    me: payload,
  },
})

export const GET_ME_FAILED = (state, payload) => ({
  ...state,
  me: {
    ...state.me,
    isFetching: false,
    isFailed: _get(payload, 'response.data.error'),
  },
})

export const SEND_FORGOT_PASSWORD_REQUESTED = (state, payload) =>
  _fp.set(
    'forgotPassword',
    {
      isFetching: true,
      data: null,
    },
    state,
  )

export const SEND_FORGOT_PASSWORD_FULFILLED = (state, payload) =>
  _fp.set(
    'forgotPassword',
    {
      isFetching: false,
      isFailed: false,
      isSent: true,
    },
    state,
  )

export const SEND_FORGOT_PASSWORD_FAILED = (state, payload) => ({
  ...state,
  forgotPassword: {
    ...state.forgotPassword,
    isFetching: false,
    isFailed: _get(payload, 'response.data.error'),
  },
})

export const CHANGE_PASSWORD_REQUESTED = (state, payload) =>
  _fp.set(
    'changedPassword',
    {
      isFetching: true,
      data: null,
    },
    state,
  )

export const CHANGE_PASSWORD_FULFILLED = (state, payload) =>
  _fp.set(
    'changedPassword',
    {
      isFetching: false,
      isFailed: false,
      isSent: true,
    },
    state,
  )

export const CHANGE_PASSWORD_FAILED = (state, payload) => ({
  ...state,
  changedPassword: {
    ...state.changedPassword,
    isFetching: false,
    isFailed: _get(payload, 'response.data.error'),
  },
})

export const FETCH_VALID_AGENT_DOCUMENT_TYPES_REQUESTED = (state, payload) => ({
  ...state,
  documentTypes: {
    ...state.documentTypes,
    isFetching: true,
  },
})

export const FETCH_VALID_AGENT_DOCUMENT_TYPES_FULFILLED = (state, payload) => ({
  ...state,
  documentTypes: {
    ...state.documentTypes,
    data: payload,
    isFetching: false,
    isFailed: false,
  },
})
export const FETCH_VALID_AGENT_DOCUMENT_TYPES_FAILED = (state, payload) => ({
  ...state,
  documentTypes: {
    ...state.documentTypes,
    isFetching: false,
    isFailed: payload,
  },
})
