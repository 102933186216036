import React from 'react'
import Select from '../Select'

const options = [
  { value: 12, label: 'Mensile' },
  { value: 6, label: 'Bimestrale' },
  { value: 2, label: 'Semestrale' },
  { value: 1, label: 'Annuale' },
]
const AnnualInstalmentsSelect = props => (
  <Select
    options={options}
    {...props}
  />
)


export default AnnualInstalmentsSelect
