import React from 'react'
import { Button, Icon, Input } from 'antd'
import { yearSelect } from './YearSelect.style'
import { compose, withPropsOnChange, withHandlers, defaultProps, withState } from 'recompose'
import moment from 'moment'

const YearSelect = props => {
  const {
    localYear,
    isNextBtnDisabled,
    handleYearInputChange,
    handleYearInputValidation,
    handlePrevBtnClick,
    handleNextBtnClick,
  } = props
  return (
    <yearSelect.Wrapper>
      <Input.Group compact style={{ width: 'auto' }}>
        <Button style={{ width: '25%' }} onClick={handlePrevBtnClick}>
          <Icon type="left" />
        </Button>

        <Input
          className={yearSelect.inputStyle}
          value={localYear}
          onChange={handleYearInputChange}
          onBlur={handleYearInputValidation}
          onPressEnter={handleYearInputValidation}
        />

        <Button style={{ width: '25%' }} onClick={handleNextBtnClick} disabled={isNextBtnDisabled}>
          <Icon type="right" />
        </Button>
      </Input.Group>
    </yearSelect.Wrapper>
  )
}

export default compose(
  defaultProps({ onChange: () => {} }),
  withPropsOnChange(['year'], ({ year }) => {
    const momentYear = moment(year)
    const currentYear = moment().startOf('year')
    if (!momentYear.isValid()) return

    return {
      isNextBtnDisabled: currentYear.diff(momentYear) === 0,
    }
  }),
  withState('localYear', 'setLocalYear', null),
  withPropsOnChange(['year'], ({ year, setLocalYear }) => setLocalYear(year)),
  withHandlers({
    handlePrevBtnClick: props => () => {
      const { year, onChange } = props

      return onChange(
        moment(year)
          .subtract(1, 'year')
          .format('YYYY'),
      )
    },
    handleNextBtnClick: props => () => {
      const { year, onChange } = props

      return onChange(
        moment(year)
          .add(1, 'year')
          .format('YYYY'),
      )
    },
    handleYearInputChange: ({ setLocalYear }) => ({ target: { value } }) => setLocalYear(value),
    handleYearInputValidation: ({ year, localYear, setLocalYear, onChange }) => () => {
      if (year === localYear) return
      const momentLocalYear = moment(localYear)
      if (momentLocalYear.isValid() && momentLocalYear.isAfter(moment('1990'))) {
        return onChange(localYear)
      }

      return setLocalYear(year)
    },
  }),
)(YearSelect)
