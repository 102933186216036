import merge from 'lodash/fp/merge'

export const SEARCH_INSURANCE_PREVENTIVES_REQUESTED = (state) => merge({
  searchInsurancePreventives: {
    isFetchingInsuranceEstimations: true,
    isFetchingInsuranceEstimationsFailed: false,
  },
}, state)

export const SEARCH_INSURANCE_PREVENTIVES_FULFILLED = (state, payload) => merge({
  searchInsurancePreventives: {
    results: payload,
    isFetchingInsuranceEstimations: false,
    isFetchingInsuranceEstimationsFailed: false,
  },
}, state)

export const SEARCH_INSURANCE_PREVENTIVES_FAILED = (state, payload) => merge({
  searchInsurancePreventives: {
    results: null,
    isFetchingInsuranceEstimations: false,
    isFetchingInsuranceEstimationsFailed: payload,
  },
}, state)
