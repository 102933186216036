import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, withPropsOnChange } from 'recompose'
import { withRouter } from 'react-router'
import _ from 'lodash'
import { select } from '@rematch/select'
import { bindActionCreators } from 'redux'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import PageTitle from '../../components/layout/PageTitle'

const columns = [
  {
    displayName: 'Nome',
    field: 'name',
  },
  {
    displayName: 'Creato da',
    render(ignored, record) {
      if (!record || !record.agent) return null
      return `${record.agent.firstname} ${record.agent.lastname}`
    },
  },
  {
    displayName: 'Data',
    field: 'updatedAt',
    sortable: true,
    defaultSortOrder: 'descend',
  },
  {
    displayName: '',
    type: 'actions',
    itemsType: 'link',
    actions: [
      {
        label: 'Visualizza',
        props: {
          href: ({ record }) => `/#/reports/view/${record.id}`,
        },
      },
      {
        label: 'Usa per nuovo report',
        visible: ({ record, props }) => () => props.isAdmin || record.agentId === props.me.id,
        props: {
          href: ({ record }) => `/#/reports/create?reportId=${record.id}`,
        },
      },
      /*{
        label: 'Elimina',
        visible: ({ record, props }) => () => props.isAdmin || record.agentId === props.me.id,
        props: {
          onClick: ({ record, props }) => () => {
            return props.handleDeletButtonClick(record)
          },
        },
      },*/
    ],
  },
]

const filtersConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['name'],
  },
]

export const ReportsList = props => {
  const {
    reports,
    findReports,
    countReports,
    count,
    initialQuery,
    downloadFromUrl,
    me,
    isAdmin,
  } = props
  return (
    <DefaultLayout>
      <PageTitle title="Lista Reports" />
      <div className="u-bgWhite">
        <SmartTable
          data={reports}
          columns={columns}
          initialQuery={initialQuery}
          filtersConfig={filtersConfig}
          count={count}
          countMethod={countReports}
          findMethod={findReports}
          downloadFromUrl={downloadFromUrl}
          me={me}
          isAdmin={isAdmin}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      reports: select.reports.getAll(state),
      count: select.reports.count(state),
      me: _.get(select.agents.me(state), 'me', {}),
      isAdmin: select.agents.isAdmin(state),
    }),
    dispatch => ({
      findReports: dispatch.reports.find,
      countReports: dispatch.reports.count,
      downloadFromUrl: dispatch.files.downloadFileFromUrl,
    }),
  ),
  withPropsOnChange(['match'], ({ match }) => {
    const type = match.path.split('/').pop()
    const include = ['agent']
    if (['agents', 'papers'].includes(type)) {
      return {
        initialQuery: {
          filter: {
            where: { type },
            include,
          },
        },
      }
    }
    return {
      initialQuery: {
        filter: { include },
      },
    }
  }),
  withRouter,
)(ReportsList)
