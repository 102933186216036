import _fp from 'lodash/fp'

export const FETCH_PATH_REQUESTED = state =>
  _fp.extend(state, {
    browse: {
      isFetching: true,
      isFailed: false,
    },
  })

export const FETCH_PATH_FULFILLED = (state, payload) =>
  _fp.extend(state, {
    browse: {
      isFetching: false,
      isFailed: false,
      path: payload.path,
      content: payload.content,
      ancestors: payload.ancestors,
    },
  })

export const FETCH_PATH_FAILED = (state, payload) =>
  _fp.extend(state, {
    browse: {
      isFetching: false,
      isFailed: payload,
      path: null,
    },
  })
