import { createSelector } from 'reselect'
import _set from 'lodash/fp/set'
import flow from 'lodash/fp/flow'
import _get from 'lodash/get'
import identity from 'lodash/identity'
import invert from 'lodash/invert'
import map from 'lodash/map'
import pick from 'lodash/pick'
import isObject from 'lodash/isObject'
import concat from 'lodash/concat'
import omit from 'lodash/omit'
import { dispatch } from '@rematch/core'

const createStoreModule = ({
  name,
  pluralName: pluralN,
  httpPath,
  provider,
  state,
  effects: additionalEffects,
  reducers: additionalReducers,
  selectors: additionalSelectors,
  subscriptions,
}) => {
  const nameCamel = camelize(name)
  const pluralName = pluralN || `${name}s`
  const pluralNameCamel = camelize(pluralName)

  const initialState = {
    [pluralName]: [],
    isFetching: false,
    [name]: null,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    ...state,
  }
  const types = createActionsTypes({ name, pluralName })
  const typesDictionary = invert(types)
  const effects = createEffects({
    name,
    pluralName,
    types,
    typesDictionary,
    httpPath,
    getProvider: getProviderInstance(provider),
  })
  const reducers = createReducers({
    name,
    pluralName,
    initialState,
    types,
  })
  const selectors = createSelectors({ name, pluralName })

  return {
    state: initialState,
    actionsTypes: types,
    effects: {
      ...effects,
      ...additionalEffects,
    },
    reducers: {
      ...reducers,
      ...additionalReducers,
    },
    selectors: {
      ...selectors,
      ...additionalSelectors,
    },
    subscriptions,
    getProvider: getProviderInstance(provider),
  }
}

export default createStoreModule

const createActionsTypes = ({ name, pluralName }) => {
  const nameUppercase = name.toUpperCase()
  const pluralNameUppercase = pluralName.toUpperCase()

  return {
    FIND_REQUESTED: `FIND_${pluralNameUppercase}_REQUESTED`,
    FIND_FULFILLED: `FIND_${pluralNameUppercase}_FULFILLED`,
    FIND_FAILED: `FIND_${pluralNameUppercase}_FAILED`,

    CLEAR_ALL: `CLEAR_${pluralNameUppercase}`,

    COUNT_REQUESTED: `COUNT_${pluralNameUppercase}_REQUESTED`,
    COUNT_FULFILLED: `COUNT_${pluralNameUppercase}_FULFILLED`,
    COUNT_FAILED: `COUNT_${pluralNameUppercase}_FAILED`,

    FIND_ITEM_REQUESTED: `FIND_${nameUppercase}_REQUESTED`,
    FIND_ITEM_FULFILLED: `FIND_${nameUppercase}_FULFILLED`,
    FIND_ITEM_FAILED: `FIND_${nameUppercase}_FAILED`,

    GET_ITEM_REQUESTED: `GET_${nameUppercase}_REQUESTED`,
    GET_ITEM_FULFILLED: `GET_${nameUppercase}_FULFILLED`,
    GET_ITEM_FAILED: `GET_${nameUppercase}_FAILED`,

    CLEAR_ITEM: `CLEAR_${nameUppercase}`,

    CREATE_ITEM_REQUESTED: `CREATE_${nameUppercase}_REQUESTED`,
    CREATE_ITEM_FULFILLED: `CREATE_${nameUppercase}_FULFILLED`,
    CREATE_ITEM_FAILED: `CREATE_${nameUppercase}_FAILED`,

    UPDATE_ITEM_REQUESTED: `UPDATE_${nameUppercase}_REQUESTED`,
    UPDATE_ITEM_FULFILLED: `UPDATE_${nameUppercase}_FULFILLED`,
    UPDATE_ITEM_FAILED: `UPDATE_${nameUppercase}_FAILED`,

    UPDATE_REQUESTED: `UPDATE_${pluralNameUppercase}_REQUESTED`,
    UPDATE_FULFILLED: `UPDATE_${pluralNameUppercase}_FULFILLED`,
    UPDATE_FAILED: `UPDATE_${pluralNameUppercase}_FAILED`,

    DELETE_ITEM_REQUESTED: `DELETE_${nameUppercase}_REQUESTED`,
    DELETE_ITEM_FULFILLED: `DELETE_${nameUppercase}_FULFILLED`,
    DELETE_ITEM_FAILED: `DELETE_${nameUppercase}_FAILED`,

    EXISTS_ITEM_REQUESTED: `EXISTS_${nameUppercase}_REQUESTED`,
    EXISTS_ITEM_FULFILLED: `EXISTS_${nameUppercase}_FULFILLED`,
    EXISTS_ITEM_FAILED: `EXISTS_${nameUppercase}_FAILED`,

    OBSERVE_REQUESTED: `OBSERVE_${pluralNameUppercase}_REQUESTED`,
    OBSERVE_FULFILLED: `OBSERVE_${pluralNameUppercase}_FULFILLED`,
    OBSERVE_RECEIVE: `OBSERVE_${pluralNameUppercase}_RECEIVE`,
    OBSERVE_FAILED: `OBSERVE_${pluralNameUppercase}_FAILED`,

    OBSERVE_ITEM_REQUESTED: `OBSERVE_${nameUppercase}_REQUESTED`,
    OBSERVE_ITEM_FULFILLED: `OBSERVE_${nameUppercase}_FULFILLED`,
    OBSERVE_ITEM_RECEIVE: `OBSERVE_${nameUppercase}_RECEIVE`,
    OBSERVE_ITEM_FAILED: `OBSERVE_${nameUppercase}_FAILED`,
  }
}

const createEffects = ({ name, pluralName, types, typesDictionary, httpPath, getProvider }) => {
  const inFlightEffects = {}

  const find = (payload, rootState, meta) => {
    const actionTypes = [types.FIND_REQUESTED, types.FIND_FULFILLED, types.FIND_FAILED]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'find',
      types: actionTypes,
    })
  }

  const count = (payload, rootState, meta) => {
    const actionTypes = [types.COUNT_REQUESTED, types.COUNT_FULFILLED, types.COUNT_FAILED]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'count',
      types: actionTypes,
    })
  }

  const findById = (payload, rootState, meta) => {
    const actionTypes = [types.GET_ITEM_REQUESTED, types.GET_ITEM_FULFILLED, types.GET_ITEM_FAILED]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'findById',
      types: actionTypes,
    })
  }

  const findOne = (payload, rootState, meta) => {
    const actionTypes = [
      types.FIND_ITEM_REQUESTED,
      types.FIND_ITEM_FULFILLED,
      types.FIND_ITEM_FAILED,
    ]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'findOne',
      types: actionTypes,
    })
  }

  const create = (payload, rootState, meta) => {
    const actionTypes = [
      types.CREATE_ITEM_REQUESTED,
      types.CREATE_ITEM_FULFILLED,
      types.CREATE_ITEM_FAILED,
    ]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'create',
      types: actionTypes,
    })
  }

  const update = (payload, rootState, meta) => {
    const actionTypes = [
      types.UPDATE_ITEM_REQUESTED,
      types.UPDATE_ITEM_FULFILLED,
      types.UPDATE_ITEM_FAILED,
    ]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'update',
      types: actionTypes,
    })
  }

  const updateAll = (payload, rootState, meta) => {
    const actionTypes = [types.UPDATE_REQUESTED, types.UPDATE_FULFILLED, types.UPDATE_FAILED]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'updateAll',
      types: actionTypes,
    })
  }

  const _delete = (payload, rootState, meta) => {
    const actionTypes = [
      types.DELETE_ITEM_REQUESTED,
      types.DELETE_ITEM_FULFILLED,
      types.DELETE_ITEM_FAILED,
    ]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'delete',
      types: actionTypes,
    })
  }

  const exists = (payload, rootState, meta) => {
    const actionTypes = [
      types.DELETE_ITEM_REQUESTED,
      types.DELETE_ITEM_FULFILLED,
      types.DELETE_ITEM_FAILED,
    ]
    return createEffect({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'delete',
      types: actionTypes,
    })
  }

  const observe = (payload, rootState, meta) => {
    const actionTypes = [
      types.OBSERVE_REQUESTED,
      types.OBSERVE_FULFILLED,
      types.OBSERVE_FAILED,
      types.OBSERVE_RECEIVE,
    ]
    return createObserve({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'observe',
      types: actionTypes,
      getProvider,
    })
  }

  const observeOne = (payload, rootState, meta) => {
    const actionTypes = [
      types.OBSERVE_ITEM_REQUESTED,
      types.OBSERVE_ITEM_FULFILLED,
      types.OBSERVE_ITEM_FAILED,
      types.OBSERVE_ITEM_RECEIVE,
    ]
    return createObserve({
      payload,
      rootState,
      meta,
      httpPath,
      method: 'observeOne',
      types: actionTypes,
      getProvider,
    })
  }

  function parseActionPayload(actionType, payload) {
    const parseList = {
      [types.FIND_REQUESTED]: payload => ({ query: payload }),
      [types.FIND_ITEM_REQUESTED]: payload => ({ query: payload }),
      [types.COUNT_REQUESTED]: payload => ({ query: payload }),
      [types.COUNT_REQUESTED]: payload => ({ query: payload }),
      [types.CREATE_ITEM_REQUESTED]: payload => ({ data: payload }),
      [types.UPDATE_ITEM_REQUESTED]: payload => ({ data: payload }),
    }

    return (parseList[actionType] && parseList[actionType](payload)) || payload
  }

  async function createEffect({ payload, meta: theMeta, method, types, httpPath, basePath }) {
    const meta = { types, basePath, ...theMeta }
    const methodFn = async payload => {
      const providerInstance = await getProvider()
      const parsedPayload = parseActionPayload(types[0], payload)

      return providerInstance[method]({
        resourceName: name,
        resourcePluralName: pluralName,
        ...parsedPayload,
        basePath: httpPath,
      })
    }

    const doEffect = async () => {
      try {
        await (dispatch[pluralName] || dispatch[name])[types[0]](payload, meta)
        const { data } = await methodFn(payload)
        await (dispatch[pluralName] || dispatch[name])[types[1]](data, meta)
        return data
      } catch (err) {
        await (dispatch[pluralName] || dispatch[name])[types[2]](err, meta)
        throw err
      }
    }

    const stringifiedEffect = JSON.stringify({ payload, meta, method, types, httpPath, basePath })
    const inflightEffect = inFlightEffects[stringifiedEffect] || doEffect()
    inFlightEffects[stringifiedEffect] = inflightEffect

    try {
      return await inflightEffect
    } catch (err) {
      throw err
    } finally {
      delete inFlightEffects[stringifiedEffect]
    }
  }

  async function createObserve({ payload, rootState, meta, method, types, basePath }) {
    const providerInstance = await getProvider()
    if (typeof providerInstance[method] !== 'function') {
      const error = new Error('The current transport does not support "observe"')
      return (dispatch[pluralName] || dispatch[name])[types[2]](error, meta)
    }

    await (dispatch[pluralName] || dispatch[name])[types[0]](payload, meta)

    const onData = ({ data }) => (dispatch[pluralName] || dispatch[name])[types[3]](data, meta)

    const onError = error => (dispatch[pluralName] || dispatch[name])[types[2]](error, meta)

    const result = await providerInstance[method]({
      resourceName: name,
      resourcePluralName: pluralName,
      query: payload,
    })

    const { observable, data } = result
    await (dispatch[pluralName] || dispatch[name])[types[1]](payload, meta)

    return {
      observable,
      subscription: observable.subscribe(onData, onError),
      data,
    }
  }

  return {
    find,
    count,
    findById,
    findOne,
    create,
    update,
    updateAll,
    delete: _delete,
    exists,
    observe,
    observeOne,
  }
}

const createReducers = ({
  name,
  pluralName,
  initialState,
  types,
  additionalReducers,
  options = {},
}) => {
  const applyStateAtPath = (key, data, meta = {}, applyProjection, checkKey) => state => {
    if (meta.skipCrudReducers) return state

    const theKey = checkKey ? meta.key || key : key
    let fullMountPath = theKey
    if (meta.mountPath) {
      fullMountPath = meta.mountPath
    } else if (meta.basePath) {
      fullMountPath = `${meta.basePath}.${theKey}`
    }

    let theData = data
    if (applyProjection && meta.projection) {
      if (isObject(data)) {
        theData = Array.isArray(meta.projection)
          ? pick(data, meta.projection)
          : _get(data, meta.projection)
      } else if (Array.isArray(data)) {
        theData = Array.isArray(meta.projection)
          ? pick(data, meta.projection)
          : map(data, item => _get(item, meta.projection))
      } else {
        theData = data
      }
    }

    if (meta.updateMethod === 'concat') {
      const currentData = _get(state, fullMountPath, [])
      theData = concat([], currentData, theData)
    }

    return _set(fullMountPath, theData, state)
  }

  const reducers = {
    // find
    [types.FIND_REQUESTED]: (state, payload, meta) => {
      return applyStateAtPath('isFetching', true, omit(meta, ['updateMethod']))(state)
      // applyStateAtPath(state, pluralName, null, meta)
    },
    [types.FIND_FULFILLED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetching', false, omit(meta, ['updateMethod'])),
        applyStateAtPath('isFetchingFailed', false, omit(meta, ['updateMethod'])),
        applyStateAtPath(pluralName, payload, meta, true, true),
      )(state)
    },
    [types.FIND_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetching', false, omit(meta, ['updateMethod'])),
        applyStateAtPath('isFetchingFailed', true, meta),
      )(state)
    },

    [types.CLEAR_ALL]: (state, payload, meta) => {
      return applyStateAtPath(pluralName, [], meta, true, true)(state)
    },

    // count
    [types.COUNT_REQUESTED]: (state, payload, meta) => {
      return applyStateAtPath('isFetchingCount', true, meta)(state)
    },
    [types.COUNT_FULFILLED]: (state, payload, meta) => {
      const count = payload.count
      return flow(
        applyStateAtPath('isFetchingCount', false, meta),
        applyStateAtPath('isFetchingCountFailed', false, meta),
        applyStateAtPath('count', count, meta),
      )(state)
    },
    [types.COUNT_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingCount', false, meta),
        applyStateAtPath('isFetchingCountFailed', true, meta),
      )(state)
    },

    // find item
    [types.FIND_ITEM_REQUESTED]: (state, payload, meta) => {
      return applyStateAtPath('isFetchingItem', true, meta)(state)
      // applyStateAtPath(name, null, meta)
    },
    [types.FIND_ITEM_FULFILLED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingItem', false, meta),
        applyStateAtPath(name, payload, meta, true, true),
      )(state)
    },
    [types.FIND_ITEM_FAILED]: (state, { payload: error, meta }) => {
      return flow(
        applyStateAtPath('isFetchingItem', false, meta),
        applyStateAtPath('isFetchingItemFailed', error, meta),
      )(state)
    },

    // get item
    [types.GET_ITEM_REQUESTED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingItem', true, meta),
        // applyStateAtPath(name, null, meta),
      )(state)
    },
    [types.GET_ITEM_FULFILLED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingItem', false, meta),
        applyStateAtPath(name, payload, meta),
      )(state)
    },
    [types.GET_ITEM_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingItem', false, meta),
        applyStateAtPath('isFetchingItemFailed', true, meta),
      )(state)
    },

    [types.CLEAR_ITEM]: (state, payload, meta) => {
      return applyStateAtPath(name, null, meta)(state)
    },

    // create
    [types.CREATE_ITEM_REQUESTED]: (state, payload, meta) => {
      return applyStateAtPath('isCreatingItem', true, meta)(state)
    },
    [types.CREATE_ITEM_FULFILLED]: (state, payload, meta) => {
      return applyStateAtPath('isCreatingItem', false, meta)(state)
    },
    [types.CREATE_ITEM_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isCreatingItem', false, meta),
        applyStateAtPath('isCreatingItemFailed', true, meta),
      )(state)
    },

    // update
    [types.UPDATE_ITEM_REQUESTED]: (state, payload, meta) => {
      return applyStateAtPath('isUpdatingItem', true, meta)(state)
    },
    [types.UPDATE_ITEM_FULFILLED]: (state, payload, meta) => {
      return applyStateAtPath('isUpdatingItem', false, meta)(state)
    },
    [types.UPDATE_ITEM_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isUpdatingItem', false, meta),
        applyStateAtPath('isUpdatingItemFailed', true, meta),
      )(state)
    },

    // updateAll
    [types.UPDATE_REQUESTED]: (state, payload, meta) => {
      return applyStateAtPath('isUpdating', true, meta)(state)
    },
    [types.UPDATE_FULFILLED]: (state, payload, meta) => {
      return applyStateAtPath('isUpdating', false, meta)(state)
    },
    [types.UPDATE_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isUpdating', false, meta),
        applyStateAtPath('isUpdatingFailed', true, meta),
      )(state)
    },

    [types.DELETE_ITEM_REQUESTED]: state => state,
    [types.DELETE_ITEM_FULFILLED]: state => state,
    [types.DELETE_ITEM_FAILED]: state => state,

    [types.EXISTS_ITEM_REQUESTED]: (state, payload, meta) => {},
    [types.EXISTS_ITEM_FULFILLED]: state => {
      return applyStateAtPath('exists', true)(state)
    },
    [types.EXISTS_ITEM_FAILED]: state => {
      return applyStateAtPath('exists', false)(state)
    },

    [types.OBSERVE_REQUESTED]: (state, payload, meta) => {
      // applyStateAtPath(state, pluralName, null, meta)
      return applyStateAtPath('isFetching', true, meta)(state)
    },
    [types.OBSERVE_FULFILLED]: (state, payload, meta) => {
      // applyStateAtPath('isFetching', true, meta)
      // applyStateAtPath(state, pluralName, null, meta)
      return state
    },
    [types.OBSERVE_RECEIVE]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetching', false, meta),
        applyStateAtPath('isFetchingFailed', false, meta),
        applyStateAtPath(pluralName, payload, meta, true, true),
      )(state)
    },
    [types.OBSERVE_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetching', false, meta),
        applyStateAtPath('isFetchingFailed', true, meta),
      )(state)
    },

    [types.OBSERVE_ITEM_REQUESTED]: (state, payload, meta) => {
      // applyStateAtPath(name, null, meta)
      return applyStateAtPath('isFetchingItem', true, meta)(state)
    },
    [types.OBSERVE_ITEM_FULFILLED]: (state, payload, meta) => {
      // const { data } = payload
      // applyStateAtPath('isFetchingItem', false, meta)
      // applyStateAtPath(name, data, meta)
      return state
    },
    [types.OBSERVE_ITEM_RECEIVE]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingItem', false, meta),
        applyStateAtPath(name, payload[0], meta),
      )(state)
    },
    [types.OBSERVE_ITEM_FAILED]: (state, payload, meta) => {
      return flow(
        applyStateAtPath('isFetchingItem', false, meta),
        applyStateAtPath('isFetchingItemFailed', true, meta),
      )(state)
    },
  }

  return reducers

  return (state = initialState, action = {}) => {
    const { type, meta = {} } = action
    if (!meta.skipCrudReducers && reducers[type]) {
      reducers[type](state, action)
      return { ...state }
    }

    if (typeof additionalReducers === 'function') {
      return additionalReducers(state, action)
    }

    return state
  }
}

/**
 * Method to create the getters
 * @param name
 * @param pluralName
 * @returns {{}}
 */
const createSelectors = ({ name, pluralName }) => {
  const getWithBasePath = fieldPath => (state, props = {}) => {
    const { basePath } = props
    const fullPath = basePath ? `${basePath}.${fieldPath}` : fieldPath
    return _get(state, fullPath)
  }

  return {
    get: createSelector(
      getWithBasePath(name),
      identity,
    ),
    isFetching: createSelector(
      getWithBasePath(`isFetching`),
      identity,
    ),
    isFetchingItem: createSelector(
      getWithBasePath(`isFetchingItem`),
      identity,
    ),
    isFetchingItemFailed: createSelector(
      getWithBasePath(`isFetchingItemFailed`),
      identity,
    ),
    getAll: createSelector(
      getWithBasePath(pluralName),
      identity,
    ),
    count: createSelector(
      getWithBasePath(`count`),
      identity,
    ),
    exists: createSelector(
      getWithBasePath(`exists`),
      identity,
    ),
    /*[`${pluralName}Hash`]: state =>
      (state[pluralName] || []).reduce((hash, value) => {
        hash[value.id] = value
        return hash
      }, {}),
    [`isFetching${nameCamel}`]: state => state.isFetchingItem,
    ,*/
  }
}

const getRequiredProviderMethods = () => [
  'find',
  'count',
  'findOne',
  'findById',
  'create',
  'update',
  'updateAll',
  'delete',
  'exists',
  'makeRequest',
]

const validateProvider = provider => {
  const requiredMethods = getRequiredProviderMethods()

  const notImplementedMethods = requiredMethods.filter(
    methodString => typeof provider[methodString] !== 'function',
  )

  if (notImplementedMethods.length > 0) {
    throw new Error(
      `
            Invalid provider instance. 
            The provided provider instance does not implement 
            the following required methods: `,
      notImplementedMethods.join(', '),
    )
  }

  provider._isValidProvider = true
  return provider
}

function getProviderInstance(provider) {
  return async () => {
    let _provider = provider
    if (typeof provider === 'function') {
      _provider = await provider()
    }

    return _provider._isValidProvider ? _provider : validateProvider(_provider)
  }
}

function camelize(str) {
  const _str = str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase(),
    )
    .replace(/\s+/g, '')

  return _str.charAt(0).toUpperCase() + _str.slice(1)
}
