// import httpProvider from '../../../lib/httpProviderInstance'
import { dispatch } from '@rematch/core'
import observeEndpoint from 'src/store/actions/observeEndpoint'

export const observeTask = async ({ queueName, uuid }) => {
  return dispatch(
    observeEndpoint({
      path: `/queues/${queueName}/${uuid}`,
      lifecycle: [
        `queues/OBSERVE_TASK_REQUESTED`,
        `queues/OBSERVE_TASK_FULFILLED`,
        `queues/OBSERVE_TASK_FAILED`,
        `queues/OBSERVE_TASK_RECEIVED`,
      ],
    }),
  )
}
