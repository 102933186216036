import BudgetEdit from './BudgetEdit'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'budget.edit',
    path: '/budget/edit',
    component: withCheckAuthOnEnter(withTracker()(BudgetEdit)),
  },
]
