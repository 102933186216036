import React from 'react'
import { Col, Row } from 'antd'
import FormItem from '../../../../../../components/form/FormItem'
import CurrencyInput from '../../../../../../components/form/CurrencyInput'
import PercentageInput from '../../../../../../components/form/PercentageInput'
import Duration from '../../../../../../components/form/Duration'

const Details = props => {
  const { productTypeName, formError, features } = props
  return (
    <section className="PapersView-main-section">
      <h3>Dettagli</h3>
      <Row type="flex" gutter={16}>
        <Col xs={24} sm={6}>
          <FormItem
            label="Importo Stipulato"
            name="amount"
            component={CurrencyInput}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
        <Col xs={24} sm={6}>
          <FormItem
            label="Netto erogato"
            name="netAmount"
            component={CurrencyInput}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>

        <Col xs={24} sm={6}>
          <FormItem
            label="Durata"
            name="duration"
            component={Duration}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
        <Col xs={24} sm={6}>
          <FormItem
            label="Importo rata"
            name="instalmentAmount"
            component={CurrencyInput}
            withReduxFormProps={true}
            errors={formError}
          />
        </Col>
      </Row>
      <Row className="PapersView-main-section" type="flex" gutter={16}>
        <Col xs={24} sm={6}>
          <FormItem
            label="TAN"
            name="rateValue"
            withReduxFormProps={true}
            errors={formError}
            component={PercentageInput}
          />
        </Col>
        <Col xs={24} sm={6}>
          <FormItem
            label="TAEG"
            name="taeg"
            withReduxFormProps={true}
            errors={formError}
            component={PercentageInput}
          />
        </Col>
        {features.hasCommission && (
          <Col xs={24} sm={6}>
            <FormItem
              label="Provvigione"
              name="commissionValue"
              withReduxFormProps={true}
              errors={formError}
              component={CurrencyInput}
            />
          </Col>
        )}
        {productTypeName === 'mortgage' && (
          <Col xs={24} sm={6}>
            <FormItem
              label="Valore Immobile"
              name="estate.value"
              component={CurrencyInput}
              withReduxFormProps={true}
              errors={formError}
            />
          </Col>
        )}
      </Row>
    </section>
  )
}

export default Details
