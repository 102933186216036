import React, { useCallback, useEffect } from 'react'
import { compose, withHandlers } from 'recompose'
import { Input } from 'antd'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import withConnectedForm from '../../form/withConnectedForm'
import FormItem from '../../form/FormItem'
import Modal from '../../modals/Modal'
import SubmitButton from '../../form/SubmitButton'

export const SaveFiltersConfigurationModal = props => {
  const {
    formError,
    visible,
    hide,
    filters,
    formValues,
    dispatchSubmit,
    createPreference,
    findPreferences,
    type,
  } = props

  const fetchPreferences = () => {
    if (!visible) return
    return findPreferences({ filter: { where: { type } } })
  }
  useEffect(
    () => {
      if (visible) fetchPreferences()
    },
    [visible],
  )

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      const result = await dispatchSubmit(
        createPreference({ ...formValues, type, value: JSON.stringify({ filters }) }),
      )
      hide(result)
    },
    [formValues, filters],
  )

  return (
    <Modal
      title={'Salva come lista veloce'}
      onCancel={() => hide()}
      onOk={handleSubmit}
      footer={<SubmitButton onClick={handleSubmit} />}
      visible={visible}
    >
      <form onSubmit={handleSubmit}>
        <p>
          Dopo il salvataggio, apparirá una nuova voce di menú, che permetterá di caricare questa
          configurazione filtri per un accesso rapido.
        </p>
        <div>
          <FormItem label="Nome Lista" name="name" component={Input} required errors={formError} />
        </div>
      </form>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      preferences: select.preferences.getAll(state),
    }),
    dispatch => ({
      findPreferences: dispatch.preferences.find,
      createPreference: dispatch.preferences.create,
    }),
  ),
  withConnectedForm('savePaperListFilter'),
)(SaveFiltersConfigurationModal)
