import { injectGlobal as css } from 'emotion'

css`
  .ant-tabs-content {
    .ant-tabs-tabpane {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .ant-collapse-item-active .ant-collapse-content {
    @media (max-width: 991px) {
      padding: 0 2px;
    }
  }

  @media (max-width: 991px) {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 12px 0 12px 15px !important;
          i.arrow {
            left: 6px !important;
          }
        }
      }
    }
  }

  .ant-select {
    .ant-select-selection {
      height: auto;
      min-height: 30px;
      &.ant-select-selection--single {
        .ant-select-selection__rendered {
          line-height: 1.2;
          .ant-select-selection-selected-value {
            overflow: visible;
            white-space: normal;
            float: none;
          }
        }
      }
    }
  }
  .ant-tabs-content {
    .ant-tabs-tabpane {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .ant-collapse-item-active .ant-collapse-content {
    @media (max-width: 991px) {
      padding: 0 2px;
    }
  }
  @media (max-width: 991px) {
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 12px 0 12px 15px !important;
          i.arrow {
            left: 6px !important;
          }
        }
      }
    }
  }

  /*TODO: refine that*/
  .ant-select {
    .ant-select-selection {
      height: auto;
      min-height: 30px;
      display: block;
      &.ant-select-selection--single {
        .ant-select-selection__rendered {
          padding: 5px 0;
          line-height: 1.2;
          display: flex;
          align-items: center;
          min-height: 30px;
          .ant-select-selection-selected-value {
            overflow: visible;
            white-space: normal;
            float: none;
          }
        }
      }
      &.ant-select-selection--multiple {
        .ant-select-selection__choice {
          height: auto;
          min-height: 24px;
          line-height: 1.2;
          padding: 5px 20px 5px 10px;
          .ant-select-selection__choice__content {
            white-space: normal;
            overflow: visible;
          }
        }
      }
    }
  }

  .ant-select-dropdown {
    .ant-select-dropdown-menu-item {
      overflow: visible;
      white-space: normal;
    }
  }

  .ant-card-head-title {
    white-space: normal;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .ant-form-item-control {
    input[readonly] {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }
`
