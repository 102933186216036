import httpProvider from 'src/lib/httpProviderInstance'

export const calculate = async function(payload) {
  await this.CALCULATE_FORMULA_REQUESTED()

  try {
    const { data } = await httpProvider.makeRequest(
      'GET',
      `/formula/calculate`,
      payload,
    )

    await this.CALCULATE_FORMULA_FULFILLED(data)
    return data
  } catch (err) {
    return this.CALCULATE_FORMULA_FAILED(err)
  }
}
