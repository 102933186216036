import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withProps } from 'recompose'
import { withRouter } from 'react-router'
import _get from 'lodash/get'
import { select } from '@rematch/select'
import { Helmet } from 'react-helmet'
import { push } from 'react-router-redux'
import { message, Modal } from 'antd'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import SmartTable from '../../components/tables/SmartTable/SmartTableClass'
import Currency from 'src/components/format/Currency'
import Percentage from 'src/components/format/Percentage'
import ProductTypeSelect from '../../components/form/ProductTypeSelect'
import ProductSelect from '../../components/form/ProductSelect'
// import OfficeSelect from '../../components/form/OfficeSelect'
import AgentSelect from '../../components/form/AgentsSelect'
import FinancialInstitutionSelect from '../../components/form/FinancialInstitutionSelect'
import PageTitle from '../../components/layout/PageTitle'
import { useListPreferences } from '../../components/tables/useListPreferences'
import jqueryDeparam from 'jquery-deparam'

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
  },
  {
    displayName: 'Data',
    field: 'createdAt',
    type: 'date',
    sortable: true,
    defaultSortOrder: 'descend',
  },
  {
    displayName: 'Cliente',
    field: 'client',
    render(client = {}, record) {
      return `${client.firstname || ''} ${client.lastname || ''}`
    },
  },
  {
    displayName: 'Agente',
    field: 'agent',
    render(agent = {}, record) {
      return `${agent.firstname || ''} ${agent.lastname || ''}`
    },
  },
  { displayName: 'Instituto', field: 'product.financialInstitution.name' },
  { displayName: 'Prodotto', field: 'product.name' },

  {
    displayName: 'Tan/Premio Lordo',
    sortable: true,
    render(ignored, record) {
      const grossPremium = _get(record, 'insurance.grossPremium')
      const rateValue = record.rateValue

      if (grossPremium) return <Currency value={grossPremium} />
      if (rateValue) return <Percentage value={rateValue} />
    },
  },
  {
    displayName: 'Taeg/Premio Netto',
    sortable: true,
    render(ignored, record) {
      const netPremium = _get(record, 'insurance.netPremium')
      const taeg = record.taeg

      if (netPremium) return <Currency value={netPremium} />
      if (taeg) return <Percentage value={taeg} />
    },
  },
  { displayName: 'Durata (mesi)', field: 'duration' },
  {
    displayName: 'Rata',
    field: 'instalmentAmount',
    type: 'currency',
    sortable: true,
  },
  { displayName: 'Importo', field: 'amount', type: 'currency', sortable: true },
  {
    displayName: 'Valore immobile',
    field: 'estate.value',
    type: 'currency',
    sortable: true,
  },
  { displayName: 'Tipo prodotto', field: 'productType.displayName' },
  {
    displayName: '',
    type: 'actions',
    itemsType: 'link',
    actions: [
      {
        label: 'Visualizza',
        props: {
          href: ({ record }) => `/#/quotes/view/${record.id}`,
        },
      },
      {
        label: 'Crea pratica',
        props: {
          onClick: ({ record, props }) => async e => {
            e.preventDefault()
            Modal.confirm({
              title: 'Crea pratica da preventivo',
              content: (
                <span>
                  Sei sicuro di voler creare una pratica dal preventivo "{record.internalId}"?
                </span>
              ),
              onOk: async () => {
                const { push, createPaperFromQuote } = props
                const { paper } = await createPaperFromQuote({
                  quoteId: record.id,
                })
                await push(`/papers/view/${paper.id}`)
                return message.success('Pratica creata')
              },
            })
          },
        },
      },
    ],
  },
]

const initialQuery = {
  filter: {
    include: [
      {
        relation: 'product',
        scope: {
          //fields: ['name', 'financialInstitution'],
          include: {
            relation: 'financialInstitution',
            scope: {
              fields: ['name'],
            },
          },
        },
      },
      'productType',
      'agent',
    ],
  },
}

const filtersConfig = [
  {
    label: 'Ricerca',
    key: 'search',
    type: 'search',
    formatValue: value => {
      const n = Number(value)
      return isNaN(n) ? value : n
    },
    fields: ['internalId', 'client.firstname', 'client.lastname', 'client.email'],
  },
  {
    key: 'productTypeId',
    label: 'Tipologia Prodotto',
    createQueryFilter: value => ({ where: { productTypeId: { inq: value } } }),
    component: props => <ProductTypeSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'productId',
    label: 'Prodotto',
    createQueryFilter: value => ({ where: { productId: { inq: value } } }),
    component: props => <ProductSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'financialInstitutionId',
    label: 'Istituto finanziario',
    createQueryFilter: value => ({ where: { financialInstitutionId: { inq: value } } }),
    component: props => <FinancialInstitutionSelect {...props} mode="multiple" allowClear={true} />,
  },
  {
    key: 'agentId',
    label: 'Agente',
    createQueryFilter: value => ({ where: { agentId: { inq: value } } }),
    component: props => <AgentSelect {...props} mode="multiple" allowClear={true} />,
  },
  /*{
    key: 'officeId',
    label: 'Sede',
    createQueryFilter: value => ({where:{ inq: value }),
    component: props => <OfficeSelect {...props} mode="multiple" allowClear={true}/>,
  },*/
  // not working due to bugged loopback-data-juggler data coercing
  /*{
    key: 'progressList.date',
    label: 'Data creazione',
    type: 'dateInterval',
    component: props => <RangePicker {...props}/>,
  },*/
]

export const PreventivesList = props => {
  const {
    preventives,
    findPreventives,
    countPreventives,
    count,
    createPaperFromQuote,
    push,
    tableProps,
  } = props

  const { fetchPreferences } = useListPreferences({
    listName: 'quotesList',
    menuPath: '2.items.0.items',
  })

  const handleFilteredListCreate = useCallback(() => {
    fetchPreferences()
  }, [])

  return (
    <DefaultLayout>
      <Helmet title="Preventivi, elenco" />
      <PageTitle title="Lista Preventivi" />
      <div className="u-bgWhite">
        <SmartTable
          data={preventives}
          columns={columns}
          initialQuery={initialQuery}
          count={count}
          filtersConfig={filtersConfig}
          countMethod={countPreventives}
          findMethod={findPreventives}
          createPaperFromQuote={createPaperFromQuote}
          identifier="quotesList"
          onFilteredListCreate={handleFilteredListCreate}
          push={push}
          {...tableProps}
        />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  withRouter,
  connect(
    state => ({
      preventives: select.quotes.getAll(state),
      count: select.quotes.count(state),
    }),
    dispatch => ({
      findPreventives: dispatch.quotes.find,
      countPreventives: dispatch.quotes.count,
      createPaperFromQuote: dispatch.quotes.createPaperFromQuote,
      push: bindActionCreators(push, dispatch),
    }),
  ),
  withProps(props => {
    return {
      tableProps: {
        defaultFilterExpanded: true,
        canSaveFilters: true,
        listFilterPreferenceId: jqueryDeparam((props.location.search || '').replace(/^(\?)/, ''))
          .listFilterPreferenceId,
      },
    }
  }),
)(PreventivesList)
