import React from 'react'
import { Modal } from 'antd'
import { compose, withPropsOnChange, pure } from 'recompose'
import { connect } from 'react-redux'
import { getPath } from '../../../store/selectors/getPath'

export const GenericModal = props => {
  const { title, visible, onCancel, ...rest } = props
  return <Modal title={title} visible={visible} onCancel={onCancel} {...rest} />
}

export default compose(
  pure,
  connect(state => ({
    isMobile: getPath(state)('env.size.isMobile'),
  })),
  withPropsOnChange(['isMobile'], ({ isMobile }) => {
    if (isMobile) {
      return {
        style: {
          top: 20,
        },
      }
    }
  }),
)(GenericModal)
