import { select } from '@rematch/select'

/**
 * UpdateChecker is responsible of the checking for new versions of the UIs
 */
export default class UpdateChecker {
  constructor({ store, pollingInterval, swRegistration }) {
    this.store = store
    this.pollingInterval = pollingInterval || 3600 * 1000
    this.swRegistration = swRegistration
    this.isPollingRegistered = false
  }

  setSwRegistration(swRegistration) {
    this.swRegistration = swRegistration
  }

  /**
   * Method to register the interval that regularly checks for UI updates
   * preferred method is service worker, it fall back to `release.json` file checking
   */
  setupPolling() {
    if (this.isPollingRegistered) return false

    const check = () => {
      if (this.swRegistration) {
        console.log('checking for service worker update')
        return this.swRegistration.update()
      }

      console.log('checking for release.json update')
      return this.performLegacyCheck()
    }

    console.log(`setting up ${this.swRegistration ? 'Service worker polling' : 'Legacy polling'}`)
    setInterval(() => check(), this.pollingInterval)
    this.isPollingRegistered = true
  }

  async performLegacyCheck(forceReload = false) {
    const currentVersion = select.ui.version(this.store.getState())
    try {
      const version = await this.store.dispatch.ui.fetchReleaseManifest()

      if (version && version !== currentVersion) {
        if (forceReload) {
          return this.store.dispatch.ui.updateUI()
        }

        return this.setUpdateMessageVisibility(true)
      }

      return null
    } catch (err) {
      console.error('Error while checking for new version:', err)
      throw err
    }
  }

  setUpdateMessageVisibility(visibility) {
    return this.store.dispatch.ui.setUpdateMessageVisibility(visibility)
  }
}
