import React, { Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { compose, withPropsOnChange } from 'recompose'
import _ from 'lodash'
import { css } from 'emotion'

const className = css({
  whiteSpace: 'nowrap',
})

const isInvalidNumber = n => _.isNull(n) || _.isUndefined(n) || isNaN(n)

const Currency = ({ value }) => {
  return (
    <Fragment>
      <NumberFormat
        className={className}
        value={value}
        displayType={'text'}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        prefix={'€ '}/>
    </Fragment>
  )
}

export default compose(
  withPropsOnChange(['value'], ({ value }) => ({
    value: isInvalidNumber(value) ? '' : value,
  })),
)(Currency)
