import React from 'react'
import { compose } from 'recompose'
import { Button } from 'antd'
import styled from 'react-emotion'
import Link from 'src/components/navigation/Link'
import { EmailList } from 'src/components/emails'
import withEnhancedRouter from 'src/components/withEnhancedRouter'

const BackButton = styled(Button)`
  margin-bottom: 20px;
`

export const Inbox = props => {
  const {
    emails,
    handleEmailSent,
    messageThreadId,
    match,
    paper = {},
    attachmentActions,
  } = props
  return (
    <article>
      <Link to={{ pathname: match.url, search: { tab: 'emails' } }}>
        <BackButton icon="left" size="big" />
      </Link>
      <br />
      <EmailList
        emails={emails}
        paperId={paper.id}
        onEmailSent={handleEmailSent}
        messageThreadId={messageThreadId}
        attachmentActions={attachmentActions}
      />
    </article>
  )
}

export default compose(withEnhancedRouter)(Inbox)
