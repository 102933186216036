import { EventEmitter } from 'events'
import Promise from 'bluebird'
import uuid from 'uuid/v4'

export default function formResultMiddleware({ getState }) {
  return next => {
    const promises = {}
    const eventBus = new EventEmitter()
    return async (action = {}) => {
      const { type, meta = {} } = action

      if (Array.isArray(meta.types)) {
        const { types } = meta
        if (action.type === meta.types[0]) {
          meta.id = meta.id || uuid()
          const thePromise = promises[meta.id] = new Promise((resolve, reject) => {
            eventBus.once(`${types[1]}_${meta.id}`, (action) => {
              resolve(action.payload)
            })
            eventBus.once(`${types[2]}_${meta.id}`, (action) => {
              reject(action.payload)
            })
          })

          next(action)

          try {
            return await thePromise
          } catch (err) {
            throw err
          } finally {
            delete promises[meta.id]
          }
        } else if (action.type === meta.types[1]) {
          eventBus.emit(`${types[1]}_${meta.id}`, action)
        } else if (action.type === meta.types[2]) {
          eventBus.emit(`${types[2]}_${meta.id}`, action)
        }
      }

      return next(action)
    }
  }
}
