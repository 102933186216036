import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import LogsTable from '../../../../../components/logs/LogsTable'

export const LogsTab = props => {
  const { initialQuery } = props
  return (
    <section>
      <LogsTable initialQuery={initialQuery} />
    </section>
  )
}

export default compose(
  withPropsOnChange(['paperId'], ({ paperId }) => ({
    initialQuery: {
      filter: {
        where: { paperId },
      },
    },
  })),
)(LogsTab)
