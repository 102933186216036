import React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { select } from '@rematch/select'
import _ from 'lodash'
import Modal from '../Modal/index'
import SmartTable from '../../tables/SmartTable/index'

const columns = [
  {
    displayName: 'ID',
    field: 'internalId',
  },
  {
    displayName: 'Nome',
    field: 'name',
  },
  {
    displayName: '',
    type: 'actions',
    itemsType: 'button',
    actions: [
      {
        label: 'Seleziona',
        props: {
          onClick: ({ record, props }) => e => {
            const { closeModal } = props
            return closeModal(record)
          },
        },
      },
    ],
  },
]

const filtersConfig = [
  {
    key: 'search',
    type: 'search',
    fields: ['name', 'body'],
  },
]

export const ChooseEmailTemplateModal = props => {
  const {
    visible,
    closeModal,
    count,
    query,
    emailTemplates,
    findEmailTemplates,
    countEmailTemplates,
  } = props

  return (
    <Modal
      title="Seleziona modello email"
      onCancel={() => closeModal()}
      width={700}
      visible={visible}
    >
      <SmartTable
        closeModal={closeModal}
        initialQuery={query}
        data={emailTemplates}
        filtersConfig={filtersConfig}
        columns={columns}
        count={count}
        countMethod={countEmailTemplates}
        findMethod={findEmailTemplates}
      />
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      emailTemplates: select.emailTemplates.getAll(state),
      count: select.emailTemplates.count(state),
    }),
    dispatch => ({
      findEmailTemplates: dispatch.emailTemplates.find,
      countEmailTemplates: dispatch.emailTemplates.count,
    }),
  ),
  withPropsOnChange(['initialQuery'], ({ initialQuery }) => ({
    query: _.assign(
      {},
      {
        filter: {
          order: 'name ASC',
          skipDeleted: true,
        },
      },
      initialQuery,
    ),
  })),
)(ChooseEmailTemplateModal)
