import React from 'react'
import DefaultLayout from 'src/components/layout/DefaultLayout/index'
import { compose, lifecycle, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { pushModalWindow } from 'redux-promising-modals'
import { Card, Alert } from 'antd'
import { select } from '@rematch/select'
import _ from 'lodash'
import withConnectedForm from 'src/components/form/withConnectedForm'
import withEnhancedRouter from 'src/components/withEnhancedRouter'
import withClearStateOnUnmount from 'src/components/withClearStateOnUnmount'
import SubmitButton from 'src/components/form/SubmitButton/index'
import SelectReportFieldsModal from '../../components/reports/reportCreate/SelectReportFieldsModal'
import { ReportForm } from '../../components/reports/'
import ReportProfileChooseModal from '../Reports/reportCreate/ReportProfileChooseModal'
import ReportProfileCreateModal from '../Reports/reportCreate/ReportProfileCreateModal'
import Spinner from '../../components/Spinner'
import Link from '../../components/navigation/Link'
import PageTitle from '../../components/layout/PageTitle'

const ReportProfileEdit = props => {
  const { fetchData, submitting, formError, handleSubmit, title } = props

  return (
    <DefaultLayout>
      <Spinner fetchData={fetchData}>
        <PageTitle title={title} />
        <Card>
          <Alert
            message="Attenzione"
            style={{ marginBottom: 20 }}
            closable
            description={
              <p>
                Questo é il form per la configurazione di un profilo che potrá essere utilizzato
                successivamente per generare un estrazione report. Se vuoi semplicemente generare un
                report,
                <Link to={`/reports/create`}>clicca qui.</Link>
              </p>
            }
            type="warning"
            showIcon
          />
          <form onSubmit={handleSubmit}>
            <ReportForm formError={formError} formType="reportProfile" {...props} />
            <div className="FormActions">
              <SubmitButton submitting={submitting}>Salva Profilo Report</SubmitButton>
            </div>
          </form>
        </Card>
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  withEnhancedRouter,
  connect(
    state => ({
      reportProfile: select.reportProfiles.get(state),
    }),
    {
      openSelectReportFieldsModal: props => dispatch =>
        dispatch(
          pushModalWindow('SELECT_REPORTS_FIELDS', {
            Component: SelectReportFieldsModal,
            ...props,
          }),
        ),
      openReportProfileChooseModal: props => dispatch =>
        dispatch(
          pushModalWindow('SELECT_REPORT_PROFILES', {
            Component: ReportProfileChooseModal,
            ...props,
          }),
        ),
      openCreateReportProfileModal: props => dispatch =>
        dispatch(
          pushModalWindow('CREATE_REPORT_PROFILE', {
            Component: ReportProfileCreateModal,
            ...props,
          }),
        ),
      push,
    },
  ),
  connect(
    null,
    dispatch => ({
      findById: dispatch.reportProfiles.findById,
      create: dispatch.reportProfiles.create,
      update: dispatch.reportProfiles.update,
    }),
  ),
  withConnectedForm('reportProfileEdit'),
  withClearStateOnUnmount('reportProfiles.reportProfile'),
  withPropsOnChange(['formValues'], ({ formValues }) => ({
    report: formValues,
  })),
  withPropsOnChange(['id', 'reportProfile'], ({ id, reportProfile }) => ({
    title: id
      ? `Modifica profilo report: ${_.get(reportProfile, 'internalId', id)}`
      : 'Nuovo Profilo Report',
  })),
  withPropsOnChange(['reportProfile'], ({ id, reportProfile, initialize }) => {
    if (reportProfile && id) {
      initialize({ type: 'papers', ...reportProfile })
    }
  }),
  withHandlers({
    fetchData: props => async () => {
      const { id, findById } = props
      if (id) {
        return findById({ id })
      }
    },
    handleSubmit: props => async e => {
      e.preventDefault()
      const { dispatchSubmit, formValues, id, create, update } = props
      const fn = id ? update : create
      return dispatchSubmit(fn({ id, ...formValues }))
    },
    handleAddColumnBtnClick: props => async () => {
      const { array, openSelectReportFieldsModal } = props
      const field = await openSelectReportFieldsModal(props)
      if (!field) {
        return array.push('columns', { key: '', value: '' })
      }

      array.push('columns', {
        key: field.fkey,
        label: field.description,
      })
    },
    handleKeySelectBtnClick: props => async ({ column, index }) => {
      const { change, openSelectReportFieldsModal } = props
      const field = await openSelectReportFieldsModal(props)
      if (field) {
        change(`columns.${index}.key`, field.fkey)
      }
    },
    handleDeleteColumnBtnClick: props => async index => {
      props.array.remove('columns', index)
    },
    handleCreateReportProfileModal: props => async () => {
      const { openCreateReportProfileModal, formValues } = props
      return openCreateReportProfileModal({ values: formValues })
    },
  }),
)(ReportProfileEdit)
