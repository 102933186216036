import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { css } from 'emotion'
import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import _ from 'lodash'
import InboxLayout from './InboxLayout'
import withInboxProps from './withInbox'
import { EmailList } from 'src/components/emails'
import Link from '../../components/navigation/Link'
import withEnhancedRouter from '../../components/withEnhancedRouter'

const className = css({
  '& .DefaultLayout-content-inner': {
    flexGrow: '1 !important',
  },
})

export const Inbox = props => {
  const {
    emails,
    handleEmailSent,
    subject,
    paperCategories,
    paperId,
    messageThreadId,
    match,
  } = props
  return (
    <InboxLayout className={className} paperCategories={paperCategories}>
      <article>
        <Link to={{ pathname: '/inbox', search: match.search }} style={{ marginBottom: 3 }}>
          <Button icon="left" size="big"/>
        </Link>
        <h3>{subject}</h3>
        <EmailList
          emails={emails}
          paperId={paperId}
          onEmailSent={handleEmailSent}
          messageThreadId={messageThreadId}
        />
      </article>
    </InboxLayout>
  )
}

export default compose(
  withInboxProps,
  withEnhancedRouter,
  withPropsOnChange(['match'], ({ match }) => ({
    messageThreadId: _.get(match, 'params.messageThreadId'),
  })),
  withPropsOnChange(['emails'], ({ emails }) => ({
    paperId: _.get(emails, '0.paperId'),
  })),
)(Inbox)
