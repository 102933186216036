import React, { useCallback } from 'react'
import { dispatch } from '@rematch/core'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Menu, Row, Col, Modal, Icon, message } from 'antd'
import { css } from 'emotion'

const btnStyle = css`
  i.anticon {
    min-width: initial;
    margin-right: initial;
    font-size: initial;
    transition: none;
  }
`

export const updateMenu = function({ path, content, updateMethod = 'concat' }) {
  this.UPDATE_MENU({ path, content, updateMethod })
}

const itemRender = ({ item, preference, path, onDeleteBtnClick }) => {
  return (
    <Menu.Item key={item.key}>
      <Link to={{ ...item, pathname: item.path }} title={item.title}>
        <Row
          type="flex"
          justify="space-between"
          style={{ alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'initial' }}
        >
          <Col>{item.title}</Col>
          <Col>
            <div onClick={onDeleteBtnClick}>
              <Icon className={btnStyle} size="small" type="delete" />
            </div>
          </Col>
        </Row>
      </Link>
    </Menu.Item>
  )
}

export const addFilteredListToMenu = async function({ listName, data, path }, state) {
  const onDeleteBtnClick = ({ item, preference }) => e => {
    e.preventDefault()
    Modal.confirm({
      title: 'Elimina lista veloce',
      content: 'Sei sicuro di voler eliminare la lista veloce?',
      onOk: async () => {
        await dispatch.preferences.delete({ id: preference.id })
        await dispatch.menu.addFilteredListToMenu({ listName: preference.type, path })
        message.success('Lista veloce eliminata')
      },
    })
  }

  const filteredLists =
    data ||
    (await dispatch.preferences.find({
      filter: { where: { type: listName } },
    }))

  const entityName = listName.split('List')[0]

  const items = _.get(state.menu.menu, path, [])
    .filter(i => !i.isFilteredList)
    .concat(
      filteredLists.map(preference => ({
        title: preference.name,
        name: `${entityName}.list`,
        search: `?listFilterPreferenceId=${preference.id}`,
        isFilteredList: true,
        render: item =>
          itemRender({
            item,
            preference,
            path,
            onDeleteBtnClick: onDeleteBtnClick({ item, preference }),
          }),
      })),
    )

  return this.updateMenu({
    path,
    content: items,
    updateMethod: 'replace',
  })
}
