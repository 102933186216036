import { injectGlobal } from 'emotion'

injectGlobal`
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  min-height: 100vh;
  display: flex;
}
`
