import React from 'react'
import Modal from 'src/components/modals/Modal/index'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Divider, Modal as AntdModal } from 'antd'
import { select } from '@rematch/select'
import SmartTable from 'src/components/tables/SmartTable/SmartTableClass'

const columns = [
  {
    displayName: 'Nome',
    field: 'name',
  },
  {
    displayName: 'Creato da',
    render(ignored, record) {
      if (!record || !record.agent) return null
      return `${record.agent.firstname} ${record.agent.lastname}`
    },
  },
  {
    displayName: 'Data',
    field: 'createdAt',
    type: 'date',
  },
  {
    displayName: '',
    render: function (ignore, record, index, props) {

      return (
        <div>
          <a onClick={e => {
            e.preventDefault()
            props.closeModal(record)
          }}>Seleziona</a>
          <Divider type="vertical"/>
          <a onClick={() => props.handleDeleteReportProfileClick(record)}>Elimina</a>
        </div>
      )
    },
  },
]

const filtersConfig = [
  {
    label: 'Search',
    key: 'search',
    type: 'search',
    fields: ['name'],
  },
]

const ReportProfileChooseModal = props => {
  const {
    visible,
    closeModal,
    reportProfiles,
    countReportProfiles,
    findReportProfiles,
    handleDeleteReportProfileClick,
    count,
  } = props

  return (
    <Modal
      title="Seleziona Profilo Report"
      onCancel={() => closeModal()}
      visible={visible}
      width={900}
      footer="">
      <SmartTable
        data={reportProfiles}
        columns={columns}
        filtersConfig={filtersConfig}
        initialQuery={{ filter: { order: 'createdAt DESC' } }}
        count={count}
        countMethod={countReportProfiles}
        findMethod={findReportProfiles}
        closeModal={closeModal}
        handleDeleteReportProfileClick={handleDeleteReportProfileClick}
      />
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      reportProfiles: select.reportProfiles.getAll(state),
      count: select.reportProfiles.count(state),
    }),
    dispatch => ({
      findReportProfiles: dispatch.reportProfiles.find,
      countReportProfiles: dispatch.reportProfiles.count,
      deleteReportProfile: dispatch.reportProfiles.delete,
    }),
  ),
  withHandlers({
    handleFieldClick: props => field => {
      props.closeModal(field)
    },
    handleDeleteReportProfileClick: props => async (report) => {
      AntdModal.confirm({
        title: 'Elimina Profilo Report',
        content: `Sei sicuro di voler eliminare il Profilo Report "${report.name}"?`,
        onOk: async () => {
          await props.deleteReportProfile({ id: report.id })
          props.findReportProfiles()
        },
      })
    },
  }),
)(ReportProfileChooseModal)
