import React from 'react'
import {
  compose,
  withStateHandlers,
  withProps,
  withState,
  withHandlers,
  defaultProps,
  withPropsOnChange,
} from 'recompose'
import { css } from 'emotion'
import { Button, message, Checkbox } from 'antd'
import { select } from '@rematch/select'
import _ from 'lodash'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Link from 'src/components/navigation/Link'
import SmartTable from '../tables/SmartTable/SmartTableClass'
import EmailThreadListItem from './EmailThreadListItem'
import EmailHeader from 'src/components/emails/EmailHeader'
import style from './EmailThreadList.style'
import withSubscription from '../withSubscription'
import { openChoosePaperModal } from 'src/components/modals/ChoosePaperModal'
import withWatcher from '../withWatcher'
import Mobile from 'src/components/layout/Responsive/Mobile'

const rowLinkClassName = css({
  '& *': {
    color: 'initial',
  },
})
const columns = [
  {
    field: 'id',
    render(value, record, ignore, props) {
      return (
        <Link className={rowLinkClassName} to={props.linkFactory(record)}>
          <EmailHeader {...props} email={record.email} />
        </Link>
      )
    },
  },
]

const EmailThreadList = props => {
  const {
    threadedEmails = [],
    count,
    countMethod,
    findMethod,
    linkFactory,
    config,
    query,
    rowSelection,
    handleMarkAsReadBtnClick,
    handleMarkAsUnreadBtnClick,
    handleMoveBtnClick,
    me,
    fetchData,
    isFetchingThreadedEmails,
    onlyUnreadLocal,
    setOnlyUnreadLocal,
    isUnreadCheckboxVisible,
    newComposeEmail,
  } = props

  return (
    <article className={style}>
      <section className="EmailThreadList-actions">
        {isUnreadCheckboxVisible && (
          <Checkbox
            checked={onlyUnreadLocal}
            onClick={() => setOnlyUnreadLocal(!onlyUnreadLocal)}
          >
            Solo non lette
          </Checkbox>
        )}
        <Button onClick={handleMarkAsReadBtnClick}>Marca come lette</Button>
        <Button onClick={handleMarkAsUnreadBtnClick}>
          Marca come non lette
        </Button>
        <Button onClick={handleMoveBtnClick}>Sposta</Button>
        <Mobile>
          <Button icon="plus" onClick={newComposeEmail}>
            Nuova Email
          </Button>
        </Mobile>
      </section>
      <SmartTable
        data={threadedEmails}
        columns={columns}
        count={count}
        initialQuery={query}
        countMethod={countMethod}
        findMethod={fetchData}
        linkFactory={linkFactory}
        config={config}
        rowSelection={rowSelection}
        loading={isFetchingThreadedEmails}
        me={me}
      />
    </article>
  )
}

export default compose(
  connect(
    state => ({
      threadedEmails: select.emails.threadedEmails(state),
      emails: select.emails.getAll(state),
      isFetchingThreadedEmails: select.emails.isFetchingThreadedEmails(state),
      count: select.emails.count(state),
      paperCategories: select.emails.paperCategories(state),
      me: _.get(select.agents.me(state), 'me'),
    }),
    dispatch => ({
      observeThreadedEmails: dispatch.emails.observeThreadedEmails,
      fetchPaperCategories: dispatch.emails.fetchPaperCategories,
      newComposeEmail: dispatch.emails.newComposeEmail,
      markAsRead: dispatch.emails.markAsRead,
      markAsUnread: dispatch.emails.markAsUnread,
      updateAll: dispatch.emails.updateAll,
      openChoosePaperModal: bindActionCreators(openChoosePaperModal, dispatch),
    }),
  ),
  defaultProps({
    onChange: () => {},
    isUnreadCheckboxVisible: true,
  }),
  withProps({
    initialQuery: props => ({
      populate: [
        {
          property: 'attachments.fileId',
          model: 'MyFile',
          on: 'file',
        },
      ],
    }),
  }),
  withStateHandlers(props => ({ query: props.initialQuery }), {
    handleTableChange: () => ({ query }) => ({ query }),
  }),
  withSubscription,
  withState('onlyUnreadLocal', 'setOnlyUnreadLocal', props => props.onlyUnread),
  withPropsOnChange(['onlyUnread'], ({ onlyUnread }) => {
    console.log(typeof onlyUnread)
    if (typeof onlyUnread !== 'boolean') return
    return {
      onlyUnreadLocal: onlyUnread,
    }
  }),
  withHandlers({
    fetchData: props => async query => {
      const { onlyUnreadLocal } = props
      await props.unsubscribeAll()
      const { paperId } = props
      const theQuery = _.merge({}, query, props.query, {
        filter: { paperId, onlyUnread: onlyUnreadLocal },
      })
      return Promise.all([
        props.addSubscription(props.observeThreadedEmails(theQuery)),
      ])
    },
  }),
  withState('selectedEmails', 'setSelectedEmails', []),
  withState('selectedRowKeys', 'setSelectedRowKeys', []),
  withWatcher(['paperId', 'onlyUnreadLocal'], ({ fetchData }) => fetchData()),
  withProps(props => ({
    rowSelection: {
      onChange: (selectedRowKeys, selectedEmails) => {
        props.setSelectedEmails(selectedEmails)
        props.setSelectedRowKeys(selectedRowKeys)
      },
      selectedRowKeys: props.selectedRowKeys,
    },
  })),
  withStateHandlers(
    { editorVisibilityHash: {} },
    {
      handleActivePanelChange: props => activePanels => {
        return {
          activePanels,
        }
      },
    },
  ),
  withHandlers({
    markSelectedAsRead: props => async () => {
      const {
        markAsRead,
        selectedEmails,
        setSelectedEmails,
        setSelectedRowKeys,
        fetchData,
        onChange,
      } = props
      if (!selectedEmails.length) return
      const emailIds = _.map(selectedEmails, 'email.id')
      await markAsRead({ emailIds })
      await setSelectedEmails([])
      await setSelectedRowKeys([])
      message.success('Email marcate come lette')
      await fetchData()
      return onChange()
    },
  }),
  withHandlers({
    handleMarkAsReadBtnClick: props => async () => {
      return props.markSelectedAsRead()
    },
    handleMarkAsUnreadBtnClick: props => async () => {
      const {
        markAsUnread,
        selectedEmails,
        setSelectedEmails,
        setSelectedRowKeys,
        fetchData,
        onChange,
      } = props
      if (!selectedEmails.length) return
      const emailIds = _.map(selectedEmails, 'email.id')
      await markAsUnread({ emailIds })
      await setSelectedEmails([])
      await setSelectedRowKeys([])
      message.success('Email marcate come non lette')
      await fetchData()
      return onChange()
    },
    handleMoveBtnClick: props => async () => {
      const {
        openChoosePaperModal,
        updateAll,
        fetchData,
        onChange,
        selectedEmails,
        setSelectedEmails,
        setSelectedRowKeys,
      } = props
      if (!selectedEmails.length) return
      const emailIds = _.map(selectedEmails, 'email.id')
      const paper = await openChoosePaperModal()
      if (paper) {
        await updateAll({
          where: { id: { inq: emailIds } },
          data: { paperId: paper.id },
        })
        await setSelectedEmails([])
        await setSelectedRowKeys([])
        message.success(`Email spostate nella pratica: ${paper.internalId}`)
        await fetchData()
        return onChange()
      }
    },
  }),
)(EmailThreadList)
