import createEmailsModel from './modules/Emails2'
import createAgentsModel from './modules/Agents'
import createLayoutModel from './modules/Layout'
import createExpenseTypesModel from './modules/ExpenseTypes'
import createClientsModel from './modules/Clients'
import createPapersModel from './modules/Papers'
import createAgentPermissionsModel from './modules/AgentPermissions'
import createRolesModel from './modules/Roles'
import createRoleMappingsModel from './modules/RoleMapping'
import createProductsModel from './modules/Products'
import createQuotesModel from './modules/Quotes'
import createProductTypesModel from './modules/ProductTypes'
import createProductRatesModel from './modules/ProductRates'
import createFinancialInstitutionsModel from './modules/FinancialInstitutions'
import createProductInsurancePremiumsModel from './modules/ProductInsurancePremiums'
import createOfficesModel from './modules/Offices'
import createPurposesModel from './modules/Purposes'
import createDocumentTypesModel from './modules/DocumentTypes'
import createDocumentsModel from './modules/Documents'
import createFilesModel from './modules/Files'
import createProgressModel from './modules/Progress'
import createAgentHierarchyModel from './modules/AgentsHierarchy'
import createDocumentTemplatesModel from './modules/DocumentTemplates'
import createQueuesModel from './modules/Queues'
import createEmailTemplatesModel from './modules/EmailTemplates'
import createChannelModel from './modules/Channels'
import createReportProfilesModel from './modules/ReportProfiles'
import createAgentGroupModel from './modules/AgentGroups'
import createPathsModel from './modules/Paths'
import createNotesModel from './modules/Notes'
import createActionLogsModel from './modules/ActionLogs'
import createAccountsModel from './modules/Accounts'
import createAccountSettingsModel from './modules/AccountSettings'
import createReportsModel from './modules/Reports'
import createVariablesModel from './modules/Variables'
import createEnvModel from './modules/env'
import createCommissionRuleModel from './modules/CommissionRules'
import createFormulaModel from './modules/Formula'
import createQuotesHistoryModel from './modules/QuotesHistory'
import createBudgetsModel from './modules/Budgets'
import createPreferencesModel from './modules/Preferences'
import createMenuModel from './modules/menu'
import createUIModel from './modules/ui'
import routes from '../routes'

export default function() {
  return {
    menu: createMenuModel(routes),
    emails: createEmailsModel(),
    agents: createAgentsModel(),
    layout: createLayoutModel(),
    expenseTypes: createExpenseTypesModel(),
    clients: createClientsModel(),
    papers: createPapersModel(),
    agentPermissions: createAgentPermissionsModel(),
    roles: createRolesModel(),
    roleMappings: createRoleMappingsModel(),
    products: createProductsModel(),
    quotes: createQuotesModel(),
    productTypes: createProductTypesModel(),
    productRates: createProductRatesModel(),
    financialInstitutions: createFinancialInstitutionsModel(),
    productInsurancePremiums: createProductInsurancePremiumsModel(),
    offices: createOfficesModel(),
    purposes: createPurposesModel(),
    documentTypes: createDocumentTypesModel(),
    documents: createDocumentsModel(),
    files: createFilesModel(),
    progress: createProgressModel(),
    agentsHierarchy: createAgentHierarchyModel(),
    documentTemplates: createDocumentTemplatesModel(),
    emailTemplates: createEmailTemplatesModel(),
    queues: createQueuesModel(),
    channels: createChannelModel(),
    reportProfiles: createReportProfilesModel(),
    agentGroups: createAgentGroupModel(),
    paths: createPathsModel(),
    notes: createNotesModel(),
    actionLogs: createActionLogsModel(),
    accounts: createAccountsModel(),
    accountSettings: createAccountSettingsModel(),
    reports: createReportsModel(),
    variables: createVariablesModel(),
    env: createEnvModel(),
    commissionRules: createCommissionRuleModel(),
    formulas: createFormulaModel(),
    quotesHistories: createQuotesHistoryModel(),
    budgets: createBudgetsModel(),
    preferences: createPreferencesModel(),
    ui: createUIModel(),
  }
}
