import React from 'react'
import { compose } from 'recompose'
import { Button } from 'antd'
import QuoteView from '../../quotes/QuoteView'
import Modal from '../Modal'

export const QuoteViewModal = props => {
  const { visible, closeModal, id, quote } = props
  const title = id ? `Preventivo ID: ${id}` : 'Preventivo'
  return (
    <Modal
      title={title}
      footer={<Button onClick={closeModal}>Chiudi</Button>}
      size="large"
      visible={visible}
      onCancel={() => closeModal()}
    >
      <QuoteView id={id} quote={quote} expensesLayout="stack"/>
    </Modal>
  )
}

export default compose()(QuoteViewModal)
