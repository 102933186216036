import React, { Fragment } from 'react'
import {
  pure,
  compose,
  withPropsOnChange,
  defaultProps,
  withHandlers,
} from 'recompose'
import { connect } from 'react-redux'
import { Select } from 'antd'
import _ from 'lodash'
import $ from 'jquery'
import withModalControls from '../../modals/withModalControls'
import { getPath } from '../../../store/selectors/getPath'
import SelectModal from './SelectModal'

const Option = Select.Option

const TheSelect = props => {
  const {
    formattedOptions,
    children,
    modal,
    value,
    isMultiple,
    handleClick,
  } = props
  return (
    <Fragment>
      <span onClick={handleClick}>
        <Select {...props}>
          {children ||
            (formattedOptions || []).map(option => (
              <Option {...option} value={option.value}>
                {option.label}
              </Option>
            ))}
        </Select>
      </span>
      <SelectModal
        {...modal}
        options={formattedOptions}
        value={value}
        isMultiple={isMultiple}
      />
    </Fragment>
  )
}

export default compose(
  pure,
  connect(state => ({
    isMobileDevice:
      getPath(state)('env.isMobileDevice') ||
      getPath(state)('env.size.isMobile'),
  })),
  defaultProps({
    mobileModal: true,
  }),
  withModalControls('modal'),
  withPropsOnChange(['isMobileDevice', 'showSearch'], props => {
    const { isMobileDevice, showSearch } = props
    if (props.hasOwnProperty('showSearch')) {
      return { showSearch }
    }

    return { showSearch: !isMobileDevice }
  }),
  withPropsOnChange(['value', 'mode'], ({ mode }) => {
    return {
      isMultiple: ['multiple', 'tags'].includes(mode),
    }
  }),
  defaultProps({
    labelField: 'label',
    valueField: 'value',
    optionFilterProp: 'children',
    filterOption: (input, option) =>
      option.props.children &&
      option.props.children.toLowerCase &&
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  }),
  withHandlers({
    getLabelField: ({ labelField }) => option => {
      if (typeof labelField === 'function') return labelField(option)

      return option[labelField]
    },
    getValueField: ({ valueField }) => option => {
      if (typeof valueField === 'function') return valueField(option)

      return option[valueField]
    },
    handleClick: props => async e => {
      const { isMobileDevice, mobileModal, modal, onChange } = props
      if (!isMobileDevice || !mobileModal) return
      const $target = $(e.target)
      if (
        !$target.is(
          '.ant-select-selection__rendered, .ant-select-selection,.ant-select-search__field,.ant-select-selection-selected-value',
        )
      ) {
        return false
      }

      e.preventDefault()
      e.stopPropagation()
      setInterval(() => $target.find('*').blur())

      const selected = await modal.show()
      return onChange(selected)
    },
  }),
  withPropsOnChange(
    ['options'],
    ({ options = [], getLabelField, getValueField }) => {
      const formattedOptions = options.map(option => ({
        label: getLabelField(option),
        value: getValueField(option),
      }))

      return { formattedOptions }
    },
  ),
  withPropsOnChange(['isMultiple', 'value'], ({ isMultiple, value }) => {
    if (isMultiple && !Array.isArray(value)) {
      return {
        value: [],
      }
    }
  }),
)(TheSelect)
