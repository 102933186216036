import { css } from 'emotion'

export default css`
& .DocumentsTab-topActions {
  margin-bottom: 30px;
  button {
    margin-right: 10px;
  }
}
`
