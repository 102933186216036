import React from 'react'
import { compose, pure, withPropsOnChange } from 'recompose'
import { select } from '@rematch/select'
import { connect } from 'react-redux'
import _ from 'lodash'

export const FeatureFlag = props => {
  const { isEnabled, children } = props

  return isEnabled ? children : null
}

export default compose(
  pure,
  connect(state => ({
    enabledFeatures: select.accountSettings.enabledFeatures(state),
  })),
  withPropsOnChange(
    ['feature', 'enabledFeatures'],
    ({ feature, enabledFeatures }) => {
      const features = Array.isArray(feature) ? feature : [feature]
      return {
        isEnabled: _.every(
          features,
          feature => (enabledFeatures || {})[feature],
        ),
      }
    },
  ),
)(FeatureFlag)
