import React from 'react'
import { Select as TheSelect } from 'antd'
import { connect } from 'react-redux'
import {
  compose,
  defaultProps,
  lifecycle,
  withHandlers,
  withPropsOnChange,
} from 'recompose'
import _fp from 'lodash/fp'
import { select } from '@rematch/select'
import { withProps } from 'recompose'
import withFormProps from 'src/components/form/withReduxFormProps'

const Option = TheSelect.Option
const Select = withFormProps(TheSelect)
const AgentGroupsSelect = props => {
  const { options = [] } = props

  return (
    <Select {...props}>
      {options.map(option => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  )
}

export default compose(
  connect(
    state => ({
      agentGroups: select.agentGroups.getAll(state),
    }),
    dispatch => ({
      find: dispatch.agentGroups.find,
    }),
  ),
  defaultProps({
    query: {
      filter: {
        order: 'name ASC',
        include: { relation: 'applicableToRole' },
        skipDeleted: true,
      },
    },
  }),
  withPropsOnChange(['agentGroups'], ({ agentGroups }) => ({
    options: _fp.flow(
      _fp.sortBy('applicableToRole.displayName'),
      _fp.map(agentGroup => ({
        label: `${agentGroup.applicableToRole.displayName} - ${
          agentGroup.name
        }`,
        value: agentGroup.id,
      })),
    )(agentGroups),
  })),
  withHandlers({
    fetchData: props => () => {
      return props.find(props.query)
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchData()
    },
  }),
)(AgentGroupsSelect)
