import trim from 'lodash/trim'
import compact from 'lodash/compact'
import identity from 'lodash/identity'
import axios from 'axios'
import { createSelector } from 'reselect'
import { getPersistor } from '@rematch/persist'

export default () => {
  return {
    state: { isUpdateMessageVisible: false, latestVersion: null },
    effects: {
      async fetchReleaseManifest() {
        const { origin, pathname } = window.location
        const pathParts = compact([origin, pathname, 'project.json'])
        const url = pathParts.map(p => trim(p, '/')).join('/')

        const rand = `${Math.random()}-${Math.random()}`

        const {
          data: { version },
        } = await axios.get(url, { params: { rand } })

        this.STORE_UI_LATEST_VERSION(version)

        return version
      },
      async updateUI(ignore, rootState) {
        await this.STORE_UI_VERSION(rootState.ui.latestVersion)
        await this.SET_UPDATE_MESSAGE_VISIBILITY(false)
        const persistor = await getPersistor()
        await persistor.flush()

        setTimeout(() => window.location.reload(true))
      },
      setUpdateMessageVisibility(visible) {
        this.SET_UPDATE_MESSAGE_VISIBILITY(visible)
      },
    },
    reducers: {
      STORE_UI_VERSION: (state, version) => ({ ...state, version }),
      STORE_UI_LATEST_VERSION: (state, latestVersion) => ({ ...state, latestVersion }),
      SET_UPDATE_MESSAGE_VISIBILITY: (state, visible) => ({
        ...state,
        isUpdateMessageVisible: visible,
      }),
    },
    selectors: {
      version: createSelector(
        state => state.version,
        identity,
      ),
      isUpdateMessageVisible: createSelector(
        state => state.isUpdateMessageVisible,
        identity,
      ),
    },
  }
}
