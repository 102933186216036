import React from 'react'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import {
  Input,
  Card,
  Select,
  Button,
  Row,
  Col,
  Menu,
  Dropdown,
  Checkbox,
  Icon,
} from 'antd'
import _ from 'lodash'
import { pushModalWindow } from 'redux-promising-modals'
import { default as TheFormItem } from 'src/components/form/FormItem/index'
import withFormProps from 'src/components/form/withReduxFormProps'
import SelectReportFieldsModal from './reportCreate/SelectReportFieldsModal'
import ReportFields from './reportCreate/ReportFields'
import ReportFilters from './reportCreate/ReportFilters'

const papersReportInitialValues = {
  type: 'papers',
  columns: [],
  criteria: {
    'agents.agentId': { inq: [] },
    productTypeId: { inq: [] },
    productId: { inq: [] },
    financialInstitutionId: { inq: [] },
    officeId: { inq: [] },
    progressId: { inq: [] },
    and: [
      {
        'currentProgress.date': {},
      },
      {
        'currentProgress.date': {},
      },
    ],
  },
}

const agentsReportInitialValues = {
  request: {
    columns: [],
    criteria: {
      id: { inq: [] },
      officeId: { inq: [] },
      roleId: { inq: [] },
    },
  },
}

const Option = Select.Option

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const FormItem = props => (
  <TheFormItem {...formItemLayout} {...props}>
    {props.children}
  </TheFormItem>
)

const InputText = withFormProps(Input)
const Type = withFormProps(
  Select,
  {
    children: [
      <Option value="papers">Pratiche</Option>,
      // <Option value="agents">Agenti</Option>,
    ],
  },
  input => {
    const { value, ...rest } = input
    return {
      ...rest,
      value,
    }
  },
)

const ReportForm = props => {
  const {
    report = {},
    formError,
    formType,
    handleReportTypeChange,
    handleNameChange,
    areDateRangesVisible,
    areDateRangesConfigurationVisible,
  } = props

  const reportType = _.get(report, 'type')
  return (
    <section>
      <div style={{ maxWidth: '540px' }}>
        <FormItem
          label="Nome"
          name="name"
          onChange={handleNameChange}
          component={InputText}
          errors={formError}
        />

        <FormItem
          label="Tipologia report"
          name="type"
          onChange={handleReportTypeChange}
          component={Type}
          errors={formError}
        />

        {formType === 'reportProfile' && (
          <FormItem
            label="Pubblico"
            name="isPublic"
            withReduxFormProps={true}
            component={Checkbox}
            errors={formError}
            extra={'Se abilitato, rende visibile il profilo pubblicamente'}
          />
        )}
      </div>
      {reportType && (
        <section>
          <div style={{ marginBottom: 20, overflowX: 'auto' }}>
            <ReportFields {...props} />
          </div>
          <div>
            <ReportFilters
              {...props}
              areDateRangesVisible={areDateRangesVisible}
              areDateRangesConfigurationVisible={
                areDateRangesConfigurationVisible
              }
            />
          </div>
        </section>
      )}
    </section>
  )
}

export default compose(
  connect(null, {
    openSelectReportFieldsModal: props => dispatch =>
      dispatch(
        pushModalWindow('SELECT_REPORTS_FIELDS', {
          Component: SelectReportFieldsModal,
          ...props,
        }),
      ),
  }),
  withPropsOnChange(['formValues'], ({ formValues }) => ({
    report: formValues,
  })),
  withPropsOnChange(['report'], ({ report }) => {
    if (!report) return {}
    const columns = _.get(report, 'columns', [])
    const dataSource = [
      columns.reduce((acc, column, index) => {
        acc[index] = column
        return acc
      }, {}),
    ]

    return {
      dataSource,
    }
  }),
  withPropsOnChange(['formType'], ({ formType }) => {
    if (formType === 'report') {
      return {
        areDateRangesVisible: true,
      }
    }

    if (formType === 'reportProfile') {
      return {
        areDateRangesConfigurationVisible: true,
      }
    }
  }),
  withHandlers({
    handleAddColumnBtnClick: props => async () => {
      const { array, openSelectReportFieldsModal } = props
      const field = await openSelectReportFieldsModal(props)
      if (!field) {
        return array.push('columns', { key: '', value: '' })
      }

      array.push('columns', {
        key: field.fkey,
        label: field.description,
      })
    },
    handleKeySelectBtnClick: props => async ({ column, index }) => {
      const { change, openSelectReportFieldsModal } = props
      const field = await openSelectReportFieldsModal(props)
      if (field) {
        change(`columns.${index}.key`, field.fkey)
      }
    },
    handleDeleteColumnBtnClick: props => async index => {
      props.array.remove('columns', index)
    },
    handleReportTypeChange: props => async (e, value) => {
      if (value === 'papers') {
        return props.initialize(papersReportInitialValues)
      }

      if (value === 'agents') {
        return props.initialize(agentsReportInitialValues)
      }
    },
    handleNameChange: props => async (e, name) => {
      const { change } = props
      return change('name', name)
    },
  }),
)(ReportForm)
