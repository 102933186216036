import React from 'react'
import { Tabs as DesktopTabs } from 'antd'
import { connect, } from 'react-redux'
import { compose, pure } from 'recompose'

const DesktopTabPane = DesktopTabs.TabPane

export const BrTabPane = (props) => {
  const { isDesktop, isMobile, children, ...rest } = props
  const TabPaneComponent = true ? DesktopTabPane : ({ children }) => <div>
          {children}
        </div>

  return (
    <TabPaneComponent {...rest}>{children}</TabPaneComponent>
  )
}

export default compose(
  connect(
    state => ({
      isDesktop: state.env.size.isDesktop,
      isMobile: state.env.size.isMobile,
    }),
  ),
  pure,
)(BrTabPane)
