import React from 'react'
import { Card } from 'antd'
import { css } from 'emotion'
import DefaultLayout from '../../components/layout/DefaultLayout'
import LogsTable from '../../components/logs/LogsTable'
import PapersTable from '../../components/papers/PapersTable'
import PageTitle from './PageTitle'

const cardStyle = css`
  margin-bottom: 15px;
  .ant-card-body {
    padding: 14px 22px;
  }

  .ant-card-head {
    padding: 0 22px;
  }
`

const homeStyle = css``

const paginationOpts = { pageSize: 5, size: 'small' }

const tableProps = { paginationOpts, defaultFilterExpanded: false }

const Home = () => {
  return (
    <DefaultLayout>
      <section className={homeStyle}>
        <PageTitle title="Homepage" />
        <Card className={cardStyle} title="Ultime Pratiche">
          <PapersTable tableProps={tableProps} />
        </Card>
        <Card className={cardStyle} title="Ultimi log">
          <LogsTable tableProps={tableProps} addPaperColumn={true} />
        </Card>
      </section>
    </DefaultLayout>
  )
}

export default Home
