import httpProvider from '../../../lib/httpProviderInstance'

export const generate = async function (args) {
  const { templateId, ...payload } = args
  await this.GENERATE_TEMPLATE_REQUESTED(args)
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'POST',
      path: `/documentTemplates/${templateId}/generate`,
      data: payload,
    })
    await this.GENERATE_TEMPLATE_FULFILLED(data)
    return data
  } catch (err) {
    await this.GENERATE_TEMPLATE_FAILED(err)
    throw err
  }
}

export const findSuitable = async function (payload) {
  try {
    const { data } = await httpProvider.makeRequest({
      method: 'POST',
      path: `/documentTemplates/findSuitable`,
      data: payload,
    })
    return data
  } catch (err) {
    throw err
  }
}
