import React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange, withHandlers, withState, lifecycle } from 'recompose'
import { Button } from 'antd'
import { select } from '@rematch/select'
import withConnectedForm from '../../form/withConnectedForm'
import Modal from '../Modal/index'
import SmartTable from '../../tables/SmartTable'

const columns = [
  {
    displayName: 'Nome Prodotto',
    field: 'name',
  },
  {
    displayName: 'Instituto finanziario',
    field: 'financialInstitution.name',
  },
  {
    displayName: '',
    render: function (ignore, record, ignored, props) {
      const { closeModal = () => {}, insuranceProductType } = props
      return (
        <Button size="small" onClick={() => closeModal({
          ...record,
          expenseTypeId: insuranceProductType,
        })}>Seleziona</Button>
      )
    },
  },
]

export const ChooseInsuranceModal = (props) => {
  const {
    visible,
    closeModal,
    data,
    findMethod,
    countMethod,
    count,
    query,
  } = props

  return (
    <Modal
      title="Seleziona Prodotto Assicurativo"
      onCancel={() => closeModal()}
      visible={visible}>
      {query && (
        <SmartTable
          data={data}
          columns={columns}
          initialQuery={query}
          count={count}
          countMethod={countMethod}
          findMethod={findMethod}
          closeModal={closeModal}
        />
      )}
    </Modal>
  )
}

export default compose(
  connect(
    (state) => ({
      insuranceProductType: select.productTypes.get(state),
      data: select.products.getAll(state),
      count: select.products.count(state),
    }),
    dispatch => ({
      findProductType: dispatch.productTypes.findOne,
      findMethod: dispatch.products.find,
      countMethod: dispatch.products.count,
    }),
  ),
  withState('query', 'setQuery', null),
  withPropsOnChange(['insuranceProductType'], ({ insuranceProductType, setQuery }) => {
    if (!insuranceProductType) return null
    return setQuery({
      filter: {
        where: { productTypeId: insuranceProductType.id },
        skipDeleted: true,
        include: {
          relation: 'financialInstitution',
          scope: { fields: ['name'] },
        },
      },
    })
  }),
  withConnectedForm('chooseInsuranceModal'),
  withHandlers({
    fetchData: props => () => {
      const {
        findProductType,
      } = props

      return findProductType({
        filter: { where: { name: 'insurance' } },
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      return this.props.fetchData()
    },
  }),
)
(ChooseInsuranceModal)

