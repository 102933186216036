import { createSelector } from 'reselect'
import _ from 'lodash'

export const enabledFeatures = createSelector(
  state => _.get(state, 'accountSettings.protected.enabledFeatures'),
  _.identity,
)

export const hasFeature = createSelector(
  (state, { feature } = {}) => {
    return Array.isArray(feature) ? feature : [feature]
  },
  enabledFeatures,
  (feature, enabledFeatures) => {
    const features = Array.isArray(feature) ? feature : [feature]
    return _.every(features, feature => (enabledFeatures || {})[feature])
  },
)
