import * as effects from './effects'
import * as reducers from './reducers'
import menu from './menuStructure'

export default routes => {
  const initialState = {
    allRoutes: routes,
    menu: menu,
  }
  return {
    state: initialState,
    effects,
    reducers,
  }
}
