import React, { Fragment } from 'react'
import _fp from 'lodash/fp'
import _ from 'lodash'
import { Row, Col, Button } from 'antd'
import TheCurrencyInput from 'src/components/form/CurrencyInput'
import withReduxFormProps from 'src/components/form/withReduxFormProps'
import { default as TheFormItem } from 'src/components/form/FormItem'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
}

const CurrencyInput = withReduxFormProps(TheCurrencyInput)

const FormItem = props => (
  <TheFormItem {...formItemLayout} {...props}>
    {props.children}
  </TheFormItem>
)

const CommissionTabTranches = props => {
  const {
    paper,
    handleRemoveCommissionBtnClick,
    handleAddCommissionBtnClick,
    onMarkAsPaidBtnClick,
    handleChooseAgent,
    formError,
    readOnly,
    isAdmin,
    Components: {
      TrancheAmount,
      AgentSelection,
      CommissionValue,
      CommissionStatus,
      DatePaid,
    },
  } = props

  const paperCommission = _.get(paper, 'paperCommissions[0]', {})
  const paperCommissionIndex = 0
  const payIns = _fp.flow(_fp.filter({ inOrOut: 'in' }))(
    paperCommission.commissions,
  )

  const payOuts = _fp.flow(_fp.filter({ inOrOut: 'out' }))(
    paperCommission.commissions,
  )

  const hasVariableAmount = paper.hasVariableCommissionsBaseAmount

  return (
    <section>
      {hasVariableAmount && (
        <Row>
          <Col xs={21} lg={11}>
            <FormItem
              label={'Importo'}
              name={`paperCommissions[0].amount`}
              style={{ marginBottom: 5 }}
              component={CurrencyInput}
              errors={formError}
            />
          </Col>
        </Row>
      )}

      {isAdmin && (
        <Fragment>
          <h3 className="TitleSection">Pay In &nbsp;</h3>
          {payIns &&
            payIns.length > 0 &&
            isAdmin && (
              <Button
                size="small"
                style={{ marginBottom: 20 }}
                onClick={onMarkAsPaidBtnClick}
              >
                Marca tutte come erogate
              </Button>
            )}
          {payIns.map((commission, index) => {
            const commissionIndex = paperCommission.commissions.indexOf(
              commission,
            )

            return (
              <div className="CommissionsTab-section-commission">
                <Row key={commission.id} type="flex" align="middle" gutter={10}>
                  <Col xs={21} lg={10}>
                    {commission.commissionRule && (
                      <FormItem
                        label="Regola"
                        showErrorLabel={false}
                        component={() => (
                          <h5>{commission.commissionRule.name}</h5>
                        )}
                        style={{ marginBottom: 5 }}
                        errors={formError}
                      />
                    )}
                    <FormItem
                      label="Importo"
                      name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].value`}
                      showErrorLabel={false}
                      component={CommissionValue}
                      style={{ marginBottom: 5 }}
                      errors={formError}
                    />
                    <FormItem
                      label="Stato"
                      name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].status`}
                      showErrorLabel={false}
                      component={CommissionStatus}
                      style={{ marginBottom: 5 }}
                      errors={formError}
                    />
                    {commission.status === 'paid' && (
                      <FormItem
                        label="Data erogazione"
                        name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].datePaid`}
                        showErrorLabel={false}
                        withReduxFormProps={true}
                        component={DatePaid}
                        style={{ marginBottom: 5 }}
                        errors={formError}
                      />
                    )}

                    <FormItem
                      className="ant-form-item--hideLabel"
                      label={' '}
                      component={() => (
                        <Button
                          onClick={handleRemoveCommissionBtnClick(
                            paperCommissionIndex,
                            commissionIndex,
                          )}
                          icon="delete"
                          disabled={readOnly}
                        >
                          Elimina
                        </Button>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            )
          })}
          {(!payIns || !payIns.length) && <p>Nessun pay in salvato</p>}
        </Fragment>
      )}

      <h3 className="TitleSection">Pay Out &nbsp;</h3>
      {payOuts &&
        payOuts.length > 0 &&
        isAdmin && (
          <Button
            size="small"
            style={{ marginBottom: 20 }}
            onClick={onMarkAsPaidBtnClick}
          >
            Marca tutte come erogate
          </Button>
        )}
      {payOuts.map((commission, index) => {
        const commissionIndex = paperCommission.commissions.indexOf(commission)

        return (
          <div className="CommissionsTab-section-commission">
            <Row key={commission.id} type="flex" align="middle" gutter={10}>
              <Col xs={21} lg={10}>
                {commission.commissionRule && (
                  <FormItem
                    label="Regola"
                    showErrorLabel={false}
                    component={() => (
                      <Fragment>
                        <h5>
                          {commission.commissionRule.name}
                          <br />
                          ({[
                            _.get(
                              commission,
                              'commissionRuleLevelValue.group.applicableToRole.displayName',
                            ),
                            _.get(
                              commission,
                              'commissionRuleLevelValue.group.name',
                            ),
                          ].join(' ')})
                        </h5>
                      </Fragment>
                    )}
                    style={{ marginBottom: 5 }}
                    errors={formError}
                  />
                )}
                <FormItem
                  label={
                    _.get(
                      commission,
                      'commissionRuleLevelValue.role.displayName',
                    ) || 'Agente'
                  }
                  style={{ marginBottom: 5 }}
                  component={props => (
                    <div
                      style={{ width: '100%' }}
                      onClick={() =>
                        isAdmin &&
                        handleChooseAgent(paperCommissionIndex, commissionIndex)
                      }
                    >
                      <AgentSelection>
                        {commission.agent &&
                          `${commission.agent.firstname} ${
                            commission.agent.lastname
                          }`}
                      </AgentSelection>
                    </div>
                  )}
                />
                <FormItem
                  label="Importo"
                  name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].value`}
                  showErrorLabel={false}
                  style={{ marginBottom: 5 }}
                  component={CommissionValue}
                  errors={formError}
                />
                <FormItem
                  label="Stato"
                  name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].status`}
                  showErrorLabel={false}
                  component={CommissionStatus}
                  style={{ marginBottom: 5 }}
                  errors={formError}
                />
                {commission.status === 'paid' && (
                  <FormItem
                    label="Data erogazione"
                    name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].datePaid`}
                    showErrorLabel={false}
                    component={DatePaid}
                    style={{ marginBottom: 5 }}
                    errors={formError}
                  />
                )}
                <FormItem
                  className="ant-form-item--hideLabel"
                  label={' '}
                  component={() => (
                    <Button
                      onClick={handleRemoveCommissionBtnClick(
                        paperCommissionIndex,
                        commissionIndex,
                      )}
                      icon="delete"
                      disabled={readOnly}
                    >
                      Elimina
                    </Button>
                  )}
                />
              </Col>
            </Row>
          </div>
        )
      })}
      {(!payOuts || !payOuts.length) && <p>Nessun pay out salvato</p>}
    </section>
  )
}

export default CommissionTabTranches
