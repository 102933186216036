import React from 'react'
import { compose, withProps } from 'recompose'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'
import jqueryDeparam from 'jquery-deparam'
import DefaultLayout from '../../components/layout/DefaultLayout/DefaultLayout'
import PapersTable from '../../components/papers/PapersTable'
import PageTitle from '../../components/layout/PageTitle'

export const PapersList = ({ tableProps }) => {
  return (
    <DefaultLayout>
      <Helmet>
        <title>Pratiche, elenco</title>
      </Helmet>
      <PageTitle title="Lista Pratiche" />
      <div className="u-bgWhite">
        <PapersTable tableProps={tableProps} />
      </div>
    </DefaultLayout>
  )
}

export default compose(
  withRouter,
  withProps(props => {
    return {
      tableProps: {
        defaultFilterExpanded: true,
        canSaveFilters: true,
        listFilterPreferenceId: jqueryDeparam((props.location.search || '').replace(/^(\?)/, ''))
          .listFilterPreferenceId,
      },
    }
  }),
)(PapersList)
