import styled from 'react-emotion'
import { css } from 'emotion'

const Wrapper = styled('div')`
  max-width: 300px;
`

const inputStyle = css`
  text-align: center;
  width: 50% !important;
`
export const yearSelect = {
  Wrapper,
  inputStyle,
}
