import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, lifecycle, defaultProps, withHandlers } from 'recompose'

const ActionLog = props => {
  const { handleClick } = props
  return <span onClick={handleClick}>{props.children}</span>
}

export default compose(
  pure,
  defaultProps({
    trigger: 'mount',
  }),
  connect(null, dispatch => ({ create: dispatch.actionLogs.create })),
  withHandlers({
    sendLog: props => () => {
      const { create, data } = props
      return create(data)
    },
  }),
  withHandlers({
    handleClick: props => () => {
      if (props.trigger === 'click') {
        return props.sendLog()
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.trigger === 'mount') {
        return this.props.sendLog()
      }
    },
  }),
)(ActionLog)
