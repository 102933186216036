import httpProvider from 'src/lib/httpProviderInstance'
import { dispatch } from '@rematch/core'

export const updateRates = async payload => {
  await dispatch.products.UPDATE_RATES_REQUESTED(payload)
  try {
    const { id, data } = payload
    const { data: responseData } = await httpProvider.makeRequest('post', `/products/${id}/updateRates`, {
      data,
    })
    await dispatch.products.UPDATE_RATES_FULFILLED(responseData)
    return data
  } catch (err) {
    await dispatch.products.UPDATE_RATES_FAILED(err)
    throw err
  }
}

export const updatePremiums = async payload => {
  await dispatch.products.UPDATE_PREMIUMS_REQUESTED(payload)
  try {
    const { id, data } = payload
    const { data: responseData } = await httpProvider.makeRequest('post', `/products/${id}/updatePremiums`, {
      data,
    })
    await dispatch.products.UPDATE_PREMIUMS_FULFILLED(responseData)
    return data
  } catch (err) {
    await dispatch.products.UPDATE_PREMIUMS_FAILED(err)
    throw err
  }
}
