import { createSelector } from 'reselect'
import _ from 'lodash'

export const stats = createSelector(
  state => _.get(state, 'stats.data'),
  _.identity,
)

export const isFetchingStats = createSelector(
  state => _.get(state, 'stats.isFetching'),
  _.identity,
)
