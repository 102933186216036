import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, defaultProps } from 'recompose'
import { select } from '@rematch/select'
import Select from '../Select'
import AsyncSelect from '../AsyncSelect'

const FinancialInstitutionSelect = props => {
  const { financialInstitutions = [], find, initialQuery } = props

  return (
    <AsyncSelect
      options={financialInstitutions}
      valueField="id"
      labelField="name"
      fetchMethod={find}
      initialQuery={initialQuery}
      {...props}
    />
  )
}

export default compose(
  connect(
    state => ({
      financialInstitutions: select.financialInstitutions.getAll(state),
    }),
    dispatch => ({
      find: dispatch.financialInstitutions.find,
    }),
  ),
  defaultProps({
    initialQuery: {
      filter: { order: 'name ASC', skipDeleted: true },
    },
  }),
)(FinancialInstitutionSelect)
