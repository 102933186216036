import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { Button } from 'antd'
import { select } from '@rematch/select'
import Modal from '../Modal/index'
import SimpleTable from '../../tables/SimpleTable/SimpleTable'
import style from './ChooseAgentDocumentTypeModal.style'
import Spinner from '../../Spinner'

const columns = [
  {
    displayName: 'Nome',
    field: 'name',
    sortable: true,
    defaultSortOrder: 'ascend',
  },
  {
    displayName: 'Tipo documento',
    field: 'type',
    sortable: true,
    render: type => ({ spreadsheet: 'Foglio di calcolo', file: 'File' })[type] || type,
  },
  {
    displayName: 'Data aggiunta',
    field: 'createdAt',
    type: 'date',
    sortable: true,
  },
  {
    displayName: '',
    render(ignore1, record, ignore2, props) {
      const { closeModal = () => {}, fetchDocumentType } = props
      return (
        <Button
          size="small"
          onClick={async () => {
            const documentType = await fetchDocumentType({ id: record.id })
            return closeModal(documentType)
          }}
        >
          Seleziona
        </Button>
      )
    },
  },
]

export const ChooseAgentModal = props => {
  const { visible, closeModal, fetchData, documentTypes, fetchDocumentType } = props

  return (
    <Modal
      className={style}
      title="Seleziona tipo documento"
      footer={[<Button onClick={() => closeModal()}>Chiudi</Button>]}
      visible={visible}
    >
      <Spinner fetchData={fetchData}>
        <SimpleTable
          closeModal={closeModal}
          data={documentTypes}
          columns={columns}
          fetchDocumentType={fetchDocumentType}
        />
      </Spinner>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      documentTypes: select.agents.documentTypes(state),
    }),
    dispatch => ({
      fetchValidAgentDocumentTypes: dispatch.agents.fetchValidAgentDocumentTypes,
      fetchDocumentType: dispatch.documentTypes.findById,
    }),
  ),
  withHandlers({
    fetchData: props => () => {
      const params = {
        filter: { fields: ['id', 'name', 'type', 'createdAt'] },
      }
      return props.fetchValidAgentDocumentTypes({ id: props.agentId, params })
    },
  }),
)(ChooseAgentModal)
