import _get from 'lodash/get'
import _fp from 'lodash/fp'

export const UPDATE_MENU = (state, { path, content, updateMethod }) => {
  const fullPath = path ? `menu.${path}` : 'menu'

  if (updateMethod === 'merge') {
    const item = _get(state, fullPath)
    return _fp.set(fullPath, Object.assign({}, item, content), state)
  } else if (updateMethod === 'replace') {
    return _fp.set(fullPath, content, state)
  } else if (updateMethod === 'concat') {
    const items = _get(state, fullPath, [])
    return _fp.set(fullPath, items.concat(content), state)
  }

  return state
}
