import React from 'react'
import PropTypes from 'prop-types'
import { pure, compose, withState } from 'recompose'
import { LocaleProvider } from 'antd'
import { ConnectedRouter } from 'react-router-redux'
import { Route } from 'react-router-dom'
import styled from 'react-emotion'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import { dispatch } from '@rematch/core'
import _ from 'lodash'
import itIT from 'antd/lib/locale-provider/it_IT'
import ModalRoot from 'src/components/modals/ModalRoot'
import ComposeEmailRoot from 'src/components/emails/ComposeEmailRoot'
import MobileMenu from 'src/components/navigation/MobileMenu'
import Mobile from '../../components/layout/Responsive/Mobile'
// import routes from '../../routes'
import withWatcher from '../../components/withWatcher'
import withMonitors from '../../components/withMonitors'
import VersionNotification from '../../components/VersionNotification'

const MainWrapper = styled('div')`
  display: flex;
  width: 100vw;
  min-height: 100vh;
`

const App = ({ history, ready, routes }) => (
  <LocaleProvider locale={itIT}>
    <ConnectedRouter history={history}>
      <>
        <MainWrapper>
          {ready &&
            (routes || []).map((route, i) => (
              <Route exact {...route} key={route.name} component={route.component} />
            ))}
          <ModalRoot />
          <ComposeEmailRoot />
          <Mobile>
            <MobileMenu />
          </Mobile>
          <VersionNotification />
        </MainWrapper>
      </>
    </ConnectedRouter>
  </LocaleProvider>
)

App.propTypes = {
  store: PropTypes.object.isRequired,
}

export default compose(
  withMonitors(),
  pure,
  connect(state => ({
    isAuthenticated: select.agents.isAuthenticated(state),
    currentAccountId: select.accounts.currentAccountId(state),
    rehydrated: _.get(state, '_persist.rehydrated'),
    accountSettings: select.accountSettings.get(state),
    isFetchingAccountSettings: select.accountSettings.isFetchingItem(state),
    me: _.get(select.agents.me(state), 'me'),
    isFetchingMe: _.get(select.agents.me(state), 'me.isFetching'),
    accounts: select.accounts.getAll(state),
    routes: state.menu.allRoutes,
  })),
  withState('ready', 'setReady', false),
  withState('accountSettingsHandled', 'setAccountSettingsHandled', false),
  // accountSettings
  withWatcher(
    ['rehydrated', 'accountSettingsHandled', 'accountSettings', 'isFetchingAccountSettings'],
    { immediate: true },
    async ({
      rehydrated,
      accountSettings,
      accountSettingsHandled,
      isFetchingAccountSettings,
      setReady,
      setAccountSettingsHandled,
    }) => {
      if (!rehydrated) return false
      if (accountSettingsHandled) return true

      if (!accountSettings && !isFetchingAccountSettings) {
        await setAccountSettingsHandled(true, dispatch.accountSettings.getMine)
      }
    },
  ),

  // currentAccountId
  withWatcher(
    ['isAuthenticated', 'rehydrated', 'currentAccountId', 'accountSettings'],
    { immediate: true },
    async ({ isAuthenticated, rehydrated, currentAccountId, accountSettings, setReady }) => {
      if (!rehydrated) return false
      if (!isAuthenticated || currentAccountId) return setReady(true)
      if (!currentAccountId && accountSettings) {
        await dispatch.accounts.setCurrent(accountSettings, () => setReady(true))
      }
    },
  ),

  // accounts
  withWatcher(
    ['isAuthenticated', 'rehydrated', 'accounts', 'currentAccountId'],
    { immediate: true },
    async ({ isAuthenticated, rehydrated, accounts, currentAccountId, setReady }) => {
      if (!rehydrated) return false
      if (!isAuthenticated) return setReady(true)
      if (!currentAccountId) return false

      if (!accounts) {
        await dispatch.accounts.find({ filter: { limit: 1000 } })
      }
    },
  ),

  // me
  withWatcher(
    ['isAuthenticated', 'rehydrated', 'currentAccountId', 'me', 'isFetchingMe'],
    { immediate: true },
    async ({ isAuthenticated, rehydrated, currentAccountId, me, isFetchingMe, setReady }) => {
      if (!rehydrated) return false
      if (!isAuthenticated) return setReady(true)
      if (!currentAccountId) return false

      if (!me && !isFetchingMe) {
        await dispatch.agents.fetchMe()
      }
    },
  ),
  pure,
)(App)
