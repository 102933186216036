import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/browser'
import App from './containers/App/App'
import { store, history } from './store'
// import configureMenu from './menu/configureMenu'
import bootScripts from './bootScripts'
import registerServiceWorker from './registerServiceWorker'
import './lib/polyfills'
import './style'

if (process.env.NODE_ENV === 'production') {
  registerServiceWorker({ store })
}

const release = process.env.REACT_APP_VERSION

LogRocket.init(process.env.REACT_APP_LOGROCKET, { release })
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
})

// (async () => {
// configureMenu(store)
bootScripts(store)
const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <PersistGate persistor={getPersistor()}>
          <Component store={store} history={history} />
        </PersistGate>
      </Provider>
    </AppContainer>,
    document.getElementById('root'),
  )
}
render(App)

if (module.hot) {
  module.hot.accept('./containers/App/App', () => {
    render(App)
  })
}
//})()
