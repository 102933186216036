import React from 'react'
import {
  compose,
  withStateHandlers,
  withPropsOnChange,
  withHandlers,
  lifecycle,
} from 'recompose'
import { Collapse, Spin } from 'antd'
import _ from 'lodash'
import BrCollapse from '../layout/BrCollapse'
import Email from './Email'
import EmailHeader from './EmailHeader'
import style from './EmailList.style'
import connect from 'react-redux/es/connect/connect'
import { select } from '@rematch/select'
import withSubscription from '../withSubscription'
import withClearStateOnUnmount from '../withClearStateOnUnmount'

const Panel = Collapse.Panel

const EmailList = props => {
  const {
    activePanels,
    handleActivePanelChange,
    emails = [],
    paper,
    paperId,
    onEmailSent,
    defaultActiveKey,
    handleReplyBtnClick,
    handleDeleteDraftBtnClick,
    isFetchingEmails,
    subject,
    attachmentActions,
  } = props

  return (
    <article className={style}>
      <section className="EmailList-header">
        <h3>{subject}&nbsp;</h3>
      </section>
      <Spin spinning={isFetchingEmails}>
        <BrCollapse
          bordered={false}
          onChange={handleActivePanelChange}
          defaultActiveKey={defaultActiveKey}
        >
          {emails.map((email, index) => (
            <Panel
              className="EmailList-item"
              header={<EmailHeader email={email} activePanels={activePanels}/>}
              key={email.id}
            >
              <section className="EmailList-item-wrapper">
                <Email
                  email={email}
                  paper={paper}
                  paperId={paperId}
                  parentEmailId={email.id}
                  attachmentActions={attachmentActions}
                />
              </section>
            </Panel>
          ))}
        </BrCollapse>
      </Spin>
    </article>
  )
}

export default compose(
  connect(
    state => ({
      emails: select.emails.getAll(state),
      paper: select.papers.get(state),
      isFetchingEmails: select.emails.isFetching(state),
      // isFetchingEmails: state.loading.effects.emails.find,
    }),
    dispatch => ({
      observeEmails: dispatch.emails.observe,
    }),
  ),
  withSubscription,
  withClearStateOnUnmount('emails.emails'),
  withHandlers({
    fetchData: props => async query => {
      const { messageThreadId, addSubscription, unsubscribeAll } = props
      await unsubscribeAll()
      await addSubscription(
        props.observeEmails({
          filter: {
            where: { messageThreadId },
            order: 'date ASC',
            populate: [
              {
                property: 'attachments.fileId',
                model: 'MyFile',
                on: 'file',
              },
              {
                property: 'attachments.fileId',
                model: 'MyFile',
                on: 'file',
              },
            ],
          },
        }),
      )
    },
  }),
  withPropsOnChange(['emails'], ({ emails }) => ({
    lastEmail: (emails || []).slice(-1)[0],
  })),
  withPropsOnChange(['lastEmail'], ({ lastEmail }) => ({
    defaultActiveKey: lastEmail ? [String(lastEmail.id)] : [],
  })),
  withStateHandlers(
    { editorVisibilityHash: {} },
    {
      handleActivePanelChange: props => activePanels => {
        return {
          activePanels,
        }
      },
    },
  ),
  withPropsOnChange(['emails'], ({ emails }) => ({
    subject: _.get(emails, '0.subject'),
  })),
  lifecycle({
    componentWillMount() {
      this.props.fetchData()
    },
    componentWillReceiveProps(nextProps) {
      if (nextProps.messageThreadId !== this.props.messageThreadId) {
        this.props.fetchData()
      }
    },
  }),
)(EmailList)
