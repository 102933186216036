import React from 'react'
import { withProps, defaultProps, withHandlers } from 'recompose'
import { Tabs } from 'antd'
import { push } from 'react-router-redux'
import jqueryParam from 'jquery-param'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'
import withEnhancedRouter from '../../withEnhancedRouter'
import BrTabPane from './BrTabPane'
import style from './BrTabs.style'

export const BrTabs = props => {
  const { isDesktop, isMobile, children, handleChange, ...rest } = props
  const TabsComponent = Tabs
  const tabs = (() => {
    if (Array.isArray(props.children)) return props.children
    if (props.children) return [props.children]
    return []
  })()
    .filter(tab => tab && tab.type && tab.type.prototype instanceof BrTabPane)
    .map(tab => ({ title: tab.props.tab }))

  return (
    <div className={style}>
      <TabsComponent {...rest} tabs={tabs} onChange={handleChange}>
        {children}
      </TabsComponent>
    </div>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      isDesktop: state.env.size.isDesktop,
      isMobile: state.env.size.isMobile,
    }),
    { push },
  ),
  withEnhancedRouter,
  defaultProps({ name: 'tabs' }),
  withProps(({ defaultActiveKey, name, syncWithAddress, match, children }) => {
    return {
      defaultActiveKey: (() => {
        if (defaultActiveKey) return defaultActiveKey
        if (syncWithAddress && match.search[name]) return match.search[name]
        return children[0] && children[0].key
      })(),
    }
  }),
  withHandlers({
    handleChange: props => activeKey => {
      const { syncWithAddress, push, name, onChange } = props
      if (syncWithAddress) {
        push({ search: jqueryParam({ [name]: activeKey }) })
      }
      if (onChange) onChange(activeKey)
    },
  }),
)(BrTabs)
