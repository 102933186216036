import { css } from 'emotion'

export default css`
  .EmailHeader-row {
    flex-wrap: nowrap !important;
    justify-content: space-between;
  }
  .EmailHeader-sender {
    margin: 0 10px;
    font-size: 12px;
    white-space: nowrap;
    &-badge {
      margin-right: 5px;
    }
    &.is-unread {
      .EmailHeader-sender-name {
        font-weight: bold;
      }
    }
  }
  .EmailHeader-contentExtract {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 35px 0 0;
    flex-grow: 1;
    &-body {
      color: gray;
    }
  }
  .EmailHeader-right {
    white-space: nowrap;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    &-date {
      padding-right: 15px;
    }
  }
  .EmailHeader-actions {
    flex-shrink: 1;
  }
  @media (max-width: 767px) {
    .EmailHeader-row {
      flex-wrap: wrap !important;
    }
    .EmailHeader-sender,
    .EmailHeader-contentExtract,
    .EmailHeader-right {
      width: 100%;
    }
  }
`
