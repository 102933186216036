import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle, withPropsOnChange } from 'recompose'
import { Button, List } from 'antd'
import { select } from '@rematch/select'
import { css } from 'emotion'
import _ from 'lodash'
import Promise from 'bluebird'
import Modal from '../Modal/index'
import Spinner from '../../Spinner'
import FormattedDate from '../../format/FormattedDate'
import Currency from '../../format/Currency'
import Duration from '../../format/Duration'
import RateType from '../../format/RateType'

const loadMoreStyle = css`
  padding: 10px 10px;
  text-align: center;
`

const itemStyle = css`
  .ant-list-item-action {
    text-align: center;
    margin: 20px 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 767px) {
      margin: 0 0 0 48px;
      width: auto;
    }
  }
`

const itemDescriptionStyle = css`
  font-size: 12px;
  color: initial;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    li {
      /*flex-basis: 33.3%;*/
      padding: 0 10px;
      /*white-space: nowrap;*/
      margin-bottom: 5px;
    }
  }
`

const LoadMore = props => (
  <div className={loadMoreStyle}>
    <Button onClick={props.onClick}>Carica altri</Button>
  </div>
)

const ItemDescription = ({ item }) => {
  const parts = [
    { prop: 'productType.displayName' },
    {
      prop: 'amount',
      format: () => (
        <>
          <b>Importo:</b> <Currency value={item.amount} />
        </>
      ),
    },
    {
      prop: 'duration',
      format: () => (
        <>
          <b>Durata:</b> <Duration value={item.duration} />
        </>
      ),
    },
    {
      prop: 'rateTypes',
      format: () =>
        item.rateTypes &&
        item.rateTypes.length > 0 && (
          <div>
            <b>Tassi:</b>{' '}
            {_.map(item.rateTypes, (r, index) => (
              <span>
                <RateType value={r} />
                {index + 1 !== item.rateTypes.length && ' | '}
              </span>
            ))}
          </div>
        ),
    },
    {
      prop: 'estate.value',
      format: () => (
        <>
          <b>Valore immobile:</b> <Currency value={item.estate.value} />
        </>
      ),
    },
    {
      prop: 'purposeIds',
      format: () =>
        item.purposes &&
        item.purposes.length > 0 && (
          <>
            <b>Finalitá:</b> {_.map(item.purposes, 'name').join(' | ')}
          </>
        ),
    },
    {
      prop: 'financialInstitutionIds',
      format: () =>
        item.financialInstitutions &&
        item.financialInstitutions.length > 0 && (
          <>
            <b>Istituti:</b> {_.map(item.financialInstitutions, 'name').join(' | ')}
          </>
        ),
    },
    {
      prop: 'expenses',
      format: () =>
        item.expenses &&
        item.expenses.length > 0 && (
          <>
            <b>Spese:</b>{' '}
            {_.map(item.expenses, (e, index) => (
              <span>
                {' '}
                {_.get(e, 'expenseType.displayName', e.description)} <Currency value={e.value} />
                {index + 1 !== item.expenses.length && ' | '}
              </span>
            ))}
          </>
        ),
    },
  ]
  const descriptionItems = parts.reduce((acc, part, index) => {
    const prop = _.get(item, part.prop)
    if (!prop) return acc
    const comp = part.format ? part.format() : prop
    acc.push(<li>{comp}</li>)
    // if (index + 1 !== parts.length) acc.push(<>, </>)

    return acc
  }, [])

  return (
    <div className={itemDescriptionStyle}>
      <div style={{ marginBottom: 10 }}>
        (<FormattedDate date={item.createdAt} format={'LLLL'} />)
      </div>
      <ul>{descriptionItems}</ul>
    </div>
  )
}

export const ChooseQuotesHistoryModal = props => {
  const {
    visible,
    closeModal,
    fetchData,
    quotesHistories,
    handleLoadMoreBtnClick,
    isFetching,
    paginationConfig,
    isLoadMoreVisible,
    handleSelectItemBtnClick,
  } = props

  return (
    <Modal
      width={800}
      title="Seleziona record cronologia"
      footer={[<Button onClick={() => closeModal()}>Chiudi</Button>]}
      onCancel={() => closeModal()}
      visible={visible}
    >
      <Spinner fetchData={fetchData}>
        <List
          loading={isFetching}
          itemLayout="horizontal"
          loadMore={isLoadMoreVisible ? <LoadMore onClick={handleLoadMoreBtnClick} /> : null}
          dataSource={quotesHistories}
          renderItem={item => (
            <List.Item
              className={itemStyle}
              actions={[
                <Button size="small" onClick={() => handleSelectItemBtnClick(item)}>
                  Utilizza
                </Button>,
              ]}
            >
              <List.Item.Meta description={<ItemDescription item={item} />} />
            </List.Item>
          )}
        />
      </Spinner>
    </Modal>
  )
}

export default compose(
  connect(
    state => ({
      quotesHistories: select.quotesHistories.getAll(state),
      quotesHistoriesCount: select.quotesHistories.count(state),
      isFetching: select.quotesHistories.isFetching(state),
    }),
    dispatch => ({
      find: dispatch.quotesHistories.find,
      count: dispatch.quotesHistories.count,
      update: dispatch.quotesHistories.update,
    }),
  ),
  withState('paginationConfig', 'setPaginationConfig', { current: 0, defaultPageSize: 10 }),
  withHandlers({
    fetchData: props => async () => {
      const { paginationConfig, setPaginationConfig } = props
      const nextCurrent = paginationConfig.current + 1
      const isFirst = paginationConfig.current === 0
      const meta = isFirst ? {} : { updateMethod: 'concat' }
      const filter = _.merge(
        {},
        {
          filter: {
            limit: paginationConfig.defaultPageSize,
            skip: (nextCurrent - 1) * paginationConfig.defaultPageSize,
            order: 'lastUsed DESC',
            include: {
              relation: 'productType',
              scope: {
                fields: ['id', 'name'],
              },
            },
            populate: [
              {
                model: 'FinancialInstitution',
                property: 'financialInstitutionIds',
                on: 'financialInstitutions',
                filter: {
                  fields: ['id', 'name'],
                },
              },
              {
                model: 'Purpose',
                property: 'purposeIds',
                on: 'purposes',
                filter: {
                  fields: ['id', 'name'],
                },
              },
              {
                model: 'ExpenseType',
                property: 'expenses.expenseTypeId',
                on: 'expenseType',
                filter: {
                  fields: ['id', 'displayName'],
                },
              },
              {
                property: 'expenses.insuranceId',
                model: 'Product',
                on: 'insurance',
                filter: {
                  fields: ['id', 'name'],
                }
              },
            ],
          },
        },
        { filter: props.filter },
      )

      await Promise.all([props.count(filter.filter), props.find(filter, meta)])

      return setPaginationConfig({ ...paginationConfig, current: nextCurrent })
    },
  }),
  withHandlers({
    handleLoadMoreBtnClick: props => () => {
      return props.fetchData()
    },
    handleSelectItemBtnClick: props => async item => {
      await props.closeModal(item)
      const { id } = item
      return props.update({ id, lastUsed: new Date(), usedCount: (item.usedCount || 0) + 1 })
    },
  }),
  withPropsOnChange(
    ['quotesHistories', 'paginationConfig'],
    ({ quotesHistories = [], paginationConfig }) => {
      return {
        isLoadMoreVisible: paginationConfig.total > quotesHistories.length,
      }
    },
  ),
  lifecycle({
    async componentDidMount() {
      process.nextTick(() => {
        this.props.setPaginationConfig({
          ...this.props.paginationConfig,
          total: this.props.quotesHistoriesCount,
        })
      })
    },
  }),
)(ChooseQuotesHistoryModal)
