import React from 'react'
import Promise from 'bluebird'
import { compose, lifecycle, withStateHandlers, withHandlers } from 'recompose'

const withSubscription = compose(
  withStateHandlers({ subscriptions: [] }, {
    addSubscription: props => (promise) => {
      return {
        subscriptions: [
          ...props.subscriptions,
          promise,
        ],
      }
    },
    setSubscriptions: props => subscriptions => ({ subscriptions }),
  }),
  withHandlers({
    unsubscribeAll: props => async () => {
      await Promise.map(props.subscriptions, async subscriptionPromise => {
        const { subscription } = await subscriptionPromise
        return subscription.unsubscribe()
      })
      props.setSubscriptions([])
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.unsubscribeAll()
    },
  }),
)


export default withSubscription
