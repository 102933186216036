import { pushModalWindow } from 'redux-promising-modals'
import QuoteViewModal from './QuoteViewModal'

export const OPEN_QUOTE_VIEW_MODAL = 'OPEN_QUOTE_VIEW_MODAL'

export const openQuoteViewModal = props => {
  return pushModalWindow(OPEN_QUOTE_VIEW_MODAL, props)
}

export default QuoteViewModal
