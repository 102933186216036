import FinancialInstitutionsList from './FinancialInstitutionsList'
import FinancialInstitutionsEdit from './FinancialInstitutionsEdit'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'financialInstitutions.list',
    path: '/financialInstitutions/list',
    component: withCheckAuthOnEnter(withPageTracker()(FinancialInstitutionsList)),
  },
  {
    name: 'financialInstitutions.view',
    path: '/financialInstitutions/view/:id',
    component: withCheckAuthOnEnter(withPageTracker()(FinancialInstitutionsEdit)),
  },
  {
    name: 'financialInstitutions.add',
    path: '/financialInstitutions/add',
    component: withCheckAuthOnEnter(withPageTracker()(FinancialInstitutionsEdit)),
  },
]
