import React, { Fragment } from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { Row, Col, Button } from 'antd'
import $ from 'jquery'
import _ from 'lodash'
import { cx } from 'emotion'
import FormattedDate from 'src/components/format/FormattedDate'
import Mobile from 'src/components/layout/Responsive/Mobile'
import Desktop from 'src/components/layout/Responsive/Desktop'
import style from './EmailHeader.style'

const EmailHeader = props => {
  const {
    activePanels = [],
    index,
    email = {},
    headerName,
    strippedBody,
    onReplyBtnClick,
    isRead,
    isSent,
  } = props
  const isActive = activePanels.includes(String(email.id))

  return (
    <article className={style}>
      <Row className="EmailHeader-row" type="flex" gutter={8}>
        <Col className={cx('EmailHeader-sender', { 'is-unread': !isRead })}>
          <span className="EmailHeader-sender-name">{isSent && `Inviato a: `}{headerName}</span>
          <br/>
          <Mobile>
            <FormattedDate date={email.date} format={'D MMM YY - hh:mm'}/>
            <Col className="EmailHeader-contentExtract">
              {!isActive && (
                <Fragment>
                  {email.subject && `${email.subject} - `}
                  <span className="EmailHeader-contentExtract-body">
                    {strippedBody}
                  </span>
                </Fragment>
              )}
            </Col>
          </Mobile>
        </Col>
        <Desktop>
          <Fragment>
            <Col className="EmailHeader-contentExtract">
              {!isActive && (
                <Fragment>
                  {email.subject && `${email.subject} - `}
                  <span className="EmailHeader-contentExtract-body">
                    {strippedBody}
                  </span>
                </Fragment>
              )}
            </Col>
            <Col className="EmailHeader-right">
              <div className="EmailHeader-right-date">
                <FormattedDate date={email.date} format={'D MMM YY - hh:mm'}/>
              </div>
            </Col>
          </Fragment>
        </Desktop>
      </Row>
    </article>
  )
}

export default compose(
  withPropsOnChange(['email', 'me'], ({ email, me }) => {
    if (!email || !me) return false
    const from = email.from || {}

    return {
      isRead: !!_.find(email.to, to => to.agentId === me.id && to.read),
      isSent: from.agentId === me.id || from.email === me.email,
    }
  }),

  withPropsOnChange(['email', 'isSent'], ({ email, isSent }) => {
    if (!email || !email.from) return {}
    let headerName = ''
    if (!isSent) {
      headerName = email.from.email
      const agent = email.from.agent
      if (agent) {
        headerName = `${agent.firstname} ${agent.lastname}`
      }
    }

    if (isSent) {
      headerName = _.map(email.to, to => {
        const person = to.agent || to.client
        if (person) {
          headerName = `${person.firstname || ''} ${person.lastname || ''}`
        }

        return to.email
      }).join(', ')
    }

    const strippedBody = $('<div/>')
      .html(email.body)
      .text()

    return { headerName, strippedBody }
  }),
)(EmailHeader)
