import React from 'react'
import { compose, withProps } from 'recompose'
import Select from 'src/components/form/Select'

const LogTypeSelect = props => {
  const { options = [] } = props

  return <Select {...props} options={options} />
}

export default compose(
  withProps({
    options: [
      {
        label: 'Cambi stato',
        value: 'changeProgress',
      },
      {
        label: 'Dettagli pratica',
        value: 'requestDetailsTabForm',
      },
      {
        label: 'Preventivi',
        value: ['syncedQuote', 'addedQuote', 'deletedQuote'],
      },
      {
        label: 'Anagrafiche clienti',
        value: 'clientsTab',
      },
      {
        label: 'Note',
        value: ['addedNote', 'deletedNote'],
      },
      {
        label: 'Documenti',
        value: ['addedDocument', 'deletedDocument'],
      },
    ],
  }),
)(LogTypeSelect)
