import React from 'react'
import Modal from 'src/components/modals/Modal/index'
import { compose, withHandlers } from 'recompose'
import _ from 'lodash'
import { Button } from 'antd'
import style from './SelectReportFieldsModal.style'


const paperPaperFields = [
  { fkey: 'internalId', description: 'ID Pratica' },
  { fkey: '$productType.displayName', description: 'Tipologia prodotto' },
  { fkey: '$product.name', description: 'Scheda prodotto' },
  { fkey: '$financialInstitution.name', description: 'Istituto Finanziario' },
  { fkey: 'institutePaperId', description: 'ID Pratica Istituto' },
  { fkey: 'amount', description: 'Importo Stipulato' },
  { fkey: '$commissions.$trancheAmount', description: 'Importo Tranche' },
  { fkey: 'commissionValue', description: 'Provvigione manuale' },
  { fkey: 'tan', description: 'TAN' },
  { fkey: 'taeg', description: 'TAEG' },
  { fkey: 'duration', description: 'Durata mesi' },
  { fkey: 'instalmentAmount', description: 'Rata' },
  { fkey: 'linkedPaperId', description: 'ID Pratica collegata' },
  { fkey: 'netAmount', description: 'Importo Netto' },
  { fkey: 'insurance.grossPremium', description: 'Premio Lordo' },
  { fkey: 'insurance.netPremium', description: 'Premio Netto' },
  { fkey: 'currentProgress.date', description: 'Data ultimo cambio stato' },
  { fkey: 'currentProgress.progress.displayName', description: 'Stato pratica' },
  { fkey: 'currentProgress.progress.value', description: 'Percentuale stato pratica' },
  { fkey: 'documentsReturn', description: 'Rientro documenti' },
  { fkey: '$channel.name', description: 'Canale' },
  { fkey: 'office.name', description: 'Rete' },
  { fkey: '$expenses.SPESE_MEDIAZIONE', description: 'Spese mediazione' },
  { fkey: '$expenses.SPESE_ISTRUTTORIA', description: 'Spese istruttoria' },

]

const paperClientFields = [
  { fkey: '$clients.$fullName', description: 'Cliente Principale: Cognome Nome' },
  { fkey: 'client.phone', description: 'Cliente Telefono' },
  { fkey: 'client.email', description: 'Cliente Email' },
]

const paperPayInPayOutFields = [
  {
    fkey: '$commissions.$trancheAmount',
    description: 'Importo tranche',
  },
]

const paperAgentsFields = [
  {
    fkey: '$agents.initialAgent.$fullName',
    description: 'Agente Iniziale: Cognome Nome',
  },
  { lineBreak: true },
  {
    fkey: '$agents.agent.$fullName',
    description: 'Agente: Cognome Nome',
  },
  {
    fkey: '$commissions.$payOut.$agentTotal',
    description: 'Agente: Pay Out (Totale)',
  },

  {
    fkey: '$commissions.$payOut.$agentCommissions',
    description: 'Agente: Pay Out Provvigione',
  },

  {
    fkey: '$commissions.$payOut.$agentMediation',
    description: 'Agente: Pay Out Mediazione',
  },

  {
    fkey: '$commissions.$payOut.$agentInquiries',
    description: 'Agente: Pay Out Istruttoria',
  },
  { lineBreak: true },
  {
    fkey: '$agents.groupManager.$fullName',
    description: 'Group Manager: Cognome Nome',
  },
  {
    fkey: '$commissions.$payOut.$groupManagerTotal',
    description: 'Group Manager: Pay Out (Totale)',
  },
  {
    fkey: '$commissions.$payOut.$groupManagerCommissions',
    description: 'Group Manager: Pay Out Provvigione',
  },
  {
    fkey: '$commissions.$payOut.$groupManagerMediation',
    description: 'Group Manager: Pay Out Mediazione',
  },

  {
    fkey: '$commissions.$payOut.$groupManagerInquiries',
    description: 'Group Manager: Pay Out Istruttoria',
  },
  { lineBreak: true },
  {
    fkey: '$agents.generalAgent.$fullName',
    description: 'Agente Generale: Cognome Nome',
  },
  {
    fkey: '$commissions.$payOut.$generalAgentTotal',
    description: 'Agente Generale: Pay Out (Totale)',
  },
  {
    fkey: '$commissions.$payOut.$generalAgentCommissions',
    description: 'Agente Generale: Pay Out Provvigione',
  },
  {
    fkey: '$commissions.$payOut.$generalAgentMediation',
    description: 'Agente Generale: Pay Out Mediazione',
  },

  {
    fkey: '$commissions.$payOut.$generalAgentInquiries',
    description: 'Agente Generale: Pay Out Istruttoria',
  },
]

const paperPayInFields = [
  {
    fkey: '$commissions.$payIn.$total',
    description: 'Pay In (Totale)',
  },
  {
    fkey: '$commissions.$payIn.$commissions',
    description: 'Pay In Provvigioni',
  },
  {
    fkey: '$commissions.$payIn.$inquiries',
    description: 'Pay In - Istruttoria',
  },

  {
    fkey: '$commissions.$payIn.$mediation',
    description: 'Pay In - Mediazione',
  },
]

const renderFields = (fields, ReportFieldComp) => fields.map((field, index) => (
  field.lineBreak
    ? (<li key={index} className="line-break"></li>)
    : <li key={index}><ReportFieldComp {...field}/></li>
))
const PapersFields = ({ onFieldClick, ReportField }) => {
  return (
    <section>
      <h3 className="SelectReportsFieldsModal-title">Pratica</h3>
      <ul className="SelectReportsFieldsModal-fieldsList u-listStyleNone">
        {renderFields(paperPaperFields, ReportField)}
      </ul>
      <h3 className="SelectReportsFieldsModal-title">Cliente</h3>
      <ul className="SelectReportsFieldsModal-fieldsList u-listStyleNone">
        {renderFields(paperClientFields, ReportField)}
      </ul>
      <h3 className="SelectReportsFieldsModal-title">Pay In/Pay Out</h3>
      <ul className="SelectReportsFieldsModal-fieldsList u-listStyleNone">
        {renderFields(paperPayInPayOutFields, ReportField)}
      </ul>
      <h3 className="SelectReportsFieldsModal-title">Pay In</h3>
      <ul className="SelectReportsFieldsModal-fieldsList u-listStyleNone">
        {renderFields(paperPayInFields, ReportField)}
      </ul>
      <h3 className="SelectReportsFieldsModal-title">Agenti/Pay Out</h3>
      <ul className="SelectReportsFieldsModal-fieldsList u-listStyleNone">
        {renderFields(paperAgentsFields, ReportField)}
      </ul>
    </section>
  )
}

const AgentsFields = ({ onFieldClick, ReportField }) => {
  return (
    <section>
      <h3 className="SelectReportsFieldsModal-title">Agenti</h3>
      <ul className="SelectReportsFieldsModal-fieldsList u-listStyleNone">
        <li>
          <ReportField fkey="$commissions.$payOut.$total" description="Pay out"/>
        </li>
        <li>
          <ReportField fkey="$commissions.$payOut.$total" description="Pay out"/>
        </li>
        <li>
          <ReportField fkey="$commissions.$payOut.$inquiries" description="Pay out: Istruttoria"/>
        </li>
        <li>
          <ReportField fkey="$commissions.$payOut.$mediation" description="Pay out: Mediazione"/>
        </li>
      </ul>
    </section>
  )
}

const SelectReportFieldsModal = props => {
  const {
    visible,
    closeModal,
    handleFieldClick,
    report,
  } = props

  const reportType = _.get(report, 'type')
  const ReportField = props => (
    <Button
      icon="plus"
      size="small"
      onClick={() => handleFieldClick(props)}>{props.description}</Button>
  )
  return (
    <Modal
      title="Seleziona Campo Report"
      className={style}
      width="900px"
      onCancel={() => closeModal()}
      visible={visible}
      footer=""
    >
      {reportType === 'papers' && <PapersFields {...{ ReportField }}/>}
      {reportType === 'agents' && <AgentsFields {...{ ReportField }}/>}
    </Modal>
  )
}

export default compose(
  withHandlers({
    handleFieldClick: props => field => {
      props.closeModal(field)
    },
  }),
)(SelectReportFieldsModal)
