import React from 'react'
import {
  compose,
  pure,
  withHandlers,
  lifecycle,
  withStateHandlers,
  withPropsOnChange,
  withProps,
} from 'recompose'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Icon } from 'antd'
import { cx } from 'emotion'
import { bindActionCreators } from 'redux'
import { select } from '@rematch/select'
import { Helmet } from 'react-helmet'
// import { actionsCreators, selectors } from '../duck'
import { BrTabs, BrTabPane } from '../../../components/layout/BrTabs'
import DefaultLayout from '../../../components/layout/DefaultLayout/DefaultLayout'
import PaperViewTop from './PapersViewTop'
import RequestDetailsTab from './Tabs/RequestDetailsTab/RequestDetailsTab'
import PreventivesTab from './Tabs/PreventivesTab/PreventivesTab'
import ClientsTab from './Tabs/ClientsTab/ClientsTab'
import MandatoryDocumentsTab from './Tabs/DocumentsTabs/MandatoryDocumentsTab'
import NotMandatoryDocumentsTab from './Tabs/DocumentsTabs/NotMandatoryDocumentsTab'
import CommissionsTab from './Tabs/CommissionsTab/CommissionsTab'
import EmailsTab from './Tabs/EmailsTab/EmailsTab'
import NotesTab from './Tabs/NotesTab/NotesTab'
import LogsTab from './Tabs/LogsTab/LogsTab'
import SettingsTab from './Tabs/SettingsTab/SettingsTab'
import { openChangePaperProgressModal } from 'src/containers/Papers/ChangePaperProgressModal'
import withClearStateOnUnmount from 'src/components/withClearStateOnUnmount'
import withEnhancedRouter from '../../../components/withEnhancedRouter'
import Spinner from '../../../components/Spinner'
import { formatName } from '../../../components/format/Name'
import ActionLog from '../../../components/actionLogs/ActionLog'
import FeatureFlag from '../../../components/FeatureFlag'

import style from './PapersView.style'

const query = {
  filter: {
    fields: [
      'id',
      'internalId',
      'amount',
      'duration',
      'propertyAmount',
      'productTypeId',
      'client',
      'agents',
      'currentProgress',
      'isLocked',
    ],
    include: ['productType', 'initialAgent'],
    populate: [
      {
        property: 'agents.agentId',
        model: 'Agent',
        on: 'agent',
      },
      {
        property: 'agents.roleId',
        model: 'Role',
        on: 'role',
      },
      {
        property: 'currentProgress.progressId',
        model: 'Progress',
        on: 'progress',
      },
    ],
  },
}

const PaperView = props => {
  const {
    isFetchingItem,
    isFetchingItemFailed,
    paper,
    handleChangeStatusBtnClick,
    fetchData,
    isAdminOrBackoffice,
    features,
    pageTitle,
    paperId,
    hasDocuments,
    hasEmails,
  } = props

  const currentAgentIndex = _.findIndex((paper || {}).agents, {
    role: { name: 'agent' },
  })
  const currentAgent = currentAgentIndex > -1 ? paper.agents[currentAgentIndex].agent : {}

  const className = cx(style, { 'PapersView--404': isFetchingItemFailed })

  const propsToPass = _.omit(props, ['fetchData'])

  return (
    <DefaultLayout className={className}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Spinner fetchData={fetchData}>
        {isFetchingItemFailed && (
          <div className="PapersView-404">
            <div className="PapersView-404-icon">
              <Icon type="exclamation-circle-o" />
            </div>
            <div className="PapersView-404-content">
              Pratica non trovata o permessi non sufficienti
            </div>
          </div>
        )}
        {!isFetchingItemFailed && paper && (
          <div>
            <ActionLog data={{ context: 'paper', paperId, type: 'view' }} />
            <PaperViewTop
              {...propsToPass}
              currentAgent={currentAgent}
              onChangeStatusBtnClick={handleChangeStatusBtnClick}
            />
            <section className="PapersView-main u-container">
              <BrTabs
                className="PapersView-main-tabs"
                syncWithAddress={true}
                destroyInactiveTabPane={true}
              >
                <BrTabPane tab="Dettagli Richiesta" key="details">
                  <RequestDetailsTab {...propsToPass} onPaperUpdate={fetchData} />
                </BrTabPane>
                <BrTabPane tab="Note" key="notes">
                  <NotesTab {...propsToPass} />
                </BrTabPane>
                {(features.hasQuote || features.hasInsurancesSearch) && (
                  <BrTabPane tab="Preventivi" key="quotes">
                    <PreventivesTab {...propsToPass} onPaperUpdate={fetchData} />
                  </BrTabPane>
                )}
                <BrTabPane tab="Clienti" key="clients">
                  <ClientsTab {...propsToPass} onPaperUpdate={fetchData} />
                </BrTabPane>
                {hasDocuments && [
                  <BrTabPane tab="Documenti Obbligatori" key="mandatoryDocuments">
                    <MandatoryDocumentsTab {...propsToPass} onPaperUpdate={fetchData} />
                  </BrTabPane>,
                  <BrTabPane tab="Altri Documenti" key="notMandatoryDocuments">
                    <NotMandatoryDocumentsTab {...propsToPass} onPaperUpdate={fetchData} />
                  </BrTabPane>,
                ]}
                <BrTabPane tab="Provvigioni" key="commissions">
                  <CommissionsTab {...propsToPass} onPaperUpdate={fetchData} />
                </BrTabPane>
                {hasEmails && (
                  <BrTabPane tab="Email" key="emails">
                    <EmailsTab {...propsToPass} onPaperUpdate={fetchData} />
                  </BrTabPane>
                )}
                <BrTabPane tab="Log" key="logs">
                  <LogsTab {...propsToPass} />
                </BrTabPane>
                {isAdminOrBackoffice && (
                  <BrTabPane tab="Amministrazione" key="settings">
                    <SettingsTab {...propsToPass} onPaperUpdate={fetchData} />
                  </BrTabPane>
                )}
              </BrTabs>
            </section>
          </div>
        )}
      </Spinner>
    </DefaultLayout>
  )
}

export default compose(
  pure,
  connect(
    state => ({
      // paper: state.papers.paper,
      paper: select.papers.get(state),
      isFetchingItem: select.papers.isFetchingItem(state),
      isFetchingItemFailed: select.papers.isFetchingItemFailed(state),
      //paper: getFormValues('paperView')(state),
      isAdminOrBackoffice: select.agents.isAdminOrBackoffice(state),
      isAdmin: select.agents.isAdmin(state),
      hasDocuments: select.accountSettings.hasFeature(state, {
        feature: 'hasDocuments',
      }),
      hasEmails: select.accountSettings.hasFeature(state, {
        feature: 'hasEmails',
      }),
    }),
    dispatch => ({
      findPaper: dispatch.papers.findOne,
      openChangePaperProgressModal: bindActionCreators(openChangePaperProgressModal, dispatch),
    }),
  ),
  withStateHandlers(
    { isChangeStatusModalVisible: false },
    {
      setChangeStatusModalVisibility: isChangeStatusModalVisible => (
        isVisible = !isChangeStatusModalVisible,
      ) => {
        return { isChangeStatusModalVisible: isVisible }
      },
    },
  ),
  withHandlers({
    fetchData: props => () => {
      const {
        findPaper,
        match: {
          params: { id },
        },
      } = props

      return findPaper({
        ...query,
        filter: {
          ...query.filter,
          where: { id },
        },
      })
    },
  }),
  withPropsOnChange(['paper'], props => {
    return {
      paperId: _.get(props, 'match.params.id'),
      features: _.get(props.paper, 'productType.features', {}),
    }
  }),
  withPropsOnChange(['paper'], ({ paper }) => {
    if (!paper) return null
    const client = paper.client || {}
    return {
      pageTitle: `${paper.internalId} - ${formatName(client)} - Pratica`,
    }
  }),
  withPropsOnChange(['paper', 'isAdmin'], ({ paper = {}, isAdmin }) => {
    const readOnly = paper && paper.isLocked && !isAdmin
    return {
      formDisabled: readOnly,
      readOnly,
    }
  }),
  withHandlers({
    handleChangeStatusBtnClick: props => async () => {
      const { openChangePaperProgressModal, paper, fetchData } = props
      const hasChanged = await openChangePaperProgressModal({
        paper,
        initialValues: { ...paper.currentProgress, date: new Date(), sendEmail: true },
      })
      if (hasChanged) fetchData()
    },
  }),
  lifecycle({
    componentWillMount() {
      // this.props.fetchData()
    },
    componentWillReceiveProps(nextProps) {
      const currentId = this.props.match.params.id
      const nextId = nextProps.match.params.id
      if (currentId && nextId && currentId !== nextId) {
        this.props.fetchData()
      }
    },
  }),
  withEnhancedRouter,
  withClearStateOnUnmount('papers.tabs'),
  withClearStateOnUnmount('papers.paper'),
)(PaperView)
