import React from 'react'
import { Select as TheSelect } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import {
  compose,
  lifecycle,
  withHandlers,
  setDisplayName,
  withStateHandlers,
} from 'recompose'
import $ from 'jquery'
import { openChooseAgentModal } from 'src/components/modals/ChooseAgentModal/index'
import { select } from '@rematch/select'
import { getPath } from 'src/store/selectors/index'
import Select from 'src/components/form/Select'
import withWatcher from 'src/components/withWatcher'

const Option = TheSelect.Option

const AgentSelect = props => {
  const { handleClick, agents = [], setRef, refs, handleChange } = props

  return (
    <div ref={setRef} onClick={handleClick}>
      <Select
        {...props}
        onChange={handleChange}
        mobileModal={false}
        dropdownStyle={{ display: 'none' }}
      >
        {agents.map(agent => (
          <Option key={agent.id} value={agent.id}>
            {agent.internalId} - {agent.firstname} {agent.lastname}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default compose(
  setDisplayName('AgentSelect'),
  connect(
    state => ({
      agents: getPath(state)('agents.agentSelect.agents'),
    }),
    dispatch => ({
      find: dispatch.agents.find,
      openChooseAgentModal: bindActionCreators(openChooseAgentModal, dispatch),
    }),
  ),
  withStateHandlers(() => {}, {
    setRef: () => ref => ({
      refs: {
        main: ref,
      },
    }),
  }),
  withHandlers({
    blur: props => $target => {
      const { refs } = props
      setTimeout(() => {
        if ($target) {
          $target.blur()
          $target.find('*').blur()
        }
        $(refs.main)
          .find('*')
          .blur()
      })
    },
  }),
  withHandlers({
    fetchData: props => () => {
      if (
        !props.value ||
        (Array.isArray(props.value) && _.isEmpty(props.value))
      ) {
        return false
      }

      const where = Array.isArray(props.value)
        ? { id: { inq: props.value } }
        : { id: { inq: [props.value] } }

      props.find(
        {
          filter: {
            where,
            fields: ['id', 'internalId', 'firstname', 'lastname'],
            order: 'lastname ASC',
          },
        },
        { basePath: 'agentSelect' },
      )
    },
    handleClick: props => async e => {
      const $target = $(e.target)
      props.blur()
      if (
        !$target.is(
          '.ant-select-selection__rendered, .ant-select-selection,.ant-select-search__field',
        )
      ) {
        return false
      }
      const { openChooseAgentModal, agents, value, onChange } = props

      return openChooseAgentModal().then(agent => {
        if (agent) {
          let theValue = agent.id
          if (['multiple', 'tags'].includes(props.mode)) {
            theValue = _.uniq([...(value || []), agent.id])
          }
          onChange(theValue)
        }
      })
    },
    handleChange: props => (...args) => {
      props.blur()
      if (props.onChange) return props.onChange(...args)
    },
  }),
  lifecycle({
    componentWillMount() {
      this.props.fetchData()
    },
  }),
  withWatcher('value', ({ fetchData }) => fetchData()),
)(AgentSelect)
