import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withPropsOnChange, defaultProps } from 'recompose'
import { Button, Divider, Form, InputNumber, Row, Col } from 'antd'
import { Element, scroller } from 'react-scroll'
import withConnectedForm from '../../form/withConnectedForm'
import FormItem from '../../form/FormItem'
import PurposesSelect from '../../form/PurposesSelect'
import FinancialInstitutionSelect from '../../form/FinancialInstitutionSelect'
import SimpleTable from '../../tables/SimpleTable/SimpleTable'
import SubmitButton from '../../form/SubmitButton'
import CurrencyInput from '../../form/CurrencyInput'
import Duration from '../../form/Duration'
import { openChooseQuotesHistoryModal } from '../../modals/ChooseQuotesHistoryModal'

const columns = [
  {
    displayName: 'Istituto finanziario',
    field: 'product.financialInstitution.name',
  },
  {
    displayName: 'Nome Prodotto',
    field: 'product.name',
  },
  {
    displayName: 'Premio Lordo',
    field: 'insurance.grossPremium',
    type: 'currency',
  },
  {
    displayName: 'Premio Netto',
    field: 'insurance.netPremium',
    type: 'currency',
  },
]

export const InsuranceQuoteForm = props => {
  const {
    closeModal,
    formResult,
    formError,
    handleSubmit,
    submitting,
    columns,
    productType,
    isQuotesHistoryVisible,
    handleOpenHistoryModalBtnClick,
  } = props

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={12}>
            {isQuotesHistoryVisible && (
              <FormItem
                label=" "
                className="ant-form-item--hideLabel"
                layout="horizontal"
                component={() => (
                  <Button onClick={handleOpenHistoryModalBtnClick}>
                    Cronologia ricerca preventivi
                  </Button>
                )}
              />
            )}
            <FormItem
              label="Durata"
              name="duration"
              component={Duration}
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Finalitá"
              name="purposeIds"
              component={PurposesSelect}
              basePath={'insuranceQuote'}
              initialQuery={{
                filter: {
                  order: 'name ASC',
                  skipDeleted: true,
                  where: { productTypeId: productType.id },
                },
              }}
              withReduxFormProps={true}
              mode="multiple"
              allowClear={true}
              layout="horizontal"
              errors={formError}
            />
          </Col>
          <Col xs={24} sm={12}>
            <FormItem
              label="Importo"
              name="amount"
              component={CurrencyInput}
              withReduxFormProps={true}
              layout="horizontal"
              errors={formError}
            />
            <FormItem
              label="Istituto finanziario"
              name="financialInstitutionIds"
              component={FinancialInstitutionSelect}
              withReduxFormProps={true}
              mode="multiple"
              allowClear={true}
              layout="horizontal"
              errors={formError}
            />
          </Col>
        </Row>
        <section className="FormActions">
          <SubmitButton submitting={submitting}>Cerca</SubmitButton>
        </section>
      </form>
      <Divider />
      <Element name="quoteResults">
        <SimpleTable closeModal={closeModal} data={formResult} columns={columns} />
      </Element>
    </div>
  )
}

export default compose(
  connect(
    null,
    dispatch => ({
      searchInsurancePreventives: dispatch.quotes.searchInsurancePreventives,
    }),
  ),
  connect(
    () => {},
    {
      openChooseQuotesHistoryModal,
    },
  ),
  withConnectedForm('insuranceForm'),
  defaultProps({
    isQuotesHistoryVisible: true,
  }),
  withPropsOnChange(['actions'], ({ actions }) => {
    const theColumns = [...columns]
    if (actions)
      theColumns.push({
        displayName: '',
        render: function(ignore, record, ignored, props) {
          return actions({ ...props, record })
        },
      })

    return { columns: theColumns }
  }),
  withHandlers({
    fetchData: props => () => {
      const { searchInsurancePreventives, formValues = {}, productType } = props

      return searchInsurancePreventives({
        ...formValues,
        productTypeId: productType.id,
      })
    },
  }),
  withHandlers({
    handleSubmit: props => async e => {
      e && e.preventDefault()
      const { dispatchSubmit } = props
      await dispatchSubmit(props.fetchData(props))
      process.nextTick(() => {
        scroller.scrollTo('quoteResults', {
          smooth: true,
          duration: 500,
          containerId: 'DefaultLayout-content',
        })
      })
    },
    handleOpenHistoryModalBtnClick: props => async () => {
      const { productType } = props
      const data = await props.openChooseQuotesHistoryModal({
        filter: { where: { productTypeId: productType.id } },
      })
      if (data) {
        return props.initialize({ ...data })
      }
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { handleSubmit, fetchOnMount, initialize, initialValues } = this.props
      initialize(initialValues)
      if (fetchOnMount) return process.nextTick(handleSubmit)
    },
  }),
)(InsuranceQuoteForm)
