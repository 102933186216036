import React, { Fragment } from 'react'
import { compose, withHandlers } from 'recompose'
import { Button, Dropdown, Menu, message, Icon } from 'antd'
import { connect } from 'react-redux'
import MoveIntoDocumentsModal from './attachmentActions/MoveIntoDocumentsModal'
import withModalControls from 'src/components/modals/withModalControls'

const AttachmentActions = props => {
  const { handleMoveIntoDocumentsClick, moveIntoDocumentsModal } = props
  const menu = (
    <Menu>
      <Menu.Item>
        <div onClick={handleMoveIntoDocumentsClick}>
          <Icon type="copy" /> Copia in documento pratica
        </div>
      </Menu.Item>
    </Menu>
  )
  return (
    <Fragment>
      <Dropdown overlay={menu} trigger={['click']}>
        <Button icon="setting" size="small" shape="circle" />
      </Dropdown>
      <MoveIntoDocumentsModal {...moveIntoDocumentsModal} />
    </Fragment>
  )
}

export default compose(
  connect(null, dispatch => ({
    uploadDocument: dispatch.documents.uploadDocument,
  })),
  withModalControls('moveIntoDocumentsModal'),
  withHandlers({
    handleMoveIntoDocumentsClick: props => async e => {
      const {
        attachment,
        moveIntoDocumentsModal,
        uploadDocument,
        paperId,
      } = props
      const documentType = await moveIntoDocumentsModal.show()
      if (documentType) {
        await uploadDocument({
          fileId: attachment.fileId,
          document: {
            name: attachment.file.name,
            paperId,
            documentTypeId: documentType.id,
          },
        })

        message.success(`Allegato copiato su: "${documentType.name}"`)
      }
    },
  }),
)(AttachmentActions)
