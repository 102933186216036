import { css } from 'emotion'

export default css`
  .EmailList-header {
    padding: 0 15px;
  }
  .EmailList-item {
    &.ant-collapse-item-active {
      .ant-collapse-header {
        padding-bottom: 0;
      }
      .ant-collapse-content {
        // margin-top: -10px;
      }
    }
    &-wrapper {
      padding: 0 0 0 33px;
      @media (max-width: 767px) {
        padding: 0 0 0 7px;
      }
    }
  }
`
