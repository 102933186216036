import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Input, Icon, Row, Col } from 'antd'
import style from './Filters.style'

const SearchFilter = ({ filter, value, onChange }) => {
  const [localValue, setLocalValue] = useState(value)
  const handleChange = useCallback(e => {
    setLocalValue(e.target.value)
  }, [])
  const onChangeDebounced = useCallback(_.debounce(onChange, 500), [])
  useEffect(
    () => {
      if (value !== localValue) onChangeDebounced(localValue)
    },
    [localValue],
  )
  useEffect(
    () => {
      setLocalValue(value)
    },
    [value],
  )
  return (
    <div>
      <Input
        autofocus
        value={localValue}
        onChange={handleChange}
        suffix={<Icon type="search" />}
        allowClear={true}
      />
    </div>
  )
}

const filtersSwitch = {
  search: props => <SearchFilter {...props} />,
}

const Filter = props => {
  const { filter } = props
  const FilterComponent = filtersSwitch[filter.type] || filter.component

  if (FilterComponent)
    return (
      <div>
        <label>{filter.label}</label>
        <FilterComponent {...props} />
      </div>
    )
  return null
}

export const Filters = props => {
  const { filters, values, onChange } = props
  if (!filters) return null

  const handleChange = useCallback(
    filter => e => {
      const value = e && e.target ? e.target.value : e
      onChange({ filter, value })
    },
    [],
  )

  return (
    <div className={style}>
      {/*values: {JSON.stringify(values)}
      hasFilterSelected: {JSON.stringify(hasFilterSelected)}*/}
      <Row type="flex">
        {filters.map((filter, index) => {
          return (
            <Col key={index} className="Filters-col">
              <Filter filter={filter} value={values[filter.key]} onChange={handleChange(filter)} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

Filters.defaultProps = {
  values: [],
  onChange: () => {},
}

export default Filters
