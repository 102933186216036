import AddClientToPaperModal from './AddClientToPaperModal'
import { openAddClientToPaperModal, closeAddClientToPaperModal } from './actions'
import { OPEN_ADD_CLIENT_TO_PAPER_MODAL } from './actionTypes'

export {
  openAddClientToPaperModal,
  closeAddClientToPaperModal,
  OPEN_ADD_CLIENT_TO_PAPER_MODAL,
}
export default AddClientToPaperModal
