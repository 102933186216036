import React from 'react'
import { Button } from 'antd'
import { getContext, compose, withPropsOnChange } from 'recompose'
import PropTypes from 'prop-types'

export const ResetButton = props => {
  const { children, reset, isPristine } = props

  const content = children || 'Resetta'
  return (
    <Button
      htmlType="reset"
      size="large"
      disabled={isPristine}
      {...props}
      onClick={reset}
    >
      {content}
    </Button>
  )
}

export default compose(
  getContext({
    submitting: Boolean,
    dirty: Boolean,
    reset: PropTypes.func,
    isPristine: PropTypes.bool,
  }),
)(ResetButton)
