import React from 'react'
import { compose, pure, withHandlers } from 'recompose'

const withReduxFormProps = (
  Component,
  cProps,
  transformInput = input => input,
) => {
  const WrappedComponent = compose(
    pure,
    withHandlers({
      onChange: props => e => {
        if (e && e.persist) {
          e.persist()
        }
        const val = typeof e === 'undefined' ? null : e
        // console.log('withReduxFormProps onChange val', val)
        // console.log(Component, props, e)

        if (!props.isArray) {
          props.onChange(val)
        }
      },
    }),
  )(Component)

  const Comp = field => {
    const additionalProps = {}
    // for checkbox
    additionalProps.checked = field.input && field.input.value

    // for select
    if (
      ['multiple', 'tags'].includes(field.mode) &&
      !Array.isArray(field.input.value)
    ) {
      field.input.value = []
    }

    const transformedInputProps = transformInput(field.input)
    return (
      <WrappedComponent
        {...field}
        {...transformedInputProps}
        {...cProps}
        {...additionalProps}
      />
    )
  }

  return compose(pure)(Comp)
}

export default withReduxFormProps
