import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, pure, withHandlers } from 'recompose'
import { select } from '@rematch/select'
import { push } from 'react-router-redux'

const withCheckAuthOnEnter = Component => {
  return compose(
    connect(
      state => ({
        isAuthenticated: select.agents.isAuthenticated(state),
        me: select.agents.me(state),
      }),
      dispatch => ({
        fetchMe: dispatch.agents.fetchMe,
        redirectToLogin: () => dispatch(push('/login')),
      }),
    ),
    withHandlers({
      checkAuth: props => async () => {
        const { isAuthenticated, redirectToLogin } = props
        if (!isAuthenticated) {
          return redirectToLogin()
        }
      },
    }),
    withHandlers({
      handleEnter: props => () => {
        return props.checkAuth()
      },
    }),
    lifecycle({
      componentWillMount() {
        this.props.handleEnter()
      },
    }),
  )(Component)
}

export default withCheckAuthOnEnter
