import createModel from 'src/lib/createModel'
import httpProvider from 'src/lib/httpProviderInstance'

export default () => {
  return createModel({
    name: 'quotesHistory',
    pluralName: 'quotesHistories',
    httpPath: 'quotesHistory',
    provider: httpProvider,
  })
}
