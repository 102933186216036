import React from 'react'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { select } from '@rematch/select'
import { Button, List, Divider, Modal, message } from 'antd'
import _ from 'lodash'
import withConnectedForm from '../../../../../components/form/withConnectedForm'
import AddNoteModal from './AddNoteModal'
import withModalControls from '../../../../../components/modals/withModalControls'
import style from './NotesTab.style'
import Spinner from 'src/components/Spinner'
import FormattedDate from 'src/components/format/FormattedDate'

export const NotesTab = props => {
  const {
    fetchData,
    notes,
    addNoteModal,
    handleAddNoteBtnClick,
    handleDeleteNoteBtnClick,
    canDeleteNote,
    paperId,
  } = props

  return (
    <section className={style}>
      <Spinner fetchData={fetchData}>
        <Button icon="plus" size="small" onClick={handleAddNoteBtnClick}>
          Aggiungi nota
        </Button>
        <Divider />
        <List
          itemLayout="horizontal"
          dataSource={notes}
          renderItem={(note, index) => (
            <List.Item
              actions={[
                canDeleteNote(note) && (
                  <Button
                    shape="circle"
                    icon="delete"
                    onClick={() =>
                      handleDeleteNoteBtnClick({
                        note,
                        index,
                      })
                    }
                  />
                ),
              ]}
            >
              <List.Item.Meta
                title={
                  <span>
                    <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                      ID: {note.internalId} (<FormattedDate
                        date={note.createdAt}
                      />)
                    </span>
                    <br />
                    {note.title}
                  </span>
                }
                description={note.body}
              />
            </List.Item>
          )}
        />
      </Spinner>
      <AddNoteModal {...addNoteModal} paperId={paperId} />
    </section>
  )
}

const getOpts = {
  basePath: 'tabs.notes',
}
export default compose(
  connect(
    state => ({
      notes: select.notes.getAll(state, getOpts),
      isAdmin: select.agents.isAdmin(state),
      me: _.get(select.agents.me(state), 'me'),
    }),
    dispatch => ({
      find: dispatch.notes.find,
      create: dispatch.notes.create,
      deleteById: dispatch.notes.delete,
      createLog: dispatch.actionLogs.create,
    }),
  ),
  withConnectedForm('notesTab'),
  withModalControls('addNoteModal'),
  withHandlers({
    fetchData: props => () => {
      const { find, paperId } = props

      return find(
        {
          filter: {
            where: { paperId },
            order: 'createdAt ASC',
            skipDeleted: true,
          },
        },
        { basePath: 'tabs.notes' },
      )
    },
  }),
  withHandlers({
    handleAddNoteBtnClick: props => async e => {
      const { addNoteModal, fetchData } = props
      await addNoteModal.show()
      return fetchData()
    },
    handleDeleteNoteBtnClick: props => ({ note, index }) => {
      const { paperId, deleteById, createLog } = props
      Modal.confirm({
        title: 'Elimina nota',
        content: 'Sei sicuro?',
        onOk: async () => {
          await deleteById({ id: note.id })
          await createLog({
            context: 'paper',
            paperId,
            type: 'deletedNote',
            data: {
              noteId: note.id,
            },
          })
          message.success('Nota eliminata')
          return props.fetchData()
        },
      })
    },
    canDeleteNote: props => note => {
      const { isAdmin, me, paper = {} } = props
      const { id } = me

      return isAdmin || (!paper.isLocked && note.agentId === id)
    },
  }),
)(NotesTab)
