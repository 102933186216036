import uuid from 'uuid/v4'
import assocPath from 'ramda/es/assocPath'
/*
 * Inbox threaded emails
 */
export const OBSERVE_THREADED_EMAILS_REQUESTED = (state, payload) => ({
  ...state,
  isFetchingThreadedEmails: true,
})

export const OBSERVE_THREADED_EMAILS_RECEIVED = (state, { results, count }) => ({
  ...state,
  threadedEmails: results,
  count,
  isFetchingThreadedEmails: false,
})

export const FETCH_EMAILS_PAPER_CATEGORIES_REQUESTED = (state, payload) => ({
  ...state,
})

export const FETCH_EMAILS_PAPER_CATEGORIES_FULFILLED = (state, payload) => ({
  ...state,
  paperCategories: payload,
})

export const NEW_COMPOSE_EMAIL = (state, { isVisible, initialData }) => ({
  ...state,
  composeEmails: [
    ...state.composeEmails,
    {
      id: uuid(),
      isVisible,
      initialData,
    },
  ],
})

export const HIDE_COMPOSE_EMAIL = (state, composeEmail) => ({
  ...state,
  composeEmails: (state.composeEmails || []).map(ce => {
    if (composeEmail.id === ce.id) {
      return { ...composeEmail, isVisible: false }
    }

    return ce
  }),
})
