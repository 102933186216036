import React from 'react'
import { compose, lifecycle } from 'recompose'
import ReactGA from 'react-ga'
import _ from 'lodash'


const withPageTracker = opts => WrappedComponent => {
  const options = _.merge(
    {
      trackPage: true,
    },
    opts,
  )
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    })

    return ReactGA.pageview(page)
  }

  return compose(
    lifecycle({
      componentDidMount() {
        if (options.trackPage) {
          const page = this.props.location.pathname
          return trackPage(page)
        }
      },
    }),
  )(WrappedComponent)
}

export default withPageTracker
