import Login from './Login/Login'
import ResetPassword from './ResetPassword'
import ForgotPassword from './ForgotPassword'
import AccountSwitcher from './AccountSwitcher'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'auth.login',
    path: '/login',
    component: withPageTracker()(Login),
  },
  {
    name: 'auth.resetPassword',
    path: '/resetPassword/:token',
    component: withPageTracker()(ResetPassword),
  },
  {
    name: 'auth.forgotPassword',
    path: '/forgotPassword',
    component: withPageTracker()(ForgotPassword),
  },
  {
    name: 'auth.accountSwitcher',
    path: '/accountSwitcher',
    component: withCheckAuthOnEnter(withPageTracker()(AccountSwitcher)),
  },
]
