import React from 'react'
import { compose, withHandlers, lifecycle, withPropsOnChange } from 'recompose'
import { css } from 'emotion'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import DefaultLayout from 'src/components/layout/DefaultLayout'
import InboxLeftPanel from './InboxLeftPanel'
import ComposeEmail from 'src/components/emails/ComposeEmail'

const className = css({
  '& .DefaultLayout-content-inner': {
    flexGrow: '1 !important',
  },
})

export const InboxLayout = ({ paperCategories, children }) => {
  return (
    <DefaultLayout
      className={className}
      leftPanel={<InboxLeftPanel paperCategories={paperCategories}/>}>
      {children}
    </DefaultLayout>
  )
}

export default compose()(InboxLayout)
