import React from 'react'
import { compose, pure } from 'recompose'
import { css } from 'emotion'
import { PageHeader } from 'antd'

const style = css`
  margin: -10px -15px 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  /*padding: 10px 24px;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);*/
`

const PageTitle = ({ children, ...rest }) => {
  return (
    <PageHeader className={style} {...rest}>
      {children}
    </PageHeader>
  )
}

export default compose(pure)(PageTitle)
