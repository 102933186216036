import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { Form, Field, reduxForm, getFormValues } from 'redux-form'
import { Input, Select, DatePicker, Checkbox, Button, Radio } from 'antd'
import withFormProps from '../../form/withReduxFormProps'
import { openChooseClientModal } from '../../clients/ChooseClientModal/index'
import { closeAddClientToPaperModal as closeModal } from './actions'
import { default as TheFormItem } from '../../form/FormItem/index'
import SubmitButton from '../../form/SubmitButton/index'
import OverlayComponent from '../../Overlay/index'
import Modal from '../Modal/index'
import { openEditClientModal } from '../../clients/EditClientModal/index'

const Option = Select.Option
const RadioGroup = Radio.Group


const ClientSelect = (props) => {
  const { children } = props
  return (
    <OverlayComponent
      style={{ width: '100%', display: 'inline-block' }}
      {...props}>
      <Select value="0">
        <Option key="0">
          {children}
        </Option>
      </Select>
    </OverlayComponent>
  )
}

const ClientTypeRadio = withFormProps(props => (
  <RadioGroup {...props}>
    <Radio value="garante">Garante</Radio>
    <Radio value="intestatario">Intestatario</Radio>
  </RadioGroup>
))


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 14,
      offset: 8,
    },
  },
}

const FormItem = (props) => (
  <TheFormItem {...formItemLayout} {...props}>{props.children}</TheFormItem>
)


export const AddClientToPaperModal = (props) => {
  const {
    visible,
    closeModal,
    handleSelectClientClick,
    paperClient,
    isFormValid,
    handleSubmit,
    handleAddClientBtnClick,
  } = props

  return (
    <Modal
      title="Aggiungi anagrafica cliente"
      onCancel={() => closeModal()}
      footer={<SubmitButton disabled={!isFormValid} onClick={handleSubmit}>Salva</SubmitButton>}
      visible={visible}>
      <form onSubmit={handleSubmit}>
        <FormItem
          label="Cliente"
          childrenAsFunction={true}>
          {(props) => (
            <div>
              <div onClick={handleSelectClientClick}>
                <ClientSelect>
                  {(() => {
                    const client = (paperClient || {}).client
                    if (client) {
                      return `${client.firstname} ${client.lastname}`
                    }

                    return 'Seleziona'
                  })()}
                </ClientSelect>
              </div>
              <Button size="small" icon="plus" onClick={handleAddClientBtnClick}>Nuovo</Button>
            </div>
          )}
        </FormItem>
        <FormItem
          label="Tipo"
          name="type"
          component={ClientTypeRadio}
          childrenAsFunction={true}>
          {(props) => (
            <Field {...props}/>
          )}
        </FormItem>
        <div style={{ textAlign: 'right' }}>

        </div>
      </form>
    </Modal>
  )
}

export default compose(
  Component => props => {
    return <Component {...props}/>
  },
  reduxForm({
    form: 'addClientToPaper',
    enableReinitialize: true,
  }),
  connect(
    state => ({
      paperClient: getFormValues('addClientToPaper')(state),
    }),
    { openChooseClientModal, openAddClientModal: openEditClientModal, closeModal },
  ),
  withPropsOnChange(['paperClient'], ({ paperClient }) => {
    return {
      isFormValid: paperClient && paperClient.clientId && paperClient.type,
    }
  }),
  withHandlers({
    handleSubmit: props => e => {
      e.preventDefault()
      props.closeModal(props.paperClient)
    },
    handleSelectClientClick: props => async e => {
      const { openChooseClientModal, change } = props
      const client = await openChooseClientModal()
      if (client) {
        change('clientId', client.id)
        change('client', client)
      }
    },
    handleAddClientBtnClick: props => async e => {
      const { change } = props
      const client = await props.openAddClientModal()
      if (client) {
        change('clientId', client.id)
        change('client', client)
      }
    },
  }),
)(AddClientToPaperModal)

