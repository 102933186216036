import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import formResult from './formResult'
import { mergeReducers } from 'src/lib/reducersUtils'
import clearStateAtPath from './clearStateAtPath'
import { modalsReducer } from 'redux-promising-modals'

export const reducers = {
  modals: modalsReducer,
  form: formReducer,
  routing: routerReducer,
  formResult,
}

const rootReducer = combineReducers(reducers)

export default mergeReducers(rootReducer, clearStateAtPath)
