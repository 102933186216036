import Browser from './Browser'
import withCheckAuthOnEnter from '../../components/withCheckAuthOnEnter'
import withPageTracker from '../../components/withPageTracker'

export const routes = [
  {
    name: 'browse',
    path: '/browse/:pathId?',
    component: withCheckAuthOnEnter(withPageTracker()(Browser)),
  },
]
