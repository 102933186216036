import React, { Fragment } from 'react'
import _fp from 'lodash/fp'
import { Collapse, Row, Col, Button, Input } from 'antd'
import { css } from 'emotion'
import { default as TheFormItem } from 'src/components/form/FormItem'
import withReduxFormProps from '../../../../../components/form/withReduxFormProps'
import TheCurrencyInput from '../../../../../components/form/CurrencyInput'
import _ from 'lodash'

const Panel = Collapse.Panel
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
}
const customPanelHeaderStyle = css`
  & {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding: 0 10px;
    .CommissionTabPanel-header-column {
    }
  }
`

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
}

const CurrencyInput = withReduxFormProps(TheCurrencyInput)

const FormItem = props => (
  <TheFormItem {...formItemLayout} {...props}>
    {props.children}
  </TheFormItem>
)

const CommissionTabTranches = props => {
  const {
    paper,
    handleRemoveCommissionBtnClick,
    handleAddCommissionBtnClick,
    handleChooseAgent,
    formError,
    onDeleteTrancheBtnClick,
    onMarkAsPaidBtnClick,
    isAdmin,
    Components: {
      TrancheAmount,
      AgentSelection,
      CommissionValue,
      CommissionStatus,
      DatePaid,
    },
  } = props

  const paperCommissions = (paper || {}).paperCommissions || []
  return (
    <Collapse bordered={false}>
      {paperCommissions.map((paperCommission, paperCommissionIndex) => {
        const payIns = _fp.flow(_fp.filter({ inOrOut: 'in' }))(
          paperCommission.commissions,
        )

        const payOuts = _fp.flow(_fp.filter({ inOrOut: 'out' }))(
          paperCommission.commissions,
        )

        let header = (
          <div className={customPanelHeaderStyle}>
            <div className="CommissionTabPanel-header-column">
              {paperCommission.description ||
                `Tranche n. ${paperCommissionIndex + 1}`}
              &nbsp;&nbsp;<b>({payIns.length + payOuts.length})</b>
            </div>
            <div className="CommissionTabPanel-header-column">
              <Button
                icon="delete"
                size="small"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  return onDeleteTrancheBtnClick(
                    paperCommission,
                    paperCommissionIndex,
                  )
                }}
              >
                Elimina
              </Button>
            </div>
          </div>
        )

        return (
          <Panel
            header={header}
            key={paperCommissionIndex}
            isActive={true}
            style={customPanelStyle}
          >
            <Row>
              <Col xs={21} lg={11}>
                <FormItem
                  label={'Importo Tranche'}
                  name={`paperCommissions[${paperCommissionIndex}].amount`}
                  style={{ marginBottom: 5 }}
                  component={CurrencyInput}
                  errors={formError}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={21} lg={11}>
                <FormItem
                  label={'Descrizione'}
                  name={`paperCommissions[${paperCommissionIndex}].description`}
                  style={{ marginBottom: 5 }}
                  component={Input}
                  errors={formError}
                />
              </Col>
            </Row>

            {isAdmin && (
              <Fragment>
                <h3 className="TitleSection">
                  Pay In &nbsp;
                  <Button
                    onClick={handleAddCommissionBtnClick(paperCommissionIndex, {
                      hasTranches: true,
                    })}
                    shape="circle"
                    icon="plus"
                    size="small"
                    style={{ marginBottom: '10px' }}
                  />
                </h3>
                {payIns &&
                  payIns.length > 0 && (
                    <Button
                      size="small"
                      style={{ marginBottom: 20 }}
                      onClick={() => onMarkAsPaidBtnClick(paperCommissionIndex)}
                    >
                      Marca tutte come erogate
                    </Button>
                  )}
                {payIns.map(commission => {
                  const commissionIndex = paperCommission.commissions.indexOf(
                    commission,
                  )

                  return (
                    <div className="CommissionsTab-section-commission">
                      <Row
                        key={commission.id}
                        type="flex"
                        align="middle"
                        gutter={10}
                      >
                        <Col xs={21} lg={10}>
                          {commission.commissionRule && (
                            <FormItem
                              label="Regola"
                              showErrorLabel={false}
                              component={() => (
                                <h5>{commission.commissionRule.name}</h5>
                              )}
                              style={{ marginBottom: 5 }}
                              errors={formError}
                            />
                          )}
                          <FormItem
                            label="Importo"
                            name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].value`}
                            showErrorLabel={false}
                            component={CommissionValue}
                            style={{ marginBottom: 5 }}
                            errors={formError}
                          />
                          <FormItem
                            label="Stato"
                            name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].status`}
                            showErrorLabel={false}
                            component={CommissionStatus}
                            style={{ marginBottom: 5 }}
                            errors={formError}
                          />
                          {commission.status === 'paid' && (
                            <FormItem
                              label="Data erogazione"
                              name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].datePaid`}
                              showErrorLabel={false}
                              component={DatePaid}
                              style={{ marginBottom: 5 }}
                              errors={formError}
                            />
                          )}
                          <FormItem
                            label=" "
                            className="ant-form-item--hideLabel"
                            component={() => (
                              <Button
                                onClick={handleRemoveCommissionBtnClick(
                                  paperCommissionIndex,
                                  commissionIndex,
                                )}
                                icon="delete"
                              >
                                Elimina
                              </Button>
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                  )
                })}
                {(!payIns || !payIns.length) && <p>Nessun pay in salvato</p>}
              </Fragment>
            )}

            <h3 className="TitleSection">
              Pay Out &nbsp;
              <Button
                onClick={handleAddCommissionBtnClick(paperCommissionIndex, {
                  hasTranches: true,
                })}
                shape="circle"
                icon="plus"
                size="small"
                style={{ marginBottom: '10px' }}
              />
            </h3>
            {payOuts &&
              payOuts.length > 0 && (
                <Button
                  size="small"
                  style={{ marginBottom: 20 }}
                  onClick={() => onMarkAsPaidBtnClick(paperCommissionIndex)}
                >
                  Marca tutte come erogate
                </Button>
              )}
            {payOuts.map(commission => {
              const commissionIndex = paperCommission.commissions.indexOf(
                commission,
              )

              return (
                <div className="CommissionsTab-section-commission">
                  <Row
                    key={commission.id}
                    type="flex"
                    align="middle"
                    gutter={10}
                  >
                    <Col xs={21} lg={10}>
                      {commission.commissionRule && (
                        <FormItem
                          label="Regola"
                          showErrorLabel={false}
                          component={() => (
                            <Fragment>
                              <h5>
                                {commission.commissionRule.name}
                                <br />
                                ({[
                                  _.get(
                                    commission,
                                    'commissionRuleLevelValue.group.applicableToRole.displayName',
                                  ),
                                  _.get(
                                    commission,
                                    'commissionRuleLevelValue.group.name',
                                  ),
                                ].join(' ')})
                              </h5>
                            </Fragment>
                          )}
                          style={{ marginBottom: 5 }}
                          errors={formError}
                        />
                      )}
                      <FormItem
                        label={
                          _.get(
                            commission,
                            'commissionRuleLevelValue.role.displayName',
                          ) || 'Agente'
                        }
                        style={{ marginBottom: 5 }}
                        component={props => (
                          <div
                            style={{ width: '100%' }}
                            onClick={() =>
                              handleChooseAgent(
                                paperCommissionIndex,
                                commissionIndex,
                              )
                            }
                          >
                            <AgentSelection>
                              {commission.agent &&
                                `${commission.agent.firstname} ${
                                  commission.agent.lastname
                                }`}
                            </AgentSelection>
                          </div>
                        )}
                      />
                      <FormItem
                        label="Importo"
                        name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].value`}
                        showErrorLabel={false}
                        style={{ marginBottom: 5 }}
                        component={CommissionValue}
                        errors={formError}
                      />
                      <FormItem
                        label="Stato"
                        name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].status`}
                        showErrorLabel={false}
                        component={CommissionStatus}
                        style={{ marginBottom: 5 }}
                        errors={formError}
                      />
                      {commission.status === 'paid' && (
                        <FormItem
                          label="Data erogazione"
                          name={`paperCommissions[${paperCommissionIndex}].commissions[${commissionIndex}].datePaid`}
                          showErrorLabel={false}
                          component={DatePaid}
                          style={{ marginBottom: 5 }}
                          errors={formError}
                        />
                      )}
                      <FormItem
                        label=" "
                        className="ant-form-item--hideLabel"
                        component={() => (
                          <Button
                            onClick={handleRemoveCommissionBtnClick(
                              paperCommissionIndex,
                              commissionIndex,
                            )}
                            icon="delete"
                          >
                            Elimina
                          </Button>
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              )
            })}
            {(!payOuts || !payOuts.length) && <p>Nessun pay out salvato</p>}
          </Panel>
        )
      })}
    </Collapse>
  )
}

export default CommissionTabTranches
