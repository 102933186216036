import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { clearStateAtPath } from 'src/store/actions'

const withClearStateOnUnmount = (paths) => {
  return compose(
    connect(null, { clearStateAtPath }),
    lifecycle({
      componentWillUnmount() {
        const allPaths = Array.isArray(paths) ? paths : [paths]
        return allPaths.map(path => this.props.clearStateAtPath(path))
      },
    }),
  )
}

export default withClearStateOnUnmount
