import React from 'react'
import { connect } from 'react-redux'
import { compose, pure, withProps, withContext } from 'recompose'
import {
  getFormValues,
  getFormInitialValues,
  reduxForm,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
} from 'redux-form'
import PropTypes from 'prop-types'
import { startSubmit } from '../../store/actions/index'
import { getFormResult, getFormErrors } from '../../store/selectors/index'

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const formResult = getFormResult()
    const formError = getFormErrors()
    const formValues = getFormValues(props.form || props.formName)
    const initialValues = getFormInitialValues(props.form || props.formName)
    const _isDirty = isDirty(props.form || props.formName)
    const _isPristine = isPristine(props.form || props.formName)
    const _isValid = isValid(props.form || props.formName)
    const _isInvalid = isInvalid(props.form || props.formName)

    return {
      formValues: formValues(state),
      pristineValues: initialValues(state),
      formResult: formResult(state, props),
      formError: formError(state, props),
      isDirty: _isDirty(state, props),
      isPristine: _isPristine(state, props),
      isValid: _isValid(state, props),
      isInvalid: _isInvalid(state, props),
    }
  }
  return mapStateToProps
}

const withConnectedForm = (formName, options) =>
  compose(
    pure,
    withProps({ formName }),
    connect(makeMapStateToProps, {
      dispatchSubmit: (formValues, meta) =>
        startSubmit(formName, formValues, meta),
    }),
    reduxForm({
      // a unique name for the form
      form: formName,
      enableReinitialize: true,
      ...options,
    }),
    withContext(
      {
        submitting: PropTypes.bool,
        readOnly: PropTypes.bool,
        dirty: PropTypes.bool,
        reset: PropTypes.func,
        formError: PropTypes.object,
        formDisabled: PropTypes.bool,
        pristineValues: PropTypes.object,
        isDirty: PropTypes.bool,
        isPristine: PropTypes.bool,
        isValid: PropTypes.bool,
        isInvalid: PropTypes.bool,
      },
      ({
        submitting,
        readOnly,
        dirty,
        reset,
        formError,
        formDisabled,
        pristineValues,
        isDirty,
        isPristine,
        isValid,
        isInvalid,
      }) => ({
        submitting,
        readOnly,
        dirty,
        reset,
        formError,
        formDisabled,
        pristineValues,
        isDirty,
        isPristine,
        isValid,
        isInvalid,
      }),
    ),
  )

export default withConnectedForm
