import { css } from 'emotion'

export default css`
  & {
    .EmailThreadList-actions {
      min-height: 50px;
      button {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
    .EmailThreadList-item {
      &.ant-collapse-item-active {
        .ant-collapse-header {
          padding-bottom: 0;
        }
        .ant-collapse-content {
          // margin-top: -10px;
        }
      }
      &-wrapper {
        padding: 0 0 0 33px;
        @media (max-width: 767px) {
          padding: 0 0 0 7px;
        }
      }
    }
    table {
      table-layout: fixed;
    }
  }
`
