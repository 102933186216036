import React from 'react'
import { connect } from 'react-redux'
import { compose, withProps, defaultProps } from 'recompose'
import { select } from '@rematch/select'
import AsyncSelect from '../AsyncSelect'

const ProductSelect = props => {
  const { products = [], find, initialQuery, labelFieldFn } = props

  return (
    <AsyncSelect
      {...props}
      options={products}
      fetchMethod={find}
      initialQuery={initialQuery}
      labelField={labelFieldFn}
      valueField="id"
    />
  )
}

export default compose(
  connect(
    state => ({
      products: select.products.getAll(state),
    }),
    dispatch => ({
      find: dispatch.products.find,
    }),
  ),
  withProps({
    labelFieldFn: product => {
      return `${product.internalId} - ${product.name}`
    },
  }),
  defaultProps({
    initialQuery: {
      filter: {
        fields: ['id', 'internalId', 'name', 'deletedAt'],
        order: 'name ASC',
        skipDeleted: true,
      },
    },
  }),
)(ProductSelect)
